<template lang="html">
  <div class="CRUDGenerico__Wrapper" v-if="canAccessPage">
    <v-row>
      <v-col cols="12" sm="3" v-if="!somenteLeitura">
        <concorrente-form
          ref="concorrenteForm"
          @FORMULARIO_CADASTRO_CONCORRENTE_SALVAR="onConcorrenteSalva"
        />
      </v-col>
      <v-col cols="12" :sm="somenteLeitura ? 12 : 9">
        <concorrente-lista
          ref="concorrenteTabela"
          class="CRUDGenerico__ContentTable"
          :title="$tc('label.concorrente', 2)"
          @PESQUISA_CONCORRENTE_EDITAR="onEditarConcorrente"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '../../common/functions/roles-computed-generator';
import ConcorrenteForm from './ConcorrenteForm';
import ConcorrenteLista from './ConcorrenteLista';

export default {
  name: 'ConcorrenteCadastro',
  data() {
    return {
    };
  },
  components: {
    ConcorrenteForm,
    ConcorrenteLista,
  },
  computed: {
    ...generateComputed('concorrente', [
      'canAccessCRUD',
      'canAccessPage',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    onEditarConcorrente(concorrente) {
      this.$refs.concorrenteForm.editar(concorrente);
    },
    onConcorrenteSalva() {
      this.$refs.concorrenteTabela.filtrar();
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
<style scoped>
.CRUDGenerico__ContentTable  {
  width: 100%;
}
</style>
