<template>
  <v-card style="margin-bottom:1em" class="RelatorioDetalhamentoNdFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.detalhamento_nd', 1) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          v-if="camposFormulario !== null"
          :ordenacao-campos="ordenacaoCamposTenant"
          :campos-formulario="camposFormulario"
          :explode-hierarquia="true"
          :formulario-filtros="true"
          :ignora-inativos="false"
          :ignora-obrigatorios="umFiltroObrigatorioSelecionado"
          :objeto="filtrosDetalhamentoNd"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '@/produto/common/functions/loading';
import { mapGetters } from 'vuex';
import { countRelatorioDetalhamentoNd } from '@/common/resources/magalu-relatorios'; // eslint-disable-line
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoDetalhamentoNdFiltrosCampos from './RelatorioExportacaoDetalhamentoNdFiltrosCampos';

export default {
  name: 'RelatorioDetalhamentoNd',
  mixins: [
    RelatorioExportacaoDetalhamentoNdFiltrosCampos,
  ],
  watch: {
    filtrosDetalhamentoNd: {
      handler() {
        this.umFiltroObrigatorioSelecionado = false;

        Object.values(this.filtrosDetalhamentoNd).every((value) => {
          if (value instanceof Array) {
            if (value && value.length) {
              this.umFiltroObrigatorioSelecionado = true;
              return false;
            }
          } else if (value) {
            this.umFiltroObrigatorioSelecionado = true;
            return false;
          }
          return true;
        });
        this.$refs.form.resetValidation();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
    ordenacaoCamposTenant() {
      if (this.isAmbienteKabum) {
        return this.ordenacaoCamposKabum;
      }
      if (this.isAmbienteEpoca) {
        return this.ordenacaoCamposEpoca;
      }
      return this.ordenacaoCampos;
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      ordenacaoCampos: [
        'numero',
        'fornecedor',
        'status_integracao',
        'status_nd',
        'descricao_passo',
        'tipo_acao',
        'cod_acao',
        'cod_apuracao',
        'divisao',
        'modalidade',
        'data_inicio_vencimento',
        'data_fim_vencimento',
        'origem',
        'data_inicio_contabilizacao',
        'data_fim_contabilizacao',
        'unidadenegocio',
        'data_inicio_vigencia',
        'data_fim_vigencia',
        'data_inicio_criacao_nd',
        'data_fim_criacao_nd',
        'codigo_campanha',
        'codigo_ads_trade',
        'valor_ads',
        'cod_fornecedor_acao',
        'ocultar_apuracoes_canceladas',
        'ocultar_nds_zeradas',
      ],
      ordenacaoCamposKabum: [
        'numero',
        'fornecedor',
        'cod_acao',
        'tipo_acao',
        'data_inicio_vencimento',
        'data_fim_vencimento',
        'divisao',
        'data_inicio_contabilizacao',
        'data_fim_contabilizacao',
        'descricao_passo',
        'data_inicio_vigencia',
        'data_fim_vigencia',
        'cod_fornecedor_acao',
      ],
      ordenacaoCamposEpoca: [
        'numero_nd',
        'numero_nf',
        'cod_anexo_comercial',
        'cod_acao',
        'grupo_fornecedor',
        'status_nd_nf',
        'origem',
        'tipo_acao',
        'status_integracao',
        'data_inicio_vencimento',
        'data_fim_vencimento',
        'descricao_passo',
        'data_inicio_contabilizacao',
        'data_fim_contabilizacao',
        'cod_fornecedor_acao',
        'data_inicio_vigencia',
        'data_fim_vigencia',
        'ocultar_apuracoes_canceladas',
        'ocultar_nds_zeradas',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosDetalhamentoNd: {},
      umFiltroObrigatorioSelecionado: false,
      limiteRegistrosExportacao: 50000,
      camposFormulario: null,
      filtrosValor: 0,
      filtrosData: 0,
      filtrosChave: 0,
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroValido() {
      // valida se algum filtro foi selecionado
      return this.umFiltroObrigatorioSelecionado;
    },
    resetaFiltros() {
      this.filtrosDetalhamentoNd = {
        ocultar_apuracoes_canceladas: true,
        ocultar_nds_zeradas: true,
      };
    },
    aplicarFiltros() {
      const filtrosAplicados = this.$refs.container.getValoresCamposPadrao();

      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (filtrosAplicados.origem !== null && typeof filtrosAplicados.origem !== 'undefined') {
        if (filtrosAplicados.origem === 'CONTRATO') {
          filtrosAplicados.contrato = true;
        } else {
          filtrosAplicados.acao = true;
        }
      }

      if (filtrosAplicados.cod_acao) {
        filtrosAplicados.id_acao = filtrosAplicados.cod_acao;
      }
      if (filtrosAplicados.cod_apuracao) {
        filtrosAplicados.id_apuracao = filtrosAplicados.cod_apuracao;
      }
      if (filtrosAplicados.cod_fornecedor_acao === '') {
        filtrosAplicados.cod_fornecedor_acao = null;
      }
      if (filtrosAplicados.id_grupo_fornecedor) {
        [filtrosAplicados.id_grupo_fornecedor_selecionado] = filtrosAplicados.id_grupo_fornecedor;
      }
      if (filtrosAplicados.cod_anexo_comercial) {
        filtrosAplicados.id_anexo_comercial = filtrosAplicados.cod_anexo_comercial;
      }
      const params = {
        ...filtrosAplicados,
      };
      if (!params.ocultar_apuracoes_canceladas) {
        delete params.ocultar_apuracoes_canceladas;
      }
      if (!params.ocultar_nds_zeradas) {
        delete params.ocultar_nds_zeradas;
      }

      this.filtrosValor = 0;
      this.filtrosData = 0;
      this.filtrosChave = 0;
      Object.entries(filtrosAplicados).forEach(([key, value]) => {
        if ((`${key}`) === 'data_inicio_contabilizacao' || (`${key}`) === 'data_fim_contabilizacao' || (`${key}`) === 'data_inicio_vencimento' || (`${key}`) === 'data_fim_vencimento' || (`${key}`) === 'data_inicio_vigencia' || (`${key}`) === 'data_fim_vigencia' || (`${key}`) === 'data_inicio_criacao_nd' || (`${key}`) === 'data_fim_criacao_nd') {
          this.filtrosData += 1;
        } else if ((`${key}`) === 'numero' || (`${key}`) === 'cod_acao' || (`${key}`) === 'cod_apuracao' || (`${key}`) === 'codigo_ads_trade' || (`${key}`) === 'codigo_campanha' || (`${key}`) === 'codigo_campanha' || (`${key}`) === 'valor_ads') {
          if ((`${value}`) !== 'null' && (`${value}`) !== '') {
            this.filtrosChave += 1;
          }
        } else if ((`${key}`) !== 'numero' && (`${key}`) !== 'cod_acao' && (`${key}`) !== 'id_acao' && (`${key}`) !== 'cod_apuracao' && (`${key}`) !== 'id_apuracao' && (`${key}`) !== 'codigo_ads_trade' && (`${key}`) !== 'codigo_campanha' && (`${key}`) !== 'codigo_campanha' && (`${key}`) !== 'valor_ads' && (`${key}`) !== 'ocultar_apuracoes_canceladas' && (`${key}`) !== 'ocultar_nds_zeradas') {
          if ((`${value}`) !== 'null' && (`${value}`) !== '') {
            this.filtrosValor += 1;
          }
        }
      });
      if (this.filtrosValor > 0 && this.filtrosData === 0) {
        this.$toast(this.$t('message.selecione_um_filtro_data'));
        return;
      }
      if (this.filtrosValor === 0 && this.filtrosData === 0 && this.filtrosChave === 0) {
        this.$toast(this.$t('message.selecione_um_filtro_obrigatorio'));
        return;
      }

      let quantidadeRegistros = 0;

      countRelatorioDetalhamentoNd(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > this.limiteRegistrosExportacao) {
            this.$toast(this.$t('errors.registros.excede_limite_dados', { limite: this.limiteRegistrosExportacao }));
          } else if (quantidadeRegistros > 0) {
            let nomeRelatorio = '';
            if (this.isAmbienteKabum) {
              nomeRelatorio = 'detalhamento_nd_kabum';
            } else if (this.isAmbienteEpoca) {
              nomeRelatorio = 'detalhamento_nd_epoca';
            } else {
              nomeRelatorio = 'detalhamento_nd_magalu';
            }
            this.$emit('RelatorioExportacaoDetalhamentoNd__AplicaFiltros',
              params,
              nomeRelatorio,
              'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio_vencimento || filtrosAplicados.data_fim_vencimento) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio_vencimento, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim_vencimento, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio_vencimento && !filtrosAplicados.data_fim_vencimento)
          || (!filtrosAplicados.data_inicio_vencimento && filtrosAplicados.data_fim_vencimento)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
      }
      if (filtrosAplicados.data_inicio_contabilizacao || filtrosAplicados.data_fim_contabilizacao) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio_contabilizacao, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim_contabilizacao, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio_contabilizacao
          && !filtrosAplicados.data_fim_contabilizacao)
          || (!filtrosAplicados.data_inicio_contabilizacao
          && filtrosAplicados.data_fim_contabilizacao)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
      }
      if (filtrosAplicados.data_inicio_vigencia || filtrosAplicados.data_fim_vigencia) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio_vigencia, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim_vigencia, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio_vigencia && !filtrosAplicados.data_fim_vigencia)
          || (!filtrosAplicados.data_inicio_vigencia && filtrosAplicados.data_fim_vigencia)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
      }
      if (filtrosAplicados.data_inicio_criacao_nd || filtrosAplicados.data_fim_criacao_nd) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio_criacao_nd, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim_criacao_nd, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio_criacao_nd && !filtrosAplicados.data_fim_criacao_nd)
          || (!filtrosAplicados.data_inicio_criacao_nd && filtrosAplicados.data_fim_criacao_nd)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
      }
      return true;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-detalhamento-nd';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-detalhamento-nd';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosDetalhamentoNd;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
  mounted() {
    if (this.isAmbienteKabum) {
      this.camposFormulario = this.camposFormularioKabum;
    } else if (this.isAmbienteEpoca) {
      this.camposFormulario = this.camposFormularioEpoca;
    } else {
      this.camposFormulario = this.camposFormularioMagalu;
    }
  },
};
</script>
<style>
  .RelatorioDetalhamentoNdFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioDetalhamentoNdFiltros__Opcoes div.v-input__slot,
  .RelatorioDetalhamentoNdFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioDetalhamentoNdFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioDetalhamentoNdFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
