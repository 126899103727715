import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'dicionario');

const dicionarioActions = {
  ...genericActions(basePath),
  implantacao: { method: 'GET', url: `${basePath}/implantacao` },
};

export default (resource) => resource(`${basePath}`, {}, dicionarioActions);
