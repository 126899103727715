import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'configuracao-campos-customizados');

const camposCustomizadosActions = {
  ...genericActions(basePath),
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&{&entidades*}` },
  listarPorEntidadeOrcamento: { method: 'GET', url: `${basePath}/entidade?entidade=ORCAMENTO` },
  listarPorEntidadeAcao: { method: 'GET', url: `${basePath}/entidade?entidade=ACAO` },
  listarPorEntidadeContrato: { method: 'GET', url: `${basePath}/entidade?entidade=CONTRATO` },
  listarPorEntidadePagamento: { method: 'GET', url: `${basePath}/entidade?entidade=PAGAMENTO` },
  listarPorEntidadeAcaoTodosProcessos: { method: 'GET', url: `${basePath}/entidade/todos-processos?entidade=ACAO` },
  listarCamposADS: { method: 'GET', url: `${basePath}/campos-ads` },
};

export default (resource) => resource(`${basePath}`, {}, camposCustomizadosActions);
