import vanillaMasker from 'vanilla-masker';

export const accessObjectViaString = (object, access) => {
  if (!access || access.length < 1) {
    return object;
  }
  let attribute = object;
  access.split('.').forEach((el) => {
    const value = attribute[el];
    attribute = value || el;
  });
  if ((attribute && !Number.isNaN(attribute))
    || (attribute && attribute.length)
    || attribute instanceof Function) {
    return attribute;
  }
  return access;
};

export const capitalize = (str) => (str ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '');

export const camelCase = (str) => {
  const string = str.toLowerCase().replace(/[^A-Za-z0-9]/g, ' ').split(' ')
    .reduce((result, word) => result + capitalize(word.toLowerCase()));
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const calculateMenuHeight = (listSize,
  height = 48,
  gap = 75,
  maxItems = 4,
  maxSize = 275) => {
  if (listSize) {
    const size = (listSize > maxItems)
      ? maxSize
      : (height * listSize) + gap;
    return size < 120 ? 120 : size;
  }
  return 'auto';
};

export const componentExpandableSize = (listSize) => {
  const size = (listSize !== 'auto')
    ? listSize
    : 'auto';
  return `${size}${Number.isNaN(size) ? '' : 'px'}`;
};

export const copyObject = (object) => JSON.parse(JSON.stringify(object));

export const getClosestVueParent = (parent, cssClass) => {
  if (!parent || !parent.$el) {
    return false;
  }

  if (parent._uid === 0) { // eslint-disable-line no-underscore-dangle
    return false;
  }

  if (parent.$el.classList && parent.$el.classList.contains(cssClass)) {
    return parent;
  }

  return getClosestVueParent(parent.$parent, cssClass);
};

export const getClosestThemedParent = ($parent) => {
  if (!$parent || !$parent.$el || $parent._uid === 0) { // eslint-disable-line
    return false;
  }

  if ($parent.mdTheme || $parent.mdName) {
    return $parent;
  }

  return getClosestThemedParent($parent.$parent);
};

export const nestedObjectToArray = (nested) => {
  if (!nested) {
    return [];
  }
  return Object.keys(nested).map((el) => nested[el]);
};

export const removeItem = (array, item) => {
  if (array && item && array.hasOwnProperty('length') && array.length) {
    const index = (Number.isNaN(Number(item)))
      ? array.indexOf(item)
      : item;
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  return array;
};

export const convertCnpjString = (cnpj) => cnpj.replace(/[./-]/g, '');

export const convertToCnpj = (cnpj) => cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

export const convertToCpf = (cpf) => cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

export const convertMoneytoFloat = (str) => {
  const money = String(str)
    .replace(/[^\d,-]/g, '')
    .replace(',', '.');
  return Number(money.length ? money : '0');
};

export const convertFloatToMoney = (str) => {
  const t1 = String(str).split('.');
  let t2 = '';
  if (t1[1]) {
    t2 = t1[1].length === 1 ? `${t1[1]}0` : t1[1];
  } else {
    t2 = '00';
  }
  return `${t1[0]}.${t2}`;
};

export const convertFloatToMoneyComma = (str) => {
  const t1 = String(str).split('.');
  let t2 = '';
  if (t1[1]) {
    t2 = t1[1].length === 1 ? `${t1[1]}0` : t1[1];
  } else {
    t2 = '00';
  }
  return `${t1[0]},${t2}`;
};

function getValueFixed(vlrModelo, vlrDefault, decimalPlaces, symbol, onRight) {
  if (vlrModelo === null || vlrModelo === undefined) {
    if (vlrDefault === undefined) return '-';
    vlrModelo = vlrDefault;
  }
  const vlrArredondado = vlrModelo.toFixed ? vlrModelo.toFixed(decimalPlaces) : vlrModelo;
  let vlrFormatado = convertFloatToMoney(vlrArredondado);
  vlrFormatado = vlrFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const money = vlrFormatado.split('.');
  const ready = onRight ? `${money[0].replace(/,/g, '.')},${money[1]}${symbol}`
    : `${symbol} ${money[0].replace(/,/g, '.')},${money[1]}`;
  return ready;
}

export const getMoney = (vlrModelo, vlrDefault, decimalPlaces = 2, currency = 'R$') => getValueFixed(vlrModelo, vlrDefault, decimalPlaces, currency);

export const getPercent = (vlrModelo, vlrDefault, decimalPlaces = 2) => getValueFixed(vlrModelo, vlrDefault, decimalPlaces, '%', true);

export const getNumber = (vlrModelo, vlrDefault, decimalPlaces = 2) => getValueFixed(vlrModelo, vlrDefault, decimalPlaces, '');

export const prepareResponseData = (response) => {
  if (response.data.hasOwnProperty('resposta')) {
    return response.data.resposta;
  }
  return response.data;
};

export const toColumnName = (n) => {
  const ordA = 'a'.charCodeAt(0);
  const ordZ = 'z'.charCodeAt(0);
  const len = (ordZ - ordA) + 1;

  let s = '';
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    n = Math.floor(n / len) - 1;
  }
  return s;
};

export const sumValues = (a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2);

export const subtractValues = (a, b) => (parseFloat(a) - parseFloat(b)).toFixed(2);

export const convertToMoney = (value) => {
  if (!value) {
    return 'R$ 0,00';
  }
  const valor = convertFloatToMoney(value);
  if (value < 0) {
    return `R$ -${vanillaMasker.toMoney(valor)}`;
  }
  return `R$ ${vanillaMasker.toMoney(valor)}`;
};

export const convertToPercent = (value) => {
  if (!value) {
    return '0,00 %';
  }
  const valor = convertFloatToMoney(value);
  if (value < 0) {
    return `-${vanillaMasker.toMoney(valor)} %`;
  }
  return `${vanillaMasker.toMoney(valor)} %`;
};

export const convertToNumberFormat = (value) => {
  if (!value) {
    return '0';
  }
  const valor = convertFloatToMoney(value);
  if (value < 0) {
    return `-${vanillaMasker.toMoney(valor)}`;
  }
  return `${vanillaMasker.toMoney(valor)}`;
};

export const convertToIntegerFormat = (value) => {
  if (!value) {
    return '0';
  }
  return Number(value).toLocaleString();
};

function isEmpty(obj) {
  if (Array.isArray(obj) && !obj.length) {
    return true;
  }
  if (obj instanceof Object && !Object.keys(obj).length) {
    return true;
  }
  if (obj === null || obj === undefined) {
    return true;
  }
  return false;
}

function replacer(key, value) {
  if (isEmpty(value)) {
    return undefined;
  }
  return value;
}

export const removeEmptyProperties = (obj) => {
  if (isEmpty(obj)) {
    return obj;
  }
  const objParseado = JSON.parse(JSON.stringify(obj, replacer));
  const jsonString = Object.assign(objParseado, objParseado.extensoes);
  delete jsonString.extensoes;
  return jsonString;
};

export const isAllEmpty = (obj) => {
  obj = removeEmptyProperties(obj);
  return isEmpty(obj);
};

export const isEqual = (obj1, obj2, ignoreKeys = []) => {
  const obj1Keys = Object.keys(removeEmptyProperties(obj1));
  const obj2Keys = Object.keys(removeEmptyProperties(obj2));

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  let valid = true;

  obj1Keys.forEach((objKey) => {
    if (ignoreKeys.includes(objKey)) {
      return;
    }
    if (obj1[objKey] instanceof Object) {
      Object.keys(obj1[objKey]).forEach((key) => {
        if (ignoreKeys.includes(key)) {
          return;
        }
        if (obj1[objKey][key] !== obj2[objKey][key]) {
          valid = false;
        }
      });
    } else if (obj1[objKey] !== obj2[objKey]) {
      valid = false;
    }
  });

  return valid;
};

export const removerItensDuplicadosPorId = (listaDuplicados) => {
  const idsUnicos = new Set();
  return listaDuplicados.filter((item) => {
    const isDuplicado = idsUnicos.has(item.id);
    idsUnicos.add(item.id);

    if (!isDuplicado) {
      return true;
    }
    return false;
  });
};
