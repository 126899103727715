<template>
  <v-dialog v-model="exibeModal"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition">
    <v-card class="DashboardModalPendenciasEmAnalise--background-color">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="confirmacaoFechamentoModal">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title><b>{{ $tc('title.analise_pendencias', 1).toUpperCase()}}</b></v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-col cols="5" sm="5" class="mx-3 pt-10 pb-6">
          <v-text-field
            v-model="searchQuery"
            append-icon="search"
            :label="$t('label.pesquisa_modal_pendencias_analise')"
            single-line
            :disabled="loading"
            clearable
            hide-details>
          </v-text-field>
        </v-col>
        <v-data-table
          :headers="headers"
          :items="listaPendenciasEmAnalise"
          :options.sync="pagination"
          :loading="loading"
          hide-default-footer
          :no-data-text="$t('label.tabela_sem_conteudo')">
          <template v-slot:[`item.acao`]="{ item }">
            <span>
              <v-tooltip bottom v-if="item.isContrato && mostrarGerarCartaParceria(item)">
                <template v-slot:activator="{ on }">
                  <v-btn text icon
                    v-on="on"
                    @click.native="realizarDownloadCartaParceria(item)">
                    <v-icon>receipt</v-icon>
                  </v-btn>
                </template>
                <span v-if="!isTenantEpoca">{{ $tc('label.gerar_carta_parceria', 1) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="(item.isAcao || item.isApuracao) && item.numNotaDebito">
                <template v-slot:activator="{ on }">
                  <v-btn text icon
                          v-on="on"
                          @click.native="exportarNota(item)">
                    <v-icon>receipt</v-icon>
                  </v-btn>
                </template>
                <span>{{ $tc('label.exportar', 1) }}</span>
              </v-tooltip>
              <v-tooltip bottom >
                <template v-slot:activator="{ on: tooltip }" >
                  <v-btn v-on="{ ...tooltip }" icon @click="abrirJustificativa(item)">
                    <v-icon>textsms</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('label.justificativa')}}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.isApuracao && item.numNotaDebito && !isModalidadeValorFixo(item)">
                <template v-slot:activator="{ on: tooltip }" >
                  <v-btn v-on="{ ...tooltip }" icon @click.native="abrirModalExecutarAnalise(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('label.executar_analise')}}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.isAcao">
                <template v-slot:activator="{ on: tooltip }" >
                  <v-btn v-on="{ ...tooltip }" icon @click="confirmarExecutarAnaliseAcao(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('label.executar_analise')}}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.isContrato">
                <template v-slot:activator="{ on: tooltip }" >
                  <v-btn v-on="{ ...tooltip }" icon @click="confirmarExecutarAnaliseContrato(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('label.executar_analise')}}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text icon
                    v-on="on"
                    @click.native="abrirDetalhamento(item)">
                    <v-icon>info</v-icon>
                  </v-btn>
                </template>
                <span>{{ $tc('label.detalhe', 2) }}</span>
              </v-tooltip>
            <v-tooltip left v-if="item.isApuracao && item.numNotaDebito && !isModalidadeValorFixo(item)">
                <template v-slot:activator="{ on }">
                  <v-btn text icon
                          v-on="on"
                          @click.native="abrirModalGerarNd(item)">
                    <v-icon>paid</v-icon>
                  </v-btn>
                </template>
                <span>{{ $tc('label.ver_nota_debito', 1) }}</span>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:[`item.numNotaDebito`]="{ item }">
            <v-btn v-if="exibirBotaoExibicaoMultiplasNds(item)" icon class="mx-0" @click.stop="abrirModalExibicaoMultiplasNds(item)">
              <v-icon>visibility</v-icon>
            </v-btn>
            <span v-else-if="item.countNotasDebito <= countNotasExibicao">{{ item.numNotaDebito }}</span>
            <v-btn icon v-else class="mx-0" @click.native="visualizarNotasDebito(item)">
              <v-icon>visibility</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-xs-center">
          <v-pagination
            v-model="pagination.page"
            v-if="totalPage > 1"
            :length="totalPage"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
    <apuracao-acao-lista-modal-gerar-nd
      ref="modalGerarNd"
      v-if="acaoModal"
      v-model="acaoModal"
      :abrir-modal="abrirModalND"
      :id-apuracao-selecionada="idApuracaoSelecionada"
      :executar-analise-selecionada="executarAnaliseSelecionada"
      :ocultaResumo="true"
      @ApuracaoAcaoListaModalGerarNd__fechar="fecharModalND">
    </apuracao-acao-lista-modal-gerar-nd>

    <modal-nota-debito
      ref="modalNotaDebito">
    </modal-nota-debito>

    <modal-exibicao-multiplas-nds
      ref="ModalExibicaoMultiplasNds">
    </modal-exibicao-multiplas-nds>

    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
      :is-aprovacao="isAprovacao">
    </planejamento-acao-justificativa>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import ApuracaoAcaoListaModalGerarNd from '@/produto/spa/apuracao-acao-lista/ApuracaoAcaoListaModalGerarNd';
import ModalNotaDebito from '@/produto/spa/apuracao-acao-lista/ModalNotaDebito';
import PlanejamentoAcaoJustificativa from '../../planejamento-acao/PlanejamentoAcaoJustificativa';
import { pendenciasAcaoApuracao, pendenciasFluxoAcaoEmAnalise } from '../../../common/resources/workflow-acao';
import { getMoney } from '../../../common/functions/helpers';
import { skipLoading } from '../../../common/functions/loading';
import basePath from '../../../common/functions/api-resource';
import exportacaoCartaParceria from '../../../common/functions/downloads';
import exportacao from '../../../common/resources/downloads';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import ModalExibicaoMultiplasNds from '../../../shared-components/ModalExibicaoMultiplasNdsCamelCase';

export default {
  name: 'DashboardModalPendenciasEmAnalise',
  components: {
    pendenciasAcaoApuracao,
    ApuracaoAcaoListaModalGerarNd,
    ModalNotaDebito,
    PlanejamentoAcaoJustificativa,
    Confirm,
    ModalExibicaoMultiplasNds,
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      exibeModal: false,
      buscaInicial: false,
      searchQuery: null,
      searchQueryArray: [],
      somenteLeitura: false,
      pendenciaExecutada: false,
      abrirModalND: false,
      countNotasExibicao: 2,
      pagination: {
        itemsPerPage: 20,
        page: 1,
      },
      totalPage: 0,
      timeout: 0,
      esperar: false,
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
      },
      headers: [
        {
          text: this.$tc('label.acao', 1), value: 'acao', sortable: false, width: '10%',
        },
        { text: this.$tc('label.id', 1), value: 'id', sortable: true },
        { text: this.$tc('label.origem', 1), value: 'origem', sortable: true },
        { text: this.$tc('label.etapa', 1), value: 'etapa', sortable: true },
        { text: this.$tc('label.numero_da_nd', 1), value: 'numNotaDebito', sortable: true },
        { text: this.$tc('label.descricao', 1), value: 'descricao', sortable: true },
        { text: this.$tc('label.fornecedor', 1), value: 'fornecedor', sortable: true },
        { text: this.$tc('label.categoria', 1), value: 'extensoes', sortable: true },
        { text: this.$tc('label.prazo_validade', 1), value: 'prazoPagamento', sortable: true },
      ],
      tituloModal: '',
      justificativaTipoUso: '',
      listaPendenciasEmAnalise: [],
      justificativa: '',
      justificativaSomenteLeitura: false,
      isAprovacao: false,
      acaoModal: null,
      loading: false,
      idApuracaoSelecionada: 0,
      executarAnaliseSelecionada: false,
      workspace: {},
    };
  },
  props: {
    value: Object,
    abrirModalPendenciaEmAnalise: false,
    listaPendenciasEmAnaliseCarregada: null,
  },
  computed: {
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
  },
  watch: {
    abrirModalPendenciaEmAnalise() {
      this.dialog = this.abrirModalPendenciaEmAnalise;
    },
    searchQuery() {
      if (this.exibeModal) {
        this.pagination.page = 1;
        if (this.timeout) {
          window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
          this.filtrar();
        }, 500);
      }
    },
  },
  methods: {
    getMoney,
    isModalidadeValorFixo(item) {
      return item.modalidade && item.modalidade === 'VALOR_FIXO';
    },
    open() {
      this.buscaInicial = true;
      if (this.listaPendenciasEmAnaliseCarregada) {
        this.buscaInicial = false;
        this.listaPendenciasEmAnalise = this.listaPendenciasEmAnaliseCarregada;
        this.searchQueryArray = [];
        this.totalPage = Math.ceil(this.listaPendenciasEmAnaliseCarregada.length / 20);
        this.escondePaginacao = (this.totalPage / this.pagination.itemsPerPage) <= 1;
        this.$forceUpdate();
      } else {
        this.carregarWorkspaceFiltros();
      }
      this.exibeModal = true;
    },
    close() {
      this.exibeModal = false;
      this.resetaModal();
      if (this.pendenciaExecutada) {
        this.$emit('DashboardModalPendenciasEmAnalise__fechar', true);
      } else {
        this.$emit('DashboardModalPendenciasEmAnalise__fechar', false);
      }
    },
    resetaModal() {
      this.searchQuery = null;
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 20;
    },
    filtrar() {
      if (!this.buscaInicial) {
        this.listaPendenciasEmAnalise = [];
        setTimeout(() => {
          this.buscaPendencias();
        }, 1000);
      }
    },
    buscaPendencias(zeraPaginacao = false) {
      if (zeraPaginacao) {
        this.pagination.page = 1;
      }
      const params = {
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: this.pagination.sortDesc ? !this.pagination.sortDesc[0] : true,
        colunaOrdenacao: this.pagination.sortBy ? this.pagination.sortBy[0] : 'idAcao',
      };
      const filtro = {};
      if (this.searchQuery && this.searchQuery.length) {
        if (this.searchQuery.includes(',')) {
          const searchQuery = this.searchQuery.split(',');
          searchQuery.forEach((valor) => {
            this.searchQueryArray.push(`${valor}%`);
          });
          params.filtroArray = this.searchQueryArray;
        } else {
          params.filtro = this.searchQuery;
        }
        filtro.searchQuery = this.searchQuery;
      }
      this.salvarFiltrosWorkspace(filtro);
      params.numeroPagina = 1;
      params.tamanhoPagina = 999999;
      this.loading = true;

      pendenciasFluxoAcaoEmAnalise(params, this.$resource)
        .then((res) => {
          this.loading = false;
          const { data } = res;
          if (!data.length && this.pagination.page > 1) {
            this.pagination.page -= 1;
            this.buscarDados();
            return;
          }
          this.buscaInicial = false;
          this.listaPendenciasEmAnalise = data;
          this.searchQueryArray = [];
          this.totalPage = Math.ceil(data.length / 20);
          this.escondePaginacao = (this.totalPage / this.pagination.itemsPerPage) <= 1;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarWorkspaceFiltros() {
      const entidade = 'pendencia_analise_massiva';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          if (Object.keys(this.workspace).length) {
            this.searchQuery = this.workspace.filtros.searchQuery;
          }
          this.buscaPendencias(false);
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace(filtro) {
      this.workspace.filtros = filtro;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'pendencia_analise_massiva';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'pendencia_analise_massiva';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    confirmacaoFechamentoModal() {
      this.close();
    },
    abrirModalGerarNd(acao) {
      this.acaoModal = acao;
      this.acaoModal.cod_acao = acao.id;
      setTimeout(() => { this.abrirModalND = true; }, 2E2);
    },
    abrirModalExecutarAnalise(acao) {
      this.acaoModal = acao;
      this.acaoModal.cod_acao = acao.id;
      this.idApuracaoSelecionada = parseInt(acao.idApuracao, 10);
      this.executarAnaliseSelecionada = true;
      setTimeout(() => { this.abrirModalND = true; }, 2E2);
    },
    fecharModalND() {
      this.abrirModalND = false;
      this.idApuracaoSelecionada = 0;
      this.executarAnaliseSelecionada = false;
      setTimeout(() => { this.acaoModal = null; }, 2E2);
    },
    visualizarNotasDebito(item) {
      setTimeout(() => this.$refs.modalNotaDebito.open(item), 2E2);
    },
    closeModalJustificativa() {
      this.dialogJustificativaTipoUso = false;
    },
    modalJustificativaConfirmar() {
      this.acaoApuracaoSelecionada.justificativaTipoUso = this.justificativaTipoUso;
      this.acaoApuracaoSelecionada = {};
      this.justificativaTipoUso = '';
      this.closeModalJustificativa();
    },
    modalJustificativaCancelar() {
      this.acaoApuracaoSelecionada.justificativaTipoUso = '';
      this.acaoApuracaoSelecionada.idTipoUso = this.acaoApuracaoSelecionada.idTipoUsoAcaoTipo;
      this.acaoApuracaoSelecionada = {};
      this.justificativaTipoUso = '';
      this.closeModalJustificativa();
    },
    isTemplateVerbaVariavel(acao) {
      const { template } = acao;
      return template === 'VERBA_VARIAVEL';
    },
    mostrarGerarCartaParceria(item) {
      return ['EM_CADASTRO', 'AGUARDANDO_APROVACAO', 'APROVADO', 'EM_ANALISE'].includes(item.status)
        && item.isCartaParceria;
    },
    realizarDownloadCartaParceria(item) {
      const getBasePath = basePath('job', 'contrato/carta-parceria');
      const servico = 'gerar-carta-parceria';
      const resource = exportacaoCartaParceria(this.$http);
      const idContrato = item.id;
      resource.download(getBasePath, idContrato, servico)
        .then(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_sucesso'));
        })
        .catch(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_erro'));
        });
    },
    exportarNota(item) {
      const idAcao = item.id;
      const getBasePath = basePath('job', 'recebimento/relatorio');
      const resource = exportacao(this.$http);

      resource.downloadGet(getBasePath, {
        param: '',
        url: `nota-debito/preview/${idAcao}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    abrirJustificativa(acao) {
      this.justificativa = acao.justificativa;
      this.justificativaSomenteLeitura = true;
      this.isAprovacao = false;
      this.$refs.modalJustificativa.open();
    },
    confirmarExecutarAnaliseAcao(acao) {
      const content = this.$t('message.deseja_executar_analise_acao');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.executarAnaliseAcao(acao);
            }
          },
        ));
    },
    executarAnaliseAcao(acao) {
      const idAcao = acao.id;
      this.planejamentoAcaoResource.executarAnalise({ idAcao }, { idAcao })
        .then(() => {
          let routeName = 'editarAcao';
          if (this.isTemplateVerbaVariavel(acao)) {
            routeName = 'editarAcaoVerbaVariavel';
          }
          this.$router.push({ name: routeName, params: { idAcao, from: 'dashboard-inicial', modalDashboard: 'PENDENCIAS_ANALISE' } });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    confirmarExecutarAnaliseContrato(contrato) {
      const content = this.$t('message.deseja_executar_analise_contrato');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.executarAnaliseContrato(contrato);
            }
          },
        ));
    },
    executarAnaliseContrato(contrato) {
      const idContrato = contrato.id;
      this.planejamentoContratoResource.executarAnalise({ idContrato }, { idContrato })
        .then(() => {
          this.$router.push({ name: 'editarContrato', params: { idContrato, from: 'dashboard-inicial', modalDashboard: 'PENDENCIAS_ANALISE' } });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirDetalhamento(item) {
      if (item.isContrato || item.isAcaoContrato) {
        this.$router.push({
          name: 'detalharContrato',
          params: {
            idContrato: item.idContrato, from: 'dashboard-inicial', carregaPassoAcoes: true, modalDashboard: 'PENDENCIAS_ANALISE',
          },
        });
      } else {
        let routeName = 'detalharAcao';
        if (this.isTemplateVerbaVariavel(item)) {
          routeName = 'detalharAcaoVerbaVariavel';
        }
        this.$router.push({ name: routeName, params: { idAcao: item.idAcao, from: 'dashboard-inicial', modalDashboard: 'PENDENCIAS_ANALISE' } });
      }
    },
    exibirBotaoExibicaoMultiplasNds(item) {
      return item.qtdNds && item.qtdNds === 'MULTIPLAS' && item.listaMultiplasNds && item.listaMultiplasNds.length;
    },
    abrirModalExibicaoMultiplasNds(item) {
      this.$refs.ModalExibicaoMultiplasNds.open(item);
    },
  },
  mounted() {
    this.dialog = this.abrirModalPendenciaEmAnalise;
    this.open();
  },
};
</script>
<style lang="scss">

.DashboardModalPendenciasEmAnalise--background-color {
  background-color: #EEEEEE !important;
}

</style>
