import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'nivel-carteira-cliente');

const nivelCarteiraClienteActions = {
  ...genericActions(basePath),
  vinculos: { method: 'GET', url: `${basePath}/vinculos` },
};

export default (resource) => resource(`${basePath}`, {}, nivelCarteiraClienteActions);
