<template lang="html">
  <div>
    <v-row>
      <v-col>
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('title.planejamento', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.ordenacao')}`" />
        </v-card-title>
        <v-row>
          <v-col cols="12" >
            <draggable :list="parametrizacao.listaPlanejamento" id="config_acao_planejamento_lista">
              <transition-group>
                <div class="list-group-item"
                     v-for="(element, indice) in parametrizacao.listaPlanejamento"
                     :key="`lista-planejamento-${indice}`"
                     style="cursor: grab">
                  <span>{{ element.nome }}</span>
                  <tooltip-ajuda
                    v-if="element.label === 'divisao'"
                    color="accent"
                    :mensagem="`${$t('message.ajuda.parametrizacao_acao.campo_divisao')}`" />
                  <tooltip-ajuda
                    v-else-if="element.label === 'regional'"
                    color="accent"
                    :mensagem="`${$t('message.ajuda.parametrizacao_acao.campo_regional')}`" />
                </div>
              </transition-group>
            </draggable>
          </v-col>
        </v-row>
      </v-col>

      <v-col
          v-if="parametrizacao.listaComplementoInformacoes
           && parametrizacao.listaComplementoInformacoes.length">
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('label.complemento_informacao', 2) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.ordenacao')}`" />
        </v-card-title>
        <v-row>
          <v-col cols="12" >
            <draggable :list="parametrizacao.listaComplementoInformacoes" id="config_lista_dinamica_ordenacao_complemento_informacao">
              <transition-group>
                <div class="list-group-item"
                     v-for="(element, indice) in parametrizacao.listaComplementoInformacoes"
                     :key="`lista-complemento-informacoes-${indice}`"
                     style="cursor: grab">{{element.nome}}</div>
              </transition-group>
            </draggable>
          </v-col>
        </v-row>
      </v-col>

      <v-col
          v-if="parametrizacao.listaLiquidacaoManual
           && parametrizacao.listaLiquidacaoManual.length">
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('label.liquidacao_manual', 2) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.ordenacao')}`" />
        </v-card-title>
        <v-row>
          <v-col cols="12" >
            <draggable :list="parametrizacao.listaLiquidacaoManual" id="config_lista_dinamica_ordenacao_liquidacao_manual">
              <transition-group>
                <div class="list-group-item"
                     v-for="(element, indice) in parametrizacao.listaLiquidacaoManual"
                     :key="`lista-liquidacao-manual-${indice}`"
                     style="cursor: grab">{{element.nome}}</div>
              </transition-group>
            </draggable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="end" class="my-4">
      <v-btn
          id="config_acao_passo7_botao_cancelar"
          @click="cancelar"
          class="mr-3">
        {{ $t('label.cancelar') }}
      </v-btn>
      <v-btn
          id="config_acao_passo7_botao_voltar"
          color="primary"
          @click="voltarPasso"
          class="mr-3">
        {{ $t('label.voltar_passo') }}
      </v-btn>
      <v-btn
          id="config_acao_passo7_botao_proximo"
          color="primary"
          @click="proximoPasso"
          class="mr-3">
        {{ $t('label.proximo_passo') }}
      </v-btn>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import draggable from 'vuedraggable';
import Passo7 from './Passo7';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoAcaoPasso7',
  data() {
    return {
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),

      parametrizacao: new Passo7(),
      listaPlanejamento: [],
      listaComplementoInformacoes: [],
      listaLiquidacaoManual: [],

      naoOrdenaveis: [
        'descricao',
        'holding',
        'unidadenegocio',
        'periodo_planejamento',
        'periodo_apuracao',
        'tipo_pagamento',
        'base_calculo',
        'valor_consumido',
        'meta_beneficio',
        'forma_bonificacao',
        'investimento_vendas',
      ],
    };
  },
  props: {
    id: Number,
    passo: Number,
  },
  components: {
    draggable,
    TooltipAjuda,
  },
  methods: {
    cancelar() {
      this.$emit('PARAMETRIZACAO_ACAO_CANCELAR');
    },
    obterValoresDoBanco(objetoBanco, lista) {
      objetoBanco.forEach((itemBanco) => {
        lista.forEach((item) => {
          if (item.label === itemBanco.label) {
            item.ordenacao = itemBanco.ordenacao || item.ordenacao;
          }
        });
      });
      return lista;
    },
    prepararListas() {
      this.parametrizacao.listaPlanejamento.forEach((item, index) => {
        item.ordenacao = index + 1;
      });
      this.parametrizacao.listaComplementoInformacoes.forEach((item, index) => {
        item.ordenacao = index + 1;
      });
      if (this.parametrizacao.listaLiquidacaoManual
        && this.parametrizacao.listaLiquidacaoManual.length) {
        this.parametrizacao.listaLiquidacaoManual.forEach((item, index) => {
          item.ordenacao = index + 1;
        });
      }
    },
    prepararPasso() {
      if (this.id) {
        this.parametrizacao.id = this.id;
        const params = {
          id: this.id,
        };

        this.paramAcaoResources.obterPasso7(params)
          .then((response) => {
            this.listaPlanejamento = [...response.data.listaPlanejamento];
            this.listaComplementoInformacoes = [...response.data.listaComplementoInformacoes];
            this.listaLiquidacaoManual = [...response.data.listaLiquidacaoManual];
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    proximoPasso() {
      this.prepararListas();
      this.$emit('PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
      this.$emit('PARAMETRIZACAO_ACAO_CONTINUAR', this.passo + 1, this.id);
    },
    ordenarListas(lista) {
      return lista.sort((a, b) => a.ordenacao - b.ordenacao);
    },
    setLista(lista) {
      if (!lista) return;
      const listaSemNaoOrdenaveis = this.removeItensNaoOrdenaveis([...lista]);
      this.parametrizacao.listaPlanejamento = this.removeCamposComplementoInformacoes([...listaSemNaoOrdenaveis]);
      this.parametrizacao.listaPlanejamento = this.obterValoresDoBanco(
        this.listaPlanejamento, this.parametrizacao.listaPlanejamento,
      );
      this.parametrizacao.listaComplementoInformacoes = lista
        .filter((l) => l.etapasFluxo && l.etapasFluxo.length);
      this.parametrizacao.listaComplementoInformacoes = this.obterValoresDoBanco(
        this.listaComplementoInformacoes,
        this.parametrizacao.listaComplementoInformacoes,
      );

      this.ordenarListas(this.parametrizacao.listaPlanejamento);
      this.ordenarListas(this.parametrizacao.listaComplementoInformacoes);
      this.$forceUpdate();
    },
    setListaLiquidacaoManual(lista) {
      if (!lista) return;

      this.parametrizacao.listaLiquidacaoManual = lista;
      this.parametrizacao.listaLiquidacaoManual = this
        .obterValoresDoBanco(this.listaLiquidacaoManual,
          this.parametrizacao.listaLiquidacaoManual);

      this.ordenarListas(this.parametrizacao.listaLiquidacaoManual);
      this.removerIdentificadoresLista();
      this.$forceUpdate();
    },
    removerIdentificadoresLista() {
      (this.parametrizacao.lista || []).forEach((el) => {
        delete el.id;
      });
    },
    removeItensNaoOrdenaveis(lista) {
      return lista.filter((item) => this.naoOrdenaveis.indexOf(item.label) < 0);
    },
    removeCamposComplementoInformacoes(lista) {
      return lista.filter((item) => !item.complementoInformacao);
    },
    voltarPasso() {
      this.prepararListas();
      this.$emit('PARAMETRIZACAO_ACAO_VOLTAR', this.passo - 1);
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .card-header {
    background-color: var(--v-primary-base);
    color: #ffffff;
  }
</style>
