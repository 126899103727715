<template lang="html">
  <div>
    <v-row>
      <v-col cols="12" sm="6" class="pr-2">
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('label.grid_filtros', 1) }} - {{ $tc('label.planejamento', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.grid_filtros')}`" />
        </v-card-title>
        <v-data-table id="config_acao_grid_filtros_planejamento"
          :headers="headers"
          :items="parametrizacao.listaPlanejamento"
          hide-default-footer
          disable-pagination
          :items-per-page="-1"
          :no-data-text="$t('message.nenhum_registro')">
          <template v-slot:item.nome="{ item }">
            <span>{{ item.nome }}</span>
            <tooltip-ajuda
              v-if="item.label === 'divisao'"
              color="accent"
              :mensagem="`${$t('message.ajuda.parametrizacao_acao.campo_divisao')}`" />
            <tooltip-ajuda
              v-else-if="item.label === 'regional'"
              color="accent"
              :mensagem="`${$t('message.ajuda.parametrizacao_acao.campo_regional')}`" />
          </template>
          <template v-slot:item.habilitaGrid="{ item }">
            <v-switch
                v-if="!item.desativaGrid"
                color="primary"
                class="my-0"
                id="config_acao_passo8_grid"
                :disabled="item.desativaGrid"
                :label="`${$t('label.grid')}`"
                v-model="item.habilitaGrid" hide-details/>
              <v-label v-if="item.desativaGrid">{{ item.habilitaGrid ? $t('label.sim') : $t('label.nao') }}</v-label>
          </template>
          <template v-slot:item.habilitaFiltro="{ item }">
            <v-switch
                v-if="!item.desativaFiltro"
                color="primary"
                class="my-0"
                id="config_acao_passo8_filtro"
                :disabled="item.desativaFiltro"
                :label="`${$tc('label.filtro', 1)}`"
                v-model="item.habilitaFiltro" hide-details/>
              <v-label v-if="item.desativaFiltro">{{ item.habilitaFiltro ? $t('label.sim') : $t('label.nao') }}</v-label>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" sm="4" class="pr-2" v-if="parametrizacao.listaLiquidacaoManual && parametrizacao.listaLiquidacaoManual.length">
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('label.grid_filtros', 1) }} - {{ $tc('label.liquidacao_manual', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.grid_filtros')}`" />
        </v-card-title>
        <v-data-table id="config_acao_grid_filtros_liquidacao_manual"
          :headers="headersLiquidacaoManual"
          :items="parametrizacao.listaLiquidacaoManual"
          hide-default-footer
          disable-pagination
          :items-per-page="-1"
          :no-data-text="$t('message.nenhum_registro')">
          <template v-slot:item.habilitaGrid="{ item }">
            <v-switch
                v-if="!item.desativaGrid"
                color="primary"
                class="my-0"
                id="config_acao_passo8_grid_lm"
                :disabled="item.desativaGrid"
                :label="`${$t('label.grid')}`"
                v-model="item.habilitaGrid" hide-details/>
              <v-label v-if="item.desativaGrid">{{ item.habilitaGrid ? $t('label.sim') : $t('label.nao') }}</v-label>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end" class="my-4">
      <v-btn id="config_acao_passo8_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
      <v-btn id="config_acao_passo8_botao_voltar" color="primary" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
      <v-btn id="config_acao_passo8_botao_proximo" color="error" @click="concluir" class="mr-3">{{ $t('label.concluir') }}</v-btn>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import Passo8 from './Passo8';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoAcaoPasso8',
  data() {
    return {
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),
      headers: [
        {
          text: this.$tc('label.campo', 1), width: '30%', value: 'nome', sortable: false,
        },
        {
          text: this.$tc('label.grid', 1), width: '30%', value: 'habilitaGrid', sortable: false,
        },
        {
          text: this.$tc('label.filtro', 1), width: '30%', value: 'habilitaFiltro', sortable: false,
        },
      ],
      headersLiquidacaoManual: [
        {
          text: this.$tc('label.campo', 1), width: '30%', value: 'nome', sortable: false,
        },
        {
          text: this.$tc('label.grid', 1), width: '30%', value: 'habilitaGrid', sortable: false,
        },
      ],
      parametrizacao: new Passo8(),
      listaPlanejamento: [],
      listaLiquidacaoManual: [],
      listaCamposPreenchimentoExterno: [],
    };
  },
  props: {
    id: Number,
    passo: Number,
  },
  components: {
    TooltipAjuda,
  },
  methods: {
    cancelar() {
      this.$emit('PARAMETRIZACAO_ACAO_CANCELAR');
    },
    concluir() {
      this.$emit('PARAMETRIZACAO_ACAO_CONCLUIR', this.passo, this.parametrizacao);
    },
    definirLista(listaOriginal) {
      listaOriginal.forEach((item) => {
        if (this.isDataHora(item) || this.isNumerico(item) || this.isTexto(item)) {
          item.habilitaFiltro = false;
          item.desativaFiltro = true;
        }
      });
    },
    isDataHora(item) {
      return item.tipoCampo && (item.tipoCampo === 'DATA' || item.tipoCampo === 'HORA');
    },
    isNumerico(item) {
      return item.tipoCampo && (item.tipoCampo === 'NUMERICO' || item.tipoCampo === 'PERCENTUAL');
    },
    isTexto(item) {
      return item.tipoCampo && item.tipoCampo === 'TEXTO';
    },
    obterValoresDoBanco(objetoBanco, lista) {
      objetoBanco.forEach((itemBanco) => {
        lista.forEach((item) => {
          if (item.label === itemBanco.label) {
            item.habilitaFiltro = itemBanco.habilitaFiltro !== null
              && itemBanco.habilitaFiltro !== undefined
              ? itemBanco.habilitaFiltro : item.habilitaFiltro;
            item.habilitaGrid = itemBanco.habilitaGrid !== null
              && itemBanco.habilitaGrid !== undefined
              ? itemBanco.habilitaGrid : item.habilitaGrid;
          }
        });
      });
      return lista;
    },
    prepararPasso() {
      if (this.id) {
        this.parametrizacao.id = this.id;
        const params = { id: this.id };
        this.paramAcaoResources.obterPasso8(params)
          .then((response) => {
            this.listaPlanejamento = Object.assign([], response.data.listaPlanejamento);
            this.listaLiquidacaoManual = Object.assign([], response.data.listaLiquidacaoManual);
          }, (err) => {
            this.$error(this, err);
          });
      }
      this.paramAcaoResources.obterCamposPreenchimentoExterno()
        .then((response) => {
          this.listaCamposPreenchimentoExterno = response.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    setListaPlanejamento(lista) {
      if (!lista) return;
      this.parametrizacao.listaPlanejamento = [...lista];
      this.parametrizacao.listaPlanejamento = [
        ...this.parametrizacao.listaPlanejamento,
        ...this.listaCamposPreenchimentoExterno.map((item) => ({
          nome: item.nomCampo,
          label: item.labelCampo,
          ordenacao: item.ordem || this.parametrizacao.listaPlanejamento.length + 1,
          desativaFiltro: item.labelCampo !== 'num_nota_debito',
        })),
        {
          nome: this.$tc('label.valor_acao', 1),
          label: 'valor',
          habilitaGrid: true,
          habilitaFiltro: false,
          desativaFiltro: true,
          desativaGrid: false,
        },
      ];
      this.parametrizacao.listaPlanejamento = this.obterValoresDoBanco(
        this.listaPlanejamento, this.parametrizacao.listaPlanejamento,
      );
      this.definirLista(this.parametrizacao.listaPlanejamento);
      this.$forceUpdate();
    },
    setListaLiquidacaoManual(lista) {
      if (!lista) return;

      this.parametrizacao.listaLiquidacaoManual = lista;
      this.parametrizacao.listaLiquidacaoManual = this.obterValoresDoBanco(
        this.listaLiquidacaoManual,
        this.parametrizacao.listaLiquidacaoManual,
      );

      this.definirLista(this.parametrizacao.listaLiquidacaoManual);
      this.$forceUpdate();
    },
    criarItemLista(nome, label, ordem) {
      return {
        nome,
        label,
        apenas_ordenacao: false,
        habilitaGrid: true,
        desativaGrid: false,
        ordenacao: ordem,
        habilitaFiltro: true,
        desativaFiltro: false,
      };
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_ACAO_VOLTAR', this.passo - 1);
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .card-header {
    background-color: var(--v-primary-base);
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
