<template>
  <v-switch
    :id="inputId"
    :label="inputLabel"
    :disabled="campoDesabilitado"
    v-model="valorInterno"
    color="primary"
  ></v-switch>
</template>
<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoBoolean',
  props: {
    value: {
      type: [Boolean],
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
      },
    },
    valorInterno(novoValor) {
      if (novoValor === this.value) {
        return;
      }
      this.emiteValorAlterado(novoValor);
    },
  },
  data() {
    return {
      valorInterno: null,
      rules: {
        requiredNome: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.objetoContainer[this.metadados.labelCampo];
          return !!(val) || msg;
        },
      },
    };
  },
  mounted() {
    const { valorDefault } = this.metadados;
    if (valorDefault) {
      this.valorInterno = valorDefault;
      this.emiteValorAlterado(this.valorInterno);
    }
  },
};
</script>
