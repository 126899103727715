<template lang="html">
  <div>
    <v-form ref="form" lazy-validation>
      <v-card-title primary-title class="card-header">
        {{ $t('title.resumo') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.resumo')}`" />
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              id="config_contrato_nome"
              v-model="parametrizacao.nome"
              :label="`${$tc('label.nome', 1)} * `"
              :rules="[rules.required]"
              maxlength="50"
              counter=50
              required>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <v-text-field
              id="config_contrato_descricao"
              v-model="parametrizacao.descricao"
              :label="`${$tc('label.descricao', 1)} * `"
              :rules="[rules.required]"
              maxlength="200"
              counter=200
              required>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              id="config_contrato_fluxo_trade"
              class="Form-text__invalid"
              name="forma_rateio"
              item-text="text"
              item-value="value"
              v-model="parametrizacao.fluxo"
              :items="fluxos"
              :rules="[rules.required]"
              :label="`${$tc('label.fluxo_trademarketing', 1)} *`"
              required>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              id="config_contrato_icone"
              v-model="parametrizacao.icone"
              :label="`${$tc('label.icone', 1)} * `"
              :rules="[rules.required]"
              maxlength="50"
              counter=50
              required>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-title primary-title class="card-header">
        {{ $tc('title.upload_arquivo') }} *
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.upload')}`" />
      </v-card-title>
      <v-container fluid>
          <v-col cols="12" sm="12" class="pb-0">
            <v-switch
              id="config_acao_upload_obrigatorio"
              :label="$tc('label.upload_arquivo_obrigatorio_aprovacao', 1)"
              v-model="parametrizacao.uploadObrigatorio"
            ></v-switch>
          </v-col>
      </v-container>
      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('label.acao', 2) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.tipos_acao')}`" />
      </v-card-title>
      <v-form
        ref="formTipoAcao"
        lazy-validation>
        <v-row>
          <v-col cols="12" sm="6" lg="4">
            <v-autocomplete
              id="config_contrato_tipo_acao"
              name="config_contrato_tipo_acao"
              v-model="tipoAcaoSelect.tipoAcao"
              :filter="filtroTipoAcao"
              :items="listaSelectTiposAcao"
              :search-input.sync="buscaListaSelectTiposAcao"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.tipo_acao', 1)} * `"
              :placeholder="$tc('label.digite_para_buscar', 1)"
              @click:append="triggerTipoAcao"
              @click.native="clickTipoAcao"
              :rules="[rules.required]"
              item-text="nome"
              item-value="id"
              clearable
              return-object>
                <template slot="item" slot-scope="data">
                  {{ data.item.nome }}
                </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <v-text-field
              id="config_contrato_qtd_acoes"
              v-model="tipoAcaoSelect.qtdMaximaAcoes"
              :label="`${$tc('label.quantidade_por_contrato', 1)} * `"
              :rules="[rules.required, rules.numeroValido]"
              maxlength="200"
              counter=200
              type="Number"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="2" class="my-3">
            <v-btn class="accent"
              @click="adicionarTipoAcao">
              {{ $t('label.adicionar') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-switch
              color="primary"
              id="config_contrato_permitir_duplicidade"
              :label="`${$t('label.habilitar_permite_duplicidade_acoes')}`"
              v-model="parametrizacao.indPermiteDuplicidadeAcao"
              @change="verificaIndPermiteDuplicidadeAcao(parametrizacao.indPermiteDuplicidadeAcao)"
              />
          </v-col>
        </v-row>
      </v-form>
      <v-col cols="12" sm="12" lg="9">
        <v-data-table id="tipo_acao_tabela"
          :headers="headersTipoAcao"
          :items="tiposAcao"
          :items-per-page="10"
          :no-data-text="$t('message.nenhum_registro')"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50],
          }">
          <template v-slot:item.nomeAcaoTipo="{ item }">
            {{ item.nomeAcaoTipo }}
          </template>
          <template v-slot:item.indAtivarDuplicidade="{ item }">
            <v-switch
              v-model="item.indAtivarDuplicidade"
              :disabled="!parametrizacao.indPermiteDuplicidadeAcao"
              />
          </template>
          <template v-slot:item.indAtivo="{ item }">
            {{ item.indAtivo ? $t('label.sim') : $t('label.nao') }}
          </template>
          <template v-slot:item.qtdMaximaAcoes="{ item }">
            {{ item.qtdMaxAcoes }}
          </template>
          <template v-slot:item.indHabilitaAcaoAgrupadora="{ item }">
            <v-switch
              :disabled="!item.isFluxoApenasPlanejamento"
              v-model="item.indHabilitaAcaoAgrupadora" />
          </template>
          <template v-slot:item.idTipoAcaoAgrupadora="{ item }">
            <v-select
              v-if="item.indHabilitaAcaoAgrupadora"
              class="Form-text__invalid"
              name="item_acao_agrupadora"
              item-text="nome"
              item-value="id"
              v-model="item.idTipoAcaoAgrupadora"
              :items="listaAcoesAgrupadoras"
              :rules="[rules.required]"
              :label="`${$tc('label.acao_agrupadora', 1)} *`"
              required
              clearable>
            </v-select>
          </template>
          <template v-slot:item.acoes="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="removerTipoAcaoPeloId(item.idAcaoTipo)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.remover') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>

      <v-row justify="end" class="my-4">
        <v-btn id="config_contrato_passo1_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn id="config_contrato_passo1_botao_proximo" color="primary" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import Passo1 from './Passo1';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoContratoPasso1',
  props: {
    id: Number,
    copy: String,
  },
  components: {
    TooltipAjuda,
  },
  watch: {
    id() {
      this.prepararPasso();
    },
    buscaListaSelectTiposAcao(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.buscarTiposAcaoAutocomplete(val);
      }, 500);
    },
  },
  data() {
    return {
      paramContratoResources: this.$api.parametrizacaoContrato(this.$resource),
      tipoAcaoResources: this.$api.tipoAcao(this.$resource),

      passo: 1,
      headersTipoAcao: [
        {
          text: this.$tc('label.tipo_acao', 1), value: 'nomeAcaoTipo', width: '50%', sortable: false,
        },
        {
          text: this.$tc('label.ativo', 1), value: 'indAtivo', width: '10%', sortable: false,
        },
        {
          text: this.$tc('label.ativar_duplicidade', 1), value: 'indAtivarDuplicidade', width: '10%', sortable: false,
        },
        {
          text: this.$tc('label.quantidade_por_contrato', 1), value: 'qtdMaximaAcoes', width: '10%', sortable: false,
        },
        {
          text: this.$tc('label.habilitar_acao_agrupadora_automatica', 1), value: 'indHabilitaAcaoAgrupadora', width: '10%', sortable: false,
        },
        {
          text: this.$tc('label.acao_agrupadora', 1), value: 'idTipoAcaoAgrupadora', width: '20%', sortable: false,
        },
        {
          text: '', value: 'acoes', width: '2%', sortable: false,
        },
      ],
      buscaListaSelectTiposAcao: null,
      listaSelectTiposAcao: [],
      listaAcoesAgrupadoras: [],
      tiposAcao: [],
      tipoAcaoSelect: {
        tipoAcao: null,
        qtdMaximaAcoes: null,
      },
      fluxos: [
        {
          text: `${this.$tc('label.planejamento', 1)} -> ${this.$tc('label.apuracao', 1)} -> ${this.$tc('label.liquidacao', 1)}`,
          value: 'PLANEJAMENTO,APURACAO,LIQUIDACAO',
        },
        {
          text: `${this.$tc('label.planejamento', 1)} -> ${this.$tc('label.apuracao', 1)} -> ${this.$tc('label.orcamento', 1)}`,
          value: 'PLANEJAMENTO,APURACAO,ORCAMENTO',
        },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        numeroValido: () => {
          let msg = this.$t('message.numero_invalido');
          const val = this.tipoAcaoSelect.qtdMaximaAcoes;
          if (!!val && Number(val) === 0) {
            msg = this.$t('errors.valor.maior_zero_inteiro');
          } else if (!val) {
            msg = this.$t('message.campo_obrigatorio');
          }
          return (!!(val) && !Number.isNaN(val.toString()) && Number(val) > 0) || msg;
        },
      },
      parametrizacao: new Passo1(),
    };
  },
  methods: {
    verificaIndPermiteDuplicidadeAcao(indPermiteDuplicidadeAcao) {
      if (!indPermiteDuplicidadeAcao) {
        this.tiposAcao.forEach((tipoAcao) => {
          tipoAcao.indAtivarDuplicidade = false;
        });
      }
    },
    obter(id, copy) {
      this.paramContratoResources.obterPasso1({ id }, { id }).then((response) => {
        this.parametrizacao = new Passo1(response.data);
        this.tiposAcao = this.parametrizacao.tiposAcao;
        if (copy) {
          this.limparCampos();
        }
        this.$emit('PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
        this.$emit('PARAMETRIZACAO_CONTRATO_DEFINIR_PASSOS');
      }, (err) => {
        this.$error(this, err);
      });
    },
    limparCampos() {
      this.parametrizacao.descricao = '';
      this.parametrizacao.nome = '';
    },
    filtroTipoAcao(item, queryText, itemText) {
      const tipoAcaoDescricao = item.descricao;
      const searchText = queryText.toLowerCase();

      return itemText.toLowerCase().indexOf(searchText) > -1
        || tipoAcaoDescricao.toLowerCase().indexOf(searchText) > -1;
    },
    triggerTipoAcao() {
      this.triggerClick('config_contrato_tipo_acao');
    },
    clickTipoAcao() {
      this.buscarTiposAcaoAutocomplete(this.buscaListaSelectTiposAcao);
    },
    buscarTiposAcaoAutocomplete(busca = null) {
      const idsTiposAcaoSelecionados = [];
      if (this.tiposAcao) {
        this.tiposAcao.forEach((value) => {
          idsTiposAcaoSelecionados.push(value.idAcaoTipo);
        });
      }

      const param = {
        autocomplete: busca,
        exceto: idsTiposAcaoSelecionados,
        limit: 30,
        page: 0,
      };

      this.tipoAcaoResources.selecao(param).then((response) => {
        this.listaSelectTiposAcao = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    triggerClick(id) {
      document.getElementById(id).click();
    },
    buscaListaAcoesAgrupadoras() {
      this.tipoAcaoResources.buscarTiposAcaoExclusivaContrato().then((response) => {
        this.listaAcoesAgrupadoras = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    removerTipoAcaoPeloId(id) {
      this.tiposAcao = this.tiposAcao.filter((t) => t.idAcaoTipo !== parseInt(id, 10));
      this.$forceUpdate();
    },
    adicionarTipoAcao() {
      if (this.$refs.formTipoAcao.validate()) {
        if (!Number(this.tipoAcaoSelect.qtdMaximaAcoes)
             || Number(this.tipoAcaoSelect.qtdMaximaAcoes) <= 0) {
          this.$toast(this.$t('errors.valor.maior_zero_inteiro'));
          return;
        }
        const tipoAcao = {
          idAcaoTipo: this.tipoAcaoSelect.tipoAcao.id,
          nomeAcaoTipo: this.tipoAcaoSelect.tipoAcao.nome,
          qtdMaxAcoes: this.tipoAcaoSelect.qtdMaximaAcoes,
          isFluxoApenasPlanejamento: this.tipoAcaoSelect.tipoAcao.isFluxoApenasPlanejamento,
          indAtivarDuplicidade: false,
          indHabilitaAcaoAgrupadora: false,
          indAtivo: this.tipoAcaoSelect.tipoAcao.indAtivo,
        };
        this.tiposAcao.push(tipoAcao);
        this.tipoAcaoSelect = {
          tipoAcao: null,
          qtdMaximaAcoes: null,
        };
        this.$refs.formTipoAcao.resetValidation();
      }
    },
    prepararPasso() {
      if (this.id) {
        // Editar - Copiar
        this.obter(this.id, this.copy);
      } else {
        // Novo
        this.parametrizacao = new Passo1();
      }
      this.buscaListaAcoesAgrupadoras();
    },
    proximoPasso() {
      if (this.$refs.form.validate()) {
        if (!this.tiposAcao.length) {
          this.$toast(this.$t('message.associar_tipo_acao_contrato'));
          return;
        }
        this.parametrizacao.tiposAcao = this.tiposAcao;
        this.$emit('PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
        this.$emit('PARAMETRIZACAO_CONTRATO_CONTINUAR', this.passo + 1, this.id);
      }
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_CONTRATO_CANCELAR');
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .card-header {
    background-color: var(--v-primary-base);
    color: #ffffff;
  }
</style>
