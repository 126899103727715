import getBasePath from '@/produto/common/functions/api-resource';
import genericActions from '@/produto/common/resources/generic-resource';

const basePath = getBasePath('api', 'tipo-uso');

const tipoUsoActions = {
  ...genericActions(basePath),
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}` },
  buscar: { method: 'GET', url: `${basePath}/{codigo}` },
};

export default (resource) => resource(`${basePath}`, {}, tipoUsoActions);
