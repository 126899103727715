import getBasePath from '../functions/api-resource';

const basePath = getBasePath('api', 'orcamento-tipo-verba');

const orcamentoActions = {
  buscarAtivos: { method: 'GET', url: `${basePath}/ativos` },
  buscarPorVisao: { method: 'GET', url: `${basePath}/visao` },
  buscarTipoVerba: { method: 'GET', url: `${basePath}/{id}` },
  ultimoAno: { method: 'GET', url: `${basePath}/{id}/ultimo-ano` },
  buscarPorIdVerbaInvestimento: { method: 'GET', url: `${basePath}/investimento/{idOrcamentoVerbaInvestimento}` },
  buscarConfigAnoFiscalPorChave: { method: 'GET', url: `${basePath}/{id}/chave/{chave}` },
};

export default (resource) => resource(`${basePath}`, {}, orcamentoActions);
