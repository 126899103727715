export default class Passo2 {
  constructor(id, listaCliente = [], listaProduto = [], listaOutros = [], listaPadrao = [],
    listaDinamica = [], listaDivisao = []) {
    this.id = id;
    this.listaCliente = listaCliente;
    this.listaProduto = listaProduto;
    this.listaOutros = listaOutros;
    this.listaPadrao = listaPadrao;
    this.listaDinamica = listaDinamica;
    this.listaDivisao = listaDivisao;
  }

  static setListaDinamica(listaDinamica) {
    this.listaDinamica = listaDinamica;
  }
}
