import getBasePath from '../functions/api-resource';

const basePath = getBasePath('job', 'exportacao_arquivo');

export default ($http, timeout) => { // eslint-disable-line
  const getResponseProperty = (obj, ...params) => {
    const attribute = params.filter((el) => obj.hasOwnProperty(el))[0];
    const value = obj[attribute];
    return value[0];
  };

  const getFileName = (response) => {
    const header = getResponseProperty(response.headers.map, 'content-disposition', 'Content-Disposition');
    const temp = header.split('filename=');
    const fileName = temp[1];
    return fileName;
  };

  const onError = (response) => {
    console.error('TODO: falha ao exportar', response); // eslint-disable-line
    throw Error('Erro ao exportar');
  };

  const onFileDownloaded = (response) => {
    if (!response || !response.data) {
      return onError(response);
    }
    const fileName = getFileName(response);
    const type = getResponseProperty(response.headers.map, 'content-type', 'Content-Type');
    const file = new window.Blob([response.data], { type }); // eslint-disable-line
    const a = document.createElement('a'); // eslint-disable-line
    a.download = fileName;
    a.href = window.URL.createObjectURL(file); // eslint-disable-line
    document.body.appendChild(a); // eslint-disable-line
    a.click();
    return a;
  };

  return {
    download(nomeExportacao, parametros, resourceOptional = null) {
      if (resourceOptional) {
        $logger.log('Ignorando resource pois todas exportacoes foram migradas', resourceOptional);
      }
      const downloadUrl = `${basePath}/planilha/${nomeExportacao}`;
      return $http.post(downloadUrl, parametros, { responseType: 'arraybuffer', timeout })
        .then(onFileDownloaded)
        .catch(onError);
    },
  };
};
