<template lang="html">
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" :editable="e1 > 1" edit-icon="$vuetify.icons.complete" step="1">{{ $t('label.geral') }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" :editable="e1 > 2" edit-icon="$vuetify.icons.complete" step="2">{{ $tc('label.cadastro_orcamento', 1) }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" :editable="e1 > 3" edit-icon="$vuetify.icons.complete" step="3">{{ $tc('label.configuracao', 1) }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 4" :editable="e1 > 4" edit-icon="$vuetify.icons.complete" step="4">{{ $tc('label.ordenacao', 1) }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 5" :editable="e1 > 5" edit-icon="$vuetify.icons.complete" step="5">{{ $tc('label.grid_filtros', 1) }}</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <parametrizacao-orcamento-passo-1
          :id="id"
          @PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_ID="atualizarId"
          @PARAMETRIZACAO_ORCAMENTO_CANCELAR="cancelar"
          @PARAMETRIZACAO_ORCAMENTO_CONTINUAR="continuar"
          @PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_PASSO="atualizarObjeto">
        </parametrizacao-orcamento-passo-1>
      </v-stepper-content>

      <v-stepper-content step="2">
        <parametrizacao-orcamento-passo-2
          :id="id"
          ref="passo2"
          @PARAMETRIZACAO_ORCAMENTO_CANCELAR="cancelar"
          @PARAMETRIZACAO_ORCAMENTO_CONTINUAR="continuar"
          @PARAMETRIZACAO_ORCAMENTO_VOLTAR="voltar"
          @PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_PASSO="atualizarObjeto">
        </parametrizacao-orcamento-passo-2>
      </v-stepper-content>

      <v-stepper-content step="3">
        <parametrizacao-orcamento-passo-3
          :id="id"
          ref="passo3"
          :composicoes="composicoes"
          :divisoes="divisoes"
          @PARAMETRIZACAO_ORCAMENTO_CANCELAR="cancelar"
          @PARAMETRIZACAO_ORCAMENTO_CONTINUAR="continuar"
          @PARAMETRIZACAO_ORCAMENTO_VOLTAR="voltar"
          @PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_PASSO="atualizarObjeto">
        </parametrizacao-orcamento-passo-3>
      </v-stepper-content>

      <v-stepper-content step="4">
        <parametrizacao-orcamento-passo-4
          :id="id"
          ref="passo4"
          @PARAMETRIZACAO_ORCAMENTO_CANCELAR="cancelar"
          @PARAMETRIZACAO_ORCAMENTO_CONTINUAR="continuar"
          @PARAMETRIZACAO_ORCAMENTO_VOLTAR="voltar"
          @PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_PASSO="atualizarObjeto">
        </parametrizacao-orcamento-passo-4>
      </v-stepper-content>
    </v-stepper-items>

    <v-stepper-content step="5">
      <parametrizacao-orcamento-passo-5
        :id="id"
        ref="passo5"
        @PARAMETRIZACAO_ORCAMENTO_CANCELAR="cancelar"
        @PARAMETRIZACAO_ORCAMENTO_CONTINUAR="continuar"
        @PARAMETRIZACAO_ORCAMENTO_VOLTAR="voltar"
        @PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_PASSO="atualizarObjeto"
        @PARAMETRIZACAO_ORCAMENTO_CONCLUIR="concluirPassos">
      </parametrizacao-orcamento-passo-5>
      </v-stepper-content>
  </v-stepper>
</template>

<script type="text/javascript">
import { copyObject } from '../../../common/functions/helpers';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import Passo1 from './Passo1';
import Passo2 from './Passo2';
import Passo3 from './Passo3';
import Passo4 from './Passo4';
import Passo5 from './Passo5';
import ParametrizacaoOrcamentoPasso1 from './ParametrizacaoOrcamentoPasso1';
import ParametrizacaoOrcamentoPasso2 from './ParametrizacaoOrcamentoPasso2';
import ParametrizacaoOrcamentoPasso3 from './ParametrizacaoOrcamentoPasso3';
import ParametrizacaoOrcamentoPasso4 from './ParametrizacaoOrcamentoPasso4';
import ParametrizacaoOrcamentoPasso5 from './ParametrizacaoOrcamentoPasso5';

export default {
  name: 'ParametrizacaoOrcamentoForm',
  data() {
    return {
      composicoes: [],
      divisoes: [],
      e1: 1,
      id: null,
      objetoOrcamento: {
        passo1: new Passo1(),
        passo2: new Passo2(),
        passo3: new Passo3(),
        passo4: new Passo4(),
        passo5: new Passo5(),
      },
      paramOrcamentoResources: this.$api.parametrizacaoOrcamento(this.$resource),
    };
  },
  components: {
    ParametrizacaoOrcamentoPasso1,
    ParametrizacaoOrcamentoPasso2,
    ParametrizacaoOrcamentoPasso3,
    ParametrizacaoOrcamentoPasso4,
    ParametrizacaoOrcamentoPasso5,
  },
  computed: {
    ...generateComputed('Param Orc', [
      'canAccessPage',
    ]),
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    adicionarDivisao(lista) {
      const div = this.$refs.passo4.getItemDivisao().length > 0
        ? this.$refs.passo4.getItemDivisao()[0] : {};
      return lista.concat({
        nome: this.$tc('label.divisao', 1),
        contexto: 'LINHA',
        obrigatorio: true,
        label: 'divisao',
        apenas_ordenacao: false,
        habilita_filtro: false,
        habilita_grid: false,
        desativa_filtro: false,
        desativa_grid: true,
        ordenacao: div.ordenacao ? div.ordenacao : lista.length + 1,
      });
    },
    atualizarId(id) {
      this.id = id;
    },
    atualizarObjeto(passo, objeto) {
      this.objetoOrcamento['passo'.concat(passo)] = copyObject(objeto);
    },
    cancelar() {
      this.$router.push({ name: 'parametrizacao-orcamento' });
    },
    concluirPassos(passo, objeto) {
      this.atualizarObjeto(passo, objeto);

      if (this.id) {
        this.paramOrcamentoResources.atualizar(this.preparaPassos()).then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$router.push({ name: 'parametrizacao-orcamento' });
        })
          .catch((err) => {
            this.$error(this, err);
          });
      } else {
        this.paramOrcamentoResources.gravar(this.preparaPassos()).then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$router.push({ name: 'parametrizacao-orcamento' });
        })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    continuar(proximoPasso, id) {
      this.e1 = proximoPasso;

      if (id) {
        this.id = id;
      }
      this.$refs.passo2.atualizarVariaveisDeContexto();
      this.montarComposicoes(this.$refs.passo2.getListaCliente());
      this.divisoes = this.$refs.passo2.getListaDivisao();
      this.$refs.passo4.setListaLinha(this.montarListaLinha());
      this.$refs.passo4.setListaResumo(this.montarListaResumo());
      this.$refs.passo5.setListaLinha(this.montarListaLinha());
      this.$refs.passo5.setListaResumo(this.montarListaResumo(true));
    },
    intersecao(array1, array2) {
      return array1.filter((a) => array2.some((b) => a === b.value));
    },
    montarComposicoes(listaCliente) {
      this.composicoes = [];
      if (listaCliente) {
        this.composicoes = listaCliente.filter((item) => item.contexto)
          .map((item) => { //eslint-disable-line
            return { text: item.nome, value: item.label, mnemonico: item.mnemonico };
          });
      }
      const compPasso3 = this.$refs.passo3.getComposicao();
      this.$refs.passo3.setComposicao(this.intersecao(compPasso3 || [], this.composicoes || []));
    },
    montarListaLinha() {
      let lista = [];
      const filtrarLinha = (item) => item.contexto === 'LINHA';
      lista = lista.concat(this.objetoOrcamento.passo2.listaPadrao
        .filter(filtrarLinha));
      lista = lista.concat(this.objetoOrcamento.passo2.listaCliente
        .filter(filtrarLinha));
      lista = lista.concat(this.objetoOrcamento.passo2.listaProduto
        .filter(filtrarLinha)
        .filter((item) => this.jaPertenceALista(lista, item)));
      lista = lista.concat(this.objetoOrcamento.passo2.listaOutros
        .filter(filtrarLinha));
      lista = lista.concat(this.objetoOrcamento.passo2.listaDinamica
        .filter(filtrarLinha));

      if (this.objetoOrcamento.passo2.listaDivisao.length > 0) {
        lista = this.adicionarDivisao(lista);
      }
      return lista;
    },
    jaPertenceALista(lista, item) {
      return !lista.find((i) => i.label === item.label);
    },
    montarListaResumo(passo5) {
      let lista = [];

      const filtrarResumo = (item) => item.contexto === 'RESUMO';
      lista = lista.concat(this.objetoOrcamento.passo2.listaPadrao
        .filter(filtrarResumo));

      if (passo5 && this.objetoOrcamento.passo1.tags) {
        lista.push({
          nome: this.$tc('label.verba', 1),
          contexto: 'RESUMO',
          obrigatorio: true,
          label: 'verba',
          apenas_ordenacao: false,
          habilita_grid: false,
          habilita_filtro: false,
          desativa_filtro: false,
          desativa_grid: false,
        });
      }

      lista = lista.concat(this.objetoOrcamento.passo2.listaCliente
        .filter(filtrarResumo));
      lista = lista.concat(this.objetoOrcamento.passo2.listaProduto
        .filter(filtrarResumo)
        .filter((item) => this.jaPertenceALista(lista, item)));
      lista = lista.concat(this.objetoOrcamento.passo2.listaOutros
        .filter(filtrarResumo));
      lista = lista.concat(this.objetoOrcamento.passo2.listaDinamica
        .filter(filtrarResumo));
      return lista;
    },
    preparaPassos() {
      const callback = (el) => el.contexto !== 'N/A';
      const filtraElemento = (tipo) => tipo.filter(callback);
      const objetoRequisicao = copyObject(this.objetoOrcamento);
      objetoRequisicao.passo2.listaCliente = filtraElemento(objetoRequisicao.passo2.listaCliente);
      objetoRequisicao.passo2.listaProduto = filtraElemento(objetoRequisicao.passo2.listaProduto);
      objetoRequisicao.passo2.listaPadrao = filtraElemento(objetoRequisicao.passo2.listaPadrao);
      objetoRequisicao.passo2.listaOutros = filtraElemento(objetoRequisicao.passo2.listaOutros);
      objetoRequisicao.passo2.listaDinamica = filtraElemento(objetoRequisicao.passo2.listaDinamica);

      if (objetoRequisicao.passo3.composicao.valor) {
        objetoRequisicao.passo3.composicao.valor = objetoRequisicao.passo3.composicao.valor.join(',');
      }
      return objetoRequisicao;
    },
    voltar(passo) {
      this.e1 = passo;
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    } else if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
    }
  },
};
</script>
