import { render, staticRenderFns } from "./TabelaGenericaRecursiva.vue?vue&type=template&id=9e24c5e0"
import script from "./TabelaGenericaRecursiva.vue?vue&type=script&lang=js"
export * from "./TabelaGenericaRecursiva.vue?vue&type=script&lang=js"
import style0 from "./TabelaGenericaRecursiva.vue?vue&type=style&index=0&id=9e24c5e0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports