<template>
  <apuracao-acao-detalhes-resumo-execucao
      :resumoExec="resumo"
      :exibe-titulo="false"
      v-if="resumo"/>
</template>

<script>
import ApuracaoAcaoDetalhesResumoExecucao from '@/produto/spa/apuracao-acao-lista/ApuracaoAcaoDetalhesResumoExecucao';

export default {
  name: 'ApuracaoAcaoListaModalGerarNdDetalhes',
  components: { ApuracaoAcaoDetalhesResumoExecucao },
  props: {
    value: Object,
  },
  watch: {
    value() {
      this.converterResumo();
    },
  },
  data() {
    return {
      resumo: null,
    };
  },
  methods: {
    converterResumo() {
      if (!this.value) return;
      const resumoTemporario = {};
      resumoTemporario.id = this.value.cod_acao;
      resumoTemporario.focoAcao = this.value.foco_acao;
      resumoTemporario.descFornecedor = this.value.nome_grupo_fornecedor
        ? this.value.nome_grupo_fornecedor : this.value.nome_fornecedor;
      resumoTemporario.dtInicioVigencia = this.value.data_inicio;
      resumoTemporario.dtFimVigencia = this.value.data_fim;
      resumoTemporario.vlrApuracaoPrevia = this.value.apuracao_previa;
      resumoTemporario.vlrApuracaoTotal = this.value.apuracao_total;
      resumoTemporario.vlrApuracaoTotalExecucao = this.value.apuracao_total_execucao;
      resumoTemporario.vlrApuracaoPreviaExecucao = this.value.apuracao_previa_execucao;
      resumoTemporario.vlrApuracaoFinal = this.value.vlr_conf_apuracao;
      resumoTemporario.vlrApuracaoFinalOriginal = this.value.vlr_conf_apuracao_original;
      resumoTemporario.vlrApuracaoFinalExecucao = this.value.vlr_conf_apuracao_execucao;
      resumoTemporario.notaDebito = this.value.nota_debito;
      resumoTemporario.valorRecebimentoPendente = this.value.valor_recebimento_pendente;
      resumoTemporario.vlrTetoAcao = this.value.teto_acao;
      resumoTemporario.vlrVerbaNaoCapturada = this.value.verba_nao_capturada;
      resumoTemporario.vlrVerbaAcimaDoTeto = this.value.verba_acima_do_teto;
      resumoTemporario.status = this.value.status_apuracoes;
      resumoTemporario.tipoCalculo = this.value.tipo_calculo;
      resumoTemporario.temGatilho = !!this.value.tipo_gatilho;
      resumoTemporario.possuiValorMaximoNd = this.value.possui_valor_maximo_nd;
      resumoTemporario.isCalculoProdutoNdMaximo = this.value.is_calculo_produto_nd_maximo;
      resumoTemporario.isCalculoCampanhaNdMaximo = this.value.is_calculo_campanha_nd_maximo;
      this.resumo = resumoTemporario;
    },
  },
  mounted() {
    this.converterResumo();
  },
};
</script>

<style scoped>

</style>
