export default class Divisao {
  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.nome = data.nome;
      this.idExterno = data.idExterno;
      this.estruturaUsuario = data.estruturaUsuario;
      this.nomeEstruturaUsuario = data.estruturaUsuario.nome;
      this.divisoesSuperiores = data.divisoesSuperiores;
      this.carteirasCliente = data.carteirasCliente;
      this.desAtributos = Divisao.montarDesAtributos(data);
    } else {
      this.estruturaUsuario = {
        nome: null,
        id: null,
      };
      this.divisoesSuperiores = [];
      this.carteirasCliente = [];
      this.desAtributos = {
        regional: false,
        diretoria: false,
      };
    }
  }

  possuiDivisoesSuperiores() {
    return this.divisoesSuperiores.length > 0;
  }

  construirAPartirDaConsultaCompleta(dadosDaConsulta) {
    this.id = dadosDaConsulta.id;
    this.nome = dadosDaConsulta.nome;
    this.idExterno = dadosDaConsulta.idExterno;
    this.nomeEstruturaUsuario = dadosDaConsulta.nomeEstruturaUsuario;
    this.estruturaUsuario.nome = dadosDaConsulta.nomeEstruturaUsuario;
    this.estruturaUsuario.id = dadosDaConsulta.idEstruturaUsuario;
    this.divisoesSuperiores = Divisao.parsearCampos(dadosDaConsulta.divisoesSuperiores, 'nome');
    this.carteirasCliente = Divisao.parsearCampos(dadosDaConsulta.carteirasCliente, 'descricao');
    this.desAtributos = dadosDaConsulta.desAtributos;
  }

  static parsearCampos(todasDivisoesEmUmaUnicaString, atributo) {
    if (!todasDivisoesEmUmaUnicaString) {
      return [];
    }
    if (todasDivisoesEmUmaUnicaString.includes(';;')) {
      return todasDivisoesEmUmaUnicaString.split(';;').map((umaDivisaoEmString) => {
        const arrayComAtributosDaDivisao = umaDivisaoEmString.split('|');
        const retorno = {};
        const [id, valor] = arrayComAtributosDaDivisao;
        retorno.id = parseInt(id, 10);
        retorno[atributo] = valor;
        return retorno;
      });
    }
    return todasDivisoesEmUmaUnicaString.split(',,').map((umaDivisaoEmString) => {
      const arrayComAtributosDaDivisao = umaDivisaoEmString.split('|');
      const retorno = {};
      const [id, valor] = arrayComAtributosDaDivisao;
      retorno.id = parseInt(id, 10);
      retorno[atributo] = valor;
      return retorno;
    });
  }

  static parsearMetadados(itemEmUmaString, manyToMany) {
    if (manyToMany) {
      if (typeof itemEmUmaString === 'object') {
        return itemEmUmaString;
      }
      if (!itemEmUmaString) {
        return [];
      }
      return itemEmUmaString.split(',').map((umaDivisaoEmString) => {
        const arrayComAtributosDaDivisao = umaDivisaoEmString.split('|');
        return {
          id: parseInt(arrayComAtributosDaDivisao[0], 10),
          idExterno: arrayComAtributosDaDivisao[1],
          nomExtensao: arrayComAtributosDaDivisao[2],
        };
      });
    }
    if (!itemEmUmaString) {
      return {};
    }

    let arrayComAtributosDaDivisao = null;
    if (Divisao.ehObjeto(itemEmUmaString)) {
      return itemEmUmaString;
    }

    arrayComAtributosDaDivisao = itemEmUmaString.split('|');
    return {
      id: parseInt(arrayComAtributosDaDivisao[0], 10),
      idExterno: arrayComAtributosDaDivisao[1],
      nomExtensao: arrayComAtributosDaDivisao[2],
    };
  }

  montarEstruturaDinamica(dadosDaConsulta, metadados) {
    Object.keys(dadosDaConsulta).forEach((dados) => {
      Object.keys(metadados.mapaEntidades).forEach((entidade) => {
        if (dados === entidade) {
          this[entidade] = Divisao.parsearMetadados(dadosDaConsulta[entidade],
            metadados.mapaEntidades[entidade].manyToMany);
        }
      });
    });
  }

  getNomesSeparadosPorVirgula(nomeObjeto, atributo) {
    if (!this[nomeObjeto]
      || (Divisao.ehObjeto(this[nomeObjeto]) && Divisao.objetoVazio(this[nomeObjeto]))
      || (Divisao.ehArray(this[nomeObjeto]) && this[nomeObjeto].length === 0)) {
      return '';
    }

    if (Divisao.ehArray(this[nomeObjeto])) {
      return this[nomeObjeto].map((obj) => obj[atributo]).join(', ');
    }
    return this[nomeObjeto][atributo];
  }

  static objetoVazio(objeto) {
    return Object.keys(objeto).length === 0 && objeto.constructor === Object;
  }

  static ehArray(objeto) {
    return Array.isArray(objeto);
  }

  static ehObjeto(objeto) {
    return objeto instanceof Object;
  }

  montarArrayComIdsDasCarteirasDeCliente() {
    return this.carteirasCliente.map((aa) => aa.id);
  }

  montarArrayComIdsDasDivisoesSuperiores() {
    return this.divisoesSuperiores.map((ds) => ds.id);
  }

  ehEdicao() {
    return this.id;
  }

  static montarDesAtributos(dados) {
    dados.desAtributos = dados.desAtributos
      && dados.desAtributos.value
      ? JSON.parse(dados.desAtributos.value) : {};

    return { ...dados.desAtributos };
  }
}
