<template>
  <div>
    <v-container fluid grid-list-md text-xs-center id="liquidacao-totalizadores">
      <v-row align="center" justify="center" fill-height>
        <v-col cols="12" class="tamanho-cards" v-for="card in totalizadoresOrdenados" :key="card.titulo">
          <v-card :style="{'background-color': `${card.cor}`}">
            <v-card-title style="white-space: nowrap">
              <v-row class="headline white--text">
                <v-col offset="2" cols="8" class="text-center pt-0"> {{ $tc(card.titulo, 1) }}</v-col>
                <v-col cols="2" class="text-right pt-0">
                  <tooltip-ajuda-lista :titulo="`${$tc('label.status', 1)}: `" :lista="card.statusComposicao || statusPadroes[card.status]" />
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row class="headline white--text">
                <v-col cols="12" class="text-center pa-0">{{ getMoney(card.valorMonetario) }}</v-col>
                <v-col cols="12" class="text-center pa-0">{{ getPercent(card.valorPercentual) }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import { listarApuracoesTotalizadores } from '../../common/resources/apuracao/apuracao-acao-listagem';
import TooltipAjudaLista from '../../shared-components/TooltipAjudaLista';

export default {
  name: 'ApuracaoCardsTotalizadores',
  components: {
    TooltipAjudaLista,
  },
  props: {
    filtros: Object,
  },
  data() {
    return {
      lista: [],
      filtrosAplicados: this.filtros,
      statusPadroes: {
        APURADO: ['status_entidade.aprovado'],
        PENDENTE_APURACAO: ['status_entidade.apuracao_previa', 'status_entidade.aguardando_apuracao', 'status_entidade.aguardando_aprovacao', 'status_entidade.em_analise'],
        REJEITADA: ['status_entidade.cancelado', 'status_entidade.reprovado'],
      },
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscaTotalizadores();
      },
    },
  },
  computed: {
    totalizadoresOrdenados() {
      function compare(a, b) {
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        return 0;
      }

      return this.lista.sort(compare);
    },
  },
  methods: {
    getMoney,
    getPercent,
    buscaTotalizadores() {
      const params = {
        ...this.filtrosAplicados,
      };

      listarApuracoesTotalizadores(params, this.$resource)
        .then((response) => {
          this.lista = response.data;
          if (this.lista.length !== 3) {
            this.tratarCards();
          }
          this.tratarCores();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    tratarCores() {
      this.lista.forEach((item) => {
        if (item.status === 'APURADO') {
          item.cor = 'rgb(26, 188, 156)';
          item.ordenacao = 1;
        } else if (item.status === 'PENDENTE_APURACAO') {
          item.cor = 'rgb(230, 126, 34)';
          item.ordenacao = 2;
        } else if (item.status === 'REJEITADA') {
          item.cor = 'rgb(231, 76, 60)';
          item.ordenacao = 3;
        }
      });
    },

    tratarCards() {
      const listaCardsSemDados = [
        { status: 'APURADO', titulo: this.$tc('title.apurado') },
        { status: 'PENDENTE_APURACAO', titulo: this.$tc('label.pendente_apuracao') },
        { status: 'REJEITADA', titulo: this.$tc('title.apuracao_rejeitada') },
      ];

      this.lista.forEach((item) => {
        if (item.status === 'APURADO') {
          listaCardsSemDados.splice(listaCardsSemDados.findIndex((card) => card.status === 'APURADO'), 1);
        } else if (item.status === 'PENDENTE_APURACAO') {
          listaCardsSemDados.splice(listaCardsSemDados.findIndex((card) => card.status === 'PENDENTE_APURACAO'), 1);
        } else if (item.status === 'REJEITADA') {
          listaCardsSemDados.splice(listaCardsSemDados.findIndex((card) => card.status === 'REJEITADA'), 1);
        }
      });

      if (listaCardsSemDados.length) {
        listaCardsSemDados.forEach((item) => {
          this.lista.push({
            titulo: item.titulo,
            status: item.status,
            valorMonetario: 0,
            valorPercentual: 0,
          });
        });
      }
    },
  },
  beforeMount() {
  },
  mounted() {
    if (this.filtros) {
      this.buscaTotalizadores();
    }
  },
};
</script>

<style>
  #liquidacao-totalizadores .tamanho-cards {
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
    padding: 12px;
  }

  @media screen and (max-width: 1420px) {
    #liquidacao-totalizadores .tamanho-cards {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      width: 100%;
      padding: 12px;
    }
  }

  @media screen and (max-width: 1060px) {
    #liquidacao-totalizadores .tamanho-cards {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      padding: 12px;
    }
  }
</style>
