import vanillaMasker from 'vanilla-masker';
import trigger from './trigger';

const updateValue = (el) => {
  trigger(el, 'input');
};

const removePunctuation = (str) => {
  str = str.replace('.', '');
  str = str.replace('.', '');
  str = str.replace('.', '');
  str = str.replace('-', '');
  str = str.replace('/', '');
  return str;
};

const getChildrenNode = (el) => (el.tagName === 'INPUT' ? el : el.querySelector('input'));

const reduceMinLength = (actualLength, possibilities) => possibilities.reduce(
  (elemenOne, elemenTwo) => {
    const elemenOneLength = removePunctuation(elemenOne).length;
    const elemenTwoLength = removePunctuation(elemenTwo).length;

    if (elemenOneLength < elemenTwoLength) {
      if (elemenOneLength >= actualLength) {
        return elemenOne;
      }
    }

    return elemenTwo;
  },
);

const docMask = ['999.999.999-99', '99.999.999/9999-99'];

const chooseMask = (el, elemenInputLength) => {
  if (el.dataset && el.dataset.tipoPessoa) {
    if (el.dataset.tipoPessoa === 'FISICA') {
      return docMask[0];
    }
    return docMask[1];
  }
  return reduceMinLength(elemenInputLength, docMask);
};

export const vCpfCnpj = {
  bind(el, binding) {
    const elementInput = getChildrenNode(el);
    const pattern = (binding.value)
      ? binding.value.mask
      : docMask[0];

    vanillaMasker(elementInput).maskPattern(pattern);
  },
  update(el, binding) {
    const elementInput = getChildrenNode(el);
    const elemenInputLength = removePunctuation(elementInput.value).length;
    const minLengthMask = chooseMask(el, elemenInputLength);

    const pattern = (binding.value)
      ? binding.value.mask
      : minLengthMask;

    const compare = vanillaMasker.toPattern(
      elementInput.value.substring(0, pattern.length).replace(/[^0-9]/gi, ''),
      pattern,
    );

    if (elementInput.value !== compare) {
      elementInput.value.replace(/\D/g, '');
      vanillaMasker(elementInput).unMask();
      vanillaMasker(elementInput).maskPattern(pattern);

      updateValue(elementInput);
    }
  },
};

export default vCpfCnpj;
