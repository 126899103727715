const ApuracaoContratoAntigo = () => import('../../../spa/apuracao-contrato-antigo/ApuracaoContratoAntigo');
const ApuracaoContratoNivel1 = () => import('../../../spa/apuracao-contrato-antigo/ApuracaoContratoNivel1');
const ApuracaoContratoNivel2 = () => import('../../../spa/apuracao-contrato-antigo/ApuracaoContratoNivel2');
const ApuracaoContratoDetalhesCard = () => import('../../../spa/apuracao-contrato-antigo/ApuracaoContratoDetalhesCard');
const ApuracaoContratoFinalizarLote = () => import('../../../spa/apuracao-contrato-antigo/ApuracaoContratoFinalizarLote');
const ApuracaoContratoFinalizarLoteIndustria = () => import('../../../spa/apuracao-contrato-antigo/finalizar-lote/ApuracaoContratoFinalizarLoteIndustria');

export default {
  path: 'apuracao-contrato-antigo',
  component: ApuracaoContratoAntigo,
  children: [
    {
      path: '',
      name: 'apuracao-contrato-antigo',
      component: ApuracaoContratoNivel1,
    },
    {
      path: 'status/:status',
      name: 'listarApuracoesContratoNivel2Status',
      component: ApuracaoContratoNivel2,
    },
    {
      path: 'contrato/:idContrato',
      name: 'listarApuracoesContratoNivel2Contrato',
      component: ApuracaoContratoNivel2,
    },
    {
      path: 'status/:status/contrato/:idContrato/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoContratoStatus',
      component: ApuracaoContratoDetalhesCard,
    },
    {
      path: 'contrato/:idContrato/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoContrato',
      component: ApuracaoContratoDetalhesCard,
    },
    {
      path: 'contrato/:idContrato/acao/:idAcao/apuracao/:id/origem/:origem',
      name: 'detalharApuracaoContratoOrigem',
      component: ApuracaoContratoDetalhesCard,
    },
    {
      path: 'status/:status/finalizar',
      name: 'finalizarLoteApuracoesContratoStatus',
      component: ApuracaoContratoFinalizarLote,
    },
    {
      path: 'contrato/:idContrato/finalizar',
      name: 'finalizarLoteApuracoesContrato',
      component: ApuracaoContratoFinalizarLote,
    },
    {
      path: 'industria/status/:status/finalizar',
      name: 'finalizarLoteApuracoesContratoIndustriaStatus',
      component: ApuracaoContratoFinalizarLoteIndustria,
    },
    {
      path: 'industria/contrato/:idContrato/finalizar',
      name: 'finalizarLoteApuracoesContratoIndustria',
      component: ApuracaoContratoFinalizarLoteIndustria,
    },
  ],
};
