<template>
  <metadados-extensao v-if="metadados.campoExtensao"
    :metadados="metadados"
    v-model="objeto[metadados.label]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    :ignora-filtro-recursivo="ignoraFiltroRecursivo"
    :novoCadastro="novoCadastro"
    :get-valores-dependencias="getValoresDependencias"
    :exibe-loading-overlay="exibeLoadingOverlay"
    :ignora-inativos="ignoraInativos"
    @MetadadosExtensao_FiltraAmostragemRecursiva="filtraAmostragemRecursiva"
    @MetadadosExtensao_ValorSelecionado="valorAlterado"
    @MetadadosExtensao_abrirCadastro="abrirCadastro"
    ref="componente"/>

  <metadados-campo-dinamico-autocomplete v-else-if="campoAutocomplete"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    :exibe-loading-overlay="exibeLoadingOverlay"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-texto v-else-if="campoTexto"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

    <metadados-campo-dinamico-texto-tooltip v-else-if="campoTextoTooltip"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    :mensagem-tooltip="mensagemTooltip"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-textarea v-else-if="campoTextArea"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-dinheiro v-else-if="campoDinheiro"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-boolean v-else-if="campoBoolean"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-data v-else-if="campoData"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-hora v-else-if="campoHora"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-numerico v-else-if="campoNumerico"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :vlr-padrao="metadados.vlrPadrao"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-percentual v-else-if="campoPercentual"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-multipla-escolha v-else-if="campoMultiplaEscolha"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-lista v-else-if="campoLista"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-checkbox v-else-if="campoCheckbox"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-radio v-else-if="campoRadio"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :nomeCampo="metadados.labelCampo"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    ref="componente"/>

  <metadados-campo-dinamico-lista-checkbox v-else-if="campoListaCheckbox"
    :metadados="metadados"
    v-model="objeto[metadados.labelCampo]"
    :objeto-container="objeto"
    :somente-leitura="somenteLeitura"
    :ignora-obrigatorios="ignoraObrigatorios"
    :ignora-desabilitados="ignoraDesabilitados"
    :grupo-ativo="grupoAtivo"
    @MetadadosCampoDinamico_valorAlterado="valorAlterado"
    @MetadadosCampoDinamicoListaCheckbox_GrupoAtivo="(val) => $emit('MetadadosComponenteDinamico_GrupoAtivo', val)"
    ref="componente"/>

</template>
<script>
import MetadadosExtensao from './MetadadosExtensao';
import MetadadosCampoDinamicoAutocomplete from './campos-dinamicos/MetadadosCampoDinamicoAutocomplete';
import MetadadosCampoDinamicoTexto from './campos-dinamicos/MetadadosCampoDinamicoTexto';
import MetadadosCampoDinamicoTextoTooltip from './campos-dinamicos/MetadadosCampoDinamicoTextoTooltip';
import MetadadosCampoDinamicoDinheiro from './campos-dinamicos/MetadadosCampoDinamicoDinheiro';
import MetadadosCampoDinamicoBoolean from './campos-dinamicos/MetadadosCampoDinamicoBoolean';
import MetadadosCampoDinamicoData from './campos-dinamicos/MetadadosCampoDinamicoData';
import MetadadosCampoDinamicoHora from './campos-dinamicos/MetadadosCampoDinamicoHora';
import MetadadosCampoDinamicoNumerico from './campos-dinamicos/MetadadosCampoDinamicoNumerico';
import MetadadosCampoDinamicoPercentual from './campos-dinamicos/MetadadosCampoDinamicoPercentual';
import MetadadosCampoDinamicoMultiplaEscolha from './campos-dinamicos/MetadadosCampoDinamicoMultiplaEscolha';
import MetadadosCampoDinamicoLista from './campos-dinamicos/MetadadosCampoDinamicoLista';
import MetadadosCampoDinamicoCheckbox from './campos-dinamicos/MetadadosCampoDinamicoCheckbox';
import MetadadosCampoDinamicoListaCheckbox from './campos-dinamicos/MetadadosCampoDinamicoListaCheckbox';
import MetadadosCampoDinamicoRadio from './campos-dinamicos/MetadadosCampoDinamicoRadio';
import MetadadosCampoDinamicoTextarea from './campos-dinamicos/MetadadosCampoDinamicoTextarea';

export default {
  props: {
    campoExtensao: {
      type: Boolean,
      default: false,
    },
    objeto: Object,
    metadados: {
      type: Object,
      required: true,
    },
    ignoraObrigatorios: {
      type: Boolean,
      default: false,
    },
    ignoraDesabilitados: {
      type: Boolean,
      default: false,
    },
    ignoraFiltroRecursivo: {
      type: Boolean,
      default: false,
    },
    novoCadastro: {
      type: Boolean,
      default: false,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    filtroRapido: {
      type: Boolean,
      default: false,
    },
    grupoAtivo: {
      type: Boolean,
      default: false,
    },
    getValoresDependencias: {
      type: Function,
      default: () => [],
    },
    exibeLoadingOverlay: {
      type: Boolean,
      default: true,
    },
    ignoraInativos: {
      type: Boolean,
      default: true,
    },
    mensagemTooltip: {
      type: String,
      default: '',
    },
  },
  components: {
    MetadadosCampoDinamicoListaCheckbox,
    MetadadosCampoDinamicoCheckbox,
    MetadadosExtensao,
    MetadadosCampoDinamicoAutocomplete,
    MetadadosCampoDinamicoTexto,
    MetadadosCampoDinamicoTextoTooltip,
    MetadadosCampoDinamicoDinheiro,
    MetadadosCampoDinamicoBoolean,
    MetadadosCampoDinamicoData,
    MetadadosCampoDinamicoHora,
    MetadadosCampoDinamicoNumerico,
    MetadadosCampoDinamicoPercentual,
    MetadadosCampoDinamicoMultiplaEscolha,
    MetadadosCampoDinamicoLista,
    MetadadosCampoDinamicoRadio,
    MetadadosCampoDinamicoTextarea,
  },
  computed: {
    campoAutocomplete() {
      return !this.metadados.campoExtensao
        && this.metadados.async != null
        && (this.metadados.tipoCampo === 'MULTIPLA_ESCOLHA'
        || this.metadados.tipoCampo === 'LISTA');
    },
    campoTexto() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'TEXTO';
    },
    campoTextoTooltip() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'TEXTO_TOOLTIP';
    },
    campoTextArea() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'TEXTAREA';
    },
    campoCheckbox() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'CHECKBOX';
    },
    campoRadio() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'RADIO';
    },
    campoListaCheckbox() {
      return !this.metadados.campoExtensao
        && (this.metadados.tipoCampo === 'LISTA_CHECKBOX'
          || (this.filtroRapido
            && (this.metadados.tipoCampo === 'LISTA'
              || this.metadados.tipoCampo === 'MULTIPLA_ESCOLHA')));
    },
    campoDinheiro() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'DINHEIRO';
    },
    campoBoolean() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'BOOLEAN';
    },
    campoData() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'DATA';
    },
    campoHora() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'HORA';
    },
    campoNumerico() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'NUMERICO';
    },
    campoPercentual() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'PERCENTUAL';
    },
    campoMultiplaEscolha() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'MULTIPLA_ESCOLHA'
        && !this.filtroRapido;
    },
    campoLista() {
      return !this.metadados.campoExtensao
        && this.metadados.tipoCampo === 'LISTA'
        && !this.filtroRapido;
    },
  },
  methods: {
    filtraAmostragemRecursiva(metadadosExtensao) {
      this.$emit('MetadadosExtensao_FiltraAmostragemRecursiva', metadadosExtensao);
    },
    refresh() {
      this.$forceUpdate();
      if (this.$refs.componente) {
        this.$refs.componente.refresh();
      }
    },
    valorAlterado(valor) {
      const label = this.metadados.label || this.metadados.labelCampo;
      const { desAtributos } = this.metadados;
      const alteracao = {
        valor,
        label,
      };
      if (this.metadados.dependentes) {
        alteracao.dependentes = this.metadados.dependentes;
      } else {
        alteracao.dependentes = desAtributos ? desAtributos.dependentes || [] : [];
      }

      this.$emit('MetadadosComponenteDinamico_valorAlterado', alteracao);
    },
    abrirCadastro(metadados) {
      this.$emit('MetadadosComponenteDinamico_abrirCadastro', metadados);
    },
  },
};
</script>
