export default {
  buildGET(resource, basePath, uri, params) {
    let queryParams = '';

    const concatParam = (key, value) => {
      const concat = queryParams.length !== 0 ? '&' : '';
      queryParams = `${queryParams}${concat}${key}=${value}`;
    };

    Object
      .keys(params)
      .filter((key) => params[key] || params[key] === false)
      .forEach((key) => {
        const value = params[key];
        if (!Array.isArray(value)) {
          concatParam(key, value);
        } else {
          value.forEach((itemValue) => {
            concatParam(key, itemValue);
          });
        }
      });

    const pathWithSlash = basePath.endsWith('/') ? basePath : `${basePath}/`;

    const actions = {
      doGet: { method: 'GET', url: `${pathWithSlash}${uri}?${queryParams}` },
    };

    return resource(basePath, {}, actions);
  },
  buildPUT(resource, basePath, uri, params) {
    let queryParams = '';

    const concatParam = (key, value) => {
      const concat = queryParams.length !== 0 ? '&' : '';
      queryParams = `${queryParams}${concat}${key}=${value}`;
    };

    Object
      .keys(params)
      .filter((key) => params[key] || params[key] === false)
      .forEach((key) => {
        const value = params[key];
        if (!Array.isArray(value)) {
          concatParam(key, value);
        } else {
          value.forEach((itemValue) => {
            concatParam(key, itemValue);
          });
        }
      });

    const pathWithSlash = basePath.endsWith('/') ? basePath : `${basePath}/`;

    const actions = {
      doPut: { method: 'PUT', url: `${pathWithSlash}${uri}?${queryParams}` },
    };

    return resource(basePath, {}, actions);
  },
  buildPOST(resource, basePath, uri, params) {
    let queryParams = '';

    const concatParam = (key, value) => {
      const concat = queryParams.length !== 0 ? '&' : '';
      queryParams = `${queryParams}${concat}${key}=${value}`;
    };

    Object
      .keys(params)
      .filter((key) => params[key] || params[key] === false)
      .forEach((key) => {
        const value = params[key];
        if (!Array.isArray(value)) {
          concatParam(key, value);
        } else {
          value.forEach((itemValue) => {
            concatParam(key, itemValue);
          });
        }
      });

    const pathWithSlash = basePath.endsWith('/') ? basePath : `${basePath}/`;

    const actions = {
      doPost: { method: 'POST', url: `${pathWithSlash}${uri}?${queryParams}` },
    };

    return resource(basePath, {}, actions);
  },
};
