import getBasePath from '../../functions/api-resource';
import genericActions from '../generic-resource';

const basePath = getBasePath('api', 'configuracao');

const configuracaoActions = {
  ...genericActions(basePath),
};

export default (resource) => resource(`${basePath}`, {}, configuracaoActions);
