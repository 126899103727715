import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'cliente');

const clienteActions = {
  ...genericActions(basePath),
  ativarMassa: { method: 'PUT', url: `${basePath}/ativacao-massa` },
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  buscarTiposBeneficio: { method: 'GET', url: `${basePath}/tipo-beneficio` },
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}` },
  selecaoPlanilha: { method: 'GET', url: `${basePath}/selecao/planilha` },
};

export default (resource) => resource(`${basePath}`, {}, clienteActions);
