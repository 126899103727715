import getBasePath from '../../functions/api-resource';
import resourceBuilder from '../../functions/metadados-resource-builder';

const basePath = getBasePath('apuracao_acao', 'edicao');

const apuracaoActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  buscarJustificativaAlteracaoValor: { method: 'GET', url: `${basePath}/{idApuracao}/justificativa-alteracao-valor` },
  buscarJustificativaAlteracaoValorBase: { method: 'GET', url: `${basePath}/{idApuracao}/justificativa-alteracao-valor-base` },
  validaSaldoContaUnica: { method: 'GET', url: `${basePath}/{idApuracao}/validacao/saldo` },
  buscaContasVinculadas: { method: 'GET', url: `${basePath}/{idApuracao}/contas` },
  atualizarDesAtributos: { method: 'PUT', url: `${basePath}/des_atributos` },
  atualizarComplementoInformacoes: { method: 'PUT', url: `${basePath}/complemento_informacoes` },
  resgatar: { method: 'PUT', url: `${basePath}/{idApuracao}/resgate` },
  concluirApuracao: { method: 'PUT', url: `${basePath}/{idApuracao}/concluir` },
  alterarDataFim: { method: 'PUT', url: `${basePath}/{idApuracao}/data-fim` },
  cancelar: { method: 'PUT', url: `${basePath}/cancelar` },
  atualizarNdPrevia: { method: 'PUT', url: `${basePath}/atualizar-nd-previa` },
  atualizarFornecedorResumo: { method: 'PUT', url: `${basePath}/atualizar-fornecedor-resumo/{idAcao}/` },
  recalcularFornecedores: { method: 'POST', url: `${basePath}/recalcular-fornecedores/{idAcao}` },
  salvarIdAcaoFornecedor: { method: 'POST', url: `${basePath}/salvar-id-acao-fornecedor/{idAcao}/{idAcaoFornecedor}` },
  salvarMultiplasNds: { method: 'PUT', url: `${basePath}/{idNotaDebitoPrevia}/salvar-multiplas-nds` },
  gerarNumeroNdMultiplasNds: { method: 'PUT', url: `${basePath}/gerar-numero-nd-multiplas-nds` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);

export const cancelarApuracaoAgrupada = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'cancelar-apuraca-agrupada', parametros).doPut(parametros);
export const cancelarApuracao = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'cancelar-apuracao', parametros).doPut(parametros);
