<template lang="html">
  <div v-if="canAccessPage">
    <workflow-table
      @WORKFLOWTABLE_EDIT="editWorkflow"
      class="mb-2"/>

    <v-tooltip left v-if="canEdit">
      <template v-slot:activator="{ on }">
        <v-btn fixed fab bottom right
         class="v-btn__fab"
         color="primary"
         v-on="on"
         @click.native="createNew">
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>{{$t('label.adicionar')}}</span>
    </v-tooltip>
  </div>
</template>

<script type="text/javascript">
import WorkflowTable from './WorkflowTable';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'WorkflowList',
  computed: {
    ...generateComputed('Fluxo', [
      'canAccessPage',
      'canEdit',
      'canPost',
    ]),
  },
  components: {
    WorkflowTable,
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
      }
    },
    createNew() {
      if (!this.canPost) {
        return;
      }
      this.$router.push({ name: 'novoWorkflow' });
    },
    editWorkflow(objeto) {
      if (objeto.id) {
        this.$router.push({ name: 'editarWorkflow', params: { id: objeto.id } });
        return;
      }
      this.$toast('Erro! Elemento não possui id.');
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>

<style lang="scss">
</style>
