import vanillaMasker from 'vanilla-masker';
import trigger from './trigger';

const updateValue = (el, compare, mask) => {
  if (el.value !== compare) {
    el.value = vanillaMasker.toPattern(compare, mask);
    trigger(el, 'input');
  }
};

/* eslint-disable no-new */
const vHourFullMask = '99:99:99';
export const vHourFull = {
  bind(el) {
    vanillaMasker(el).maskPattern(vHourFullMask);
  },
  update(el) {
    const compare = el.value.substring(0, vHourFullMask.length).replace(/[^0-9:]/gi, '');

    updateValue(el, compare, vHourFullMask);
  },
};

const vHourMask = '99:99';
export const vHour = {
  bind(el) {
    vanillaMasker(el).maskPattern(vHourMask);
  },
  update(el) {
    const compare = el.value.substring(0, vHourMask.length).replace(/[^0-9:]/gi, '');

    updateValue(el, compare, vHourMask);
  },
};

const vMesAnoMask = '99-9999';
export const vMesAno = {
  bind(el) {
    vanillaMasker(el).maskPattern(vMesAnoMask);
  },
  update(el) {
    if (el.value) {
      const compare = el.value.substring(0, vMesAnoMask.length).replace(/[^0-9-]/gi, '');
      updateValue(el, compare, vMesAnoMask);
    }
  },
};

const vDateFullMask = '99-99-9999';
export const vDateFull = {
  bind(el) {
    vanillaMasker(el).maskPattern(vDateFullMask);
  },
  update(el) {
    const compare = el.value.substring(0, vDateFullMask.length).replace(/[^0-9-]/gi, '');

    updateValue(el, compare, vDateFullMask);
  },
};

const vDateInternationalMask = '9999-99-99';
export const vDateInternational = {
  bind(el) {
    vanillaMasker(el).maskPattern(vDateInternationalMask);
  },
  update(el) {
    const compare = el.value.substring(0, vDateInternationalMask.length).replace(/[^0-9-]/gi, '');

    updateValue(el, compare, vDateInternationalMask);
  },
};

const vDateMask = '99/99/99';
export const vDate = {
  bind(el) {
    vanillaMasker(el).maskPattern(vDateMask);
  },
  update(el) {
    const compare = el.value.substring(0, vDateMask.length).replace(/[^0-9/]/gi, '');

    updateValue(el, compare, vDateMask);
  },
};

const vPhoneMask = '(99) 99999-9999';
export const vPhone = {
  bind(el, binding) {
    const pattern = (binding.value) ? binding.value.mask : vPhoneMask;
    vanillaMasker(el).maskPattern(pattern);
  },
  update(el) {
    const compare = el.value.substring(0, vPhoneMask.length).replace(/[^0-9-()\s]/gi, '');
    if (el.value.length === 1) {
      updateValue(el, '', vPhoneMask);
    }
    updateValue(el, compare, vPhoneMask);
  },
};

const vCNPJMask = '99.999.999/9999-99';
export const vCNPJ = {
  bind(el) {
    vanillaMasker(el).maskPattern(vCNPJMask);
  },
  update(el) {
    const compare = el.value.substring(0, vCNPJMask.length).replace(/[^0-9.,-/]/gi, '');

    updateValue(el, compare, vCNPJMask);
  },
};

const vCPFMask = '999.999.999-99';
export const vCPF = {
  bind(el) {
    vanillaMasker(el).maskPattern(vCPFMask);
  },
  update(el) {
    const compare = el.value.substring(0, vCPFMask.length).replace(/[^0-9.,-/]/gi, '');

    updateValue(el, compare, vCPFMask);
  },
};

const vCEPMask = '99999-999';
export const vCEP = {
  bind(el) {
    vanillaMasker(el).maskPattern(vCEPMask);
  },
  update(el) {
    const compare = el.value.substring(0, vCEPMask.length).replace(/[^0-9.,-/]/gi, '');

    updateValue(el, compare, vCEPMask);
  },
};

export const vMoney = {
  bind(el, binding) {
    const params = binding.value || {};

    vanillaMasker(el).maskMoney({
      // Decimal precision -> "90"
      precision: params.precision || 2,
      // Decimal separator -> ",90"
      separator: params.separator || ',',
      // Number delimiter -> "12.345.678"
      delimiter: params.delimiter || '.',
      // Money unit -> "R$ 12.345.678,90"
      unit: params.unit || 'R$',
      // Money unit -> "12.345.678,90 R$"
      suffixUnit: params.suffixUnit || '',
      // Force type only number instead decimal,
      // masking decimals with ",00"
      // Zero cents -> "R$ 1.234.567.890,00"
      zeroCents: !!params.zeroCents,
    });
  },
  update(el) {
    const cleanInput = el.value.replace(/[^0-9]/gi, '');
    const tempDollars = cleanInput.substring(0, cleanInput.length - 2);
    const tempCents = cleanInput.substring(cleanInput.length - 2, cleanInput.length);
    const dollars = tempDollars || '0';
    const cents = tempCents || '00';
    const compare = `${dollars}.${cents}`;

    if (el.value !== compare) {
      el.value = `R$ ${vanillaMasker.toMoney(compare)}`;
      window.setTimeout(() => {
        trigger(el, 'input');
      }, 100);
    }
  },
};

export const vInteger = {
  bind(el) {
    vanillaMasker(el).maskNumber();
  },
  update(el) {
    const cleanInput = el.value.replace(/[^0-9]/gi, '');
    const defaultValue = '0';
    if (!cleanInput) {
      el.value = +vanillaMasker.toNumber(defaultValue);
      window.setTimeout(() => {
        trigger(el, 'input');
      }, 100);
    } else {
      el.value = +cleanInput;
    }
  },
};
