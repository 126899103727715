<template>
  <div>
    <div style="text-align: center" class="title justify-center">
      <p>{{ $tc('title.nota_debito', 2) }}</p>
    </div>
    <v-container fluid grid-list-md class="DashFornecedor">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.campo_pesquisar_vencimento', 1)}`"
            single-line
            hide-details
            clearable
            solo>
            <v-menu ref="menu"
              v-model="menuFiltroData"
              :close-on-content-click="false"
              :return-value.sync="filtroAnoMes"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              slot="append-outer">
              <template v-slot:activator="{ on }">
                <v-btn text icon
                  style="margin:0"
                  v-on="on">
                  <v-icon>calendar_today</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="filtroAnoMes"
                type="month"
                color="primary"
                no-title
                scrollable
                :allowed-dates="dataValidaSelecao">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="limparFiltroMesNotas()">
                  {{$tc('label.limpar', 1)}}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="filtraMesNotas(filtroAnoMes)">
                  {{$tc('label.filtrar', 1)}}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-iterator
        :items="dados"
        :server-items-length="totalPage"
        :options.sync="pagination"
        :hide-default-footer="pagination.escondePaginacao"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:default="props">
          <v-row align="start">
            <v-col cols="12"
              md="6"
              v-for="(item, index) in props.items"
              :key="index">
              <v-hover>
                <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 5 : 1}`" fill-height class="Card_Nota_Debito">
                  <v-card-title class="Card_Title">
                    <v-container fluid>
                      <v-row justify="space-between">
                        <span>{{ `#${item.idExterno} :: ${item.tipo}`}}</span>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn text icon
                                  v-on="on"
                                  @click.native="abrirDetalhamento(item)">
                              <v-icon >info</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $tc('label.detalhe', 2) }}</span>
                        </v-tooltip>
                      </v-row>
                      <v-row>
                        <span>{{ `${getMoney(item.valor)}` }}</span>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text class="Card_Content">
                    <v-container fluid>
                      <v-row justify="center" align="center">
                        <v-col cols="12">
                          <span v-if="item.extensoes">{{`${item.extensoes}`}} <br /></span>
                          <span>{{ `${ $t('label.cnpj')}: ${item.desCnpj}` }}</span>
                        </v-col>
                        <v-col cols="12">
                          <v-row>
                            <v-col cols="12" md="9">
                              <span>{{ `${ $t('label.vencimento')}: ${item.vencimento}` }}</span>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-row justify="end">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn text icon
                                        v-on="on"
                                        @click.native="abrirModalConfirmacaoImpressao(item)">
                                      <v-icon >print</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    {{ $t('label.imprimir') }} {{ $tc('label.nota_debito', 1) }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn text icon
                                        v-on="on"
                                        @click.native="abrirModalConfirmacaoExportacao(item)">
                                      <v-icon >get_app</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    {{ $t('label.exportar') }} {{ $tc('label.nota_debito', 1) }}
                                  </span>
                                </v-tooltip>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <confirm
        ref="modalConfirmacao"
        :message="mensagemConfirmacao"
        @agree="validarAcao">
      </confirm>
      <v-row v-if="visualizaTodasNotas">
        <v-spacer />
        <v-btn style="margin:0"
          @click="abrirTodasNotas"
          color="accent">
          {{$t('label.consultar_todas')}}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import { getMoney } from '../../../../common/functions/helpers';
import { listarNotasDebito } from '../../../../common/resources/liquidacao/recebimento';
import Confirm from '../../../../shared-components/vuetify/dialog/Confirm';
import getBasePath from '../../../../common/functions/api-resource';
import download from '../../../../common/resources/downloads';
import exportacao from '../../../../common/functions/exportacao';

export default {
  name: 'DashboardFornecedorNotaDebito',
  components: {
    Confirm,
  },
  props: {
    filtros: Object,
  },
  data() {
    return {
      menuFiltroData: false,
      filtroAnoMes: null,

      dados: [],
      pesquisaCards: '',
      esperarDigitar: false,
      pagination: {
        escondePaginacao: false,
        rowsPerPage: 10,
      },
      totalPage: 0,
      filtrosAplicados: this.filtros,
      mensagemConfirmacao: '',
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscarDados();
      },
    },
    pagination: {
      handler() {
        this.buscarDados();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    visualizaTodasNotas() {
      return this.getAllRoles.indexOf('LIQ_ND_PAGINA') >= 0;
    },
  },
  methods: {
    getMoney,
    buscarDados() {
      const params = {
        ...this.filtrosAplicados,
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      listarNotasDebito(params, this.$resource)
        .then((res) => {
          this.dados = res.data.resposta;
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.pagination.escondePaginacao = this.escondePaginacao(this.pagination);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;

      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscarDados();
      }, 1000);
    },
    dataValidaSelecao(anoMes) {
      const formato = 'YYYYMM',
        dataSelecao = moment(anoMes, 'YYYY-MM').format(formato),
        agora = moment().format(formato);
      return parseInt(dataSelecao, 10) >= parseInt(agora, 10);
    },
    filtraMesNotas(anoMes) {
      this.menuFiltroData = false;
      this.pesquisaCards = moment(anoMes, 'YYYY-MM')
        .format('MM/YYYY');

      setTimeout(() => this.buscarDados());
    },
    limparFiltroMesNotas() {
      this.filtroAnoMes = null;
      this.menuFiltroData = false;
      this.pesquisaCards = null;

      setTimeout(() => this.buscarDados());
    },
    abrirDetalhamento(item) {
      const breadcrumbsParam = [
        {
          nivel: 1,
          disabled: false,
          to: 'dashboard-inicial',
          text: this.$t('label.voltar_dashboard'),
        },
      ];

      this.$router.push({
        name: 'detalharRecebimento',
        params: { id: item.idRecebimento, from: 'dashboard-inicial', breadcrumbsParam },
      });
    },
    escondePaginacao(pagination) {
      return (this.totalPage / pagination.itemsPerPage) <= 1;
    },
    abrirModalConfirmacaoExportacao(item) {
      this.mensagemConfirmacao = this.$t('message.deseja_exportar');
      this.acaoConfirmacao = () => this.exportar(item);
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    abrirModalConfirmacaoImpressao(item) {
      this.mensagemConfirmacao = this.$t('message.deseja_imprimir');
      this.acaoConfirmacao = () => this.imprimir(item);
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    imprimir(item) {
      const { idExterno, idRecebimento } = item;
      const numNota = idExterno;
      const basePath = getBasePath('job', 'recebimento/relatorio');
      const resource = download(this.$http);

      resource.downloadGet(basePath, {
        param: `?numNota=${numNota}`,
        url: `nota-debito/${idRecebimento}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    exportar(item) {
      const params = {
        id_apuracao: item.idApuracao,
        id_fornecedor: item.idFornecedor,
      };

      if (item.calculoBonificacao === 'SELLOUT') {
        exportacao.exportar(null, 'notas_sellout_memoria', this, params);
      } else {
        exportacao.exportar(null, 'notas_sellin_memoria', this, params);
      }
    },
    validarAcao() {
      if (this.acaoConfirmacao) {
        this.acaoConfirmacao();
      }
    },
    abrirTodasNotas() {
      const route = this.$router.resolve({ name: 'notas-debito-consulta' });
      window.open(route.href, '_blank');
    },
  },
  mounted() {
    this.buscarDados();
  },
};
</script>

<style>
.DashFornecedor {
  padding-top: 0px;
}

.DashFornecedor .Card_Nota_Debito {
  min-height: 146px !important;
  height: 100%;
  border-radius: 6px;
}

.DashFornecedor .Card_Nota_Debito .Card_Title {
  padding: 7px 7px 0px 7px ;
}

.DashFornecedor .Card_Nota_Debito .Card_Title span {
  font-weight: 700;
  margin-left: 10px;
  font-size: 16px;
}

.DashFornecedor .Card_Nota_Debito .Card_Content {
  padding: 7px 7px 0px 7px ;
}

.DashFornecedor .Card_Nota_Debito .Card_Content span {
  font-size: 14px;
}

.DashFornecedor .Card_Nota_Debito .Card_Title button span i,
.DashFornecedor .Card_Nota_Debito .Card_Title span,
.DashFornecedor .Card_Nota_Debito .Card_Content span,
.DashFornecedor .Card_Nota_Debito .Card_Content button span i {
  color: rgba(0,0,0,.70) !important;
}

.DashFornecedor .Card_Nota_Debito .Card_Actions {
  padding-top: 0px;
}
</style>
