export default {
  data() {
    return {
      magaluRelatoriosResource: this.$api.magaluRelatorios(this.$resource),
      acaoCadastroResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      perfilResource: this.$api.perfil(this.$resource),

      tiposUsuario: [
        {
          texto: this.$t('tipo_usuario.FUNCIONARIO'),
          valor: 'FUNCIONARIO',
        },
        {
          texto: this.$t('tipo_usuario.FORNECEDOR'),
          valor: 'FORNECEDOR',
        },
      ],
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'tipo_usuario',
            nomCampoId: 'tipo_usuario',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: this.tiposUsuario,
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'fornecedor',
            nomCampoId: 'id_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.acaoCadastroResource.buscarFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'cnpjNome',
            },
          },
          {
            labelCampo: 'perfil',
            nomCampoId: 'id_perfil',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.perfilResource.selecao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomPerfil',
            },
          },
          {
            labelCampo: 'status_aprovacao',
            nomCampoId: 'status_aprovacao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: false,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('status_entidade.em_cadastro', 1)}`, valor: 'EM_CADASTRO' },
                  { texto: `${this.$tc('status_entidade.aguardando_aprovacao', 1)}`, valor: 'AGUARDANDO_APROVACAO' },
                  { texto: `${this.$tc('status_entidade.aprovado', 1)}`, valor: 'APROVADO' },
                  { texto: `${this.$tc('status_entidade.reprovado', 1)}`, valor: 'REPROVADO' },
                  { texto: `${this.$tc('status_entidade.em_analise', 1)}`, valor: 'EM_ANALISE' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'ativo',
            tipoCampo: 'BOOLEAN',
          },
        ],
      },
    };
  },
};
