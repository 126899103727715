<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="40%">
      <v-card>
        <v-card-title>
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text >
          {{ valor  }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text
                 @click="close()">{{$t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalListaValores',
  props: {
  },
  data() {
    return {
      dialog: false,
      valor: '',
      titulo: '',
    };
  },
  methods: {
    open(lista) {
      this.valor = lista.valor;
      this.titulo = lista.titulo;
      this.dialog = true;
    },
    close() {
      this.lista = '';
      this.titulo = '';
      this.dialog = false;
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
