<template>
  <v-text-field
    :id="id"
    :label="inputLabel"
    v-model="valorInterno"
    :prepend-icon="prependIcon"
    :disabled="disabled"
    :required="required"
    :loading="loading"
    :hint="hint"
    :persistent-hint="persistentHint"
    :rules="[rules.requiredNome, rules.numeroValido, rules.intervaloValido]"
    @input="numeroAlterado"
    v-mask="mask"
    :hide-details="hideDetails">
  </v-text-field>
</template>
<script>
export default {
  name: 'InputNumber',
  props: {
    value: {
      type: [Number],
    },
    prependIcon: {
      type: String,
      default: 'filter_1',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    max: Number,
    min: Number,
    id: {
      type: String,
      default: () => {
        const hash = Math.floor(Math.random() * 1000) + 1;
        return `input-number-${hash}`;
      },
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    mask: {
      type: String,
      default: '########',
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
      },
    },
  },
  computed: {
    inputLabel() {
      if (this.label && this.label.length > 0) {
        return `${this.label} ${this.required ? '*' : ''}`;
      }
      return '';
    },
  },
  data() {
    return {
      valorInterno: null,
      rules: {
        requiredNome: () => {
          if (!this.required) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.valorInterno;
          return !!(val) || msg;
        },
        numeroValido: () => {
          if (!this.valorInterno) return true;
          const msg = this.$t('message.numero_invalido');
          const val = this.valorInterno;
          return (!!(val) && !Number.isNaN(val.toString())) || msg;
        },
        intervaloValido: () => {
          if (!this.valorInterno) return true;
          const { max, min } = this;
          if (!max || !min) {
            return true;
          }
          const msg = this.$t('message.intervalo_numerico', { max, min });
          const val = this.valorInterno;
          return (!!(val) && val <= max && val >= min) || msg;
        },
      },
    };
  },
  methods: {
    numeroAlterado(valor) {
      const numero = Number(valor);
      this.$emit('InputNumber_valorAlterado', numero);
      this.$emit('input', numero);
    },
  },
};
</script>
