import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'filtro-cliente');

const filtroClienteActions = {
  ...genericActions(basePath),
  listarAtivos: { method: 'GET', url: `${basePath}?tamanhoPagina=40&numeroPagina=1&indAtivo=true` },
};

export default (resource) => resource(`${basePath}`, {}, filtroClienteActions);
