<template lang="html">
  <div>
    <v-card-title v-if="title">{{ title }}</v-card-title>
    <v-card-text v-if="subtitle">{{ subtitle }}</v-card-text>

    <v-container>
      <dropzone
        :id="id"
        :name="name"
        :accepted-file-types="acceptedFileTypes"
        :maxFileSizeInMB="maxFileSizeInMB"
        :maxNumberOfFiles="maxNumberOfFiles"
        :url="url"
        :numMaxArquivos="numMaxArquivos"
        @UPLOADFILE_FILE_ADDED="fileAdded"
        @UPLOADFILE_FILE_REMOVED="fileRemoved"
        @UPLOADFILE_SUCCESS="uploadSuccess"
        @UPLOADFILE_ERROR="uploadError"
        @UPLOADFILE_SENDING="uploadSending"
      />
    </v-container>
  </div>
</template>

<script type="text/javascript">
import Dropzone from './Dropzone';

export default {
  props: {
    title: String,
    subtitle: String,
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: 'uploadfile-component',
    },
    numMaxArquivos: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
    acceptedFileTypes: {
      type: String,
    },
    maxFileSizeInMB: {
      type: Number,
      default: 50,
    },
    maxNumberOfFiles: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Dropzone,
  },
  methods: {
    fileAdded(...params) {
      this.$emit('UPLOADFILE_FILE_ADDED', ...params);
    },
    fileRemoved(...params) {
      this.$emit('UPLOADFILE_FILE_REMOVED', ...params);
    },
    uploadSuccess(...params) {
      this.$emit('UPLOADFILE_SUCCESS', ...params);
    },
    uploadError(...params) {
      this.$emit('UPLOADFILE_ERROR', ...params);
    },
    uploadSending(...params) {
      this.$emit('UPLOADFILE_SENDING', ...params);
    },
  },
};
</script>
