<template>
  <v-list class="pa-0">
    <v-list-item v-for="tenantId in getTenantIds" :key="tenantId" @click="selecionarTenant(tenantId)"
      :disabled="tenantId === getTenantId">
      <v-list-item-content>
        <v-list-item-title>{{ $t(`sistema.${tenantId}`) }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TenantList',
  computed: {
    ...mapGetters('roles', [
      'getTenantIds',
      'getTenantId',
    ]),
  },
  methods: {
    selecionarTenant(tenantID) {
      this.$emit('TenantList_selecionarTenant', tenantID);
    },
  },
};
</script>
