<template>
  <div :id="id" :style="`width: 100%; height: 100%; min-height: 350px; max-height: 550px; min-width: 200px; ${height ? 'height: ' + height + 'px' : ''}`"></div>
</template>

<script>
import loadChart from '../../shared-components/chart/loadChart';

export default {
  name: 'grafico-barras',
  props: {
    categoryField: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    sortColumns: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Array,
    },
    valueFields: {
      type: Array,
      required: true,
    },
    dados: {
      type: Array,
      required: true,
    },
    unitYAxis: {
      type: String,
      default: '',
    },
    height: 0,
  },
  watch: {
    dados() {
      this.montarGrafico();
    },
  },
  data() {
    return {
      isReady: false,
      barchart3dconf: {
        theme: 'light',
        type: 'serial',
        sortColumns: false,
        gridAboveGraphs: false,
        startDuration: 1,
        graphs: [],
        plotAreaFillAlphas: 0.1,
        depth3D: 12,
        angle: 55,
        autoResize: true,
        rotate: false,
        categoryAxis: {
          gridPosition: 'start', gridAlpha: 0, tickPosition: 'start', tickLength: 20,
        },
        legend: {
          enabled: true,
          maxColumns: 2,
          align: 'center',
          useGraphSettings: true,
        },
        export: {
          enabled: false,
        },
        valueAxes: [],
      },
      id: 'bar',
      chartTitle: '',
    };
  },
  methods: {
    montarGrafico() {
      if (typeof AmCharts === 'undefined') return;
      this.barchart3dconf.categoryField = this.categoryField;
      this.barchart3dconf.export.fileName = this.title !== '' ? this.title : this.chartTitle;
      this.barchart3dconf.colors = this.colors ? this.colors : ['#2196F3', '#FFEB3B'];
      this.barchart3dconf.sortColumns = this.sortColumns;
      this.barchart3dconf.columnSpacing = 12;
      this.barchart3dconf.titles = [];
      this.barchart3dconf.titles.push({
        text: this.title ? this.title : this.chartTitle,
        size: 16,
      });
      this.barchart3dconf.valueAxes.push({
        gridColor: '#90A4AE',
        unit: this.unitYAxis,
        gridAlpha: 0.5,
        axisAlpha: 0,
        dashLength: 1,
        unitPosition: 'left',
        position: 'left',
        title: '(mil)',
      });
      this.barchart3dconf.graphs = [];
      this.valueFields.forEach((el) => {
        this.barchart3dconf.graphs.push(
          {
            balloonText: `${el.balloonText}`,
            fillAlphas: 0.8,
            lineAlpha: 0.2,
            title: `${el.title}`,
            type: 'column',
            valueField: el.valueField,
            precision: 2,
            decimalSeparator: ',',
            thousandsSeparator: '.',
          },
        );
      });
      this.barchart3dconf.numberFormatter = {
        precision: 2,
        decimalSeparator: ',',
        thousandsSeparator: '.',
      };
      this.barchart3dconf.dataProvider = this.dados || [];
      AmCharts.addInitHandler((chart) => {
        if (chart.sortColumns !== true) {
          return;
        }

        for (let i = 0; i < chart.dataProvider.length; i + 1) {
          const row = chart.dataProvider[i];
          const values = [];
          for (let g = 0; g < chart.graphs.length; g + 1) {
            values.push({
              value: row[graph.valueField],
              graph: chart.graphs[g],
            });
          }

          values.sort((a, b) => a.value - b.value);

          for (let x = 0; x < values.length; x + 1) {
            const { graph } = values[x];
            graph.columnIndexField = `${graph.valueField}_index`;
            row[graph.columnIndexField] = x;
          }
        }
      }, ['serial']);

      AmCharts.makeChart(this.id, this.barchart3dconf);
    },
    refresh() {
      this.barchart3dconf.dataProvider = this.dados;
      this.barchart3dconf.validateData();
    },
  },
  mounted() {
    window.addEventListener('AmChartCarregado', () => {
      this.isReady = true;
    });
  },
  beforeMount() {
    loadChart().then(() => {
      window.dispatchEvent(new Event('AmChartCarregado'));
      this.montarGrafico();
    });
  },
};
</script>
