<template>
  <v-dialog v-model="dialog" :persistent="persistent" :max-width="maxWidth">
    <v-card>
      <v-card-title class="headline">{{ titleDialog }}</v-card-title>
      <v-card-text v-html="message || messageDialog" class="justify"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="cancel" color="primary darken-1" v-if="showCancel" text @click.native="close">
          {{cancelLabel ? cancelLabel : $t('label.cancelar')}}
        </v-btn>
        <v-btn id="nao" color="primary darken-1" v-if="!hideDisagree" text @click.native="disagree">
          {{disagreeLabel ? disagreeLabel : $t('label.nao')}}
        </v-btn>
        <v-btn id="sim" color="primary darken-1" text @click.native="agree">
          {{agreeLabel ? agreeLabel : $t('label.sim')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    title: String,
    message: String,
    persistent: false,
    agreeLabel: String,
    disagreeLabel: String,
    cancelLabel: String,
    maxWidth: {
      type: String,
      default: '350',
    },
    hideDisagree: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleDialog: '',
      messageDialog: '',

      dialog: false,
      callback: null,
    };
  },
  methods: {
    open() {
      this.titleDialog = this.title;
      if (!this.title || !this.title.length) {
        this.titleDialog = this.$t('title.atencao');
      }
      this.dialog = true;
      this.callback = null;
    },
    openWithParams(title, message, callback) {
      this.titleDialog = title;
      this.messageDialog = message;
      this.callback = callback;
      this.dialog = true;
    },
    disagree() {
      this.close();
      this.$emit('disagree');
      if (this.callback) {
        this.callback(false);
      }
    },
    agree() {
      this.close();
      this.$emit('agree', this.callback);
      if (this.callback) {
        this.callback(true);
      }
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
