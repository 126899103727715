import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'nivel-produto');

const nivelProdutoActions = {
  ...genericActions(basePath),
  listarPorEntidadeOrcamento: { method: 'GET', url: `${basePath}/entidade?entidade=ORCAMENTO` },
  labels: { method: 'GET', url: `${basePath}/labels` },
  categorias: { method: 'GET', url: `${basePath}/categorias-recursivas` },
  orcamento: { method: 'GET', url: `${basePath}/orcamento` },
  acao: { method: 'GET', url: `${basePath}/acao` },
};

export default (resource) => resource(`${basePath}`, {}, nivelProdutoActions);
