<template lang="html">
  <div>
    <v-dialog v-model="dialogCampoCustomizado" persistent max-width="800px">
      <campos-customizados-form
        ref="campoCustomizado"
        :desabilitarSelecaoEntidade="true"
        @atualizarGrid="atualizarGridCamposCustomizados"
        @cancelar="cancelarCamposCustomizados">
        <div slot="button">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="cancelarCamposCustomizados">{{ $t('label.cancelar') }}</v-btn>
            <v-btn @click.native="validarSalvarCamposCustomizados" color="primary">{{ $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </div>
      </campos-customizados-form>
    </v-dialog>
    <v-container fluid>
      <v-card-title primary-title class="card-header">
        {{ $tc('title.campo_padrao', 2) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.campo_padrao')}`" />
      </v-card-title>
      <v-data-table id="config_orcamento_tabela_lista_padrao"
        :headers="headers"
        :items="parametrizacao.listaPadrao"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.contexto="{ item }">
          {{ $tc(`label.${item.contexto.toLowerCase()}`, 1) }}
        </template>
        <template v-slot:item.obrigatorio="{ item }">
          {{ item.obrigatorio ? 'Sim' : 'Não' }}
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('label.campo_customizado', 2) }}
        <v-spacer />
        <div style="cursor: pointer" class="mr-2">
          <span v-html="`${$t('label.nao_encontrou_campo_clique_aqui')}`" @click.stop="novoCampoCustomizado"/>
        </div>
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.campos_customizados')}`" />
      </v-card-title>
      <v-data-table
        id="config_orcamento_tabela_campos_customizados"
        :headers="headersCamposDinamicos"
        :items="parametrizacao.listaDinamica"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.contexto="{ item }">
          <v-radio-group v-model="item.contexto" row class="ma-0">
            <v-radio class="margem" :label="$t('label.nao_aplica')" value="N/A"/>
            <v-radio v-if="contexto.value" v-for="(contexto, indice) in contextos" class="margem"
              :label="contexto.text" :value="contexto.value" v-bind:key="indice"/>
          </v-radio-group>
        </template>
        <template v-slot:item.obrigatorio="{ item }">
          {{ item.obrigatorio ? 'Sim' : 'Não' }}
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('label.divisao', 2) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.divisao')}`" />
      </v-card-title>
      <v-row class="ml-4 mr-4 mt-4">
        <v-col cols="12" md="4">
          <v-checkbox
            hide-details
            v-model="divisaoHabilitada"
            :label="$t('label.habilitar_divisao_orcamento')"/>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            id="config_orcamento_divisao"
            name="config_orcamento_divisao"
            v-model="nomeDivisao"
            :items="listaDivisoes"
            :search-input.sync="buscaListaDivisao"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nomeDivisao"
            item-value="idDivisao"
            :label="`${$tc('label.divisao', 1)}`"
            :placeholder="$tc('label.digite_para_buscar', 1)"
            @change="setDivisao"
            clearable
            :disabled="!divisaoHabilitada"
            return-object>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table id="config_orcamento_tabela_divisao"
        :headers="headersDivisao"
        :items="parametrizacao.listaDivisao"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.acoes="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon class="mx-0" v-on="on" @click="removerDivisaoPeloId(item.idDivisao)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.remover') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.cliente', 1) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.cliente')}`" />
      </v-card-title>
      <v-data-table id="config_orcamento_tabela_cliente"
        :headers="headersRecursivo"
        :items="parametrizacao.listaCliente"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.contexto="{ item }">
          <v-radio-group v-model="item.contexto" row class="ma-0">
            <v-radio class="margem" :label="$t('label.nao_aplica')" value="N/A"/>
            <v-radio v-if="contexto.value" v-for="(contexto, indice) in contextos" class="margem"
              :label="contexto.text" :value="contexto.value" v-bind:key="indice"/>
          </v-radio-group>
        </template>
        <template v-slot:item.aplicar_se_a="{ item }">
          {{ item.aplicarse ? item.aplicarse : ' - ' }}
        </template>
        <template v-slot:item.obrigatorio="{ item }">
          {{ item.obrigatorio ? 'Sim' : 'Não' }}
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.produto', 1) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.produto')}`" />
      </v-card-title>
      <v-data-table id="config_orcamento_tabela_produto"
        :headers="headersRecursivo"
        :items="parametrizacao.listaProduto"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.contexto="{ item }">
          <v-radio-group v-model="item.contexto" row class="ma-0">
            <v-radio class="margem" :label="$t('label.nao_aplica')" value="N/A"/>
            <v-radio v-if="contexto.value" v-for="(contexto, indice) in contextos" class="margem"
              :label="contexto.text" :value="contexto.value" v-bind:key="indice"/>
          </v-radio-group>
        </template>
        <template v-slot:item.aplicar_se_a="{ item }">
          {{ item.aplicarse ? item.aplicarse : ' - ' }}
        </template>
        <template v-slot:item.obrigatorio="{ item }">
          <v-checkbox v-model="item.obrigatorio" hide-details></v-checkbox>
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.campo_geral_planejamento_acao', 2) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.campos_gerais')}`" />
      </v-card-title>
      <v-data-table id="config_orcamento_tabela_lista_outros"
        :headers="headers"
        :items="parametrizacao.listaOutros"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.contexto="{ item }">
          <v-radio-group v-model="item.contexto" row class="ma-0">
            <v-radio class="margem" :label="$t('label.nao_aplica')" value="N/A"/>
            <v-radio v-if="contexto.value" v-for="(contexto, indice) in contextos" class="margem"
              :label="contexto.text" :value="contexto.value" v-bind:key="indice"/>
          </v-radio-group>
        </template>
        <template v-slot:item.obrigatorio="{ item }">
          <v-checkbox v-model="item.obrigatorio" hide-details></v-checkbox>
        </template>
      </v-data-table>

      <v-row justify="end" class="mt-4">
        <v-btn id="config_orcamento_passo2_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_orcamento_passo2_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn color="primary" id="config_orcamento_passo2_botao_proximo" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script type="text/javascript">
import CamposCustomizadosForm from '../../campos-customizados/CamposCustomizadosForm';
import listaPadraoList from './listaPadrao';
import Passo2 from './Passo2';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoOrcamentoPasso2',
  props: {
    id: Number,
  },
  components: {
    CamposCustomizadosForm,
    TooltipAjuda,
  },
  data() {
    return {
      passo: 2,
      camposCustomizadosResources: this.$api.camposCustomizados(this.$resource),
      paramOrcamentoResources: this.$api.parametrizacaoOrcamento(this.$resource),
      nivelClienteResources: this.$api.nivelCliente(this.$resource),
      nivelProdutoResources: this.$api.nivelProduto(this.$resource),
      divisaoResources: this.$api.divisao(this.$resource),
      dialogCampoCustomizado: false,
      buscaListaDivisao: null,
      contextos: [
        { text: '', value: null },
        { text: this.$tc('label.resumo', 1), value: 'RESUMO' },
        { text: this.$tc('label.linha', 1), value: 'LINHA' },
      ],
      divisaoHabilitada: true,
      headers: [
        { text: this.$tc('label.campo', 1), width: '30%', value: 'nome' },
        { text: this.$tc('label.contexto', 1), width: '30%', value: 'contexto' },
        {
          text: this.$tc('label.obrigatorio', 1), width: '10%', sortable: false, value: 'obrigatorio',
        },
      ],
      headersCamposDinamicos: [
        { text: this.$tc('label.campo', 1), width: '20%', value: 'nomCampo' },
        { text: this.$tc('label.tipo', 1), width: '20%', value: 'tipoCampo' },
        { text: this.$tc('label.contexto', 1), value: 'contexto', width: '30%' },
        {
          text: this.$tc('label.obrigatorio', 1), sortable: false, value: 'obrigatorio', width: '10%',
        },
      ],
      headersDivisao: [
        { text: this.$tc('label.divisao', 1), width: '30%', value: 'nomeDivisao' },
        { text: this.$tc('label.estrutura_usuario', 1), width: '30%', value: 'nomEstruturaUsuario' },
        {
          text: this.$tc('label.acoes', 1), sortable: false, width: '10%', value: 'acoes',
        },
      ],
      headersRecursivo: [
        {
          text: this.$tc('label.campo', 1), width: '15%', sortable: false, value: 'nome',
        },
        {
          text: this.$tc('label.aplicar_se_a', 1), width: '15%', sortable: false, value: 'aplicar_se_a',
        },
        {
          text: this.$tc('label.contexto', 1), width: '30%', sortable: false, value: 'contexto',
        },
        {
          text: this.$tc('label.obrigatorio', 1), sortable: false, width: '10%', value: 'obrigatorio',
        },
      ],
      listaCliente: [
        {
          nome: this.$tc('label.cliente', 1), contexto: 'N/A', obrigatorio: false, label: 'cliente',
        },
      ],
      listaProduto: [
        {
          nome: this.$tc('label.produto', 1), contexto: 'N/A', obrigatorio: false, label: 'produto',
        },
      ],
      listaOutros: [
        {
          nome: this.$tc('label.centro_custo', 1), contexto: 'N/A', obrigatorio: false, label: 'centro_custo',
        },
      ],
      listaPadrao: listaPadraoList(this),
      listaDivisoes: [],
      nomeDivisao: '',
      listaDinamica: [],
      parametrizacao: {
        listaDivisao: [],
      },
      tiposNivelProduto: [],
      tiposNivelCliente: [],
      menorIndiceCliente: 0,
      menorIndice: 0,
    };
  },
  watch: {
    id() {
      this.prepararPasso();
    },
    buscaListaDivisao(val) {
      if (this.timeoutDivisao) {
        window.clearTimeout(this.timeoutDivisao);
      }
      this.timeoutDivisao = window.setTimeout(() => {
        if (val != null) this.buscarDivisaoAutocomplete(val);
      }, 500);
    },
    parametrizacao: {
      handler(valor) {
        if (valor && valor.listaDivisao && valor.listaDivisao.length > 0) {
          this.divisaoHabilitada = true;
        } else {
          this.divisaoHabilitada = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    adicionaSeForNovo(objeto, item) {
      let hasItem = false;
      objeto.forEach((itemObj) => {
        if (itemObj.label === item.label) {
          hasItem = true;
        }
      });
      if (!hasItem) objeto.push(item);
    },
    atualizarGridCamposCustomizados(id) {
      this.carregarCampoDinamicoEspecifico(id)
        .then(() => {
          this.dialogCampoCustomizado = false;
        });
    },
    buscarDivisaoAutocomplete(busca = null) {
      const ids = [];
      this.parametrizacao.listaDivisao.forEach((value) => {
        ids.push(value.idDivisao);
      });

      const param = {
        filtro: busca,
        size: 20,
        page: 0,
        idsFilhosNotIn: ids,
      };

      this.divisaoResources.arvore(param).then((response) => {
        this.listaDivisoes = Object.assign([], response.data.resposta);
      }, (err) => {
        this.$error(this, err);
      });
    },
    buscarERemoverDivisoesFilhas(divisaoSelecionada) {
      const ids = [];
      ids.push(divisaoSelecionada.idDivisao);
      return this.divisaoResources.buscarFilhos({ idsFilhosNotIn: ids }).then((response) => {
        const arrayIds = response.data && response.data.length > 0
          ? response.data.map((resp) => resp.id) : [];
        arrayIds.forEach((id) => {
          this.removerDivisaoPeloId(id);
        });
      }, (err) => {
        this.$error(this, err);
      });
    },
    buscarMetadadosNivelCliente() {
      return this.nivelClienteResources.orcamento().then((resultado) => {
        this.tiposNivelCliente = resultado.data;
        this.menorIndiceCliente = Math.min(...this.tiposNivelCliente.map((tipo) => tipo.indice));

        this.tiposNivelCliente
          .filter((c) => c.label !== 'unidadenegocio')
          .forEach((nivel) => {
            const item = {
              nome: nivel.descricao,
              contexto: 'N/A',
              obrigatorio: false,
              label: nivel.label,
              idNivelExtensao: nivel.idNivelExtensao,
              mnemonico: nivel.mnemonico,
              aplicarse: this.exibirPaiCliente(nivel),
            };

            this.adicionaSeForNovo(this.listaCliente, item);
          });
      });
    },
    buscarMetadadosNivelProduto() {
      return this.nivelProdutoResources.orcamento().then((resultado) => {
        this.tiposNivelProduto = resultado.data;
        this.menorIndice = Math.min(...this.tiposNivelProduto.map((tipo) => tipo.indice));

        this.tiposNivelProduto.forEach((nivel) => {
          const item = {
            nome: nivel.descricao,
            contexto: 'N/A',
            obrigatorio: false,
            label: nivel.label,
            idNivelExtensao: nivel.idNivelExtensao,
            aplicarse: this.exibirPaiProduto(nivel),
          };

          this.adicionaSeForNovo(this.listaProduto, item);
        });
      });
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_CANCELAR');
    },
    cancelarCamposCustomizados() {
      this.$refs.campoCustomizado.limparFormulario();
      this.dialogCampoCustomizado = false;
    },
    cancelarModalDivisao() {
      this.dialogDivisao = false;
    },
    carregarCamposDinamicos() {
      return this.camposCustomizadosResources.listarPorEntidadeOrcamento()
        .then((response) => {
          this.listaDinamica = response.data;
          this.listaDinamica.forEach((l) => {
            l.nome = l.nomCampo;
            l.desAtributos = l.desAtributos && l.desAtributos.value
              ? JSON.parse(l.desAtributos.value) : {};
            l.idCampoDinamico = l.id;
            l.label = l.labelCampo;
            l.contexto = 'N/A';
            l.obrigatorio = l.vlrObrigatorio;
            delete l.id;
          });
        }, (err) => {
          this.$error(this, err);
        });
    },
    carregarCampoDinamicoEspecifico(id) {
      const param = { id, page: 1, size: 10 };
      return this.camposCustomizadosResources.listar(param).then((response) => {
        const resultado = response.data.resposta;
        if (resultado && resultado.length > 0) {
          const novoCampoDinamico = resultado[0];
          novoCampoDinamico.nome = novoCampoDinamico.nomCampo;
          novoCampoDinamico.desAtributos = novoCampoDinamico.desAtributos
            && novoCampoDinamico.desAtributos.value
            ? JSON.parse(novoCampoDinamico.desAtributos.value) : {};
          novoCampoDinamico.idCampoDinamico = novoCampoDinamico.id;
          novoCampoDinamico.label = novoCampoDinamico.labelCampo;
          novoCampoDinamico.contexto = 'N/A';
          novoCampoDinamico.obrigatorio = novoCampoDinamico.vlrObrigatorio;
          delete novoCampoDinamico.id;
          this.listaDinamica.push(novoCampoDinamico);
          Passo2.setListaDinamica(this.listaDinamica);
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    definirLista(listaPrincipal, lista) {
      lista.forEach((l) => {
        const sel = listaPrincipal.filter((el) => el.label === l.label);
        if (sel.length) {
          sel[0].contexto = l.contexto;
          sel[0].obrigatorio = l.obrigatorio;
        }
      });
    },
    exibirPaiProduto(nivel) {
      if (nivel.indice == null || nivel.indice === this.menorIndice
        || nivel.indice === 0) {
        return ' - ';
      }
      return this.tiposNivelProduto
        .filter((item) => item.indice === (nivel.indice - 1))[0].descricao;
    },
    exibirPaiCliente(nivel) {
      if (nivel.indice == null || nivel.indice === this.menorIndiceCliente
        || nivel.indice === 0) {
        return ' - ';
      }
      return this.tiposNivelCliente
        .filter((item) => item.indice === (nivel.indice - 1))[0].descricao;
    },
    getListaCliente() {
      return this.parametrizacao.listaCliente;
    },
    getListaDivisao() {
      return this.parametrizacao.listaDivisao;
    },
    novoCampoCustomizado() {
      this.dialogCampoCustomizado = true;
      window.setTimeout(() => {
        this.$refs.campoCustomizado.habilitarApenasUmaEntidade('ORCAMENTO');
      });
    },
    obter(id) {
      this.paramOrcamentoResources.obterPasso2({ id }, { id }).then((response) => {
        this.definirLista(this.listaCliente, response.data.listaCliente);
        this.definirLista(this.listaProduto, response.data.listaProduto);
        this.definirLista(this.listaOutros, response.data.listaOutros);
        this.definirLista(this.listaDinamica, response.data.listaDinamica);
        this.parametrizacao.listaDivisao = response.data.listaDivisao;
        this.buscarDivisaoAutocomplete();

        this.parametrizacao = new Passo2(this.id,
          this.listaCliente, this.listaProduto, this.listaOutros,
          this.listaPadrao, this.listaDinamica,
          this.parametrizacao.listaDivisao);
      }, (err) => {
        this.$error(this, err);
      });
    },
    prepararPasso() {
      this.carregarCamposDinamicos()
        .then(this.buscarMetadadosNivelProduto)
        .then(this.buscarMetadadosNivelCliente)
        .then(() => {
          if (this.id) {
            this.obter(this.id);
          } else {
            this.buscarDivisaoAutocomplete();
            this.parametrizacao = new Passo2(this.id,
              this.listaCliente, this.listaProduto, this.listaOutros,
              this.listaPadrao, this.listaDinamica,
              this.parametrizacao.listaDivisao);
          }
        });
    },
    atualizarVariaveisDeContexto() {
      const callback = (el) => {
        el.contexto = el.contexto === 0 ? 'N/A' : el.contexto;
      };
      this.listaCliente.forEach(callback);
      this.listaProduto.forEach(callback);
      this.listaOutros.forEach(callback);
    },
    proximoPasso() {
      if (this.divisaoHabilitada && this.parametrizacao.listaDivisao.length === 0) {
        this.$toast(this.$t('message.selecione_divisao'));
        return;
      }

      this.$emit('PARAMETRIZACAO_ORCAMENTO_CONTINUAR', this.passo + 1, this.id);
      this.$emit('PARAMETRIZACAO_ORCAMENTO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
    },
    removerDivisaoPeloId(id) {
      this.parametrizacao.listaDivisao = this.parametrizacao
        .listaDivisao.filter((div) => div.idDivisao !== parseInt(id, 10));
      this.buscarDivisaoAutocomplete();
      this.$forceUpdate();
    },
    setDivisao(hit) {
      if (!hit) return;
      this.buscarERemoverDivisoesFilhas(hit).then(() => {
        window.setTimeout(() => {
          this.nomeDivisao = '';
        });
        this.parametrizacao.listaDivisao.push(hit);
        this.buscarDivisaoAutocomplete();
      });
    },
    validarSalvarCamposCustomizados() {
      this.$refs.campoCustomizado.abrirDialogSalvar();
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_VOLTAR', this.passo - 1);
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .card-header {
    background-color: var(--v-primary-base);
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
