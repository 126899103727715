export default class Passo1 {
  constructor(data = {}) {
    this.id = data.id;
    this.nome = data.nome;
    this.descricao = data.descricao;
    this.uploadObrigatorio = data.uploadObrigatorio;
    if (data.statusPermitidosParaUpload
      && data.statusPermitidosParaUpload.length) {
      this.statusPermitidosUploadPlanejamento = {
        emCadastro: data.statusPermitidosParaUpload.indexOf('EM_CADASTRO') > -1,
        aprovado: data.statusPermitidosParaUpload.indexOf('APROVADO') > -1,
        aguardandoAprovacao: data.statusPermitidosParaUpload.indexOf('AGUARDANDO_APROVACAO') > -1,
        reprovado: data.statusPermitidosParaUpload.indexOf('REPROVADO') > -1,
        emAnalise: data.statusPermitidosParaUpload.indexOf('EM_ANALISE') > -1,
        cancelado: data.statusPermitidosParaUpload.indexOf('CANCELADO') > -1,
      };
    } else {
      this.statusPermitidosUploadPlanejamento = {
        emCadastro: false,
        aprovado: false,
        aguardandoAprovacao: false,
        reprovado: false,
        emAnalise: false,
        cancelado: false,
      };
    }
    this.fluxo = data.fluxo;
    this.investimentos = data.investimentos || [];
    this.categoriaAcao = data.categoriaAcao;
    this.indAtivo = data.indAtivo != null ? data.indAtivo : true;
    this.indExclusivoContrato = data.indExclusivoContrato;
  }

  ehNovo() {
    return !this.id;
  }

  setId(id) {
    this.id = id;
  }
}
