<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="800"
      transition="scale-transition"
      offset-x
      left>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...menu }" icon :color="corIcone">
              <v-icon>{{ icone }}</v-icon>
            </v-btn>
          </template>
          <span>{{ textoAcao }}</span>
        </v-tooltip>
      </template>

      <v-card>

        <v-card-title>
          <div>
            <h3 class="headline mb-0">{{ textoAcao }}</h3>
          </div>
        </v-card-title>

        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="4" v-if="isAprovacao && podeAlterarDataContabilizacao">
                <v-menu
                  ref="data_contabilizacao"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      id="data_contabilizacao"
                      class="CalendarioForm-data__text"
                      readonly
                      clearable
                      :rules="[rules.required]"
                      v-model="dataContabilizacaoFormatada"
                      :label="`${$t('label.data_contabilizacao')} *`"
                      prepend-icon="event">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    color="primary"
                    scrollable
                    @input="setaDataFormatada()"
                    v-model="dataContabilizacao">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col :cols="isAprovacao && usuarioAprovadorNDComplementoInformacoes ? '8': '12'">
                <v-textarea
                  id="Acaofluxo_justificativa"
                  index="1"
                  ref="textareaJustificativa"
                  name="Acaofluxo_justificativa"
                  v-model="justificativa"
                  auto-grow
                  autofocus
                  rows="1"
                  :rules="[required]"
                  :disabled="bloquearCampos"
                  :label="validarJustificativa ? `${$t('label.justificativa')} *` : $t('label.justificativa')"
                  :counter="500"
                  maxlength="500">
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            v-if="fornecedoresNd && fornecedoresNd.length > 0"
            class="elevation-0 mx-3"
            :headers="cabecalho"
            :items="fornecedoresNd"
            hide-default-footer
            disable-pagination
            :items-per-page="-1"
            :no-data-text="$t('label.tabela_sem_conteudo')">
            <template v-slot:[`item.num_nota_debito`]="{ item }">
              <v-btn v-if="exibirBotaoExibicaoMultiplasNds(item)" icon class="mx-0" @click.stop="abrirModalExibicaoMultiplasNds(item)">
                <v-icon>visibility</v-icon>
              </v-btn>
              <span v-else>
                {{ item.numNotaDebito }}
              </span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    @click="imprimirNDPrevia(item)">
                    <v-icon>receipt</v-icon>
                  </v-btn>
                </template>
                <span>{{ $tc('label.imprimir_nd', 1) }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.cod_fornecedor`]="{ item }">
              {{ item.codFornecedor }}
            </template>
            <template v-slot:[`item.cnpj`]="{ item }">
              {{ item.cnpj }}
            </template>
            <template v-slot:[`item.des_razao_social`]="{ item }">
              {{ item.desRazaoSocial }}
            </template>
            <template v-slot:[`item.recebimento_calculado`]="{ item }">
              {{ getMoney(item.recebimentoCalculado) }}
            </template>
            <template v-slot:[`item.verba_manual`]="{ item }">
              {{ getMoney(item.verbaManual) }}
            </template>
            <template v-slot:[`item.justificativa`]="{ item }">
              {{ item.justificativa }}
            </template>
            <template v-slot:[`item.tipo_uso`]="{ item }">
              <v-autocomplete
                v-if="podeAlterarTipoDeUso"
                id="justificativa-tipo-uso"
                class="custom-autocomplete"
                v-model="item.idTipoUso"
                :rules="[rules.required]"
                :items="listaTiposUso"
                :label="`${$tc('label.tipo_uso', 1)} *`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nome"
                item-value="id"
                required
                :disabled="bloquearCampos"
                :clearable="true"
              ></v-autocomplete>
            </template>
          </v-data-table>
        </v-form>

        <v-card-title>
          <div>
            <h3 class="headline mb-0">{{ $tc('title.anexos', 1) }}</h3>
          </div>
        </v-card-title>
        <list-files
          :habilitar-exclusao="false"
          ref="listFiles"
          :uri="uri"
          v-if="idApuracao"
          col-xs="12"
          col-sm="12"
          col-md="6"
          col-lg="4">
        </list-files>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-tooltip bottom class="mr-3">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="cancelar" :disabled="bloquearCampos">
                <v-icon>cancel</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.cancelar') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon :color="corIcone" @click="executarAcao" :disabled="bloquearCampos">
                <v-icon>{{ icone }}</v-icon>
              </v-btn>
            </template>
            <span>{{ textoAcao }}</span>
          </v-tooltip>
        </v-card-actions>

      </v-card>

      <v-card v-if="exibirPerguntaProximo">
        <v-card-text>
          {{ $t('message.deseja_seguir_proxima_apuracao') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="naoIrProximo" color="primary">
            {{ $t('label.nao') }}
          </v-btn>

          <v-btn @click="irProximo" color="accent">
            {{ $t('label.sim') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-menu>

    <modal-exibicao-multiplas-nds
      ref="ModalExibicaoMultiplasNds">
    </modal-exibicao-multiplas-nds>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import basePath from '@/produto/common/functions/api-resource';
import ListFiles from '@/produto/shared-components/files/ListFiles';
import { getMoney } from '@/produto/common/functions/helpers';
import download from '@/produto/common/resources/downloads';
import ModalExibicaoMultiplasNds from '@/produto/shared-components/ModalExibicaoMultiplasNdsCamelCase';

export default {
  name: 'ApuracaoFluxo',
  props: {
    apuracao: {
      type: Object,
      default: null,
    },
    corIcone: {
      type: String,
      default: null,
    },
    icone: {
      type: String,
      default: '',
    },
    disabled: false,
    status: {
      type: String,
      default: '',
    },
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
    validarJustificativa: false,
    textoAcao: {
      type: String,
      default: '',
    },
    idAcao: {
      type: Number,
      default: null,
    },
    idApuracao: {
      type: Number,
      default: null,
    },
    isAprovacao: {
      type: Boolean,
      default: false,
    },
    isModalidadeValorFixo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apuracaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      workflowApuracaoAcaoMagalu: this.$api.workflowApuracaoAcaoMagalu(this.$resource),

      fornecedoresNd: [],
      menu: false,
      valid: false,
      bloquearCampos: false,
      justificativa: '',
      exibirPerguntaProximo: false,
      tipoUso: null,
      prazoPagamento: null,
      listaTiposUso: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      usuarioAprovadorNDComplementoInformacoes: false,
      dataContabilizacaoFormatada: null,
      dataContabilizacao: new Date().toJSON().substr(0, 10),

      cabecalhoBase: [
        {
          text: this.$tc('label.numero_nd', 1), value: 'num_nota_debito', sortable: false, width: '10%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.cod_fornecedor', 1), value: 'cod_fornecedor', sortable: false, width: '10%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.cnpj', 1), value: 'cnpj', sortable: false, width: '15%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.razao_social', 1), value: 'des_razao_social', sortable: false, width: '20%',
        },
        {
          text: this.$tc('label.recebimento_calculado', 1), value: 'recebimento_calculado', sortable: false, width: '10%', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.valor_nd', 1), value: 'verba_manual', sortable: false, width: '10%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.justificativa_alteracao', 1), value: 'justificativa', sortable: false, width: '10%',
        },
      ],

      cabecalhoFornecedor: [
        {
          text: this.$tc('label.numero_nd', 1), value: 'num_nota_debito', sortable: false, width: '10%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.cod_fornecedor', 1), value: 'cod_fornecedor', sortable: false, width: '10%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.cnpj', 1), value: 'cnpj', sortable: false, width: '20%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.razao_social', 1), value: 'des_razao_social', sortable: false, width: '20%',
        },
        {
          text: this.$tc('label.valor_nd', 1), value: 'verba_manual', sortable: false, width: '10%', class: 'nowrap', cellClass: 'nowrap',
        },
      ],

      listaDocumentosGerados: [],
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: this.$tc('label.nome_arquivo', 1), value: 'nomeArquivo', width: '100%', align: 'center', sortable: false,
        },
      ],
    };
  },
  components: {
    ListFiles,
    ModalExibicaoMultiplasNds,
  },
  watch: {
    menu(val) {
      if (val) {
        setTimeout(() => this.$refs.textareaJustificativa.focus(), 5E2);
      }
    },
    idApuracao() {
      if (this.idApuracao) {
        this.verificaSeUsuarioAprovadorNDComplementoInformacoes();
      }
    },
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isUsuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    podeAlterarTipoDeUso() {
      return this.usuarioAprovadorNDComplementoInformacoes && !this.validarJustificativa;
    },
    podeAlterarDataContabilizacao() {
      return this.usuarioAprovadorNDComplementoInformacoes;
    },
    uri() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.idAcao}/apuracao/${this.idApuracao}`;
    },
    cabecalho() {
      if (this.isUsuarioFornecedor) {
        return [...this.cabecalhoFornecedor];
      }
      const cabecalhoFinal = [...this.cabecalhoBase];
      if (this.podeAlterarTipoDeUso) {
        cabecalhoFinal.push({
          text: this.$tc('label.tipo_uso', 1), value: 'tipo_uso', sortable: false, width: '20%',
        });
      }
      return cabecalhoFinal;
    },
  },
  methods: {
    getMoney,
    required(v) {
      return (!this.validarJustificativa || !!v) || this.$t('label.campo_obrigatorio');
    },
    cancelar() {
      this.fechar();
    },
    fechar() {
      this.justificativa = '';
      this.menu = false;
      this.exibirPerguntaProximo = false;
      this.desbloquearCampos();
    },
    naoIrProximo() {
      this.$emit('AcaoFluxo_executarAcao', this.justificativa, false);
      this.fechar();
    },
    irProximo() {
      this.$emit('AcaoFluxo_executarAcao', this.justificativa, true);
      this.fechar();
    },
    acaoExecutada() {
      this.exibirPerguntaProximo = true;
    },
    desbloquearCampos() {
      this.bloquearCampos = false;
    },
    executarAcao() {
      if (!this.$refs.form.validate()) return;
      this.bloquearCampos = true;
      if (this.isAprovacao) {
        this.salvarTipoUso();
        this.salvarDataContabilizacao();
      }
      if (this.perguntarProximo) {
        this.acaoExecutada();
      } else {
        this.naoIrProximo();
      }
    },
    getStatus(status) {
      return {
        APROVADO: this.$tc('status_entidade.aprovado', 1),
        AGUARDANDO_APROVACAO: this.$tc('status_entidade.aprovado', 1),
        EM_ANALISE: this.$tc('status_entidade.em_analise', 1),
        REPROVADO: this.$tc('status_entidade.reprovado', 1),
      }[status];
    },
    carregarTipoUsoDaAcao() {
      const { idAcao } = this;
      this.workflowAcaoMagaluResource
        .carregarTipoUso({ idAcao })
        .then((res) => {
          this.tipoUso = { id: res.data.id, nome: res.data.nome };
          const tipoUso = this.listaTiposUso.find((tipo) => tipo.nome === this.tipoUso.nome);
          if (tipoUso) {
            tipoUso.id = this.tipoUso.id;
            this.fornecedoresNd.forEach((fornecedor) => {
              fornecedor.idTipoUso = this.tipoUso.id;
            });
          }
        })
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    buscarTiposUso() {
      return this.workflowAcaoMagaluResource
        .buscarTiposUso()
        .then((res) => res)
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    salvarTipoUso() {
      if (this.tipoUso) {
        const { idApuracao } = this;
        const tiposUso = this.fornecedoresNd.map((nd) => ({
          idTipoUso: nd.idTipoUso,
          idNotaDebitoPrevia: nd.idNotaDebitoPrevia,
        }));
        this.workflowApuracaoAcaoMagalu.salvarApuracaoAcaoTipoUso({ idApuracao }, tiposUso)
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    verificaSeUsuarioAprovadorNDComplementoInformacoes() {
      const params = {
        idApuracao: this.idApuracao,
        idPerfil: this.usuarioLogado.idPerfil,
      };
      this.workflowApuracaoAcaoMagalu
        .verificarSeUsuarioAprovadorNDComplementoInformacoes(params)
        .then((res) => {
          this.usuarioAprovadorNDComplementoInformacoes = res.data;
          if (this.podeAlterarDataContabilizacao) {
            this.setaDataFormatada();
          }
          if (this.usuarioAprovadorNDComplementoInformacoes) {
            Promise.all([this.buscarTiposUso(), this.buscarFornecedoresNd()])
              .then((response) => {
                const [retorno] = response;
                this.listaTiposUso = retorno.data;
                this.carregarTipoUsoDaAcao();
              });
          } else {
            this.buscarFornecedoresNd();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvarDataContabilizacao() {
      if (this.podeAlterarDataContabilizacao && this.dataContabilizacao) {
        const { idApuracao } = this,
          { dataContabilizacao } = this;
        this.workflowApuracaoAcaoMagalu.salvarApuracaoAcaoDataContabilizacao({ idApuracao },
          { data_contabilizacao: dataContabilizacao })
          .then(() => {})
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    setaDataFormatada() {
      this.dataContabilizacaoFormatada = this.retornaDataFormatada(this.dataContabilizacao);
    },
    retornaDataFormatada(data) {
      if (!data) return null;
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    buscarFornecedoresNd() {
      return this.apuracaoListagemResource.buscarFornecedoresNdFluxo({ idApuracao: this.idApuracao })
        .then((res) => {
          this.fornecedoresNd = res.data;
        });
    },
    imprimirNDPrevia(item) {
      const { idAcao, idApuracao, idFornecedor } = item,
        url = basePath('job', 'recebimento/relatorio'),
        resource = download(this.$http);

      resource.downloadGet(url, {
        param: '',
        url: `nota-debito-por-fornecedor/preview/${idAcao}/${idApuracao}/${idFornecedor}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    exibirBotaoExibicaoMultiplasNds(item) {
      return item.qtdNds && item.qtdNds === 'MULTIPLAS' && item.listaMultiplasNds && item.listaMultiplasNds.length;
    },
    abrirModalExibicaoMultiplasNds(item) {
      this.$refs.ModalExibicaoMultiplasNds.open(item);
    },
  },
  mounted() {
    if (this.idApuracao) {
      this.verificaSeUsuarioAprovadorNDComplementoInformacoes();
    }
  },
};
</script>
