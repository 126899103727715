<template>
  <v-img :src="image" v-if="image" max-height="80vh" contain class="mt-3"></v-img>
</template>

<script>
export default {
  name: 'ViewImage',
  props: {
    file: {
      defaut: () => {},
    },
    uri: '',
    extension: null,
  },
  data() {
    return {
      image: null,
    };
  },
  methods: {
    downloadView() {
      const file = this.file.name;
      this.$http.get(`${this.uri}/view?file=${file}&mediaType=image/${this.extension}`)
        .then((response) => {
          if (response.data) {
            this.image = `data:image/${this.extension};base64,${response.data}`;
          }
        });
    },
  },
  mounted() {
    this.downloadView();
  },
};
</script>
