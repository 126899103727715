<template>
  <v-autocomplete
    :id="inputId"
    class="Metadados__Autocomplete custom-autocomplete"
    v-model="objetoInterno"
    :rules="[rules.requiredNome]"
    :items="itens"
    :label="inputLabel"
    :no-data-text="$tc('message.nenhum_registro', 1)"
    :item-text="metadados.async.itemText"
    :item-value="metadados.async.itemValue"
    :disabled="somenteLeitura || campoDesabilitado"
    :chips="multiplaEscolha"
    :multiple="multiplaEscolha"
    :clearable="!campoDesabilitado"
    :hint="metadados.hintText? metadados.hintText : ''"
    persistent-hint
    :loading="exibeBarraLoading"
    :menu-props="menuProps"
    :filter="!metadados.filter ? filterObject : metadados.filter"
    deletable-chips
    return-object
    :placeholder="(somenteLeitura || campoDesabilitado) ? null : $tc('message.digite_para_pesquisar', 1)"
    @click:append="triggerSelecao"
    @click.native="buscarItens"
    @input="emiteValorAlterado"
    :search-input.sync="trigger">
    <template v-slot:selection="data">
      <v-chip
        v-if="multiplaEscolha"
        :input-value="data.selected"
        close
        class="chip--select-multi"
        @click:close="removeChipSelecao(data.item)"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span class="trunc-ellipsis" v-on="on">
              {{ data.item[metadados.async.itemText] }}
            </span>
          </template>
          {{ data.item[metadados.async.itemText] }}
        </v-tooltip>
      </v-chip>
      <span v-if="!multiplaEscolha">{{ data.item[metadados.async.itemText] }}</span>
    </template>
  </v-autocomplete>
</template>
<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';
import { skipLoading } from '../../../common/functions/loading';

export default {
  name: 'MetadadosCampoDinamicoAutocomplete',
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  props: {
    value: {
      type: [Object, Array],
    },
    exibeLoadingOverlay: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    valorDefaultSelecao() {
      return this.multiplaEscolha ? [] : {};
    },
    multiplaEscolha() {
      return this.metadados.tipoCampo === 'MULTIPLA_ESCOLHA';
    },
    appendIcon() {
      return !this.campoDesabilitado ? 'search' : '';
    },
    menuProps() {
      return this.metadados.menuProps
        || ({
          closeOnClick: false,
          closeOnContentClick: false,
          openOnClick: false,
          maxHeight: 300,
        });
    },
  },
  watch: {
    trigger(val) {
      if (!this.multiplaEscolha) {
        const selecao = this.objetoInterno || {},
          vlrSelecao = selecao[this.metadados.async.itemText];

        if (vlrSelecao === val) {
          return;
        }
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarItens(val);
      }, 500);
    },
    value: {
      immediate: true,
      handler(alterado, anterior) {
        const selecaoAtual = alterado || this.valorDefaultSelecao,
          selecaoAnterior = anterior || this.valorDefaultSelecao;

        if (this.multiplaEscolha) {
          if (this.listasDiferentes(this.objetoInterno || [], selecaoAtual)) {
            this.itens = [...selecaoAtual];
            this.objetoInterno = [...selecaoAtual];
          }
          return;
        }

        if (selecaoAtual[this.metadados.async.itemValue]
          === (this.objetoInterno || {})[this.metadados.async.itemValue]) {
          return;
        }

        if (Object.keys(selecaoAtual).length === 0) {
          this.removeModelo(selecaoAnterior);
          this.objetoInterno = {};
        } else {
          this.objetoInterno = { ...selecaoAtual };
          this.aplicaModelo(selecaoAtual);
        }
      },
    },
  },
  data() {
    return {
      itens: [],
      objetoInterno: null,

      trigger: null,
      timeoutTrigger: null,

      exibeBarraLoading: false,

      rules: {
        requiredNome: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const objeto = this.objetoInterno || this.valorDefaultSelecao;

          if (this.multiplaEscolha) {
            return (objeto && objeto.length && objeto.length > 0) || msg;
          }

          const val = objeto[this.metadados.async.itemText];
          return !!(val) || msg;
        },
      },
    };
  },
  methods: {
    removeChipSelecao(item) {
      const selecao = this.objetoInterno;
      const index = selecao.map((s) => s.id).indexOf(item.id);
      if (index >= 0) {
        selecao.splice(index, 1);
        this.emiteValorAlterado(selecao);
      }
    },
    filterObject(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase())
          > -1
        || itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    listasDiferentes(lista1, lista2) {
      if (!Array.isArray(lista1)
        || !Array.isArray(lista2)
        || lista1.length === 0
        || lista2.length === 0) {
        return true;
      }
      const hashItens = lista1.map((i) => i.id).join(''),
        hashModelo = lista2.map((i) => i.id).join('');
      return hashItens !== hashModelo;
    },
    triggerSelecao() {
      const id = this.inputId;
      setTimeout(() => document.getElementById(id).click());
    },
    buscarItens(autocomplete) {
      const { async } = this.metadados;
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      if (!this.exibeLoadingOverlay) {
        this.exibeBarraLoading = true;
        skipLoading();
      }
      async.fetchFunction(autocomplete)
        .then((response) => {
          const { data } = response;
          this.itens = Array.isArray(data)
            ? data : data.resposta;
          if (this.multiplaEscolha) {
            const selecao = this.objetoInterno || [];
            this.itens = [...selecao, ...this.itens];
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        })
        .finally(() => {
          this.exibeBarraLoading = false;
        });
    },
    aplicaModelo(selecaoAtual) {
      if (this.multiplaEscolha) {
        this.itens = [...selecaoAtual];
      } else {
        this.itens.push(selecaoAtual);
      }
    },
    removeModelo(selecaoAnterior) {
      if (this.multiplaEscolha) {
        let indice = 0;
        for (; indice < this.itens.length; indice += 1) {
          if (selecaoAnterior.id === valor.id) {
            break;
          }
        }
        this.itens.splice(indice, 1);
      } else {
        this.itens = [];
      }
    },
  },
};
</script>
<style>
  .Metadados__Autocomplete .chip--select-multi {
    width: 195px;
  }
  .Metadados__Autocomplete .chip--select-multi .v-chip__content span.trunc-ellipsis {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 150px;
  }
</style>
