<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-row justify-end>
        <v-col cols="12" sm="12">
          <h3 class="headline mb-0">{{ $t('title.calendario') }}</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="search"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-menu
            ref="mes_pesquisa"
            :close-on-content-click="false"
            v-model="menuMesPesquisa"
            id="mes_pesquisa"
            name="mes_pesquisa"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                class="CalendarioTabela-data__text"
                :return-masked-value="true"
                v-mask="'##-####'"
                :rules="[rules.validDate]"
                v-model="mesPesquisaFormatado"
                :label="$t('label.mes')"
                prepend-icon="event">
              </v-text-field>
            </template>
            <v-date-picker locale="pt-br" color="primary" type="month" v-model="mesPesquisa" @input="menuMesPesquisa = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-switch
            v-model="indCancelado"
            color="primary"
            @change="filtrar"
            :label="$t('label.eventos_cancelados')"
            single-line
            hide-details>
          </v-switch>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="eventos"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.cor="{ item }">
        <v-icon :color="item.cor">lens</v-icon>
      </template>
      <template v-slot:item.nome="{ item }"> {{ item.nome }}</template>
      <template v-slot:item.descricao="{ item }"> {{ item.descricao }}</template>
      <template v-slot:item.dataInicio="{ item }"> {{ convertDate(item.dataInicio) }}</template>
      <template v-slot:item.dataFim="{ item }"> {{ convertDate(item.dataFim) }}</template>
      <template v-slot:item.cancelamento="{ item }">
        <v-tooltip bottom v-if="dataFimMaiorQueDataAtual(item) && !item.indCancelado">
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click="cancelarItem(item)">
              <v-icon>cancel</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.cancelar') }}</span>
        </v-tooltip>
      </template>
      <template slot="pageText" slot-scope="props">
        {{ props.pageStart }} - {{ props.pageStop }} {{$tc('label.de', 1)}} {{ props.itemsLength }}
      </template>
    </v-data-table>
    <confirm
      ref="cancelDialog"
      :message="$t('message.deseja_cancelar_evento')"
      :persistent="true"
      @disagree="cancelarAlteracaoStatus"
      @agree="cancelarEvento">
    </confirm>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_ativar_inativar')"
      :persistent="true"
      @disagree="cancelarAlteracaoStatus"
      @agree="alterarStatus">
    </confirm>
  </div>
</template>

<script>
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'CalendarioTabela',
  components: {
    Confirm,
  },
  props: {
    somenteLeitura: true,
  },
  data() {
    return {
      search: '',
      resources: this.$api.calendario(this.$resource),
      totalPage: 0,
      mesAnoPesquisa: this.moment().format('MM-YYYY'),
      indCancelado: false,
      esperar: false,
      menuMesPesquisa: false,
      evento: {},
      pagination: {},
      headers: [
        { text: this.$tc('label.cor', 1), value: 'cor', sortable: false },
        { text: this.$t('label.nome', 1), value: 'nome', sortable: false },
        { text: this.$tc('label.tipo', 1), value: 'descricao', sortable: false },
        { text: this.$tc('label.data_inicio', 1), value: 'dataInicio', sortable: false },
        { text: this.$tc('label.data_fim', 1), value: 'dataFim', sortable: false },
        { text: this.$t('label.usuario_criador'), value: 'usuarioCriacao', sortable: false },
        { text: this.$t('label.usuario_alteracao'), value: 'usuarioAlteracao', sortable: false },
        { text: this.$t('label.data_do_cancelamento'), value: 'dataCancelamento', sortable: false },
        { text: this.$t('label.cancelar'), value: 'cancelamento', sortable: false },
      ],
      rules: {
        validDate: (value) => this.isDataValida(value) || this.$t('message.data_invalida'),
      },
      eventos: [],
    };
  },
  computed: {
    mesPesquisaFormatado: {
      get() {
        return this.mesAnoPesquisa;
      },
      set(newValue) {
        this.mesAnoPesquisa = newValue;
      },
    },
    mesPesquisa: {
      get() {
        return this.moment(this.mesAnoPesquisa, 'MM-YYYY').format('YYYY-MM');
      },
      set(newValue) {
        this.mesAnoPesquisa = this.moment(newValue, 'YYYY-MM').format('MM-YYYY');
      },
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    mesAnoPesquisa() {
      this.filtrar();
    },
  },
  methods: {
    cancelarEvento() {
      const params = {
        id: this.evento.id,
      };
      this.resources.cancelar({ id: this.evento.id }, params).then(() => {
        this.$toast(this.$t('message.atualizado_confira_tabela'));
        this.esperar = true;
        setTimeout(() => {
          this.buscar();
        }, 1000);
      }, (err) => {
        this.cancelarAlteracaoStatus();
        this.$error(this, err);
      });
    },
    dataFimMaiorQueDataAtual(item) {
      if (item && item.dataFim) {
        return this.moment(item.dataFim, 'DD/MM/YYYY').isSameOrAfter(this.moment(), 'day')
                || this.moment(item.dataFim, 'YYYY-MM-DD').isSameOrAfter(this.moment(), 'day');
      }
      return false;
    },
    cancelarItem(evento) {
      this.evento = evento;
      this.$refs.cancelDialog.open();
    },
    isDataValida(value) {
      return (!value || (value.length === 7 && this.moment(value, 'MM-YYYY').isValid()));
    },
    convertDate(data) {
      const dataM = this.moment(data, 'DD/MM/YYYY HH:mm:ss');
      if (dataM.isValid()) {
        return dataM.format('DD/MM/YYYY');
      }
      return this.moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    ativarInativa(evento) {
      this.evento = evento;
      this.$refs.confirmDialog.open();
    },
    cancelarAlteracaoStatus() {
      this.evento.indAtivo = !this.evento.indAtivo;
    },
    alterarStatus() {
      const params = {
        id: this.evento.id,
        status: this.evento.indAtivo,
      };
      this.resources.ativarInativar(params, params).then(() => {
        this.$toast(this.$t('message.atualizado_confira_tabela'));
      }, (err) => {
        this.cancelarAlteracaoStatus();
        this.$error(this, err);
      });
    },
    filtrar() {
      if (this.esperar) return;
      if (!this.mesAnoPesquisa || !this.mesAnoPesquisa.length
        || this.isDataValida(this.mesAnoPesquisa)) {
        this.esperar = true;
        setTimeout(() => {
          this.buscar();
        }, 1000);
      }
    },
    editItem(item) {
      this.$emit('edit', item.id);
    },
    buscar() {
      const params = {
        nome: this.search,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        indCancelado: this.indCancelado,
        colunaOrdenacao: this.pagination.sortBy[0],
        dataInicio: this.getDataInicio(),
        dataFim: this.getDataFim(),
      };

      this.resources.listar(params).then((response) => {
        this.eventos = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    getDataInicio() {
      if (!this.mesAnoPesquisa || !this.mesAnoPesquisa.length) return null;
      return this.moment(this.mesAnoPesquisa, 'MM-YYYY').startOf(1).format('YYYY-MM-DD');
    },
    getDataFim() {
      if (!this.mesAnoPesquisa || !this.mesAnoPesquisa.length) return null;
      return this.moment(this.mesAnoPesquisa, 'MM-YYYY').endOf('month').format('YYYY-MM-DD');
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  beforeMount() {
    if (!this.somenteLeitura) {
      this.headers.push({ text: '', value: 'acao', sortable: false });
    }
  },
  mounted() {
    this.filtrar();
  },
};
</script>

<style>
  .CalendarioTabela-data__text > label {
    animation: unset !important;
  }
</style>
