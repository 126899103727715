<template lang="html">
  <div>
    <v-card class="mr-1 mt-1 mb-1 card-size">
      <v-card-title>
        <h3 class="headline mb-0">{{ $tc('label.grupo_fornecedor', 1) }}</h3>
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="search"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="listaGrupos"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.id="{ item }">
          <span :class="{ 'row--disabled': !item.indAtivo }">
            {{ isAmbienteEpoca ? item.idExterno : item.id }}
          </span>
        </template>
        <template v-slot:item.nom_extensao="{ item }">
          <span :class="{ 'row--disabled': !item.indAtivo }">
            {{ item.nomExtensao }}
          </span>
        </template>
        <template v-slot:item.ind_ativo="{ item }">
          <span :class="{ 'row--disabled': !item.indAtivo }">
            {{ item.indAtivo ? $t('label.sim') : $t('label.nao')}}
          </span>
        </template>
        <template v-slot:item.fornecedor_matriz="{ item }">
          <span v-if="item.descricoesCnpjMatriz.length > 1">
            <v-menu open-on-hover
              offset-x
              top
              :nudge-width="300"
              :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{`${item.descricoesCnpjMatriz[0]}${item.descricoesCnpjMatriz.length > 1 ? '...' : ''}`}}
                </span>
              </template>
              <v-card>
                <v-card-text>
                  <v-treeview :items="item.tree"></v-treeview>
                </v-card-text>
              </v-card>
            </v-menu>
          </span>
          <span v-else>{{ item.descricoesCnpjMatriz[0] }}</span>
        </template>
        <template v-slot:item.acoes="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon class="mx-0" v-on="on" @click="editItem(item)" :disabled="somenteLeitura">
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.editar') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'NivelFornecedorTabela',
  components: {
    Confirm,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    niveisExtensoes: Array,
  },
  data() {
    return {
      search: '',
      resources: this.$api.extensao(this.$resource),
      esperar: false,
      pagination: {},
      totalPage: 0,
      listaGrupos: [],
      headers: [
        {
          text: '', value: 'acoes', width: '5%', sortable: false,
        },
        { text: this.$tc('label.codigo', 1), value: 'id' },
        { text: this.$tc('label.descricao', 1), value: 'nom_extensao' },
        { text: this.$tc('label.fornecedor_pagador', 1), value: 'fornecedor_matriz' },
        { text: this.$tc('label.ativo', 1), value: 'ind_ativo' },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
  },
  methods: {
    buscar() {
      if (this.search) {
        this.pagination.page = 1;
      }

      const params = {
        filtro: this.search,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
        idsNiveisExtensao: this.niveisExtensoes,
      };

      this.resources.listarDetalhado(params).then((response) => {
        this.listaGrupos = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.montarTreeCnpjMatriz();
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    editItem(item) {
      this.$emit('edit', item);
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    montarTreeCnpjMatriz() {
      this.listaGrupos.forEach((grupo) => {
        grupo.tree = grupo.descricoesCnpjMatriz.map((descricao) => ({ name: descricao }));
      });
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
<style>
.NivelFornecedorTabela__Hier {
  color: black !important;
}
</style>
