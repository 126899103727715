import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'estado');

const estadoActions = {
  ...genericActions(basePath),
};

export const estadoResource = (resource) => resource(`${basePath}`, {}, estadoActions);

export default estadoResource;
