import getBasePath from '../functions/api-resource';

const basePath = getBasePath('usuario', 'usuario-pre-cadastro');

const usuarioPreCadastroActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  gravar: { method: 'POST', url: `${basePath}` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  primeiroAcesso: { method: 'POST', url: `${basePath}/primeiro-acesso` },
  excluir: { method: 'DELETE', url: `${basePath}/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, usuarioPreCadastroActions);
