import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('job', 'exportacao-assincrona-realizado');

const tipoUsoActions = {
};

export default (resource) => resource(`${basePath}`, {}, tipoUsoActions);

export const executarExportacao = (parametros, resource) => resourceBuilder.buildPOST(resource, basePath, 'executar', parametros).doPost(parametros);

export const verificarDadosExportacao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'verificar-dados-exportacao', parametros).doGet();
