<template lang="html">
  <v-tooltip right v-if="lista" content-class="TooltipAjudaLista__content">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">info</v-icon>
    </template>
    <v-card class="pa-2" >
      <span class="pb-0 text-md-body-1">{{titulo}}</span>
      <v-container class="pt-0 mt-0 text-lg-h6" grid-list-md>
          <div v-for="(valor, i) in lista"
            :key="i">
            <span>{{ $tc(valor, 1) }}</span>
          </div>
      </v-container>
    </v-card>
  </v-tooltip>
</template>

<script type="text/javascript">
export default {
  name: 'TooltipAjudaLista',
  props: {
    lista: Array,
    titulo: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>

<style >
.TooltipAjudaLista__content {
  background-color: transparent !important;
}
</style>
