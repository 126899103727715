<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="80%">
      <v-card class="py-6 px-6">
        <v-card-title>
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-container>
          <v-row no-gutters>
          <v-col cols="3">
          <p class="font-weight-black m-0 p-0">
            {{ $tc('message.categoria_contrato_aprovacao', 2) }}
          </p>
        </v-col>
        <v-col cols="3">
          <v-data-table
          dense
          :headers="headerCategoria"
          hide-default-header
          hide-default-footer
          disable-pagination
          class="m-0 p-0"
          :items="listaCategoriasAprovacao">
            <template class="m-0 p-0" v-slot:[`item.categoria`]="{ item }">
              {{ item.categoria }}
            </template>
          </v-data-table>
        </v-col>
        </v-row>
        </v-container>
        <div>
          <v-data-table
          :headers="headerRenovacaoContrato"
          :items="contratosRenovacao"
          :no-data-text="$t('label.tabela_sem_contrato_substituicao')"
          >
          <template v-slot:[`item.origem_contrato`]="{ item }">
            {{ item.origem_contrato }}
          </template>
          <template v-slot:[`item.data_inicio`]="{ item }">
            {{ item.data_inicio }}
          </template>
          <template v-slot:[`item.data_fim`]="{ item }">
            {{ item.data_fim }}
          </template>
          <template v-slot:[`item.categoria`]="{ item }">
            {{ item.categoria }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status }}
          </template>
          <template v-slot:[`item.tipo_contrato`]="{ item }">
            {{ item.tipo_contrato }}
          </template>
          <template v-slot:[`item.detalhar`]="{ item }">
            <v-btn small depressed color="primary" @click="abrirDetalhes(item)">
            {{ $tc('label.detalhe', 2) }}
            </v-btn>
          </template>
        </v-data-table>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text
                 @click="close()">{{$t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalContratoSubstituicao',
  props: {
  },
  data() {
    return {
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      dialog: false,
      idContrato: null,
      titulo: '',
      contratosRenovacao: [],
      listaCategoriasAprovacao: [],
      headerCategoria: [
        {
          text: this.$tc('label.categoria', 1), value: 'categoria', width: '50%', align: 'left', sortable: false,
        },
      ],
      headerRenovacaoContrato: [
        {
          text: '', value: 'checkbox', width: '1%', sortable: false,
        },
        {
          text: this.$tc('label.id', 1), value: 'id_contrato', width: '1%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.origem_contrato', 1), value: 'origem_contrato', width: '12%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.data_inicio', 1), value: 'data_inicio', width: '6%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.data_fim', 1), value: 'data_fim', width: '5%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.categoria', 1), value: 'categoria', width: '10%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.status', 1), value: 'status', width: '5%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.tipo_contrato', 1), value: 'tipo_contrato', width: '10%', align: 'center', sortable: false,
        },
        {
          text: '', value: 'detalhar', width: '2%', sortable: false,
        },
      ],
    };
  },
  methods: {
    open(dados) {
      this.resetaCampos();
      this.idContrato = dados.idContrato;
      this.titulo = dados.titulo;
      this.dialog = true;
      this.buscarContratosRenovacao(this.idContrato);
    },
    close() {
      this.resetaCampos();
      this.dialog = false;
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
    resetaCampos() {
      this.titulo = '';
      this.contratosRenovacao = [];
      this.listaCategoriasAprovacao = [];
    },
    buscarContratosRenovacao(idContrato) {
      this.workflowContratoResource.buscarContratosRenovacao({ idContrato }).then((res) => {
        this.contratosRenovacao = res.data;
        this.listaCategoriasAprovacao = [];
        this.contratosRenovacao.forEach((contrato) => {
          if (!this.listaCategoriasAprovacao.find((contratoListaCategoria) => contratoListaCategoria.categoria === contrato.categoria)) {
            this.listaCategoriasAprovacao.push(contrato);
          }
        });
      });
    },
    abrirDetalhes(contrato) {
      this.$router.push({ name: 'detalharContrato', params: { idContrato: contrato.id_contrato, from: 'dashboard-inicial', modalDashboard: 'PENDENCIAS_CONTRATO' } });
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
