import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'tipo-acao');

const tipoAcaoActions = {
  ...genericActions(basePath),
  buscarAtivos: { method: 'GET', url: `${basePath}/ativos` },
  buscarTodosTiposAcao: { method: 'GET', url: `${basePath}/todos-tipos-acao` },
  buscarAtivosDeContrato: { method: 'GET', url: `${basePath}/contrato/ativos` },
  buscarConfiguradasEmTipoContrato: { method: 'GET', url: `${basePath}/configuradas/tipo-contrato` },
  buscarPorVisao: { method: 'GET', url: `${basePath}/visao` },
  buscarTipoAcaoComConfiguracao: { method: 'GET', url: `${basePath}/{idTipoAcao}/configuracao` },
  buscarTipoAcao: { method: 'GET', url: `${basePath}/{id}` },
  buscarTiposAcaoExclusivaContrato: { method: 'GET', url: `${basePath}/exclusiva-contrato` },
  selecao: { method: 'GET', url: `${basePath}/selecao{?exceto*&contendo*}` },
};

export default (resource) => resource(`${basePath}`, {}, tipoAcaoActions);
