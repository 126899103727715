<template>
  <v-dialog v-model="dialog" eager max-width="400px" persistent>
    <v-form ref="formJustificativa" v-model="valido" lazy-validation>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12">
              <span class="headline">{{ title || bloquearJustificativa ? $t('label.justificativa') : $t('label.informe_justificativa') }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                ref="justificativa"
                :label="`${$t('label.justificativa')} *`"
                :rules="[rules.required]"
                v-if="!bloquearJustificativa"
                maxlength="200"
                required
                v-model.trim="novaJustificativa">
              </v-text-field>
              <span v-else>
                {{ novaJustificativa }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text v-if="bloquearJustificativa" @click="close()">{{ $t('label.ok') }}</v-btn>
          <v-btn color="primary" text v-else @click="cancelar()">{{ $t('label.cancelar') }}</v-btn>
          <v-btn color="primary" text v-if="!bloquearJustificativa"
                 @click="afirmativo()">{{ textoBotaoAfirmativo ? textoBotaoAfirmativo : $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { copyObject } from '../../../common/functions/helpers';

export default {
  name: 'JustificativaFluxo',
  props: {
    title: '',
    textoBotaoAfirmativo: null,
    justificativa: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      valido: false,
      dialog: false,
      bloquearJustificativa: true,
      novaJustificativa: '',
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  watch: {
    justificativa() {
      if (this.justificativa && this.justificativa.length) {
        this.novaJustificativa = copyObject(this.justificativa);
      }
    },
  },
  methods: {
    open() {
      this.bloquearJustificativa = false;
      this.novaJustificativa = this.justificativa !== null ? copyObject(this.justificativa) : '';
      this.dialog = true;
      setTimeout(() => {
        this.$refs.justificativa.focus();
      }, 2E2);
    },
    openReadOnly(justificativa = null) {
      this.bloquearJustificativa = true;
      this.novaJustificativa = justificativa !== null ? copyObject(justificativa) : '';
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.novaJustificativa = '';
      this.$refs.formJustificativa.reset();
    },
    afirmativo() {
      if (!this.$refs.formJustificativa.validate()) {
        return;
      }
      this.$emit('confirmar', this.novaJustificativa);
      this.close();
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
  },
};
</script>
