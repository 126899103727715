import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('api', 'configuracao-workflow-aprovacao');

const configuracaoWorkflowAprovacaoActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  pesquisar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  verificar: { method: 'GET', url: `${basePath}/habilitado?chave={chave}` },
};

export default (resource) => resource(`${basePath}`, {}, configuracaoWorkflowAprovacaoActions);
