export default {
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      magaluRelatoriosResource: this.$api.magaluRelatorios(this.$resource),

      camposFormularioEpoca: {
        padrao: [
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ incluirAcoesContrato: true, autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
        ],
      },
    };
  },
};
