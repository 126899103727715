import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'cidade');

const cidadeActions = {
  ...genericActions(basePath),
  listarAtivos: { method: 'GET', url: `${basePath}?nome={nome}&colunaOrdenacao=nom_cidade` },
};

export default (resource) => resource(`${basePath}`, {}, cidadeActions);
