<template>
  <div>
    <v-card class="card-size">
      <v-card-title>
        <h3 class="headline mb-0">{{ $tc('title.configuracao_sistema', 2) }}</h3>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="configuracoes"
        item-key="chave"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [20, 35, 50],
        }">
        <template v-slot:[`item.valor`]="{ item }">
            <v-row>
              <v-col cols="12">
                <span>{{ item.valor }}</span>
                <v-btn
                  v-if="canPost"
                  :id="'more_vert_' + item.chave"
                  icon
                  @click.native="abrirModalEdicao(item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-col>
            </v-row>
        </template>
      </v-data-table>
      <dialog-editar-config-sistema
        ref="editarConfigSistema"
        :item-selecionado="itemSelecionado"
        @ATUALIZAR_CONFIGURACAO="atualizar">
      </dialog-editar-config-sistema>
    </v-card>
  </div>
</template>

<script>
import { generateComputed } from '../../common/functions/roles-computed-generator';
import DialogEditarConfigSistema from './DialogEditarConfigSistema';

export default {
  name: 'ConfiguracaoSistema',
  data() {
    return {
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      configuracoes: [],
      headers: [
        { text: this.$tc('label.chave', 1), value: 'chave' },
        { text: this.$tc('label.descricao', 2), value: 'descricao' },
        { text: this.$tc('label.valor', 1), value: 'valor' },
        { text: this.$tc('label.formato', 1), value: 'formato' },
      ],
      totalPage: 0,
      itemSelecionado: {},
    };
  },
  components: {
    DialogEditarConfigSistema,
  },
  computed: {
    ...generateComputed('CONFIG_SISTEMA', [
      'canAccessPage',
      'canPost',
      'canSearch',
    ]),
  },
  methods: {
    editar(item) {
      this.$router.push({ name: 'editarEstruturaUsuario', params: { id: item.id } });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    buscar() {
      this.configSistemaResource.buscarConfiguracoes()
        .then((response) => {
          this.configuracoes = response.data;
          this.totalPage = response.data.quantidadeRegistrosPagina;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirModalEdicao(item) {
      this.itemSelecionado = item;
      this.$refs.editarConfigSistema.open(item);
    },
    atualizar(item) {
      const parametros = {
        chave: item.chave,
        valor: item.novoValor,
      };
      this.configSistemaResource.atualizarConfiguracao(parametros, parametros)
        .then(() => {
          item.valor = item.novoValor;
          delete item.novoValor;
        })
        .catch((err) => {
          delete item.novoValor;
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.buscar();
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
