import exportacao from '../resources/exportacoes';

function exportarFiltros(filtros, cb, contexto, nomeExportador,
  limit, timeout, resourceOptional = null) {
  const resource = exportacao(contexto.$http, timeout);
  contexto.$toast(contexto.$t('message.exportacao_download'));
  resource.download(nomeExportador,
    {
      limit,
      parametrosConsulta: filtros,
    }, resourceOptional)
    .then(() => {
      if (cb) {
        cb();
      }
    })
    .catch(() => {
      if (cb) {
        cb();
      }
      contexto.$toast('Erro ao exportar. Tente novamente.');
    });
}

export default {
  exportar(cb, nomeExportador, contexto, parametros = {}, limit = 500,
    timeout = 120000, resourceOptional = null) {
    exportarFiltros(parametros, cb, contexto, nomeExportador, limit, timeout, resourceOptional);
  },
};
