<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="400"
      transition="scale-transition"
      offset-x
      left>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...menu }" icon :color="corIcone">
              <v-icon>{{ icone }}</v-icon>
            </v-btn>
          </template>
          <span>{{ textoAcao }}</span>
        </v-tooltip>
      </template>

      <v-card :style="isUsuarioControleComercial ? 'max-width: 1500px' : 'max-width: 800px'">
        <div v-if="!acaoPossuiPassoFornecedor">
          <v-alert type="warning"
                   elevation="2"
                   class="pa-2"
          >
            {{ this.$t('message.acao_sem_aprovacao_fornecedor') }}
          </v-alert>
        </div>

        <v-card-title>
          <div>
            <h3 class="headline mb-0">{{ textoAcao }}</h3>
          </div>
        </v-card-title>

        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" :sm="isUsuarioControleComercial ? '6' : '12'">
                <v-autocomplete
                  v-if="!isContrato && podeAlterarTipoDeUso"
                  id="justificativa-tipo-uso"
                  class="custom-autocomplete"
                  v-model="tipoUso"
                  :rules="[rules.required]"
                  :items="listaTiposUso"
                  :label="`${$tc('label.tipo_uso', 1)} *`"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  item-text="nome"
                  item-value="id"
                  required
                  :disabled="bloquearCampos"
                  :clearable="true"
                  @click:append="() => triggerSelecao('justificativa-tipo-uso')"
                  @click.native="(i) => buscaAutocomplete(i, buscarTiposUso)"
                ></v-autocomplete>
                <v-text-field
                  v-if="isContrato && usuarioAprovadorContratoComplementoInformacoes"
                  :label="$tc('label.prazo_pagamento_dias', 1)"
                  v-model="prazoPagamento"
                  type="Number"
                  min="1"
                  :hint="$tc('label.prazo_pagamento_padrao', 1)"
                  :persistent-hint="true"
                ></v-text-field>
                <v-menu
                  v-if="!isContrato && podeAlterarDataContabilizacao"
                  ref="data_contabilizacao"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      id="data_contabilizacao"
                      class="CalendarioForm-data__text"
                      readonly
                      clearable
                      :rules="[rules.required]"
                      v-model="dataContabilizacaoFormatada"
                      :label="`${$t('label.data_contabilizacao')} *`"
                      prepend-icon="event">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    color="primary"
                    scrollable
                    @input="setaDataFormatada()"
                    v-model="dataContabilizacao">
                  </v-date-picker>
                </v-menu>
                <span v-if="isContrato && isUsuarioFornecedor && isAprovacao">
                  {{ this.$t('message.fornecedor_aprovador_ler_carta_parceria') }}
                </span>
                <v-textarea
                  id="Acaofluxo_justificativa"
                  index="1"
                  ref="textareaJustificativa"
                  name="Acaofluxo_justificativa"
                  v-model="justificativa"
                  auto-grow
                  autofocus
                  rows="1"
                  :rules="[required]"
                  :disabled="bloquearCampos"
                  :label="validarJustificativa ? `${$t('label.justificativa')} *` : $t('label.justificativa')"
                  :counter="150"
                  maxlength="150">
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <div v-if="isContrato && isCartaParceria && isAprovacao">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <div>
                          <span>{{ this.$t('message.aprovar_carta_parceria_lista_documentos', datasFormatadas) }}</span>
                            <v-tooltip bottom v-if="isUsuarioControleComercial">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="grey darken-3"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  info
                                </v-icon>
                              </template>
                              <v-data-table
                              :headers="headers"
                              hide-default-header
                              hide-default-footer
                              disable-pagination
                              :items-per-page="-1"
                              :items="listaDocumentosGerados"
                              :no-data-text="$t('label.tabela_sem_conteudo')">
                                <template v-slot:[`item.nome_arquivo`]="{ item }">
                                  {{ item.nomeArquivo }}
                                </template>
                              </v-data-table>
                            </v-tooltip>
                        </div>
                        <div class="py-2" :align="isUsuarioControleComercial? 'start' : 'center'">
                          <v-btn
                            class="mb-2"
                            color="primary"
                            @click.native="realizarDownloadCartaParceria()">
                            {{ $tc('label.download', 1) }}
                          </v-btn>
                        </div>
                        <v-data-table v-if="!isUsuarioControleComercial"
                          :headers="headers"
                          hide-default-header
                          hide-default-footer
                          disable-pagination
                          :items-per-page="-1"
                          :items="listaDocumentosGerados"
                          :no-data-text="$t('label.tabela_sem_conteudo')">
                          <template v-slot:[`item.nome_arquivo`]="{ item }">
                            {{ item.nomeArquivo }}
                          </template>
                        </v-data-table>
                        <div v-if="isUsuarioControleComercial && listaCategoriasAprovacao.length">
                          <h3 class="red--text font-weight-bold">
                            {{ $tc('title.atencao_alerta', 1) }}
                          </h3>
                          <p class="mb-0 font-weight-regular">
                            {{ $tc('message.contratos_em_cancelamento', 1) }}
                          </p>
                          <p class="font-weight-regular">
                            {{ $tc('message.contratos_em_cancelamento_verificacao', 1) }}
                          </p>
                        </div>
                        <v-container v-if="isUsuarioControleComercial && listaCategoriasAprovacao.length">
                          <v-row no-gutters>
                            <v-col cols="3">
                              <p class="font-weight-black m-0 p-0">
                                {{ $tc('message.categoria_contrato_aprovacao', 2) }}
                              </p>
                            </v-col>
                            <v-col cols="3">
                              <v-data-table
                                dense
                                :headers="headerCategoria"
                                hide-default-header
                                hide-default-footer
                                disable-pagination
                                class="m-0 p-0"
                                :items="listaCategoriasAprovacao">
                                <template class="m-0 p-0" v-slot:[`item.categoria`]="{ item }">
                                  {{ item.categoria }}
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-container>
                        <div>
                        <v-data-table
                          v-if="isUsuarioControleComercial && contratosRenovacao.length && paginaCarregada"
                          :headers="headerRenovacaoContrato"
                          :items="contratosRenovacao"
                          :no-data-text="$t('label.tabela_sem_contrato_substituicao')"
                          >
                          <template v-slot:[`item.origem_contrato`]="{ item }">
                              {{ item.origem_contrato }}
                          </template>
                          <template v-slot:[`item.data_inicio`]="{ item }">
                              {{ item.data_inicio }}
                          </template>
                          <template v-slot:[`item.data_fim`]="{ item }">
                              {{ item.data_fim }}
                          </template>
                          <template v-slot:[`item.categoria`]="{ item }">
                              {{ item.categoria }}
                          </template>
                          <template v-slot:[`item.status`]="{ item }">
                              {{ item.status }}
                          </template>
                          <template v-slot:[`item.tipo_contrato`]="{ item }">
                              {{ item.tipo_contrato }}
                          </template>
                          <template v-slot:[`item.detalhar`]="{ item }">
                            <v-btn
                            small
                            depressed
                            color="primary"
                            @click="abrirDetalhes(item)">
                              {{ $tc('label.detalhe', 2) }}
                            </v-btn>
                          </template>
                        </v-data-table>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-tooltip bottom class="mr-3">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="cancelar" :disabled="bloquearCampos">
                <v-icon>cancel</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.cancelar') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon :color="corIcone" @click="executarAcao" :disabled="bloquearCampos">
                <v-icon>{{ icone }}</v-icon>
              </v-btn>
            </template>
            <span>{{ textoAcao }}</span>
          </v-tooltip>
        </v-card-actions>

      </v-card>

      <v-card v-if="exibirPerguntaProximo">
        <v-card-text>
          {{ $t('message.deseja_seguir_proxima_apuracao') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="naoIrProximo" color="primary">
            {{ $t('label.nao') }}
          </v-btn>

          <v-btn @click="irProximo" color="accent">
            {{ $t('label.sim') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import {
  buscaAutocomplete,
  triggerSelecao,
} from '@/produto/common/functions/autocomplete-utils';
import exportacaoCartaParceria from '@/produto/common/functions/downloads';
import basePath from '@/produto/common/functions/api-resource';

export default {
  name: 'AcaoFluxo',
  props: {
    corIcone: {
      type: String,
      default: null,
    },
    icone: {
      type: String,
      default: '',
    },
    disabled: false,
    status: {
      type: String,
      default: '',
    },
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
    validarJustificativa: false,
    textoAcao: {
      type: String,
      default: '',
    },
    idAcao: {
      type: Number,
      default: null,
    },
    idContrato: {
      type: Number,
      default: null,
    },
    isContrato: {
      type: Boolean,
      default: false,
    },
    isCartaParceria: {
      type: Boolean,
      default: false,
    },
    isAprovacao: {
      type: Boolean,
      default: false,
    },
    isModalidadeValorFixo: {
      type: Boolean,
      default: false,
    },
    dataInicioContrato: String,
    dataFimContrato: String,
  },
  data() {
    return {
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),

      menu: false,
      valid: false,
      bloquearCampos: false,
      justificativa: '',
      exibirPerguntaProximo: false,
      tipoUso: null,
      prazoPagamento: null,
      listaTiposUso: [],
      listaCategoriasAprovacao: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      paginaCarregada: false,
      indSubstituicaoContrato: false,
      usuarioAprovadorNDComplementoInformacoes: false,
      usuarioAprovadorContratoComplementoInformacoes: false,
      dataContabilizacaoFormatada: null,
      dataContabilizacao: new Date().toJSON().substr(0, 10),
      acaoPossuiPassoFornecedor: true,
      idPassoAtual: 0,
      listaDocumentosGerados: [],
      contratosRenovacao: [],
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: this.$tc('label.nome_arquivo', 1), value: 'nomeArquivo', width: '100%', align: 'center', sortable: false,
        },
      ],
      headerCategoria: [
        {
          text: this.$tc('label.categoria', 1), value: 'categoria', width: '50%', align: 'left', sortable: false,
        },
      ],
      headerRenovacaoContrato: [
        {
          text: '', value: 'checkbox', width: '1%', sortable: false,
        },
        {
          text: this.$tc('label.id', 1), value: 'id_contrato', width: '1%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.origem_contrato', 1), value: 'origem_contrato', width: '12%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.data_inicio', 1), value: 'data_inicio', width: '6%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.data_fim', 1), value: 'data_fim', width: '5%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.categoria', 1), value: 'categoria', width: '10%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.status', 1), value: 'status', width: '5%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.tipo_contrato', 1), value: 'tipo_contrato', width: '10%', align: 'center', sortable: false,
        },
        {
          text: '', value: 'detalhar', width: '2%', sortable: false,
        },
      ],
    };
  },
  watch: {
    menu(val) {
      if (val) {
        setTimeout(() => this.$refs.textareaJustificativa.focus(), 5E2);
      }
    },
    idAcao() {
      if (this.idAcao) {
        this.resetaCampos();
        const promises = [];
        promises.push(this.verificaSeUsuarioAprovadorNDComplementoInformacoes());
        promises.push(this.verificaSePossuiPassoFornecedor());
        Promise.all(promises)
          .then()
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    idContrato() {
      if (this.idContrato && this.isAprovacao) {
        this.resetaCampos();
        const promises = [];
        promises.push(this.verificaSeUsuarioAprovadorContratoComplementoInformacoes());
        if (this.isCartaParceria) {
          promises.push(this.carregaListaDocumentosGerados());
        }
        Promise.all(promises)
          .then()
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    textoAcao() {
      if (this.idContrato && this.isCartaParceria && this.isAprovacao) {
        this.carregaListaDocumentosGerados();
      }
    },
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isUsuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    isUsuarioControleComercial() {
      return this.usuarioLogado.perfil.toLowerCase() === 'controle comercial' && this.indSubstituicaoContrato;
    },
    datasFormatadas() {
      if (!this.dataInicioContrato || !this.dataFimContrato) return null;
      return {
        dataInicio: this.dataInicioContrato,
        dataFim: this.dataFimContrato,
      };
    },
    podeAlterarTipoDeUso() {
      return this.usuarioAprovadorNDComplementoInformacoes && !this.validarJustificativa;
    },
    podeAlterarDataContabilizacao() {
      return this.usuarioAprovadorNDComplementoInformacoes;
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    required(v) {
      return !this.validarJustificativa || !!v || this.$t('label.campo_obrigatorio');
    },
    cancelar() {
      this.fechar();
    },
    fechar() {
      this.justificativa = '';
      this.menu = false;
      this.exibirPerguntaProximo = false;
      this.desbloquearCampos();
    },
    naoIrProximo() {
      this.$emit('AcaoFluxo_executarAcao', this.justificativa, false);
      this.fechar();
    },
    irProximo() {
      this.$emit('AcaoFluxo_executarAcao', this.justificativa, true);
      this.fechar();
    },
    acaoExecutada() {
      this.exibirPerguntaProximo = true;
    },
    desbloquearCampos() {
      this.bloquearCampos = false;
    },
    executarAcao() {
      if (!this.$refs.form.validate()) return;
      this.bloquearCampos = true;
      this.salvarTipoUso();
      if (this.isAprovacao) {
        if (this.contratosRenovacao.length) {
          Promise.all([
            this.alterarContratoSubstituicaoAguardandoEncerramento(),
            this.salvarDataContabilizacao(),
            this.salvarComplementoInformacoes(),
          ]).then(() => {});
        } else {
          Promise.all([
            this.salvarDataContabilizacao(),
            this.salvarComplementoInformacoes(),
          ]).then(() => {});
        }
      }
      if (this.perguntarProximo) {
        this.acaoExecutada();
      } else {
        this.naoIrProximo();
      }
    },
    alterarContratoSubstituicaoAguardandoEncerramento() {
      const promises = [];
      this.contratosRenovacao.forEach((contrato) => {
        const param = {
          idContratoAtual: this.idContrato,
          idContratoEncerramento: contrato.id_contrato,
        };
        const promise = this.planejamentoContratoResource.alterarContratoSubstituicaoAguardandoEncerramento(param, param)
          .then(() => {})
          .catch((err) => {
            this.$toast(err.data.error);
          });
        promises.push(promise);
      });
      Promise.all(promises);
    },
    getStatus(status) {
      return {
        APROVADO: this.$tc('status_entidade.aprovado', 1),
        AGUARDANDO_APROVACAO: this.$tc('status_entidade.aprovado', 1),
        EM_ANALISE: this.$tc('status_entidade.em_analise', 1),
        REPROVADO: this.$tc('status_entidade.reprovado', 1),
      }[status];
    },
    carregarTipoUsoDaAcao() {
      const { idAcao } = this;
      this.workflowAcaoMagaluResource
        .carregarTipoUso({ idAcao })
        .then((res) => {
          const tipoUso = { id: res.data.id, nome: res.data.nome };
          const tipoUsoLista = this.listaTiposUso.find((tipo) => tipo.nome === tipoUso.nome);
          if (tipoUsoLista) {
            tipoUsoLista.id = tipoUso.id;
          }
          this.tipoUso = tipoUso.id;
        })
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    buscarTiposUso() {
      return this.workflowAcaoMagaluResource
        .buscarTiposUso()
        .then((res) => res)
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    salvarTipoUso() {
      if (this.tipoUso) {
        const { idAcao } = this,
          { tipoUso } = this;
        this.workflowAcaoMagaluResource.salvarAcaoTipoUso({ idAcao },
          { tipo_uso: tipoUso })
          .then(() => {})
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    verificaSeUsuarioAprovadorNDComplementoInformacoes() {
      const params = {
        idAcao: this.idAcao,
        idPerfil: this.usuarioLogado.idPerfil,
      };
      return this.workflowAcaoMagaluResource
        .verificarSeUsuarioAprovadorNDComplementoInformacoes(params)
        .then((res) => {
          this.usuarioAprovadorNDComplementoInformacoes = res.data;
          if (this.podeAlterarDataContabilizacao) {
            this.setaDataFormatada();
          }
          if (this.usuarioAprovadorNDComplementoInformacoes) {
            Promise.all([this.buscarTiposUso()])
              .then((response) => {
                const [retorno] = response;
                this.listaTiposUso = retorno.data;
                this.carregarTipoUsoDaAcao();
              });
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvarDataContabilizacao() {
      if (this.podeAlterarDataContabilizacao && this.dataContabilizacao) {
        const { idAcao } = this,
          { dataContabilizacao } = this;
        this.workflowAcaoMagaluResource.salvarAcaoDataContabilizacao({ idAcao },
          { data_contabilizacao: dataContabilizacao })
          .then(() => {})
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    setaDataFormatada() {
      this.dataContabilizacaoFormatada = this.retornaDataFormatada(this.dataContabilizacao);
    },
    retornaDataFormatada(data) {
      if (!data) return null;
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    verificaSePossuiPassoFornecedor() {
      const { idAcao } = this;
      this.workflowAcaoMagaluResource
        .verificarSePossuiPassoFornecedor({ idAcao })
        .then((res) => {
          this.acaoPossuiPassoFornecedor = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregaListaDocumentosGerados() {
      const params = {
        idContrato: this.idContrato,
      };
      return this.planejamentoContratoResource.buscarListaDocumentosGerados(params)
        .then((res) => {
          if (res.data && res.data.length) {
            this.listaDocumentosGerados = res.data;
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    realizarDownloadCartaParceria() {
      const getBasePath = basePath('job', 'contrato/carta-parceria');
      const servico = 'gerar-carta-parceria';
      const resource = exportacaoCartaParceria(this.$http);
      const { idContrato } = this;
      resource.download(getBasePath, idContrato, servico)
        .then(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_sucesso'));
        })
        .catch(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_erro'));
        });
    },
    verificaSeUsuarioAprovadorContratoComplementoInformacoes() {
      const params = {
        idContrato: this.idContrato,
        idPerfil: this.usuarioLogado.idPerfil,
      };
      return this.workflowContratoResource
        .verificaSeUsuarioAprovadorContratoComplementoInformacoes(params)
        .then((res) => {
          this.usuarioAprovadorContratoComplementoInformacoes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvarComplementoInformacoes() {
      if (this.usuarioAprovadorContratoComplementoInformacoes) {
        this.salvarPrazoPagamento();
      }
    },
    buscarAprovadorPorPasso(idPassoExecucao) {
      this.fluxoExecucaoResource.aprovadoresPassosClientes({ idPassoExecucao })
        .then((response) => {
          this.usuariosCliente = response.data;
        })
        .catch(this.erroRequisicao);
    },
    salvarPrazoPagamento() {
      if (!this.prazoPagamento) {
        return;
      }

      const { idContrato, prazoPagamento } = this;
      this.workflowContratoResource.salvarPrazoPagamento({ idContrato },
        { prazo_pag_contrato: prazoPagamento })
        .then(() => {})
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirDetalhes(item) {
      const route = this.$router.resolve({ name: 'detalharContrato', params: { idContrato: item.id_contrato, from: 'dashboard-inicial', carregaPassoAcoes: true } });
      window.open(route.href, '_blank');
    },
    buscarInformacoesFluxo(idContrato) {
      this.workflowContratoResource.status({ idContrato })
        .then((response) => {
          this.idPassoAtual = response.body.passoAtual.id;
          this.buscaIndSubstituicaoContrato(this.idPassoAtual);
        });
    },
    buscaIndSubstituicaoContrato(idPassoAtual) {
      this.workflowContratoResource.buscaFlagSubstituicaoContrato({ idPassoAtual })
        .then((response) => {
          this.indSubstituicaoContrato = response.body;
          if (this.indSubstituicaoContrato) {
            this.buscarContratosRenovacao();
          }
        });
    },
    buscarContratosRenovacao() {
      const { idContrato } = this;
      this.workflowContratoResource.buscarContratosRenovacao({ idContrato }).then((res) => {
        this.contratosRenovacao = res.data;
        this.listaCategoriasAprovacao = [];
        this.contratosRenovacao.forEach((contrato) => {
          if (!this.listaCategoriasAprovacao.find((contratoListaCategoria) => contratoListaCategoria.categoria === contrato.categoria)) {
            this.listaCategoriasAprovacao.push(contrato);
          }
        });
      });
    },
    resetaCampos() {
      this.tipoUso = null;
      this.prazoPagamento = null;
      this.listaTiposUso = [];
      this.listaCategoriasAprovacao = [];
      this.listaDocumentosGerados = [];
      this.contratosRenovacao = [];
    },
  },
  mounted() {
    const promises = [];
    if (this.idAcao) {
      promises.push(this.verificaSeUsuarioAprovadorNDComplementoInformacoes());
      promises.push(this.verificaSePossuiPassoFornecedor());
    } else if (this.idContrato && this.isAprovacao) {
      promises.push(this.verificaSeUsuarioAprovadorContratoComplementoInformacoes());
      promises.push(this.buscarInformacoesFluxo(this.idContrato));
      if (this.isCartaParceria) {
        promises.push(this.carregaListaDocumentosGerados());
      }
    }
    Promise.all(promises)
      .then(() => {
        this.paginaCarregada = true;
      })
      .catch((err) => {
        this.$error(this, err);
      });
  },
};
</script>
