const Liquidacao = () => import('../../../spa/liquidacao-acao/Liquidacao');
const LiquidacaoListNivel1 = () => import('../../../spa/liquidacao-acao/LiquidacaoListNivel1');
const LiquidacaoListNivel2 = () => import('../../../spa/liquidacao-acao/LiquidacaoListNivel2');
const RecebimentoDetalhes = () => import('../../../spa/liquidacao-acao/recebimento/RecebimentoDetalhes');

export default {
  path: 'recebimento',
  component: Liquidacao,
  children: [
    {
      path: '',
      name: 'recebimento',
      component: LiquidacaoListNivel1,
    },
    {
      path: 'fornecedor/:idFornecedor',
      name: 'nivel2Recebimento',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'fornecedor/:idFornecedor/acao/:idAcao/pagamento/:id',
      name: 'detalharRecebimento',
      component: RecebimentoDetalhes,
    },
  ],
};
