<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h2 style="font-size: 18px; width: 100%" class="mb-0">{{ regiao.ehEdicao() ? $t('title.editar_regiao') : $t('title.cadastrar_nova_regiao') }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="regiao_code"
              name="regiao_code"
              v-model="regiao.codRegiao"
              :label="`${$t('label.cod_regiao')} *`"
              :counter="50"
              maxlength="50"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="regiao_name"
              name="regiao_name"
              v-model="regiao.nomRegiao"
              :label="`${$t('label.nome')} *`"
              :counter="50"
              maxlength="50"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>
  </v-form>
</template>

<script>
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Regiao from './Regiao';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'RegiaoForm',
  data() {
    return {
      regiao: new Regiao(),
      regiaoResources: this.$api.regiao(this.$resource),
      disableButton: false,
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: true,
        md12: true,
        sm6: false,
        md3: false,
        md4: false,
        md6: false,
        md9: false,
      },
      dialog: {
        content: '',
      },
      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    ...generateComputed('Regiao', [
      'canAccessCRUD',
    ]),
  },
  components: {
    Confirm,
  },
  methods: {
    cancel() {
      this.limparFormulario();
    },
    editar(regiao) {
      this.regiao = new Regiao({ ...regiao });
    },
    limparFormulario() {
      this.regiao = new Regiao();
      this.$refs.form.reset();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.dialog.content = this.$t('message.salvar');
      this.$refs.confirmDialog.open();
    },
    save() {
      const objArmazenamento = {
        ...this.regiao,
      };

      let salvarOuAtualizar;
      let inclusao = true;
      if (this.regiao.id != null) {
        inclusao = false;
        salvarOuAtualizar = this.regiaoResources.atualizar(objArmazenamento);
      } else {
        salvarOuAtualizar = this.regiaoResources.gravar(objArmazenamento);
      }

      salvarOuAtualizar.then(() => this.limparFormulario())
        .then(() => this.$toast(this.$t((inclusao ? 'message.adicionado_confira_tabela' : 'message.atualizado_confira_tabela'))))
        .then(() => {
          this.$emit('FORMULARIO_CADASTRO_REGIAO_SALVAR', { ...this.regiao });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
  },
};
</script>

<style lang="css">
</style>
