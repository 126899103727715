<template>
  <v-dialog v-model="showModal" max-width="800">
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $tc('label.justificativa', 1) }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            id="justificativa"
            v-if="!obrigatorio && !somenteLeitura"
            name="justificativa"
            :label="`${$tc('label.justificativa', 1)}`"
            v-model="justificativa"
            auto-grow
            rows="1"
            :disabled="somenteLeitura"
          ></v-textarea>
          <v-textarea
            id="justificativa_obrigatoria"
            v-if="obrigatorio && !somenteLeitura"
            name="justificativa-obrigatoria"
            :label="`${$tc('label.justificativa', 1)} *`"
            v-model="justificativa"
            auto-grow
            rows="1"
            :rules="[rules.required]"
            :disabled="somenteLeitura"
          ></v-textarea>
          <v-textarea
            v-if="somenteLeitura"
            name="observacao"
            :label="`${$tc('label.justificativa', 1)}`"
            :value="observacao"
            auto-grow
            rows="1"
            :disabled="somenteLeitura"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="somenteLeitura" text @click.native="cancela">{{ $t('label.fechar') }}</v-btn>
          <v-btn v-if="!somenteLeitura" text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
          <v-btn v-if="!somenteLeitura" color="primary darken-1" text @click.native="salvar">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      showModal: false,
      justificativa: '',
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  props: {
    obrigatorio: Boolean,
    salvarJustificativa: Function,
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    observacao: String,
  },
  methods: {
    open() {
      this.justificativa = '';
      this.showModal = true;
    },
    cancela() {
      this.showModal = false;
    },
    salvar() {
      if (!this.$refs.form.validate()) return;
      this.salvarJustificativa(this.justificativa);
      this.showModal = false;
    },
  },
  mounted() {
  },
};
</script>
