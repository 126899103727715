import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'loja');

const lojaActions = {
  ...genericActions(basePath),
  buscar: { method: 'GET', url: `${basePath}/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, lojaActions);
