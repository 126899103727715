import anoFiscal from './modules/ano-fiscal';
import importacao from './modules/importacao';
import controlador from './modules/controlador';
import metadados from './modules/metadados';
import roles from './modules/roles';
import usuario from './modules/usuario';
import toast from './modules/toast';
import parametros from './modules/parametros';
import implantacao from './modules/implantacao';
import filtros from './modules/filtros';
import acaoSaldoContaCorrente from './modules/acao-saldo-conta-corrente';

// A Vuex instance is created by combining the state, the actions,
// and the mutations. Because the actions and mutations are just
// functions that do not depend on the instance itself, they can
// be easily tested or even hot-reloaded (see counter-hot example).

export default {
  modules: {
    anoFiscal,
    importacao,
    controlador,
    metadados,
    roles,
    usuario,
    parametros,
    implantacao,
    filtros,
    acaoSaldoContaCorrente,
    toast,
  },
  strict: true,
};
