<template>
  <v-list>
    <v-list-group
      v-model="active"
      no-action
      class="MetadadosCampoDinamicoListaCheckbox__list-group">
      <template v-slot:activator>
        <v-list-item class="MetadadosCampoDinamicoListaCheckbox__list_item">
          <v-list-item-content class="mx-0">
            <v-list-item-title>{{ `${inputLabel}${quantidadeItens}` }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list-item
        class="MetadadosCampoDinamicoListaCheckbox__list_item"
        v-for="item in itens"
        :key="item[valorItem]"
        @click="setValue(item[valorItem])">
        <v-list-item-action>
          <v-checkbox v-model="objetoInternoCheckbox[item[valorItem]]"></v-checkbox>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{ item[textoItem] }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoListaCheckbox',
  props: {
    value: {
      type: [Array],
    },
    grupoAtivo: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        const selecaoAtual = alterado || [];
        this.objetoInterno = [...selecaoAtual];
        this.montaObjetoInternoCheckbox();
      },
    },
    grupoAtivo(val) {
      this.active = val;
    },
    active(val) {
      if (val) {
        this.$emit('MetadadosCampoDinamicoListaCheckbox_GrupoAtivo', true);
      }
    },
  },
  computed: {
    quantidadeItens() {
      return this.objetoInterno.length ? ` (${this.objetoInterno.length})` : '';
    },
  },
  data() {
    return {
      itens: [],
      objetoInterno: [],
      objetoInternoCheckbox: {},
      active: false,
    };
  },
  methods: {
    montaObjetoInternoCheckbox() {
      this.itens.forEach((item) => {
        this.objetoInternoCheckbox[item[this.valorItem]] = this.estaMarcado(item[this.valorItem]);
      });
    },
    setValue(value) {
      if (this.estaMarcado(value)) {
        this.objetoInternoCheckbox[value] = false;
        this.objetoInterno.splice(this.objetoInterno.indexOf(value), 1);
      } else {
        this.objetoInternoCheckbox[value] = true;
        this.objetoInterno.push(value);
      }
      this.$forceUpdate();
      this.emiteValorAlterado(this.objetoInterno);
    },
    estaMarcado(value) {
      return this.objetoInterno.includes(value);
    },
    carregarItens() {
      const { desAtributos, async } = this.metadados;
      if (!desAtributos && !async) {
        throw new Error('DesAtributos e async não pode ser null!');
      }

      if (desAtributos) {
        const { textoItem, valorItem, lista } = desAtributos;
        if (textoItem) {
          this.textoItem = textoItem;
          this.valorItem = valorItem;
          this.itens = lista;
        } else {
          this.textoItem = 'texto';
          this.valorItem = 'valor';
          this.itens = lista.map((item) => {
            const formatado = {};
            formatado[this.textoItem] = item;
            formatado[this.valorItem] = item;
            return formatado;
          });
        }
        this.montaObjetoInternoCheckbox();
      } else {
        const { fetchFunction, itemValue, itemText } = async;
        this.textoItem = itemText;
        this.valorItem = itemValue;

        fetchFunction()
          .then((response) => {
            const { data } = response;
            this.itens = Array.isArray(data) ? data : data.resposta;
            this.itens = [...this.itens];
            this.montaObjetoInternoCheckbox();
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
  },
  mounted() {
    this.carregarItens();
  },
};
</script>

<style>
  div.MetadadosCampoDinamicoListaCheckbox__list-group >
  div.v-list-group__header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: rgba(0, 0, 0, .87) !important;
  }
  div.MetadadosCampoDinamicoListaCheckbox__list_item {
    padding-left: 0 !important;
  }
  div.MetadadosCampoDinamicoListaCheckbox__list-group >
  div.v-list-group__header >
  div.v-list-group__header__append-icon {
    padding: 0 !important;
  }
</style>
