<template>
  <v-card v-if="podeAcessarPagina">
    <v-card-text>
      <div class="ma-3">
        <h6 class="title">{{ $t('title.gestao_aprovacao') }}</h6>
      </div>
      <v-divider></v-divider>

      <filtros-gestao-aprovacao @filtrar="preencharFiltro"></filtros-gestao-aprovacao>

      <v-divider></v-divider>

      <gestao-aprovacao-tabela
          :filtros="filtros"
          @TransferirPasso="transferirPasso">
      </gestao-aprovacao-tabela>

      <transferir-modal ref="transgerirModal" :passo="passo" :filtros="filtros" @filtrar="preencharFiltro"></transferir-modal>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import FiltrosGestaoAprovacao from './FiltrosGestaoAprovacao';
import GestaoAprovacaoTabela from './GestaoAprovacaoTabela';
import TransferirModal from './TransferirModal';

export default {
  name: 'GestaoAprovacao',
  components: {
    TransferirModal,
    GestaoAprovacaoTabela,
    FiltrosGestaoAprovacao,
  },
  data() {
    return {
      filtros: {},
      passo: {},
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeAcessarPagina() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'FLUXO_GEST_APRO').length;
    },
  },
  methods: {
    preencharFiltro(filtros) {
      this.filtros = { ...filtros };
    },
    transferirPasso(passo) {
      this.passo = passo;
      this.$refs.transgerirModal.open();
    },
  },
  mounted() {
    if (!this.podeAcessarPagina) {
      this.$router.push({ name: 'inicio' });
    }
  },
};
</script>
