import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'sell-out');

const sellInActions = {
  ...genericActions(basePath),
  listarConsolidado: { method: 'GET', url: `${basePath}/consolidado?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}` },
  listarDetalhado: { method: 'GET', url: `${basePath}/obter/detalhado?nota={nota}&competencia={competencia}&cliente={idcliente}&filtro={filter}` },
};

export default (resource) => resource(`${basePath}`, {}, sellInActions);
