<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card class="card-size">
      <v-card-title>
        <h2 style="font-size: 18px; width: 100%" class="mb-0">{{ filial.ehEdicao() ? $t('title.editar_filial') : $t('title.cadastrar_nova_filial') }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          :metadados-entidade="metadadosFilial"
          :objeto="filial"
          :campos-formulario="camposFormulario"
          :ordenacao-campos="ordenacaoFormulario"
          :input-layout="inputLayout"
          :layout-class="layoutClass"
          v-if="metadadosFilial"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="openSaveDialog" color="primary">
          {{ $t('label.salvar') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Filial from './Filial';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';

export default {
  name: 'FilialForm',
  data() {
    return {
      filial: new Filial(),
      filialResources: this.$api.filial(this.$resource),
      regiaoResource: this.$api.regiao(this.$resource),
      inputLayout: {
        xs12: true,
        sm12: true,
        md12: true,
        sm6: false,
        md3: false,
        md4: false,
        md6: false,
        md9: false,
      },
      layoutClass: { wrap: true },
      listaFiliais: [],
      dialog: {
        content: '',
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredRegiao: (value) => (!!value && !!value.id) || this.$t('message.campo_obrigatorio'),
        requiredMultiplaSelecao: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
      },
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'nome',
            tipoCampo: 'TEXTO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_filial',
            tipoCampo: 'TEXTO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'ativo',
            tipoCampo: 'BOOLEAN',
          },
          {
            labelCampo: 'regiao',
            nomCampoId: 'id_regiao',
            tipoCampo: 'LISTA',
            vlrObrigatorio: true,
            async: {
              fetchFunction:
                  (autocomplete) => this.regiaoResource.selecao({ autocomplete, ativo: true }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
        ],
      },
      ordenacaoFormulario: [],
    };
  },
  components: {
    Confirm,
    MetadadosContainerLayout,
  },
  computed: {
    ...mapGetters('metadados', [
      'getFilialMetadado',
    ]),
    metadadosFilial() {
      if (this.getFilialMetadado && this.getFilialMetadado.mapaEntidades) {
        return this.getFilialMetadado;
      }
      return undefined;
    },
  },
  methods: {
    cancel() {
      this.limparFormulario();
      this.$emit('FORMULARIO_CADASTRO_FILIAL_CANCELAR');
    },
    editar(filial) {
      this.filial = new Filial({ ...filial });
      this.filial.montarEstruturaDinamica(filial, this.metadadosFilial);
    },
    limparFormulario() {
      this.filial = new Filial();
      this.$refs.form.reset();
    },
    closeDialog(ref, cb) {
      this.$refs[ref].close();
      this.$refs[ref].active = false;
      if (cb) {
        cb();
      }
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.dialog.content = this.$t('message.salvar_nova_filial');
      this.$refs.confirmDialog.open();
    },
    save() {
      const objArmazenamento = {
        ...this.$refs.container.getValoresCamposPadrao(),
        ...this.$refs.container.getValoresDependencias(),
      };

      if (this.filial.id) {
        objArmazenamento.id = this.filial.id;
      }

      let salvarOuAtualizar;
      if (this.filial.id != null) {
        salvarOuAtualizar = this.filialResources.atualizar({ id: this.filial.id },
          objArmazenamento);
      } else {
        salvarOuAtualizar = this.filialResources.gravar(objArmazenamento);
      }

      salvarOuAtualizar.then(() => this.limparFormulario())
        .then(() => this.$toast(this.$t('message.adicionado_confira_tabela')))
        .then(() => {
          this.$emit('FORMULARIO_CADASTRO_FILIAL_SALVAR', objArmazenamento);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    const { mapaEntidades } = this.metadadosFilial;
    this.ordenacaoFormulario.push(
      'cod_filial',
      'nome',
      'regiao',
      ...Object.keys(mapaEntidades),
      'ativo',
    );
  },
};
</script>
