<template>
  <v-checkbox
    :id="inputId"
    :label="inputLabel"
    v-model="valorInterno"
    :disabled="campoDesabilitado"
    :required="campoObrigatorio"
    :rules="[rules.requiredTime]"
    @input="emiteValorAlterado"
  ></v-checkbox>
</template>

<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoCheckbox',
  props: {
    value: {
      type: [String],
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
      },
    },
  },
  data() {
    return {
      valorInterno: null,
      rules: {
        requiredTime: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.valorInterno;
          return !!(val) || msg;
        },
      },
    };
  },
};
</script>
