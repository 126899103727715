import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'unidade-medida');

const unidadeMedidaActions = {
  ...genericActions(basePath),
};

export default (resource) => resource(`${basePath}`, {}, unidadeMedidaActions);
