<template lang="html">
  <v-tabs
    v-if="mapaCamposDinamicos && mapaCamposDinamicos.length"
    centered
    v-model="tabSelecionada"
    color="accent"
    background-color="rgba(0,0,0,0)"
    class="NivelProduto__tabs">
    <v-tab
      v-for="(item, index)  in mapaCamposDinamicos"
      :href="`#tab-${item.label}`"
      :key="`nivel-produto-tab-${index}`">
      {{ item.entidadeEstrangeira }}
    </v-tab>

    <v-tabs-items v-model="tabSelecionada">
      <v-tab-item
        v-for="(item, index)  in mapaCamposDinamicos"
        :id="`tab-${item.label}`"
        :somenteLeitura="somenteLeitura"
        :key="`nivel-produto-tab-item-${index}`">
        <component
          :is="item.indRecursiva ? 'TabelaGenericaRecursiva' : 'TabelaGenerica'"
          :item="item"
          :idNivelExtensao="item.idNivelExtensao"
          />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import TabelaGenerica from './abas/TabelaGenerica';
import TabelaGenericaRecursiva from './abas/TabelaGenericaRecursiva';

export default {
  name: 'NivelProduto',
  data() {
    return {
      tabSelecionada: null,
      resourceMetadado: this.$api.metadado(this.$resource),
    };
  },
  computed: {
    ...generateComputed('Nivel Prd', [
      'canEdit',
      'canGet',
      'canAccessPage',
    ]),
    somenteLeitura() {
      return !this.canEdit && this.canGet;
    },
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    mapaCamposDinamicos() {
      if (this.getProdutoMetadado && this.getProdutoMetadado.mapaEntidades) {
        const array = Object.keys(this.getProdutoMetadado.mapaEntidades).filter((el) => {
          if (this.getProdutoMetadado.mapaEntidades[el].dependentes
            && this.getProdutoMetadado.mapaEntidades[el].dependentes.includes('PRODUTO')) {
            return true;
          }
          return false;
        });
        return array
          .map((el) => this.getProdutoMetadado.mapaEntidades[el])
          .sort((el) => !el.indRecursiva);
      }
      return [];
    },
    metadadosProduto() {
      if (this.getProdutoMetadado && this.getProdutoMetadado.mapaEntidades) {
        return this.getProdutoMetadado;
      }
      return undefined;
    },
  },
  components: {
    TabelaGenericaRecursiva,
    TabelaGenerica,
  },
  methods: {
    ...mapActions('metadados', [
      'setProdutoMetadado',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    requestPopularHeader() {
      if (this.getProdutoMetadado) {
        return;
      }
      this.setProdutoMetadado({
        resource: this.resourceMetadado.listarProduto,
      });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
    this.requestPopularHeader();
  },
};
</script>
<style lang="css">
.NivelProduto__tabs a.v-tab {
  color: var(--v-accent) !important;
}
.NivelProduto__tabs .v-tabs-items {
  background: none;
}
</style>
