<template>
  <div>
    <v-row class="ma-3">
      <v-spacer></v-spacer>
      <v-btn color="accent" @click="transferirTodos" :disabled="tipoNaoSelecionado">{{ $t('label.transferir_todos') }}</v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="passos"
      :options.sync="pagination"
      :server-items-length="totalPassos"
      :no-data-text="$tc('message.nenhum_registro', 1)"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.tipo }}</td>
          <td>{{ item.solicitante }}</td>
          <td>{{ item.dtaSolicitacao }}</td>
          <td>{{ item.aprovadorAtual }}</td>
          <td class="text-center">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn text icon v-on="on" @click="transferirPasso(item)">
                  <v-icon>swap_horiz</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.transferir') }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'GestaoAprovacaoTabela',
  props: {
    filtros: null,
  },
  data() {
    return {
      resourceFluxoExecucao: this.$api.workflowPassos(this.$resource),
      passos: [],
      totalPassos: 0,
      loading: false,
      pagination: {},
      headers: [
        {
          text: this.$t('label.tipo_de_fluxo'),
          sortable: false,
          value: 'tipo',
        },
        {
          text: this.$t('label.solicitante'),
          value: 'solicitante',
        },
        {
          text: this.$t('label.data_solicitacao'),
          value: 'dta_solicitacao',
        },
        {
          text: this.$t('label.aprovador_atual'),
          value: 'aprovador_atual',
        },
        {
          text: this.$tc('label.acoes', 2),
          align: 'center',
          sortable: false,
          value: '',
        },
      ],
    };
  },
  watch: {
    filtros() {
      this.$forceUpdate();
      if (this.pagination.page === 1) {
        this.buscarPassos();
      } else {
        this.pagination.page = 1;
      }
    },
    pagination: {
      handler() {
        this.buscarPassos();
      },
      deep: true,
    },
  },
  computed: {
    tipoNaoSelecionado() {
      return !this.filtros.tipo || !this.filtros.tipo.id;
    },
  },
  methods: {
    transferirTodos() {
      if (this.tipoNaoSelecionado) return;
      const passo = {
        tipo: this.filtros.tipo.nome,
        solicitante: this.filtros.solicitante ? this.filtros.solicitante.solicitante : null,
        usuarioAprovador: this.filtros.aprovador ? this.filtros.aprovador.aprovador : null,
        idAprovador: this.filtros.aprovador ? this.filtros.aprovador.id : null,
        tarefaAprovacao: this.filtros.passo.descricao,
        passocliente: this.filtros.passo.passocliente,
      };
      this.$emit('TransferirPasso', passo);
    },
    transferirPasso(passo) {
      passo.passocliente = this.filtros.passo.passocliente;
      passo.tarefaAprovacao = this.filtros.passo.descricao;
      this.$emit('TransferirPasso', passo);
    },
    buscarPassos() {
      if (this.tipoNaoSelecionado) return;
      this.loading = true;
      this.resourceFluxoExecucao.buscarPassos(this.preencherFiltro())
        .then((response) => {
          this.loading = false;
          this.passos = response.data.resposta;
          this.totalPassos = response.data.quantidadeRegistrosPagina;
        })
        .catch((err) => {
          this.$error(this, err);
          this.loading = false;
        });
    },
    preencherFiltro() {
      const filtro = {};
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.pagination;
      if (this.filtros.aprovador && this.filtros.aprovador.id) {
        filtro.idAprovador = this.filtros.aprovador.id;
      }
      if (this.filtros.solicitante && this.filtros.solicitante.id) {
        filtro.idSolicitante = this.filtros.solicitante.id;
      }
      filtro.idTipo = this.filtros.tipo.id;
      filtro.passo = this.filtros.passo.descricao;
      filtro.passoCliente = this.filtros.passo.passocliente;
      filtro.numeroPagina = page;
      filtro.tamanhoPagina = itemsPerPage;
      const [sort] = sortBy;
      filtro.colunaOrdenacao = sort;
      filtro.asc = !sortDesc[0];
      return filtro;
    },
  },
};
</script>

<style scoped>
</style>
