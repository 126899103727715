import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'fornecedor');

const fornecedorActions = {
  ...genericActions(basePath),
  pesquisar: { method: 'GET', url: `${basePath}` },
  ativarMassa: { method: 'PUT', url: `${basePath}/ativacao-massa` },
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}` },
  listarGruposFornecedor: { method: 'GET', url: `${basePath}/selecao` },
  selecaoPlanilha: { method: 'GET', url: `${basePath}/selecao/planilha` },
  buscarApuracoesImpactadas: { method: 'GET', url: `${basePath}/{idFornecedor}/apuracoes_impactadas` },
};

export default (resource) => resource(`${basePath}`, {}, fornecedorActions);
