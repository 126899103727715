export default class Passo4 {
  constructor(id, indVigenciaAutomatica = false, indVigenciaTrimestral = false,
    listaVinculo = [], fluxoAprovacao = []) {
    this.id = id;
    this.indVigenciaAutomatica = indVigenciaAutomatica;
    this.indVigenciaTrimestral = indVigenciaTrimestral;
    this.listaVinculo = listaVinculo;
    this.fluxoAprovacao = fluxoAprovacao;
  }
}
