<template>
  <v-text-field
    :id="inputId"
    v-if="existeMascara"
    :label="inputLabel"
    :return-masked-value="true"
    v-mask="mascara"
    v-model="valorInterno"
    prepend-icon="filter_1"
    :disabled="campoDesabilitado"
    :required="campoObrigatorio"
    :rules="[rules.requiredNome, rules.numeroValido, rules.intervaloValido]"
    @input="numeroAlterado"
    type="Number"
  ></v-text-field>
  <v-text-field
    :id="inputId"
    v-else
    :label="inputLabel"
    v-model="valorInterno"
    prepend-icon="filter_1"
    :disabled="campoDesabilitado"
    :required="campoObrigatorio"
    :rules="[rules.requiredNome, rules.numeroValido, rules.intervaloValido]"
    @input="numeroAlterado"
    type="Number"
  ></v-text-field>
</template>
<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoNumerico',
  props: {
    value: {
      type: [Number],
    },
    vlrPadrao: {
      type: [Number],
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
      },
    },
    vlrPadrao: {
      immediate: true,
      handler(vlrPadrao) {
        if (!this.valorInterno) {
          this.valorInterno = vlrPadrao;
        }
      },
    },
  },
  data() {
    return {
      valorInterno: null,
      rules: {
        requiredNome: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.valorInterno;
          return !!(val) || msg;
        },
        numeroValido: () => {
          if (!this.valorInterno) return true;

          let msg = this.$t('message.numero_invalido');
          const val = this.valorInterno;

          if (val <= 0) {
            msg = this.$t('errors.valor.maior_zero_inteiro');
          }
          return (!!(val) && !Number.isNaN(val.toString()) && Number(val) >= 0) || msg;
        },
        intervaloValido: () => {
          if (!this.valorInterno) return true;

          const { desAtributos } = this.metadados;
          if (!desAtributos || !desAtributos.max
            || desAtributos.min === null || desAtributos.min === undefined) {
            return true;
          }
          const { max, min } = desAtributos;

          const msg = this.$t('message.intervalo_numerico', desAtributos);
          const val = this.valorInterno;
          return (!!(val) && val <= max && val >= min) || msg;
        },
      },
    };
  },
  methods: {
    numeroAlterado(valor) {
      if (valor) {
        this.emiteValorAlterado(Number(valor));
      } else {
        this.emiteValorAlterado(null);
      }
    },
  },
};
</script>
