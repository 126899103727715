import acaoConfiguracaoApuracaoTemplate from './acao-configuracao/apuracao-template';
import acaoConfiguracaoCampos from './acao-configuracao/acao-configuracao-campos';
import acessoUsuario from './acessoUsuario';
import agendamento from './agendamento';
import alcada from './alcada';
import alertaManutencao from './alerta-manutencao';
import anoFiscal from './ano-fiscal/ano-fiscal';
import anoFiscalRelatorio from './ano-fiscal/ano-fiscal-relatorio';
import apuracaoAcaoEdicao from './apuracao/apuracao-acao-edicao';
import apuracaoAcaoListagem from './apuracao/apuracao-acao-listagem';
import apuracaoAcaoPagamento from './apuracao/apuracao-acao-pagamento';
import apuracaoAcaoVerbaVariavelListagem from './apuracao/apuracao-acao-verba-variavel-listagem';
import apuracaoConfiguracao from './apuracao/apuracao-configuracao';
import apuracaoContrato from './apuracao/apuracao-contrato';
import apuracaoContratoParcial from './apuracao/apurcao-contrato-parcial';
import arquivoConfiguracao from './arquivo-configuracao';
import bloqueioSistema from './bloqueio-sistema';
import cacheManager from './cache-manager';
import calendario from './calendario';
import camposCustomizados from './campos-customizados';
import carteiraCliente from './carteira-cliente/carteira-cliente';
import clienteCarteiraImplicita from './carteira-cliente/cliente-carteira-implicita';
import centroCusto from './centro-custo';
import cep from './cep';
import configuracaoSistema from './configuracao-sistema';
import cidade from './cidade';
import cliente from './cliente';
import concorrente from './concorrente';
import configuracao from './configuracao/configuracao';
import configuracaoPlanejamento from './configuracao/configuracao-planejamento';
import configuracaoWorkflowAprovacao from './configuracao/configuracao-workflow-aprovacao';
import configuracaoWorkflowContrato from './configuracao/configuracao-workflow-contrato';
import origemVerba from './conta-corrente/origem-verba';
import controladorTrade from './controlador-trade/controlador-trade';
import transferenciaPosse from './controlador-trade/transferencia-posse';
import transferenciaPosseDefinitiva from './controlador-trade/transferencia-posse-definitiva';
import dashboardClienteConfiguracao from './dashboard-cliente-configuracao';
import dashboardFornecedorConfiguracao from './dashboard-fornecedor-configuracao';
import dashboardInicialConfiguracao from './dashboard-inicial-configuracao';
import dashboardPagamento from './dashboard-pagamento';
import dicionario from './dicionario';
import divisao from './divisao';
import endereco from './endereco';
import estado from './estado';
import estruturaUsuario from './estrutura-usuario';
import exportacoes from './exportacoes';
import extensao from './extensao';
import filial from './filial';
import filtroCliente from './filtro-cliente';
import fornecedor from './fornecedor';
import fornecedorIntegracao from './fornecedor-integracao';
import funcionario from './funcionario';
import importacoes from './importacoes';
import liquidacaoConfiguracao from './liquidacao/liquidacao-configuracao';
import pagamento from './liquidacao/pagamento';
import pagamentoCadastro from './liquidacao/pagamento-cadastro';
import pagamentoContrato from './liquidacao/pagamento-contrato';
import pagamentoContratoCadastro from './liquidacao/pagamento-contrato-cadastro';
import recebimento from './liquidacao/recebimento';
import loja from './loja';
import margemcontribuicao from './margem-contribuicao';
import metadado from './metadado';
import nivelCliente from './nivel-cliente';
import nivelProduto from './nivel-produto';
import nivelCarteiraCliente from './nivel-carteira-cliente';
import notas from './notas';
import notasDebitoConsulta from './notas-debito-consulta';
import notasDebitoConsultaConfiguracao from './notas-debito-consulta-configuracao';
import notasDebitoContabilizacao from './notas-debito-contabilizacao';
import notasDebitoContabilizacaoConfiguracao from './notas-debito-contabilizacao-configuracao';
import orcamentoTipoInvestimento from './orcamento-tipo-investimento';
import orcamentoTipoVerba from './orcamento-tipo-verba';
import orcamentoVerbaInvestimento from './orcamento-verba-investimento';
import parametrizacaoAcao from './parametrizacao-acao';
import parametrizacaoCliente from './parametrizacao-cliente';
import parametrizacaoContrato from './parametrizacao-contrato';
import parametrizacaoOrcamento from './parametrizacao-orcamento';
import perfil from './perfil';
import planejamentoAcaoCadastro from './planejamento/planejamento-acao-cadastro';
import planejamentoAcaoAprovadaEdicao from './planejamento/planejamento-acao-aprovada-edicao';
import planejamentoAcaoConfiguracao from './planejamento/planejamento-acao-configuracao';
import planejamentoAcaoListagem from './planejamento/planejamento-acao-listagem';
import planejamentoAcaoVariavelCadastro from './planejamento/planejamento-acao-variavel-cadastro';
import planejamentoContratoCadastro from './planejamento/planejamento-contrato-cadastro';
import planejamentoContratoConfiguracao from './planejamento/planejamento-contrato-configuracao';
import planejamentoContratoListagem from './planejamento/planejamento-contrato-listagem';
import produto from './produto';
import redefinicaoEmailUsuario from './redefinicao-email-usuario';
import regiao from './regiao';
import relatorios from './relatorios';
import representante from './representante';
import representanteCliente from './representante-cliente';
import representanteLogado from './representante-logado';
import sellIn from './sell-in';
import sellOut from './sell-out';
import ssoGrupo from './sso/grupo';
import ssoRole from './sso/role';
import ssoUsuario from './sso/usuario';
import termoAceite from './termo-aceite';
import tipoAcao from './tipo-acao';
import tipoBeneficio from './tipo-beneficio';
import tipoContrato from './tipo-contrato';
import tipoInvestimento from './tipo-investimento';
import unidadeMedida from './unidade-medida';
import usuario from './usuario';
import usuarioCliente from './usuario-cliente';
import usuarioFornecedor from './usuario-fornecedor';
import usuarioLogado from './usuario-logado';
import usuarioUnidadeNegocio from './usuario-unidade-negocio';
import usuarioPreCadastro from './usuario-pre-cadastro';
import workflowAcao from './workflow-acao';
import workflowApuracaoAcao from './workflow-apuracao-acao';
import fluxoAprovacao from './workflow-cadastro';
import workflowAjusteVerba from './workflow-ajuste-verba';
import workflowContrato from './workflow-contrato';
import workflowOrcamento from './workflow-orcamento';
import workspaceFiltros from './workspace-filtros';

export default {
  acaoConfiguracaoApuracaoTemplate,
  acaoConfiguracaoCampos,
  acessoUsuario,
  agendamento,
  alcada,
  alertaManutencao,
  anoFiscal,
  anoFiscalRelatorio,
  apuracaoAcaoEdicao,
  apuracaoAcaoListagem,
  apuracaoAcaoPagamento,
  apuracaoAcaoVerbaVariavelListagem,
  apuracaoConfiguracao,
  apuracaoContrato,
  apuracaoContratoParcial,
  arquivoConfiguracao,
  bloqueioSistema,
  cacheManager,
  camposCustomizados,
  carteiraCliente,
  calendario,
  cidade,
  cep,
  centroCusto,
  configuracaoSistema,
  cliente,
  clienteCarteiraImplicita,
  concorrente,
  configuracao,
  configuracaoPlanejamento,
  configuracaoWorkflowAprovacao,
  configuracaoWorkflowContrato,
  controladorTrade,
  dashboardClienteConfiguracao,
  dashboardFornecedorConfiguracao,
  dashboardInicialConfiguracao,
  dashboardPagamento,
  dicionario,
  divisao,
  endereco,
  estado,
  estruturaUsuario,
  exportacoes,
  extensao,
  filial,
  filtroCliente,
  fluxoAprovacao,
  fornecedor,
  fornecedorIntegracao,
  funcionario,
  importacoes,
  loja,
  margemcontribuicao,
  metadado,
  nivelCliente,
  nivelProduto,
  nivelCarteiraCliente,
  notas,
  notasDebitoContabilizacao,
  notasDebitoContabilizacaoConfiguracao,
  notasDebitoConsulta,
  notasDebitoConsultaConfiguracao,
  orcamentoTipoInvestimento,
  orcamentoTipoVerba,
  orcamentoVerbaInvestimento,
  origemVerba,
  pagamento,
  pagamentoCadastro,
  pagamentoContrato,
  pagamentoContratoCadastro,
  parametrizacaoAcao,
  parametrizacaoCliente,
  parametrizacaoContrato,
  parametrizacaoOrcamento,
  perfil,
  planejamentoAcaoConfiguracao,
  planejamentoAcaoAprovadaEdicao,
  planejamentoAcaoCadastro,
  planejamentoAcaoListagem,
  planejamentoAcaoVariavelCadastro,
  planejamentoContratoCadastro,
  planejamentoContratoConfiguracao,
  planejamentoContratoListagem,
  produto,
  recebimento,
  liquidacaoConfiguracao,
  redefinicaoEmailUsuario,
  regiao,
  representante,
  representanteCliente,
  representanteLogado,
  sellIn,
  sellOut,
  ssoUsuario,
  ssoGrupo,
  ssoRole,
  termoAceite,
  tipoAcao,
  tipoBeneficio,
  tipoContrato,
  tipoInvestimento,
  transferenciaPosse,
  transferenciaPosseDefinitiva,
  unidadeMedida,
  usuario,
  usuarioCliente,
  usuarioFornecedor,
  usuarioLogado,
  usuarioPreCadastro,
  usuarioUnidadeNegocio,
  workflowAjusteVerba,
  workflowAcao,
  workflowContrato,
  workflowApuracaoAcao,
  workflowOrcamento,
  workspaceFiltros,
  relatorios,
};
