import { render, staticRenderFns } from "./ParametrizacaoOrcamentoPasso3.vue?vue&type=template&id=27de9ae9&lang=html"
import script from "./ParametrizacaoOrcamentoPasso3.vue?vue&type=script&lang=js"
export * from "./ParametrizacaoOrcamentoPasso3.vue?vue&type=script&lang=js"
import style0 from "./ParametrizacaoOrcamentoPasso3.vue?vue&type=style&index=0&id=27de9ae9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports