<template lang="html">
  <v-tooltip bottom v-if="mensagem">
    <template v-slot:activator="{ on }">
      <v-icon
        v-on="on"
        :color="color"
        dark>info</v-icon>
    </template>
    <span v-html="mensagem"></span>
  </v-tooltip>
</template>

<script type="text/javascript">
export default {
  name: 'TooltipAjuda',
  props: {
    mensagem: String,
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>
