<template>
  <v-container class="mt-6">
    <v-row alignment="center" justify="center">
      <v-btn @click="checkAndDrop" color="red">
        Drop Redis Cache
      </v-btn>
    </v-row>

    <confirm ref="dialog"
      :persistent="true"
      :message="$t('message.confirmacao_drop_cache')"
      @agree="dropCache()"
      />
  </v-container>
</template>
<script>

import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  components: {
    Confirm,
  },
  data() {
    return {
      resource: this.$api.cacheManager(this.$resource),
    };
  },
  methods: {
    checkAndDrop() {
      this.$refs.dialog.open();
    },
    dropCache() {
      this.resource.dropCache();
    },
  },
};
</script>
