import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'estrutura-usuario');

// Actions são ações que o resource terá.
// No exemplo abaixo, criamos uma ação 'list', que é um GET
// na url que aponta para a raíz da API
const estruturaUsuarioActions = {
  ...genericActions(basePath),
  ativarInativarRegistros: { method: 'POST', url: `${basePath}/ativarInativarRegistros` },
  atualizaUsuarioRel: { method: 'PUT', url: `${basePath}/atualizarUsuarioRel` },
  inserirUsuarioRel: { method: 'POST', url: `${basePath}/inserirUsuarioRel` },
  listarAutocomplete: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true&{&idsEstruturaUsuarioNotIn*}` },
  listarEstruturasPai: { method: 'GET', url: `${basePath}?nome={nome}&idEdicao={idEdicao}` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  buscarEstruturasPorVisao: { method: 'GET', url: `${basePath}/visao` },
};

// Exportamos então o recurso, que não passa de uma função que recebe um
// Vue.$resource como parâmetro e retorna um resource customizado.
// Vue.$resource tem a seguinte assinatura:
// function Resource(url, params, actions, options)

export default (resource) => resource(`${basePath}`, {}, estruturaUsuarioActions);
