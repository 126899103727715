export default ($http) => { // eslint-disable-line
  const getResponseProperty = (obj, ...params) => {
    const attribute = params.filter((el) => obj.hasOwnProperty(el))[0];
    const value = obj[attribute];
    return value[0];
  };

  const getFileName = (response) => {
    const header = getResponseProperty(response.headers.map, 'content-disposition', 'Content-Disposition');
    const temp = header.split('filename=');
    const fileName = temp[1];
    return fileName;
  };

  const onError = (response) => {
    console.error('TODO: falha ao fazer download', response); // eslint-disable-line
    throw Error('Erro ao fazer download');
  };

  const onFileDownloaded = (response) => {
    if (!response || !response.data) {
      return onError(response);
    }
    const fileName = getFileName(response);
    const type = getResponseProperty(response.headers.map, 'content-type', 'Content-Type');
    const file = new window.Blob([response.data], { type }); // eslint-disable-line
    const a = document.createElement('a'); // eslint-disable-line
    a.download = fileName;
    a.href = window.URL.createObjectURL(file); // eslint-disable-line
    document.body.appendChild(a); // eslint-disable-line
    a.click();
    return a;
  };

  const onFileOpen = (response) => {
    if (!response || !response.data) {
      return onError(response);
    }
    const type = getResponseProperty(response.headers.map, 'content-type', 'Content-Type');
    const file = new window.Blob([response.data], { type }); // eslint-disable-line
    const a = document.createElement('a'); // eslint-disable-line
    a.href = window.URL.createObjectURL(file); // eslint-disable-line
    a.target = '_blank';
    document.body.appendChild(a); // eslint-disable-line
    a.click();
    return a;
  };

  return {
    download(basePath, parametros, servico) {
      const downloadUrl = `${basePath}/${servico}`;
      return $http.post(downloadUrl, parametros, { responseType: 'arraybuffer' })
        .then(onFileDownloaded)
        .catch(onError);
    },
    downloadGet(basePath, parametros) {
      const { param } = parametros;
      const { url } = parametros;
      const downloadUrl = `${basePath}/${url}${param}`;
      return $http.get(downloadUrl, { responseType: 'arraybuffer' })
        .then(onFileDownloaded)
        .catch(onError);
    },
    downloadGetAba(basePath, parametros) {
      const { param } = parametros;
      const { url } = parametros;
      const downloadUrl = `${basePath}/${url}${param}`;
      return $http.get(downloadUrl, { responseType: 'arraybuffer' })
        .then(onFileOpen)
        .catch(onError);
    },
  };
};
