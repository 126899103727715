<template>
  <v-form>
      <v-row>
        <v-col cols="12">
          <v-text-field
          :id="inputId"
          :label="inputLabel"
          v-model="valorInterno"
          :disabled="campoDesabilitado"
          :required="campoObrigatorio"
          :rules="[rules.requiredTime]"
          :counter="maxCaracteres"
          :maxlength="maxCaracteres"
          @input="emiteValorAlterado"
          >
            <template v-slot:append-outer>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">info</v-icon>
                </template>
                {{mensagemTooltip}}
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>

      </v-row>
  </v-form>
</template>
<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoTextoTooltip',
  props: {
    value: {
      type: [String],
    },
    mensagemTooltip: {
      type: String,
      default: '',
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
      },
    },
  },
  computed: {
    maxCaracteres() {
      const { desAtributos } = this.metadados;
      if (!desAtributos) return null;
      return desAtributos.max;
    },
  },
  data() {
    return {
      valorInterno: null,
      rules: {
        requiredTime: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.valorInterno ? this.valorInterno.trim() : null;
          return !!(val) || msg;
        },
      },
    };
  },
};
</script>
