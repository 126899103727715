<template>
  <v-card class="elevation-2" :disabled="emAnalise(item)" :key="`passos-${chave}-${item.id}`">
    <v-main v-if="!isPassoParalelo" class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col class="title pb-0 mb-0">
          <span v-if="itemPossuiDescricao(item)" v-html="`${ $tc('label.passo', 1) } ${ item.sequencia } - ${ item.descricao }`" />
          <span v-else v-html="`${ $tc('label.passo', 1) } ${ item.sequencia }`" />
        </v-col>
      </v-row>
    </v-main>
    <v-card-title class="title historico_header px-3 py-2">
      <div class="title" v-if="item.usuarioAprovador">
        <span v-if="usuarioFornecedor && !item.usuarioAprovador.grupo.includes('Fornecedor')">
          {{ $t('label.status_por', { status: formatarStatus(item.statusPasso) }) + ' ' + item.usuarioAprovador.grupo}}
        </span>
        <span v-else>
          {{ $t('label.status_por', { status: formatarStatus(item.statusPasso) }) + ' ' + item.usuarioAprovador.nome + ' ' +
        item.usuarioAprovador.sobrenome}}
        </span>
      </div>
      <div class="title" v-if="aguardandoAprovacao(item) && !isPassoParalelo">
        {{ $t('label.aprovadores') }}
      </div>
      <div class="title" v-if="transferido(item)">
        {{ $t('label.aprovador_anterior') }}
      </div>
      <v-spacer></v-spacer>
      <slot name="historico"></slot>
      <v-btn @click.native="reenviarEmailAprovacao(item)" v-if="item.isProximoPasso && !ehRepresentanteLegal" small color="primary">{{ $t('label.reenviar_email_aprovacao') }}</v-btn>
    </v-card-title>

    <v-card-text class="historico_body">
      <v-row v-if="aguardandoAprovacao(item) || transferido(item)">
        <v-list two-line>

          <v-list-item v-if="item.usuariosAprovadores.usuarios"
                       v-for="(usuario, uIndex) in item.usuariosAprovadores.usuarios"
                       :key="`${chave}-${usuario.id}-${uIndex}`">
            <v-list-item-avatar>
              <v-icon v-if="uIndex === 0 && item.usuariosAprovadores.usuarios.length == 1" color="primary">
                person
              </v-icon>
              <v-icon v-if="uIndex === 0 && item.usuariosAprovadores.usuarios.length > 1" color="primary">
                group
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="usuarioFornecedor && !isPassoFornecedor(item)">{{ usuario.grupo }}</v-list-item-title>
              <v-list-item-title v-else>{{ usuario.nome }}</v-list-item-title>
              <v-list-item-subtitle>{{ $tc('label.usuario', 1) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="item.usuariosAprovadores.grupos"
                       v-for="(grupo, pIndex) in item.usuariosAprovadores.grupos"
                       :key="`${chave}-${grupo.id}-${pIndex}`">
            <v-list-item-avatar>
              <v-icon v-if="pIndex === 0" color="primary">group_work</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="isPassoFornecedor(item) && ehContrato">
                {{ grupo.nome + ' - ' + $tc('label.tipos_fornecedor.' + item.tipoFornecedor.toLowerCase(), 1) }}
              </v-list-item-title>
              <v-list-item-title v-else>{{ grupo.nome }}</v-list-item-title>
              <v-list-item-subtitle>{{ $tc('label.perfil', 1) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="item.usuariosAprovadores.subgrupos"
                       v-for="(subgrupo, eIndex) in item.usuariosAprovadores.subgrupos"
                       :key="`${chave}-${subgrupo.id}-${eIndex}`">
            <v-list-item-avatar>
              <v-icon v-if="eIndex === 0" color="primary">recent_actors</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{subgrupo.nome}}</v-list-item-title>
              <v-list-item-subtitle>{{ $tc('label.estrutura_usuario', 0) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-row>

      <v-row v-if="item.sla" class="px-3 py-2">
        <span><b class="pr-1">{{ $t('label.sla') }}:</b></span>
        <span>{{ item.sla}}</span>
      </v-row>
      <v-row v-if="transferido(item)" class="px-3 py-2">
        <span><b class="pr-1">{{ $t('label.transferido_por') }}:</b></span>
        <span>{{ item.usuarioTransferenciaPasso.usuarioTransferencia }}</span>
      </v-row>
      <v-row v-if="transferido(item)" class="px-3 py-2">
        <span><b class="pr-1">{{ $t('label.data_transferencia') }}:</b></span>
        <span>{{ item.usuarioTransferenciaPasso.dtaTransferencia}}</span>
      </v-row>
      <v-row v-if="!aguardandoAprovacao(item) && !transferido(item)" class="px-3 py-2">
        <span><b class="pr-1">{{ $t('label.data') }}:</b></span>
        <span v-if="item.dtaAvaliacao">{{ toDate(item.dtaAvaliacao) }}</span>
        <span v-if="!item.dtaAvaliacao">{{ $t('label.data_indefinida') }}</span>
      </v-row>
      <v-row class="px-3 py-2">
        <span><b class="pr-1">{{ $tc('label.perfil', 1) }}: </b></span>
        <span>{{ item.perfil? item.perfil : nomePerfilUsuarioAprovador(item) }}</span>
      </v-row>
      <v-row v-if="item.observacao" class="px-3 py-2">
        <span><b class="pr-1">{{ $tc('label.observacao', 1) }}:</b></span>
        <span>{{ item.observacao }}</span>
      </v-row>
      <v-row v-if="item.dados" v-for="(dados, xIndex) in item.dados" :key="`${chave}-${xIndex}`" class="px-3 py-2">
        <span> {{ dados }} </span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import PopoverLista from '../PopoverLista';

export default {
  components: {
    PopoverLista,
  },
  name: 'AcompanhamentoItem',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    entidade: String,
    idEntidade: Number,
    chave: String,
    isPassoParalelo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resourceFluxo: this.$api.fluxoAprovacao(this.$resource),
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ehContrato() {
      return this.entidade === 'CONTRATO';
    },
    ehRepresentanteLegal() {
      return this.entidade === 'REPRESENTANTE_LEGAL';
    },
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
  },
  methods: {
    aguardandoAprovacao(passo) {
      return passo.statusPasso === 'AGUARDANDO_APROVACAO';
    },
    emAnalise(passo) {
      return passo.statusPasso === 'EM_ANALISE';
    },
    transferido(passo) {
      return passo.statusPasso === 'TRANSFERIDO';
    },
    toDate(data) {
      const formatos = ['DD/MM/YYYY HH:mm:ss', moment.ISO_8601];
      return moment(data, formatos, true).format('DD-MM-YYYY HH:mm:ss');
    },
    isPassoFornecedor(item) {
      return item.tipoFornecedor !== undefined;
    },
    nomePerfilUsuarioAprovador(item) {
      if (item.usuarioAprovador !== undefined) {
        return item.usuarioAprovador.grupo;
      }
      if (item.usuariosAprovadores !== undefined && item.usuariosAprovadores.grupos.length > 0) {
        return item.usuariosAprovadores.grupos[0].nome;
      }
      if (item.tipoFornecedor) {
        return 'Fornecedor';
      }
      return '';
    },
    reenviarEmailAprovacao(item) {
      const parametros = {
        idPasso: item.id,
        entidade: this.entidade,
      };
      this.resourceFluxo.reenviarEmailAprovacao(parametros, {})
        .then(() => {
          this.$toast(this.$t('message.email_reenviado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formatarStatus(status) {
      return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    },
    itemPossuiDescricao(item) {
      return item.descricao !== undefined && item.descricao !== null;
    },
  },
  mounted() {
  },
};
</script>
