export default class Passo4 {
  constructor(id, nomeTipoVerba, indManual = true, template = '', periodos = [], indApurarInicio = true, meta = 'N/A',
    calculosApuracao = [], bases = [], bonificacoes = [], calculosBonificacao = [], pagamentos = [],
    basesVendas = [], indEvidencia = false, indCalculoAutomaticoVendas = false,
    periodoHistorico = null, indEvidenciaSellin = false, indEvidenciaSellout = false,
    indCrescimentoManual = true, unidadesMedida = [], descontos = [],
    indHabilitarEvidencia = false) {
    this.id = id;
    this.nomeTipoVerba = nomeTipoVerba;
    this.indManual = indManual;
    this.template = template;
    this.indApurarInicio = indApurarInicio;
    this.indEvidencia = indEvidencia;
    this.indCalculoAutomaticoVendas = indCalculoAutomaticoVendas;
    this.periodoHistorico = periodoHistorico;
    this.indEvidenciaSellin = indEvidenciaSellin;
    this.indEvidenciaSellout = indEvidenciaSellout;
    this.indHabilitarEvidencia = indHabilitarEvidencia;
    this.periodos = periodos;
    this.meta = meta;
    this.calculosApuracao = calculosApuracao;
    this.bases = bases;
    this.bonificacoes = bonificacoes;
    this.calculosBonificacao = calculosBonificacao;
    this.pagamentos = pagamentos;
    this.basesVendas = basesVendas;
    this.indCrescimentoManual = indCrescimentoManual;
    this.unidadesMedida = unidadesMedida;
    this.descontos = descontos;
  }
}
