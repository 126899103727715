const loadScript = (path) => new Promise((resolve, reject) => $.getScript(path, resolve, reject));

export default () => {
  if (window.iviewLoaded) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    loadScript('amcharts/amcharts.js')
      .then(() => loadScript('amcharts/plugins/export/export.min.js'))
      .then(() => loadScript('amcharts/serial.js'))
      .then(() => loadScript('amcharts/pie.js'))
      .then(() => loadScript('amcharts/xy.js'))
      .then(() => {
        window.iviewLoaded = true;
        resolve();
      })
      .catch(reject);
  });
};
