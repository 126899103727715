export default class Passo1 {
  constructor(data = {}) {
    this.id = data.id;
    this.nome = data.nome;
    this.descricao = data.descricao;
    this.comportamento = data.comportamento;
    this.tags = data.tags;
    this.status = data.status ? data.status : 'CONCLUIDO';
    this.icone = data.icone;
    this.idsTipoInvestimento = data.ids_tipo_investimento ? data.ids_tipo_investimento.split(',') : [];
    this.tiposInvestimentos = data.tipos_investimento || [];
  }

  alterarDados(filtros) {
    const keys = Object.keys(filtros);
    for (let i = 0; i < keys.length; i += 1) {
      this[`_${keys[i]}`] = filtros[keys[i]];
    }
  }

  ehNovo() {
    return !this.id;
  }

  setId(id) {
    this.id = id;
  }

  setTiposInvestimento(ids) {
    this.idsTipoInvestimento = ids;
  }

  getTiposInvestimento() {
    return this.tiposInvestimentos;
  }
}
