function getError(context, err) {
  if (err.status === 403) {
    return context.$t('message.acesso_negado');
  }
  return err.data && err.data.error ? err.data.error : err;
}

const errorHandler = (context, err, callback, toastType = 'bottom', toastTime = 5E3) => {
  const error = getError(context, err);
  context.$toast(error, toastType, toastTime);
  if (callback) {
    callback();
  }
};

const acessoNegado = (context, error, callback = () => {}, route) => {
  if (error && error.status !== 403) {
    errorHandler(context, error, callback);
  } else {
    context.$toast(context.$t('message.acesso_negado'));
    context.$router.push({ name: route || 'dashboard' });
    callback();
  }
};

function plugin(Vue) {
  Vue.prototype.$error = errorHandler;
  Vue.prototype.$acessoNegado = acessoNegado;
}

if (typeof window !== 'undefined' && window.Vue) { // eslint-disable-line no-undef
  window.Vue.use(plugin); // eslint-disable-line no-undef
}

// module.exports = plugin;
export default plugin;
