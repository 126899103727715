<template>
  <v-card style="margin-bottom:1em" class="RelatorioLogUsuarioFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.log_usuario', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :explode-hierarquia="true"
          :formulario-filtros="true"
          :ignora-inativos="false"
          :ignora-obrigatorios="umFiltroObrigatorioSelecionado"
          :objeto="filtrosLogUsuario"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '@/produto/common/functions/loading';
import { countRelatorioLogUsuario } from '@/common/resources/magalu-relatorios'; // eslint-disable-line
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoLogUsuarioFiltrosCampos from './RelatorioExportacaoLogUsuarioFiltrosCampos';

export default {
  name: 'RelatorioLogUsuario',
  mixins: [
    RelatorioExportacaoLogUsuarioFiltrosCampos,
  ],
  watch: {
    filtrosLogUsuario: {
      handler() {
        this.umFiltroObrigatorioSelecionado = false;

        Object.values(this.filtrosLogUsuario).every((value) => {
          if (value instanceof Array) {
            if (value && value.length) {
              this.umFiltroObrigatorioSelecionado = true;
              return false;
            }
          } else if (value) {
            this.umFiltroObrigatorioSelecionado = true;
            return false;
          }
          return true;
        });
        this.$refs.form.resetValidation();
      },
      deep: true,
    },
  },
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      ordenacaoCampos: [
        'nome_usuario',
        'data_inicio',
        'data_fim',
        'tipo_usuario',
        'grupo_fornecedor',
        'tipo_alteracao',
        'fornecedor_representante_legal_atual',
        'ultima_alteracao',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosLogUsuario: {},
      umFiltroObrigatorioSelecionado: false,
      limiteRegistrosExportacao: 50000,
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroValido() {
      // valida se algum filtro foi selecionado
      return this.umFiltroObrigatorioSelecionado;
    },
    resetaFiltros() {
      this.filtrosLogUsuario = {};
    },
    aplicarFiltros() {
      const filtrosAplicados = this.$refs.container.getValoresCamposPadrao();

      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (!this.filtroValido()) {
        this.$toast(this.$t('message.selecione_um_filtro_obrigatorio'));
        return;
      }
      const params = {
        ...filtrosAplicados,
      };
      if (!params.ultima_alteracao) {
        delete params.ultima_alteracao;
      }
      let quantidadeRegistros = 0;

      countRelatorioLogUsuario(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > this.limiteRegistrosExportacao) {
            this.$toast(this.$t('errors.registros.excede_limite_dados', { limite: this.limiteRegistrosExportacao }));
          } else if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoLogUsuario__AplicaFiltros',
              params,
              'log_usuario_magalu',
              'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
      }
      return true;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-log-usuario';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-log-usuario';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosLogUsuario;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
};
</script>
<style>
  .RelatorioLogUsuarioFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioLogUsuarioFiltros__Opcoes div.v-input__slot,
  .RelatorioLogUsuarioFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioLogUsuarioFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioLogUsuarioFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
