export default {
  methods: {
    montaDependenciaCruzadaCampo(campo) {
      const { metadadosCampo } = campo;
      const { metadadosEntidade } = this;

      // pega o nome de todas dependencias de uma entidade; ex: entidade Produto
      // dependencias -> marca, categoria, unidadenegocio
      const dependenciasRef = Object.values(metadadosCampo.mapaEntidades)
        .map((dependencia) => dependencia.label);

      const { mapaEntidades } = metadadosEntidade;
      const dependenciasEntidade = Object.values(mapaEntidades);

      dependenciasEntidade
        .filter((dependencia) => dependenciasRef.indexOf(dependencia.label) >= 0)
        .forEach((dependencia) => {
          dependencia.dependentes.push(metadadosCampo.entidade.toLowerCase());
        });
    },
    /**
     * Relaciona dependencias entre diferentes metadados.
     * Ex:
     *
     * Orcamento [Marca, Unidade, Canal, Grupo]
     * Centro Custo [Marca, Unidade]
     *
     * Deste modo, a unidade do orcamento devera filtrar
     * a selecao do centro de custo
     */
    montaDependenciasCruzadas() {
      const relacaoCampos = this.camposFormulario.padrao;

      relacaoCampos
        .filter((campo) => campo.metadadosCampo != null)
        .forEach((campo) => this.montaDependenciaCruzadaCampo(campo));
    },
  },
};
