<template>
  <div>
    <v-tabs centered
      background-color="rgba(0,0,0,0)"
      color="accent"
      v-model="tabSelecionada">
      <v-tab class="aba-text" href="#tab-temporaria">{{$t('label.transferencia_temporaria')}}</v-tab>
      <v-tab class="aba-text" href="#tab-historico">{{$t('label.historico')}}</v-tab>

      <v-tabs-items v-model="tabSelecionada">
        <v-tab-item id="tab-temporaria" class="mt-3">
          <v-container ml-0>
            <v-row justify-end>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="searchQueryVigente"
                  append-icon="search"
                  :label="$t('label.pesquisar')"
                  single-line
                  clearable
                  @click:clear="limparEBuscarTransferenciasVigentes()"
                  hide-details>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            :headers="headersPossesTemporarias"
            :items="possesTemporarias"
            :options.sync="paginationTransferenciasVigentes"
            :server-items-length="totalPageVigente"
            class="elevation-1"
            v-bind:class="{ 'controls-puller': possesTemporarias.length > 5 }"
            :footer-props="{
              itemsPerPageText: $t('label.linhas_por_pagina'),
              itemsPerPageOptions: [10, 25, 50],
            }"
            :no-data-text="$t('label.tabela_sem_conteudo')">
            <template v-slot:item.acoes="{ item }">
              <v-btn icon class="mx-0" @click="editar(item)" v-if="canEdit">
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.existeUsuarioAssociado="{ item }">
            <v-tooltip bottom >
              <template v-slot:activator="{ on }" v-if="!item.existeUsuarioAssociado">
                <v-btn icon class="mx-0" v-on="on">
                  <v-icon>warning</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('message.usuario_associado_divisao', { de: item.de, para: item.para}) }}</span>
            </v-tooltip>
          </template>
            <template v-slot:item.dtaInicio="{ item }">
              {{formataData(item.dtaInicio)}}
            </template>
            <template v-slot:item.dtaFim="{ item }">
              {{formataData(item.dtaFim)}}
            </template>
          </v-data-table>
          <v-btn class="v-btn__fab"
            color="primary"
            dark fixed fab bottom right
            @click="registrar"
            v-if="canEdit">
            <v-icon>add</v-icon>
          </v-btn>
        </v-tab-item>
        <v-tab-item id="tab-historico" class="mt-3">
          <v-container ml-0>
            <v-row justify-end>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="searchQueryHistorico"
                  append-icon="search"
                  :label="$t('label.pesquisar')"
                  single-line
                  clearable
                  @click:clear="limparEBuscarTransferenciasHistorico()"
                  hide-details>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            :headers="headersHistoricoPosses"
            :items="historicoPosses"
            :options.sync="paginationHistorico"
            :server-items-length="totalPageHistorico"
            class="elevation-1"
            :footer-props="{
              itemsPerPageText: $t('label.linhas_por_pagina'),
              itemsPerPageOptions: [10, 25, 50],
            }"
            :no-data-text="$t('label.tabela_sem_conteudo')">
            <template v-slot:item.dtaInicio="{ item }">
              {{formataData(item.dtaInicio)}}
            </template>
            <template v-slot:item.dtaFim="{ item }">
              {{formataData(item.dtaFim)}}
            </template>
            <template slot="pageText" slot-scope="props">
              {{ props.pageStart }} - {{ props.pageStop }} {{$tc('label.de', 2)}} {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <transferencia-posse-form
      ref="transfForm"
      :somente-leitura="!canAccessPage"
      @cancelar="fecharForm"
      @atualizarGrid="carregarGrids()">
    </transferencia-posse-form>
  </div>
</template>
<script>
import moment from 'moment';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import TransferenciaPosseForm from './TransferenciaPosseForm';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'TransferenciaPosseAbas',
  components: {
    TransferenciaPosseForm,
    Confirm,
  },
  computed: {
    ...generateComputed('TRANS_POSSE', [
      'canAccessPage',
      'canEdit',
    ]),
  },
  data() {
    return {
      tabSelecionada: null,
      dialogForm: false,
      esperar: false,
      posse: {},
      possesTemporarias: [],
      historicoPosses: [],
      resources: this.$api.transferenciaPosse(this.$resource),
      paginationTransferenciasVigentes: {},
      paginationHistorico: {},
      posseSelecionada: null,
      searchQueryVigente: null,
      searchQueryHistorico: null,
      totalPageVigente: 0,
      totalPageHistorico: 0,
      alturaTela: window.innerHeight,
      headersPossesTemporarias: [
        { text: '', value: 'acoes', sortable: false },
        { text: '', value: 'existeUsuarioAssociado', sortable: false },
        { text: this.$tc('label.de', 2), value: 'de', sortable: false },
        { text: this.$tc('label.para', 1), value: 'para', sortable: false },
        { text: this.$tc('label.motivo', 1), value: 'motivo', sortable: false },
        { text: this.$tc('label.data_inicio', 1), value: 'dtaInicio', sortable: false },
        { text: this.$tc('label.data_fim', 1), value: 'dtaFim', sortable: false },
      ],
      headersHistoricoPosses: [
        { text: this.$tc('label.de', 2), value: 'de', sortable: false },
        { text: this.$tc('label.para', 1), value: 'para', sortable: false },
        { text: this.$tc('label.motivo', 1), value: 'motivo', sortable: false },
        { text: this.$tc('label.data_inicio', 1), value: 'dtaInicio', sortable: false },
        { text: this.$tc('label.data_fim', 1), value: 'dtaFim', sortable: false },
      ],
      rules: {
        validDate: (value) => this.isDataValida(value) || this.$t('message.data_invalida'),
      },
    };
  },
  watch: {
    searchQueryVigente(v) {
      if (!v) return;
      if (this.timeoutTemp) {
        window.clearTimeout(this.timeoutTemp);
      }
      this.timeoutTemp = window.setTimeout(() => {
        this.buscarTransferenciasVigentes();
      }, 500);
    },
    searchQueryHistorico(v) {
      if (!v) return;
      if (this.timeoutHist) {
        window.clearTimeout(this.timeoutHist);
      }
      this.timeoutHist = window.setTimeout(() => {
        this.buscarTransferenciasHistorico();
      }, 500);
    },
    paginationTransferenciasVigentes: {
      handler() {
        this.buscarTransferenciasVigentes();
      },
      deep: true,
    },
    paginationHistorico: {
      handler() {
        this.buscarTransferenciasHistorico();
      },
      deep: true,
    },
  },
  methods: {
    editar(transferenciaPossse) {
      this.$refs.transfForm.abrir(transferenciaPossse);
    },
    registrar() {
      this.$refs.transfForm.abrir();
    },
    limparEBuscarTransferenciasVigentes() {
      this.searchQueryVigente = '';
      this.buscarTransferenciasVigentes();
    },
    limparEBuscarTransferenciasHistorico() {
      this.searchQueryHistorico = '';
      this.buscarTransferenciasHistorico();
    },
    buscarTransferenciasVigentes() {
      const params = {
        nome: this.search,
        numeroPagina: this.paginationTransferenciasVigentes.page || 1,
        tamanhoPagina: this.paginationTransferenciasVigentes.itemsPerPage || 10,
        filtro: this.searchQueryVigente,
      };

      this.resources.listarTemporarios(params).then((response) => {
        this.possesTemporarias = response.data.resposta;
        this.totalPageVigente = response.data.quantidadeRegistrosPagina;
      }, (err) => {
        this.$error(this, err);
      });
    },
    buscarTransferenciasHistorico() {
      const params = {
        nome: this.search,
        numeroPagina: this.paginationHistorico.page || 1,
        tamanhoPagina: this.paginationHistorico.itemsPerPage || 10,
        filtro: this.searchQueryHistorico,
      };

      this.resources.listarHistorico(params).then((response) => {
        this.historicoPosses = response.data.resposta;
        this.totalPageHistorico = response.data.quantidadeRegistrosPagina;
      }, (err) => {
        this.$error(this, err);
      });
    },
    carregarGrids() {
      this.dialogForm = false;
      this.buscarTransferenciasHistorico();
      this.buscarTransferenciasVigentes();
    },
    fecharForm() {
      this.idEvento = null;
      this.dialogForm = false;
    },
    formataData(data) {
      return moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      this.$router.push({ name: 'inicio' });
      this.$toast('Acesso negado');
    }
  },
};
</script>

<style>
  .aba-text .v-tabs__item {
    color: rgba(121, 85, 72, .7) !important;
  }

  .aba-text .v-tabs__item--active {
    color: rgb(121, 85, 72) !important;
  }

  .controls-puller .v-data-footer {
    padding-right: 60px;
  }
</style>
