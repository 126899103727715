<template>
  <v-menu
    :close-on-content-click="false"
    v-model="pickerHora"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="somenteLeitura"
    :required="campoObrigatorio"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
          :id="inputId"
          v-on="on"
          v-model="valorInterno"
          :label="inputLabel"
          prepend-icon="access_time"
          readonly
          :disabled="somenteLeitura"
          :required="campoObrigatorio"
          clearable
          @click:clear="limpaHora"
          :rules="[rules.requiredHour]"></v-text-field>
    </template>

    <v-time-picker
      v-if="pickerHora"
      v-model="valorInterno"
      format="24hr"
      :disabled="somenteLeitura"
      :required="campoObrigatorio"
      @input="horaAlterada()">
    </v-time-picker>
  </v-menu>
</template>
<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoHora',
  props: {
    value: {
      type: [String],
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
      },
    },
  },
  data() {
    return {
      valorInterno: null,
      pickerHora: null,
      time: null,
      rules: {
        requiredHour: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.valorInterno;
          return !!(val) || msg;
        },
      },
    };
  },
  methods: {
    limpaHora() {
      this.valorInterno = null;
      this.emiteValorAlterado(null);
    },
    horaAlterada() {
      this.emiteValorAlterado(this.valorInterno);
      this.pickerHora = false;
    },
  },
};
</script>
