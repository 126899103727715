export default class CentroCusto {
  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.nome = data.nome;
      this.indAtivo = data.indAtivo;
      this.idExterno = data.idExterno;
      this.desAtributos = CentroCusto.montarDesAtributos(data);
    } else {
      this.indAtivo = true;
    }
  }

  construirAPartirDaConsultaCompleta(dadosDaConsulta) {
    this.id = dadosDaConsulta.id;
    this.nome = dadosDaConsulta.nome;
    this.indAtivo = dadosDaConsulta.indAtivo;
    this.idExterno = dadosDaConsulta.idExterno;
    this.desAtributos = dadosDaConsulta.desAtributos;
  }

  ehEdicao() {
    return this.id;
  }

  static montarDesAtributos(dados) {
    dados.desAtributos = dados.desAtributos
      && dados.desAtributos.value
      ? JSON.parse(dados.desAtributos.value) : {};

    if (!dados.desAtributos.regional) {
      return { regional: false };
    }

    return { ...dados.desAtributos };
  }
}
