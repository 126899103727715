export default {
  methods: {
    filtraAmostragemRecursiva(metadadosExtensao) {
      this.$refs.dialogFiltros.filtraAmostragemRecursiva(metadadosExtensao);
    },
    filtroAplicado(filtrosRecursao) {
      const { objeto } = this;
      Object.keys(filtrosRecursao)
        .filter((chave) => filtrosRecursao[chave] != null)
        .forEach((chave) => {
          objeto[chave] = { ...filtrosRecursao[chave] };
        });
      this.refresh();
    },
    identificaNiveisRecursivos() {
      const metadados = this.metadadosEntidade,
        dependencias = Object.values(metadados.mapaEntidades);

      let idNiveisRecursivos = dependencias
        .filter((dependencia) => dependencia.recursiva)
        .map((dependencia) => dependencia.idNivelExtensao);
      idNiveisRecursivos = Array.from(new Set(idNiveisRecursivos));

      return idNiveisRecursivos;
    },
    identificaToposHierarquias(idNiveisRecursivos) {
      const metadados = this.metadadosEntidade,
        dependencias = Object.values(metadados.mapaEntidades);

      const mapaTopoHierarquia = {};
      idNiveisRecursivos.forEach((idNivelExtensao) => {
        let maiorIndice = -1;
        dependencias
          .filter((d) => d.idNivelExtensao === idNivelExtensao)
          .forEach((dependencia) => {
            const { indiceRecursao } = dependencia;
            if (indiceRecursao > maiorIndice) {
              maiorIndice = indiceRecursao;
              mapaTopoHierarquia[idNivelExtensao] = dependencia;
            }
          });
      });
      return Object.values(mapaTopoHierarquia);
    },
    vinculaDependenciasRecursivamente(dependencia) {
      const metadados = this.metadadosEntidade;
      const dependenciasHier = Object.values(metadados.mapaEntidades)
        .filter((d) => d.idNivelExtensao === dependencia.idNivelExtensao);

      let nomesDependencias = [dependencia.label],
        referencia = dependencia;

      while (referencia != null) {
        const dependente = dependenciasHier
          .filter((dependenciaRec) => { // eslint-disable-line no-loop-func
            const { dependencias } = dependenciaRec;
            return dependencias.indexOf(referencia.label) >= 0;
          })[0];

        if (dependente != null) {
          dependente.dependencias = nomesDependencias;
          nomesDependencias = [
            ...nomesDependencias,
            dependente.label,
          ];
          referencia = dependente;
        } else {
          referencia = null;
        }
      }
    },
    /**
     * Associa os topos das hierarquias como dependencias
     * da hierarquia.
     * Exemplo:
     * Produto <- Subgrupo <- Grupo <- Categoria
     *
     * Neste caso ira colocar Categoria como dependencia de
     * Grupo e Subgrupo, e assim por diante.
     * Assim, ao editar Cateogria ira apagar Grupo e Subgrupo.
     */
    vinculaTopoHierarquiaComDependentesIndiretos() {
      if (!this.containerPossuiMapaEntidades()) {
        return;
      }

      const idNiveisRecursivos = this.identificaNiveisRecursivos();
      const toposHierarquias = this.identificaToposHierarquias(idNiveisRecursivos);

      toposHierarquias
        .forEach((dependencia) => this.vinculaDependenciasRecursivamente(dependencia));
    },
    /**
     * Associa a entidade como dependente de todas extensoes
     * da hierarquia.
     * Exemplo:
     * Produto <- Subgrupo <- Grupo <- Categoria
     *
     * Neste caso ira colocar produto como dependente de
     * Grupo e Categoria. Assim se um destes campos, quando
     * renderizados, for alterado então Produto será apagado.
     */
    vinculaEntidadeComDependenciasIndiretas() {
      if (!this.containerPossuiMapaEntidades()) {
        return;
      }

      const { entidade, mapaEntidades } = this.metadadosEntidade;
      const dependencias = Object.values(mapaEntidades);
      dependencias
        .filter((dependencia) => dependencia.dependentes.indexOf(entidade) < 0)
        .forEach((dependencia) => dependencia.dependentes.push(entidade));
    },
  },
};
