import getBasePath from '../functions/api-resource';

const basePath = getBasePath('job', 'arquivo-configuracao');

const configActions = {
  buscarFormatosAceitos: { method: 'GET', url: `${basePath}/upload/formatos-aceitos` },
  buscarLimitDeTamanhoArquivo: { method: 'GET', url: `${basePath}/upload/tamanho-maximo` },
};

export default (resource) => resource(`${basePath}`, {}, configActions);
