import getBasePath from '@/produto/common/functions/api-resource';

const basePath = getBasePath('api', 'notas/sellin-devolucao');

const notasActions = {
  listarNotasSellInDevolucaoConsolidado: { methods: 'GET', url: `${basePath}/consolidado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasSellInDevolucaoDetalhado: { methods: 'GET', url: `${basePath}/detalhado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasSellInDevolucaoLinhaDetalhado: { methods: 'GET', url: `${basePath}/por-linha/detalhado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasSellInDevolucaoLinhaEFamiliaDetalhado: { methods: 'GET', url: `${basePath}/por-linha-e-familia/detalhado?tamanhoPagina={size}&numeroPagina={page}` },
};

export default (resource) => resource(`${basePath}`, {}, notasActions);
