<template>
  <v-dialog v-model="showModalMultiplasNds" :persistent="true" max-width="70%">
    <v-card style="min-height:168px; height: 100%;">
      <v-card-title class="justify-center" style="word-break: break-word; width: 97%; text-align: center;">
        {{ $tc('label.recebimento_calculado', 1) }}: {{ getMoney(fornecedorNd.verbaManual) }} ||
        {{ $tc('label.valor_pendente', 1) }}: {{ getMoney(valorPendente) }} ||
        {{ $tc('label.valor_alocado', 1) }}: {{ getMoney(valorAlocado) }}
      </v-card-title>
      <v-card-text>
        <v-container fluid px-0 py-0>
          <v-row>
            <v-col cols="12" >
              <v-data-table
                :headers="headers"
                :items="listaMultiplasNds"
                hide-default-footer
                disable-pagination
                :key="tableKey"
                :items-per-page="-1"
                :no-data-text="$t('message.nenhum_registro')"
                style="overflow:hidden">
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-center">
                      <span v-text="item.numNotaDebito"></span>
                    </td>
                    <td class="text-center">
                      <span v-text="item.codReferenciaNdFornecedor"></span>
                    </td>
                    <td class="text-center">
                      <span v-text="convertToMoney(item.valorNd)"></span>
                    </td>
                    <td class="justify-center px-0" style="text-align: center;" v-if="!somenteLeitura">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon class="mx-0" v-on="on" @click="editar(item)">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('label.editar') }}</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon class="mx-0" v-if="!isMesmoNumNdPrevia(item)" v-on="on" @click="openConfirmRemocao(item)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $tc('label.remover') }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-btn absolute
                small
                right
                top
                icon
                @click="incluirNovaNd()"
                color="primary"
                v-if="!somenteLeitura">
                <v-tooltip bottom left>
                  <span>{{$t('label.novo')}}</span>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">add</v-icon>
                  </template>
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-show="isValoresNdsExcedemTotalApuracao" >
            <v-col cols="12" sm="12" align="right">
              <span class="font-weight-bold" style="color:#EF5350;">
                {{$t('message.multiplas_nds.valor_nds_excede_total')}}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="somenteLeitura" @click.native="close">{{ $t('label.fechar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" @click.native="cancelar">{{ $t('label.cancelar') }}</v-btn>
        <v-btn
          v-if="!somenteLeitura"
          color="primary darken-1"
          :disabled="!podeSalvar"
          @click.native="salvar">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>

      <v-dialog v-model="showModalNovaNd" :persistent="true" eager max-width="800">
        <v-form ref="formModal" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ $tc('label.nota_debito', 1) }}
              </span>
            </v-card-title>
            <v-card-text>
              <v-container fluid grid-list-md>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      :label="`${$tc('label.numero_nd', 1)}`"
                      v-model="nd.numNotaDebito"
                      :disabled="true"
                      type="Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :label="`${$t('label.cod_referencia_nd_fornecedor', 1)} *`"
                      v-model="nd.codReferenciaNdFornecedor"
                      :disabled="somenteLeitura"
                      :rules="[rules.required, v => rules.valorPgmExistente(v, nd.index)]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <input-money
                      id="valor_nd"
                      :label="`${$t('label.valor_nd', 1)} *`"
                      v-model="nd.valorNd"
                      :value="nd.valorNd"
                      :disabled="somenteLeitura"
                      class="v-text-field"
                      :rules="[rules.requiredValorPositivo]"
                      :required="true"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="somenteLeitura" @click.native="fecharModalAdicionarNd">{{ $t('label.fechar') }}</v-btn>
              <v-btn v-if="!somenteLeitura" @click.native="fecharModalAdicionarNd">{{ $t('label.cancelar') }}</v-btn>
              <v-btn color="primary darken-1" @click.native="adicionarNd">{{ $t('label.adicionar') }}</v-btn>
            </v-card-actions>
          </v-card>
          <confirm
            ref="confirmRemocao"
            :message="msgRemoverNd"
            :persistent="true"
            @agree="remover"
            @disagree="resetarNd">
          </confirm>
        </v-form>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import InputMoney from '../../shared-components/inputs/InputMoney';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import {
  convertToMoney,
} from '../../common/functions/numeric';
import { copyObject, getMoney } from '../../common/functions/helpers';

export default {
  data() {
    return {
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      showModalNovaNd: false,
      showModalMultiplasNds: false,
      fornecedorNd: {},
      tableKey: 1,
      rules: {
        required: (value) => (!!value || value === 0 || value === '0') || this.$t('message.campo_obrigatorio'),
        requiredValorPositivo: (value) => (!!value && value > 0) || this.$t('message.campo_obrigatorio'),
        valorPgmExistente: (value, indexNd) => (!!value && !this.listaMultiplasNds.some((nd, index) => nd.codReferenciaNdFornecedor === value && indexNd !== index)) || this.$t('message.multiplas_nds.valor_pgm_existente'),
      },
      edicao: false,
      listaMultiplasNds: [],
      listaMultiplasNdsCopia: [],
      msgRemoverNd: this.$t('message.deseja_remover_nd'),
      nd: {
        id: null,
        index: -1,
        numNotaDebito: null,
        codReferenciaNdFornecedor: '',
        valorNd: 0.0,
      },
      headers: [
        {
          text: this.$t('label.numero_nd'), value: 'numero_nd', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.cod_referencia_nd_fornecedor'), value: 'cod_referencia_nd_fornecedor', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.valor_nd'), value: 'valor_nd', sortable: false, align: 'center',
        },
      ],
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    apuracao: Object,
  },
  watch: {
  },
  components: {
    InputMoney,
    Confirm,
  },
  computed: {
    valorAlocado() {
      return this.listaMultiplasNds.reduce((a, b) => a + b.valorNd, 0);
    },
    valorPendente() {
      if (!this.listaMultiplasNds.length) {
        return this.fornecedorNd.verbaManual;
      }
      return this.fornecedorNd.verbaManual.toFixed(2) - (this.listaMultiplasNds.reduce((a, b) => a + b.valorNd, 0)).toFixed(2);
    },
    isValoresNdsIgualTotalApuracao() {
      return this.fornecedorNd.verbaManual > 0 && this.valorPendente === 0;
    },
    isValoresNdsExcedemTotalApuracao() {
      return this.fornecedorNd.verbaManual > 0 && this.valorPendente < 0;
    },
    isValoresNdsAbaixoTotalApuracao() {
      return this.fornecedorNd.verbaManual > 0 && this.valorPendente > 0;
    },
    podeSalvar() {
      return this.listaMultiplasNds.length > 1 && !this.isValoresNdsExcedemTotalApuracao;
    },
  },
  methods: {
    convertToMoney,
    getMoney,
    open(fornecedorNd) {
      this.fornecedorNd = fornecedorNd;
      if (this.fornecedorNd.listaMultiplasNds && this.fornecedorNd.listaMultiplasNds.length) {
        this.listaMultiplasNds = [...this.fornecedorNd.listaMultiplasNds];
        this.listaMultiplasNdsCopia = copyObject(this.listaMultiplasNds);
      }
      this.ordenarLista();
      this.showModalMultiplasNds = true;
    },
    close() {
      this.resetar();
      this.showModalMultiplasNds = false;
    },
    resetar() {
      this.resetarNd();
      this.listaMultiplasNds = [];
      this.edicao = false;
    },
    cancelar() {
      this.fornecedorNd.listaMultiplasNds = [...this.listaMultiplasNdsCopia];
      this.close();
    },
    resetarNd() {
      this.nd = {
        id: null,
        index: -1,
        numNotaDebito: null,
        codReferenciaNdFornecedor: '',
        valorNd: 0.0,
      };
      this.$refs.formModal.resetValidation();
    },
    fecharModalAdicionarNd() {
      this.showModalNovaNd = false;
      this.resetarNd();
    },
    salvar() {
      if (this.isValoresNdsExcedemTotalApuracao) {
        return;
      }
      const { idNotaDebitoPrevia } = this.fornecedorNd;
      this.apuracaoAcaoResource.salvarMultiplasNds({ idNotaDebitoPrevia }, this.listaMultiplasNds)
        .then((res) => {
          this.listaMultiplasNds = res.data;
          this.ordenarLista();
          this.$emit('ModalGerarNdApuracaoFornecedores__fecharModalMultiplasNds', this.listaMultiplasNds);
          this.close();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    adicionarNd() {
      if (this.nd.valorNd) {
        this.nd.valorNd = Number(this.nd.valorNd);
      }
      if (!this.$refs.formModal.validate()
        || this.valoresNegativos()) return;
      if (this.edicao) {
        this.listaMultiplasNds.forEach((nd, index) => {
          if (index === this.nd.index) {
            nd.codReferenciaNdFornecedor = this.nd.codReferenciaNdFornecedor;
            nd.valorNd = this.nd.valorNd;
          }
        });
      } else {
        this.nd.idNotaDebitoPrevia = this.fornecedorNd.idNotaDebitoPrevia;
        this.listaMultiplasNds.push(this.nd);
      }
      this.ordenarLista();
      this.tableKey += 1;
      this.showModalNovaNd = false;
      this.resetarNd();
    },
    ordenarLista() {
      function compare(a, b) {
        if (!!a.numNotaDebito && !b.numNotaDebito) return -1;
        if (!!b.numNotaDebito && !a.numNotaDebito) return 1;
        if (!a.id && !b.id) return 0;
        if (!a.id) return 1;
        if (!b.id) return -1;
        return a.id - b.id;
      }
      this.listaMultiplasNds.sort(compare);
    },
    editar(item) {
      this.edicao = true;
      this.nd = copyObject(item);
      this.nd.index = this.listaMultiplasNds.indexOf(item);
      this.showModalNovaNd = true;
    },
    openConfirmRemocao(item) {
      this.nd = copyObject(item);
      if (!this.nd.numNotaDebito) {
        this.msgRemoverNd = this.$t('message.deseja_remover_nd');
      } else {
        this.msgRemoverNd = this.$t('message.deseja_remover_nd_com_nd_existente');
      }
      this.$refs.confirmRemocao.open();
    },
    remover() {
      this.listaMultiplasNds.splice(this.listaMultiplasNds.indexOf(this.nd), 1);
      this.tableKey += 1;
    },
    valoresNegativos() {
      if (this.nd.valorNd < 0) {
        this.$toast(this.$t('errors.nd.negativa'));
        return true;
      }
      return false;
    },
    incluirNovaNd() {
      this.resetarNd();
      this.edicao = false;
      if (!this.listaMultiplasNds.length) {
        this.nd.numNotaDebito = this.fornecedorNd.numNotaDebito;
      }
      this.showModalNovaNd = true;
    },
    isMesmoNumNdPrevia(item) {
      return item.numNotaDebito === this.apuracao.numNotaDebito;
    },
  },
  mounted() {
    if (!this.somenteLeitura) {
      this.headers.push(
        {
          text: '', value: '', sortable: false, align: 'center',
        },
      );
    }
  },
};
</script>
