import VueResource from 'vue-resource';
import money from 'v-money';
import VueTheMask from 'vue-the-mask';
import Toast from '../common/functions/toast';
import ErrorHandler from '../common/functions/error-handler';
import Moment from '../common/functions/moment';
import Lodash from '../common/functions/lodash';

export default (Vue, ...params) => {
  // Adicionando plugins ao Core do Vue
  params.filter((el) => typeof el !== 'undefined')
    .map((le) => Vue.use(le));
  Vue.use(VueResource);
  Vue.use(Toast);
  Vue.use(Moment);
  Vue.use(ErrorHandler);
  Vue.use(Lodash);
  Vue.use(money, { precision: 2 });
  Vue.use(VueTheMask);
};
