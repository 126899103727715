import numeric from '../common/directives/numberic';
import {
  vCNPJ,
  vCPF,
  vCEP,
  vDateFull,
  vDateInternational,
  vHour,
  vMesAno,
  vMoney,
  vPhone,
  vInteger,
} from '../common/directives/masks';

import {
  vCpfCnpj,
} from '../common/directives/document-masks';

export default (Vue) => {
  // Exemplo de como usar uma diretiva em Vue
  Vue.directive('cnpj', vCNPJ);
  Vue.directive('cpf', vCPF);
  Vue.directive('cpfcnpj', vCpfCnpj);
  Vue.directive('cep', vCEP);
  Vue.directive('datefull', vDateFull);
  Vue.directive('dateinternational', vDateInternational);
  Vue.directive('hour', vHour);
  Vue.directive('mesAno', vMesAno);
  Vue.directive('money', vMoney);
  Vue.directive('numeric', numeric);
  Vue.directive('phone', vPhone);
  Vue.directive('integer', vInteger);
};
