import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'nivel-cliente');

const nivelProdutoActions = {
  ...genericActions(basePath),
  orcamento: { method: 'GET', url: `${basePath}/orcamento` },
  acao: { method: 'GET', url: `${basePath}/acao` },
  labels: { method: 'GET', url: `${basePath}/labels` },
  extensoesRecusiva: { method: 'GET', url: `${basePath}/extensao-recursivas` },
};

export default (resource) => resource(`${basePath}`, {}, nivelProdutoActions);
