export default class Alcada {
  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.descricao = data.descricao;
      this.vlrLimiteInicial = data.vlrLimiteInicial;
      this.fluxo = data.fluxo;
      this.idsWorkflows = data.idsWorkflows;
      this.workflow = data.workflow;
      this.estruturaUsuario = data.estruturaUsuario;
      if (!data.vlrLimiteFinal) {
        this.indSemValorFim = true;
        this.vlrLimiteFinal = 0;
      } else {
        this.indSemValorFim = false;
        this.vlrLimiteFinal = data.vlrLimiteFinal;
      }
    } else {
      this.indAtivo = true;
      this.indSemValorFim = false;
    }
  }

  construirAPartirDaConsultaCompleta(dadosDaConsulta) {
    this.id = dadosDaConsulta.id;
    this.descricao = dadosDaConsulta.descricao;
    this.vlrLimiteInicial = dadosDaConsulta.vlrLimiteInicial;
    this.indAtivo = dadosDaConsulta.indAtivo;
    this.idsWorkflows = dadosDaConsulta.idsWorkflows;
    this.estruturaUsuario = {
      nomEstruturaUsuario: dadosDaConsulta.nomEstruturaUsuario,
      id: dadosDaConsulta.idEstruturaUsuario,
    };
    this.fluxo = {
      nome: dadosDaConsulta.fluxo,
      id: dadosDaConsulta.idWorkflowTipo,
    };
    this.workflow = {
      descricao: dadosDaConsulta.workflow,
      id: dadosDaConsulta.idWorkflow,
    };
    if (!dadosDaConsulta.vlrLimiteFinal) {
      this.indSemValorFim = true;
      this.vlrLimiteFinal = 0;
    } else {
      this.indSemValorFim = false;
      this.vlrLimiteFinal = dadosDaConsulta.vlrLimiteFinal;
    }
  }

  ehEdicao() {
    return this.id;
  }
}
