<template lang="html">
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="400"
    left
    offset-x>
    <template v-slot:activator="{ on }">
      <v-btn text dark icon v-on="on">
        <v-icon>info_outline</v-icon>
      </v-btn>
    </template>

    <div>
      <v-text-field
        solo
        index="1"
        ref="manualFiltro"
        class="ma3"
        v-model="filtro"
        :label="$t('label.filtrar')"
        append-icon="search">
      </v-text-field>

      <div class="ManualSistema__list">
        <v-list two-line>
          <template v-for="(manual, index) in manuaisFiltrados">

            <v-divider v-if="index > 0"></v-divider>

            <v-list-item
              :key="manual.descricao"
              v-bind:href="getPath(manual.arquivo)"
              target="_blank">

              <v-list-item-content>
                <v-list-item-title>{{ manual.descricao }}</v-list-item-title>
                <v-list-item-subtitle>{{ manual.personas }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon ripple>
                  <v-icon>cloud_download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </div>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      path: '/static/pdf/',
      menu: false,
      filtro: '',
      manuais: [
        {
          descricao: 'Manual de usuário - Administrador',
          arquivo: 'Manual de usuário - Administrador - v1.3.pdf',
          personas: 'Administrador, Trade Campo',
        },
        {
          descricao: 'Manual de usuário - Agência',
          arquivo: 'Manual de usuário - Agência - v1.2.pdf',
          personas: 'Agência',
        },
        {
          descricao: 'Manual de usuário - Gerente',
          arquivo: 'Manual de usuário - Gerente - v1.4.pdf',
          personas: 'GTV, GTM, GCE',
        },
        {
          descricao: 'Manual de usuário - RTV',
          arquivo: 'Manual de usuário - RTV - v1.4.pdf',
          personas: 'RTV, Trade Campo, Planejamento, Execução',
        },
        {
          descricao: 'Manual de usuário - RTV Modalidade 4',
          arquivo: 'Manual de usuário - RTV - Modalidade 4 - v2.0.pdf',
          personas: 'Modalidade 4',
        },
      ],
    };
  },
  watch: {
    menu(val) {
      if (val) {
        setTimeout(() => this.$refs.manualFiltro.focus(), 5E2);
      }
    },
  },
  methods: {
    getPath(arquivo) {
      return `${this.path}${arquivo}`;
    },
    includesFiltro(descricao, personas, filtro) {
      return descricao.toLowerCase().includes(filtro.toLowerCase())
        || personas.toLowerCase().includes(filtro.toLowerCase());
    },
  },
  computed: {
    manuaisFiltrados() {
      if (this.filtro && this.filtro.length) {
        return this.manuais
          .filter((m) => this.includesFiltro(m.descricao, m.personas, this.filtro));
      }
      return this.manuais;
    },
  },
};
</script>

<style scoped>
  .ManualSistema__list {
    max-height: 80vh;
    overflow-y: auto;
  }
</style>
