import CarteiraCliente from '../../../spa/carteira-cliente/CarteiraCliente';

const CarteirasClientes = () => import('../../../spa/carteira-cliente/CarteirasClientes');

export default {
  path: 'carteira-cliente',
  component: CarteiraCliente,
  children: [
    {
      path: '',
      name: 'carteira-cliente',
      component: CarteirasClientes,
    },
  ],
};
