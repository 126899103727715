<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card class="card-size">
      <v-card-title>
        {{ ehEdicao ? $t('title.editar_unidade_medida') : $t('title.cadastrar_nova_unidade_medida') }}
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="unidade_sigla"
              name="unidade_sigla"
              v-model="unidade.sigla"
              class="Form-text__invalid"
              :label="`${$tc('label.sigla', 1)} *`"
              :counter="20"
              maxlength="20"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="unidade_nome"
              name="unidade_nome"
              v-model="unidade.nomUnidade"
              class="Form-text__invalid"
              :label="`${$tc('label.nome', 1)} *`"
              :counter="80"
              maxlength="80"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>
  </v-form>
</template>

<script>
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'UnidadeMedidaForm',
  data() {
    return {
      unidadeMedidaResources: this.$api.unidadeMedida(this.$resource),

      unidade: {},
      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
      },
      dialog: {
        content: '',
      },
    };
  },
  watch: {
  },
  components: {
    Confirm,
  },
  methods: {
    cancel() {
      this.limparFormulario();
    },
    editar(unidade) {
      this.unidade = { ...unidade };
    },
    limparFormulario() {
      this.unidade = {};
      this.$refs.form.reset();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.dialog.content = this.$t('message.salvar');
      this.$refs.confirmDialog.open();
    },
    save() {
      let salvarOuAtualizar;
      let inclusao = true;

      if (this.unidade.id != null) {
        inclusao = false;
        salvarOuAtualizar = this.unidadeMedidaResources.atualizar(this.unidade);
      } else {
        salvarOuAtualizar = this.unidadeMedidaResources.gravar(this.unidade);
      }

      salvarOuAtualizar
        .then(() => this.limparFormulario())
        .then(() => this.$toast(
          this.$t(
            inclusao
              ? 'message.adicionado_confira_tabela'
              : 'message.atualizado_confira_tabela',
          ),
        ))
        .then(() => {
          this.$emit('FORMULARIO_CADASTRO_UNIDADE_SALVAR', this.unidade, this.clearForm);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  computed: {
    ehEdicao() {
      return this.unidade.id;
    },
  },
  mounted() {

  },
};
</script>
