<template lang="html">
  <div tabindex="100">
    <v-card style="min-height: 83vh;">
      <v-card-title>
        {{title}}
      </v-card-title>

      <v-container>
        <v-row justify-end>
          <v-col cols="12"  sm="6" md="3" class="mr-3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('label.pesquisar')"
              single-line
              clearable
              hide-details>
            </v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="registros"
          :search="search"
          :items-per-page="5"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          :footer-props="{itemsPerPageText: $t('label.linhas_por_pagina')}">
          <template v-slot:item.desImportacao="{ item }">
            {{ item.desImportacao }}
          </template>
          <template v-slot:item.nomArquivoOrigem="{ item }">
            {{ item.nomArquivoOrigem }}
          </template>
          <template v-slot:item.dtaCriacao="{ item }">
            {{ item.dtaCriacao }}
          </template>
          <template v-slot:item.desResultado="{ item }" v-if="isErros">
            <v-tooltip bottom >
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click="openDialogErros(item)">
                  <v-icon>visibility</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.visualizar_detalhes') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </div>
</template>

<script type="text/javascript">
export default {
  props: {
    headers: Array,
    isErros: Boolean,
    registros: Array,
    title: String,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    possuiData() {
      return this.headers.filter((header) => header.text === 'data').length > 0;
    },
  },
  methods: {
    openDialogErros(row) {
      this.$emit('IMPORTACAOTABELA_OPEN_DIALOG_ERROS', row);
    },
  },
};
</script>
