import AnoFiscal from '../../spa/ano-fiscal/AnoFiscal';
import AnoFiscalPesquisar from '../../spa/ano-fiscal/AnoFiscalPesquisar';
import AnoFiscalForm from '../../spa/ano-fiscal/AnoFiscalForm';

export default {
  path: 'ano-fiscal',
  component: AnoFiscal,
  children: [
    {
      path: '',
      name: 'ano-fiscal',
      component: AnoFiscalPesquisar,
    },
    {
      path: ':tipo/novo',
      name: 'novoAnoFiscal',
      component: AnoFiscalForm,
    },
    {
      path: ':tipo/novo/fechar/:idAnoFechar',
      name: 'novoFecharAnoFiscal',
      component: AnoFiscalForm,
      props: {
        fecharDepoisSalvar: true,
      },
    },
  ],
};
