<template>
  <div v-if="canAccessPage">
    <dashboard-fornecedor-filtro
      v-model="abrirFiltro"
      @DashboardFornecedorFiltro__AplicaFiltros="aplicarFiltros"
      @FiltroRapido__HabilitaIconBadge="habilitaIconBadge">
    </dashboard-fornecedor-filtro>

    <v-container fluid grid-list-md pa-0>
      <v-row align="center" justify="end" class="font-weight-black" style="min-height: 50px">
        <v-col cols="12" class="text-right" xs1>
          <v-badge
              bordered
              color="orange"
              icon="mdi-lock"
              overlap
              v-show="!abrirFiltro"
              v-if="habilitarIconBadge">
              <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
          </v-badge>
          <v-icon v-else v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
      <v-row v-if="!possuiPermissaoApuracaoMassiva">
        <v-col cols="12">
          <liquidacao-cards-totalizadores
            ref="cards_totalizadores"
            :filtros="filtros"
            percentual="quantidade">
          </liquidacao-cards-totalizadores>
        </v-col>
      </v-row>
      <v-row v-if="possuiPermissaoApuracaoMassiva">
        <v-col cols="12">
          <dashboard-cards-pendencias-totalizadores
            ref="cards_pendencias_totalizadores"
            :possui-permissao-apuracao-massiva-acoes-apuracoes="possuiPermissaoApuracaoMassivaAcoesApuracoes"
            :possui-permissao-pendencias-de-analise="possuiPermissaoPendenciasDeAnalise"
            :possui-permissao-apuracao-massiva-contratos="possuiPermissaoApuracaoMassivaContratos"
            @abrirModalPendencias="abrirModalPendencias">
          </dashboard-cards-pendencias-totalizadores>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :sm="!naoPossuiAlgumaPermissaoApuracaoMassiva? 12 : 6" :md="!naoPossuiAlgumaPermissaoApuracaoMassiva? 12 : 8">
          <v-row>
            <v-col cols="12" class="px-3">
              <v-card style="width: 100%;">
                <dashboard-varejo-grafico :filtros="filtros"/>
              </v-card>
            </v-col>
            <v-col cols="12" >
              <dashboard-fornecedor-nota-debito :filtros="filtros">
              </dashboard-fornecedor-nota-debito>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="pt-0">
          <v-row>
            <v-col cols="12" d-flex v-if="!possuiPermissaoApuracaoMassivaAcoesApuracoes || !possuiPermissaoApuracaoMassivaContratos">
              <dashboard-pendencias-planejamento
                :filtros="filtros"
                :dashFornecedor="true" />
            </v-col>
            <v-col cols="12" d-flex v-if="!possuiPermissaoApuracaoMassivaAcoesApuracoes">
              <dashboard-pendencias-apuracao
                :filtros="filtros"
                :dashFornecedor="true" />
            </v-col>
            <v-col cols="12" d-flex v-if="!possuiPermissaoPendenciasDeAnalise">
              <dashboard-pendencias-em-analise
                :filtros="filtros" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <dashboard-modal-pendencias
    ref="modalPendencias"
    v-if="modalPendencias"
    v-model="modalPendencias"
    :abrir-modal-pendencia="abrirModalPendencia"
    @DashboardModalPendencias__fechar="fecharModalPendencia">
  </dashboard-modal-pendencias>
  <dashboard-modal-pendencias-contratos
  ref="modalPendenciasContratos"
  v-if="modalPendenciasContratos"
  v-model="modalPendenciasContratos"
  :abrir-modal-pendencia-contrato="abrirModalPendenciaContratos"
  @DashboardModalPendenciasContrato__fechar="fecharModalPendenciaContrato">
</dashboard-modal-pendencias-contratos>
  <dashboard-modal-pendencias-em-analise
    ref="modalPendenciasEmAnalise"
    v-if="modalPendenciasEmAnalise"
    v-model="modalPendenciasEmAnalise"
    :abrir-modal-pendencia-em-analise="abrirModalPendenciaEmAnalise"
    @DashboardModalPendenciasEmAnalise__fechar="fecharModalPendenciaEmAnalise">
  </dashboard-modal-pendencias-em-analise>
  </div>
</template>

<script type="text/javascript">
import moment from 'moment';
import { generateComputed } from '../../../../common/functions/roles-computed-generator';
import LiquidacaoCardsTotalizadores from '../../../liquidacao-acao/LiquidacaoCardsTotalizadores';

import DashboardVarejoGrafico from '../DashVarejoGrafico';
import DashboardPendenciasApuracao from '../../DashboardPendenciasApuracao';
import DashboardPendenciasPlanejamento from '../../DashboardPendenciasPlanejamento';
import DashboardPendenciasEmAnalise from '../../DashboardPendenciasEmAnalise';
import DashboardCardsPendenciasTotalizadores from '../DashboardCardsPendenciasTotalizadores';
import DashboardModalPendencias from '../DashboardModalPendencias';
import DashboardModalPendenciasEmAnalise from '../DashboardModalPendenciasEmAnalise';
import DashboardModalPendenciasContratos from '../DashboardModalPendenciasContratos';

import DashboardFornecedorFiltro from './DashFornecedorFiltro';
import DashboardFornecedorNotaDebito from './DashFornecedorNotaDebito';

export default {
  name: 'DashboardFornecedor',
  data() {
    return {
      filtros: null,
      abrirFiltro: false,
      habilitarIconBadge: false,
      abrirModalPendencia: false,
      abrirModalPendenciaContratos: false,
      abrirModalPendenciaEmAnalise: false,
      modalPendencias: null,
      modalPendenciasContratos: null,
      modalPendenciasEmAnalise: null,
    };
  },
  components: {
    LiquidacaoCardsTotalizadores,
    DashboardVarejoGrafico,
    DashboardFornecedorFiltro,
    DashboardPendenciasApuracao,
    DashboardPendenciasPlanejamento,
    DashboardFornecedorNotaDebito,
    DashboardPendenciasEmAnalise,
    DashboardCardsPendenciasTotalizadores,
    DashboardModalPendencias,
    DashboardModalPendenciasContratos,
    DashboardModalPendenciasEmAnalise,
  },
  computed: {
    ...generateComputed('ACESSO_FORNECEDOR', [
      'getAllRoles',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('ACESSO_FORNECEDOR')).length;
    },
    possuiPermissaoApuracaoMassivaAcoesApuracoes() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'APU_MASSIVA_ACOES_APURACOES').length;
    },
    possuiPermissaoPendenciasDeAnalise() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'PENDENCIAS_DE_ANALISE').length;
    },
    possuiPermissaoApuracaoMassivaContratos() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'APU_MASSIVA_CONTRATOS').length;
    },
    possuiPermissaoApuracaoMassiva() {
      if (this.possuiPermissaoApuracaoMassivaAcoesApuracoes || this.possuiPermissaoPendenciasDeAnalise
       || this.possuiPermissaoApuracaoMassivaContratos) {
        return true;
      }
      return false;
    },
    naoPossuiAlgumaPermissaoApuracaoMassiva() {
      if (!this.possuiPermissaoApuracaoMassiva
      || (this.possuiPermissaoApuracaoMassiva
      && (!this.possuiPermissaoApuracaoMassivaAcoesApuracoes
      || !this.possuiPermissaoPendenciasDeAnalise
      || !this.possuiPermissaoApuracaoMassivaContratos))) {
        return true;
      }
      return false;
    },
    desabilitaBuscaContratoPendencia() {
      if (!this.possuiPermissaoApuracaoMassivaAcoesApuracoes && this.possuiPermissaoApuracaoMassivaContratos) {
        return true;
      }
      return false;
    },
    desabilitaBuscaAcaoPendencia() {
      if (this.possuiPermissaoApuracaoMassivaAcoesApuracoes && !this.possuiPermissaoApuracaoMassivaContratos) {
        return true;
      }
      return false;
    },
  },
  methods: {
    abrirModalPendencias(modal) {
      if (modal.status === 'PENDENCIAS_NDS') {
        this.modalPendencias = modal;
        setTimeout(() => { this.abrirModalPendencia = true; }, 2E2);
      }
      if (modal.status === 'PENDENCIAS_CONTRATO') {
        this.modalPendenciasContratos = modal;
        setTimeout(() => { this.abrirModalPendenciaContratos = true; }, 2E2);
      }
      if (modal.status === 'PENDENCIAS_ANALISE') {
        this.modalPendenciasEmAnalise = modal;
        setTimeout(() => { this.abrirModalPendenciaEmAnalise = true; }, 2E2);
      }
    },
    fecharModalPendencia(pendenciaExecutada) {
      this.abrirModalPendencia = false;
      setTimeout(() => { this.modalPendencias = null; }, 2E2);
      if (pendenciaExecutada) {
        this.$refs.cards_pendencias_totalizadores.buscaTotalizadores();
      }
    },
    fecharModalPendenciaContrato(pendenciaExecutada) {
      this.abrirModalPendenciaContratos = false;
      setTimeout(() => { this.modalPendenciasContratos = null; }, 2E2);
      if (pendenciaExecutada) {
        this.$refs.cards_pendencias_totalizadores.buscaTotalizadores();
      }
    },
    fecharModalPendenciaEmAnalise(pendenciaExecutada) {
      this.abrirModalPendenciaEmAnalise = false;
      setTimeout(() => { this.modalPendenciasEmAnalise = null; }, 2E2);
      if (pendenciaExecutada) {
        this.$refs.cards_pendencias_totalizadores.buscaTotalizadores();
      }
    },
    aplicarFiltros(filtrosAplicados) {
      if (!filtrosAplicados || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (!filtrosAplicados.data_inicio && !filtrosAplicados.data_fim) {
        filtrosAplicados.data_inicio = moment(new Date(new Date().getFullYear(), 0, 1), 'DD-MM-YYYY')
          .format('YYYY-MM-DD');
        filtrosAplicados.data_fim = moment(new Date(new Date().getFullYear(), 11, 31), 'DD-MM-YYYY')
          .format('YYYY-MM-DD');
      }
      this.filtros = {
        ...filtrosAplicados,
        id_acao: filtrosAplicados.id_campanha ? filtrosAplicados.id_campanha : '',
      };
      if (this.desabilitaBuscaContratoPendencia) {
        this.filtros.desabilita_contrato = this.desabilitaBuscaContratoPendencia;
      }
      if (this.desabilitaBuscaAcaoPendencia) {
        this.filtros.desabilita_acao = this.desabilitaBuscaAcaoPendencia;
      }
    },
    habilitaIconBadge(valor) {
      this.habilitarIconBadge = valor;
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');
        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
  },
  mounted() {
    if (this.$route.params && this.$route.params.modalDashboard) {
      if (this.$route.params.modalDashboard === 'PENDENCIAS_NDS') {
        this.modalPendencias = true;
        setTimeout(() => { this.abrirModalPendencia = true; }, 2E2);
      }
      if (this.$route.params.modalDashboard === 'PENDENCIAS_CONTRATO') {
        this.modalPendenciasContratos = true;
        setTimeout(() => { this.abrirModalPendenciaContratos = true; }, 2E2);
      }
      if (this.$route.params.modalDashboard === 'PENDENCIAS_ANALISE') {
        this.modalPendenciasEmAnalise = true;
        setTimeout(() => { this.abrirModalPendenciaEmAnalise = true; }, 2E2);
      }
    }
  },
};
</script>
