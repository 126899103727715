export default class Passo3 {
  constructor(id, tipoVigencia = '', listaFluxosTrade = [], fechamentoParcial = false,
    coeficienteCalculo = false, anosDuracaoVigencia = null, indEnviaEmailNd = false,
    indGerarNdSemHistoricoNotas = false, listaUsuariosEnvioNd = []) {
    this.id = id;
    this.tipoVigencia = tipoVigencia;
    this.listaFluxosTrade = listaFluxosTrade;
    this.fechamentoParcial = fechamentoParcial;
    this.coeficienteCalculo = coeficienteCalculo;
    this.anosDuracaoVigencia = anosDuracaoVigencia;
    this.indEnviaEmailNd = indEnviaEmailNd;
    this.indGerarNdSemHistoricoNotas = indGerarNdSemHistoricoNotas;
    this.listaUsuariosEnvioNd = listaUsuariosEnvioNd;
  }
}
