import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('usuario', 'usuario');

const usuarioActions = {
  ...genericActions(basePath),
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  ativarRepresentante: { method: 'PUT', url: `${basePath}/representante/{id}/true` },
  desativarRepresentante: { method: 'PUT', url: `${basePath}/representante/{id}/false` },
  listarSuperior: { method: 'GET', url: `${basePath}/superior` },
  listarUsuariosFornecedores: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}{&idsGrupoFornecedor*}{&statusFluxo*}{&indUsuarioExternoMaster*}` },
  listarAtivosPorEmail: { method: 'GET', url: `${basePath}?email={email}&indAtivo=true&colunaOrdenacao=email` },
  listarAtivosPorEmailNaoConfigurados: { method: 'GET', url: `${basePath}?filter={email}&colunaOrdenacao=email&naoConfiguradosTradelinks=true` },
  listarNaoConfigurados: { method: 'GET', url: `${basePath}?filter={filter}&naoConfiguradosTradelinks=true` },
  informacao: { method: 'GET', url: `${basePath}/informacao` },
  tipos: { method: 'GET', url: `${basePath}/tipos` },
  listarConfiguracao: { method: 'GET', url: `${basePath}/configuracao` },
  atualizarConfiguracao: { method: 'PUT', url: `${basePath}/configuracao` },
  redefinirSenha: { method: 'PUT', url: `${basePath}/senha/redefinicao/usuario/{idUsuario}` },
  redefinirSenhaPadraoTradelinks: { method: 'PUT', url: `${basePath}/senha/redefinicao-senha-padrao/usuario/{idUsuario}` },
  listarAtivosResumido: { method: 'GET', url: `${basePath}/resumido?nome={nome}&indAtivo=true{&ids*}` },
  listarTodos: { method: 'GET', url: `${basePath}?nome={nome}` },
  buscaUltimaAlteracao: { method: 'GET', url: `${basePath}/ultima-alteracao/{idUsuario}` },
  dadosFluxosAprovacaoImpactados: { method: 'GET', url: `${basePath}/dados-fluxos-aprovacao-impactados/{idUsuario}` },
  buscaTermoAceiteUsuarioCadastro: { method: 'GET', url: `${basePath}/busca-termo-aceite-cadastro/{idUsuario}` },
  aprovarUsuarioMaster: { method: 'PUT', url: `${basePath}/aprovar-usuario-master` },
  buscarFluxoAprovacao: { method: 'GET', url: `${basePath}/buscar-fluxo-aprovacao/{idUsuario}` },
  verificarRepresentacaoConjunto: { method: 'GET', url: `${basePath}/verificar-rep-conjunto/{idGrupoFornecedor}` },
  atualizarRepresentacaoConjunto: { method: 'PUT', url: `${basePath}/atualizar-rep-conjunto/{idGrupoFornecedor}/{indRepresentacaoConjunto}` },
  buscarNoOmni: { method: 'GET', url: `${basePath}/{tipo}/omni/{email}` },

};

export default (resource) => resource(`${basePath}`, {}, usuarioActions);
