<template>
  <v-dialog v-model="dialog" :persistent="true" :max-width="350" :min-width="350">
    <tenant-card @TenantCard_selecionarTenant="selecionarTenant"/>
  </v-dialog>
</template>

<script>
import TenantCard from '@/produto/spa/tenant/TenantCard';

export default {
  name: 'TenantDialog',
  components: { TenantCard },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    selecionarTenant(tenantID) {
      this.$emit('TenantDialog_selecionarTenant', tenantID);
      this.close();
    },
  },
};
</script>
