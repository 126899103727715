<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card class="card-size">
      <v-card-title>
        <h2 style="font-size: 18px; width: 100%" class="mb-0">{{ centroCusto.ehEdicao() ? $t('title.editar_centro_custo') : $t('title.cadastrar_novo_centro_custo') }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          :metadados-entidade="metadadosCentroCusto"
          :objeto="centroCusto"
          :input-layout="inputLayout"
          :layout-class="layoutClass"
          v-if="metadadosCentroCusto"
          ref="container">
          <v-col cols="12" slot="antes">
            <v-text-field
              class="Form-text__invalid"
              id="centroCusto_code"
              name="centroCusto_code"
              v-model="centroCusto.idExterno"
              :label="`${$t('label.cod_centro_custo')} *`"
              :counter="10"
              maxlength="10"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="12" slot="antes">
            <v-text-field
              class="Form-text__invalid"
              id="centroCusto_nome"
              name="centroCusto_nome"
              v-model="centroCusto.nome"
              :label="`${$t('label.nome')} *`"
              :counter="100"
              maxlength="100"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
        </metadados-container-layout>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import CentroCusto from './CentroCusto';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';
import { montarEstruturaDinamica } from '../../common/functions/metadados';

export default {
  name: 'CentroCustoForm',
  data() {
    return {
      centroCusto: new CentroCusto(),
      extensaoResources: this.$api.extensao(this.$resource),
      centroCustoResources: this.$api.centroCusto(this.$resource),
      inputLayout: {
        xs12: true,
        sm12: true,
        md12: true,
        sm6: false,
        md3: false,
        md4: false,
        md6: false,
        md9: false,
      },
      layoutClass: { wrap: true },
      listaUnidadesNegocio: [],
      vlrSelecaoEstrutura: {},
      dialog: {
        content: '',
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  watch: {
    searchInputCentroCusto(val) {
      if (this.usuario && this.usuario.idCentroCusto) {
        const selecao = this.centroCusto
          .filter((est) => est.id === this.usuario.idCentroCusto);
        if (selecao.length > 0 && selecao[0].nomCentroCusto === val) {
          return;
        }
      }

      if (this.timeoutCentroCusto) {
        window.clearTimeout(this.timeoutCentroCusto);
      }
      this.timeoutCentroCusto = window.setTimeout(() => {
        if (val != null) this.buscarCentroCusto(val);
      }, 500);
    },
  },
  components: {
    Confirm,
    MetadadosContainerLayout,
  },
  computed: {
    ...mapGetters('metadados', [
      'getCentroCustoMetadado',
    ]),
    metadadosCentroCusto() {
      if (this.getCentroCustoMetadado && this.getCentroCustoMetadado.mapaEntidades) {
        return this.getCentroCustoMetadado;
      }
      return undefined;
    },
  },
  methods: {
    buscarCentroCusto(content, idCentroCusto) {
      const param = {
        autocomplete: content,
      };
      if (idCentroCusto) {
        param.id = idCentroCusto;
      }
      return this.centroCustoResource.selecao(param)
        .then((res) => {
          this.centrosCusto = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancel() {
      this.limparFormulario();
      this.$emit('FORMULARIO_CADASTRO_CENTRO_CUSTO_CANCELAR');
    },
    editar(centroCusto) {
      this.centroCusto = new CentroCusto({ ...centroCusto });
      montarEstruturaDinamica(centroCusto, this.metadadosCentroCusto, this.centroCusto);
    },
    limparFormulario() {
      this.centroCusto = new CentroCusto();
      this.$refs.form.reset();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.dialog.content = this.$t('message.salvar');
      this.$refs.confirmDialog.open();
    },
    save() {
      const objArmazenamento = {
        ...this.centroCusto,
        ...this.$refs.container.getValoresDependencias(),
      };

      let salvarOuAtualizar;
      let inclusao = true;
      if (this.centroCusto.id != null) {
        inclusao = false;
        salvarOuAtualizar = this.centroCustoResources.atualizar(objArmazenamento);
      } else {
        salvarOuAtualizar = this.centroCustoResources.gravar(objArmazenamento);
      }

      salvarOuAtualizar.then(() => this.limparFormulario())
        .then(() => this.$toast(this.$t((inclusao ? 'message.adicionado_confira_tabela' : 'message.atualizado_confira_tabela'))))
        .then(() => {
          this.$emit('FORMULARIO_CADASTRO_CENTRO_CUSTO_SALVAR', { ...this.centroCusto });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {},
};
</script>
