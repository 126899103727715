const mutations = {
  alterarMensagem(state, anoFiscal) {
    if (!state.anoFiscal[anoFiscal.idTipoVerba]) state.anoFiscal[anoFiscal.idTipoVerba] = {};
    state.anoFiscal[anoFiscal.idTipoVerba].mensagem = anoFiscal.mensagem;
  },
  alterarNaoLimparMensagem(state, anoFiscal) {
    if (!state.anoFiscal[anoFiscal.idTipoVerba]) state.anoFiscal[anoFiscal.idTipoVerba] = {};
    state.anoFiscal[anoFiscal.idTipoVerba].naoLimparMensagem = anoFiscal.naoLimparMensagem;
  },
};

const actions = {
  alterarMensagem({ commit }, anoFiscal) {
    commit('alterarMensagem', anoFiscal);
  },
  alterarNaoLimparMensagem({ commit }, anoFiscal) {
    commit('alterarNaoLimparMensagem', anoFiscal);
  },
};

const getters = {
  getAnosFiscal(state) {
    return state.anoFiscal;
  },
};

const state = {
  anoFiscal: {
    1: {
      mensagem: '',
      naoLimparMensagem: false,
    },
    2: {
      mensagem: '',
      naoLimparMensagem: false,
    },
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
