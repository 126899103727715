export const prepareResponseData = (response) => {
  if (response.data && response.data.hasOwnProperty('resposta')) {
    return response.data.resposta;
  }
  return response.data;
};

export const extractRequestData = (request) => {
  if (request.data.hasOwnProperty('data')) {
    return request.data.data;
  }
  return request.data;
};

export const removeParametrosNulos = (parametros) => {
  Object.keys(parametros)
    .forEach((k) => (!parametros[k] && parametros[k] !== undefined) && delete parametros[k]);
};

export default prepareResponseData;
