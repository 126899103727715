<template>
  <v-navigation-drawer
    v-model="abrirSidebar"
    left
    width="300"
    hide-overlay
    clipped
    temporary
    disable-resize-watcher
    app>
    <div>
      <v-card-title v-if="title">{{ title }}</v-card-title>
      <v-card-text v-if="subtitle">{{ subtitle }}</v-card-text>
        <dropzone-form
          v-if="render"
          ref="UploadArquivo__dropzone"
          :id="`UploadArquivo__dropzone_${idEntidade}`"
          :name="`UploadArquivo__dropzone_${idEntidade}`"
          :accepted-file-types="getAcceptedFileTypes"
          :maxFileSizeInMB="getMaxFileSizeInMB"
          :url="url"
          :auto-process-queue="autoProcessQueue"
          @UPLOADFILE_FILE_ADDED="fileAdded"
          @UPLOADFILE_FILE_REMOVED="fileRemoved"
          @UPLOADFILE_SUCCESS="uploadSuccess"
          @UPLOADFILE_ERROR="uploadError"
          @UPLOADFILE_SENDING="uploadSending"
          :custom-validate="customValidate">

          <template v-slot:campos>
            <slot></slot>
          </template>

          <template v-slot:botaoSecundario>
            <v-btn @click="close" class="mr-3">{{ $t('label.fechar') }}</v-btn>
          </template>
        </dropzone-form>
    </div>
  </v-navigation-drawer>
</template>

<script>
import DropzoneForm from './DropzoneForm';

export default {
  name: 'UploadFileForm',
  props: {
    title: String,
    subtitle: {
      type: String,
      default: '',
    },
    url: String,
    acceptedFileTypes: {
      type: String,
      default: null,
    },
    maxFileSizeInMB: {
      type: Number,
      default: null,
    },
    autoProcessQueue: {
      type: Boolean,
      default: true,
    },
    timeout: {
      type: Number,
      default: 120000,
    },
    customValidate: Function,
    idEntidade: Number,
  },
  data() {
    return {
      abrirSidebar: false,
      defaultValueFromDatabase: null,
      defaultSizeFromDatabase: null,
      render: false,
      resource: this.$api.arquivoConfiguracao(this.$resource),
    };
  },
  computed: {
    uploadFileDropzoneId() {
      return `myVueDropzone${(Math.random() * Math.random() * 100).toString().replace('.', '')}`;
    },
    getAcceptedFileTypes() {
      return this.acceptedFileTypes || this.defaultValueFromDatabase;
    },
    getMaxFileSizeInMB() {
      return this.maxFileSizeInMB || this.defaultSizeFromDatabase;
    },
  },
  components: {
    DropzoneForm,
  },
  methods: {
    close(ref) {
      console.log(`Closed: ${ref}`); // eslint-disable-line
      this.abrirSidebar = false;
    },
    open(ref) {
      console.log(`Opened: ${ref}`); // eslint-disable-line
      if (this.$refs.UploadArquivo__dropzone) {
        this.$refs.UploadArquivo__dropzone.resetForm();
      }
      this.abrirSidebar = true;
    },
    fileAdded(file) {
      this.$emit('UPLOADFILE_FILE_ADDED', file);
    },
    fileRemoved(...params) {
      this.$emit('UPLOADFILE_FILE_REMOVED', ...params);
    },
    uploadError(file, msg) {
      this.$emit('UploadArquivo__error', msg);
    },
    uploadSuccess() {
      this.$emit('UploadArquivo__success');
    },
    uploadSending(...params) {
      this.$emit('UPLOADFILE_SENDING', ...params);
    },
    habilitaRenderizacao() {
      this.render = true;
    },
    buscarFormatos() {
      return this.resource.buscarFormatosAceitos()
        .then(({ bodyText }) => {
          if (bodyText) {
            this.defaultValueFromDatabase = bodyText;
          }
        });
    },
    buscarTamanhoArquivo() {
      return this.resource.buscarLimitDeTamanhoArquivo()
        .then(({ body }) => {
          if (body) {
            this.defaultSizeFromDatabase = body;
          }
        });
    },
  },
  mounted() {
    this.buscarFormatos()
      .then(this.buscarTamanhoArquivo)
      .finally(this.habilitaRenderizacao);
  },
};
</script>
