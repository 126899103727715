import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('api', 'configuracao/contrato');

const configuracaoWorkflowContratoActions = {
  atualizarTipoAcordo: { method: 'PUT', url: `${basePath}/{nomeAcordo}?valorSelecao={valorSelecao}` },
  atualizarTipoProvisao: { method: 'PUT', url: `${basePath}/tipo-provisao/{nomeAcordo}` },
  pesquisarTipoProvisao: { method: 'GET', url: `${basePath}/tipo-provisao/{nomeAcordo}` },
  atualizarConfiguracoesContrato: { method: 'PUT', url: `${basePath}` },
  atualizarSubConfiguracoesContrato: { method: 'PUT', url: `${basePath}/sub-configuracao` },
  pesquisar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  pesquisarConfiguracoesContrato: { method: 'GET', url: `${basePath}/{nomeAcordo}` },
  pesquisarRoiBaseCalculo: {
    method: 'GET',
    url: `${basePath}/{nomeAcordo}/roi/base-calculo`,
  },
  pesquisarBaseCalculo: {
    method: 'GET',
    url: `${basePath}/{nomeAcordo}/base-calculo`,
  },
  pesquisarBonificacao: {
    method: 'GET',
    url: `${basePath}/{nomeAcordo}/bonificacao`,
  },
  pesquisarMetasBeneficios: {
    method: 'GET',
    url: `${basePath}/{nomeAcordo}/metas-beneficios`,
  },
  obtemValorConfiguracao: {
    method: 'GET',
    url: `${basePath}/{nomeAcordo}/configuracao/{nomeConfig}`,
  },
  obtemValorSubConfiguracao: {
    method: 'GET',
    url: `${basePath}/{nomeAcordo}/sub-configuracao/{nomeConfig}`,
  },
};
export default (resource) => resource(`${basePath}`, {}, configuracaoWorkflowContratoActions);
