export default (context) => [
  {
    nome: context.$tc('label.descricao', 1), contexto: 'RESUMO', obrigatorio: true, label: 'descricao', apenas_ordenacao: false, habilita_grid: true, desativa_filtro: true, desativa_grid: true,
  },
  {
    nome: context.$tc('label.unidade_negocio', 1), contexto: 'RESUMO', obrigatorio: true, label: 'unidadenegocio', apenas_ordenacao: false, habilita_grid: false, habilita_filtro: false, desativa_filtro: false, desativa_grid: false,
  },
  {
    nome: context.$tc('label.periodo', 1), contexto: 'RESUMO', obrigatorio: true, label: 'periodo', apenas_ordenacao: false, habilita_grid: true, habilita_filtro: true, desativa_filtro: true, desativa_grid: true,
  },
  {
    nome: context.$tc('label.descricao_linha_investimento', 1), contexto: 'LINHA', obrigatorio: true, label: 'descricao_linha', apenas_ordenacao: false, desativa_filtro: true, habilita_filtro: false,
  },
  {
    nome: context.$tc('label.tipo_investimento', 1), contexto: 'LINHA', obrigatorio: true, label: 'tipo_investimento', apenas_ordenacao: false, desativa_filtro: true, habilita_filtro: true,
  },
  {
    nome: context.$tc('label.status', 1), contexto: 'RESUMO', obrigatorio: true, label: 'status', apenas_ordenacao: false, habilita_grid: true, habilita_filtro: true, desativa_filtro: false, desativa_grid: true,
  },
  {
    nome: context.$tc('label.planejado', 1), contexto: 'RESUMO', obrigatorio: true, label: 'planejado', apenas_ordenacao: false, habilita_grid: true, desativa_filtro: true, desativa_grid: true,
  },
  {
    nome: context.$tc('label.remanescente', 1), contexto: 'RESUMO', obrigatorio: true, label: 'remanescente', apenas_ordenacao: false, desativa_filtro: true, desativa_grid: false,
  },
  {
    nome: context.$tc('label.reservado', 1), contexto: 'RESUMO', obrigatorio: true, label: 'reservado', apenas_ordenacao: true, desativa_filtro: true, desativa_grid: false,
  },
  {
    nome: context.$tc('label.comprometido', 1), contexto: 'RESUMO', obrigatorio: true, label: 'comprometido', apenas_ordenacao: true, desativa_filtro: true, desativa_grid: false,
  },
  {
    nome: context.$tc('label.apurado', 1), contexto: 'RESUMO', obrigatorio: true, label: 'apurado', apenas_ordenacao: true, desativa_filtro: true, desativa_grid: false,
  },
  {
    nome: context.$tc('label.consumido', 1), contexto: 'RESUMO', obrigatorio: true, label: 'consumido', apenas_ordenacao: true, habilita_grid: true, desativa_filtro: true, desativa_grid: true,
  },
  {
    nome: context.$tc('label.adendo', 1), contexto: 'RESUMO', obrigatorio: true, label: 'adendo', apenas_ordenacao: true, desativa_filtro: true, desativa_grid: false,
  },
  {
    nome: context.$tc('label.reducao', 1), contexto: 'RESUMO', obrigatorio: true, label: 'reducao', apenas_ordenacao: true, desativa_filtro: true, desativa_grid: false,
  },
  {
    nome: context.$tc('label.saldo', 1), contexto: 'RESUMO', obrigatorio: true, label: 'saldo', apenas_ordenacao: true, habilita_grid: true, desativa_filtro: true, desativa_grid: true,
  },
  {
    nome: context.$tc('label.periodo_orcamentario', 1), contexto: 'RESUMO', obrigatorio: true, label: 'periodo_orcamentario', apenas_ordenacao: false, habilita_grid: false, desativa_filtro: false, desativa_grid: false,
  },
  {
    nome: context.$tc('label.divisao', 1), contexto: 'RESUMO', obrigatorio: true, label: 'divisao', apenas_ordenacao: false, habilita_grid: false, habilita_filtro: false, desativa_filtro: false, desativa_grid: true,
  },
];
