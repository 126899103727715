<template>
  <v-form ref="form" lazy-validation>
    <v-card class="mb-1" :class="{'ml-1': inputLayout.ml1, 'mt-1': inputLayout.mt1, 'mt-2': inputLayout.mt2 }">
      <v-card-title>
        <h2 style="font-size: 18px; width: 100%" class="mb-0">
          {{ `${$t('label.cadastrar_editar')} ${indRecursiva ? $t('label.hierarquia_de') : ''} ${$t('label.' + tipoCadastroSemAcento)}` }}
        </h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <slot name="contentPreForm"></slot>
        <v-row>
          <v-col cols="12" v-if="mostrarIdExterno">
            <v-text-field
              class="Form-text__invalid"
              id="nome"
              name="nome"
              v-model="objeto.idExterno"
              :label="`${$t('label.codigo')} *`"
              :counter="100"
              maxlength="100"
              :rules="[rules.required]"
              :disabled="somenteLeitura">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="nome_interno"
              name="nome_interno"
              v-model="objeto.nomExtensao"
              :label="`${$t('label.descricao')} *`"
              :counter="100"
              maxlength="100"
              :rules="[rules.required]"
              :disabled="somenteLeitura">
            </v-text-field>
          </v-col>
          <slot name="contentPosForm"></slot>
          <v-col cols="12">
            <v-switch :label="$t('label.ativo')" color="primary" v-show="mostrarAtivacao" v-model="objeto.indAtivo" :disabled="somenteLeitura" class="mt-3"></v-switch>
          </v-col>
        </v-row>
      </v-container>
      <slot name="button">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="cancelar" :disabled="somenteLeitura">{{ $t('label.cancelar') }}</v-btn>
          <v-btn @click.native="abrirDialogSalvar" color="primary" :disabled="somenteLeitura">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </slot>
    </v-card>
    <confirm
      ref="confirmDialog"
      :message="`${$t('message.deseja_salvar', {text: $t('label.' + tipoCadastro)})}`"
      :persistent="true"
      @agree="salvar">
    </confirm>
  </v-form>
</template>

<script>
import { copyObject } from '../../common/functions/helpers';
import Confirm from '../vuetify/dialog/Confirm';

export default {
  name: 'MetadadosExtensaoForm',
  components: {
    Confirm,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    idNivelExtensao: {
      type: Number,
      default: null,
    },
    mostrarAtivacao: {
      type: Boolean,
      default: true,
    },
    mostrarIdExterno: {
      type: Boolean,
      default: true,
    },
    tipoCadastro: {
      type: String,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    inputLayout: {
      type: Object,
      default: () => ({
        mt1: true,
        ml1: true,
        mt2: false,
      }),
    },
    indRecursiva: Boolean,
    idsCnpjsMatriz: {
      type: Array,
    },
  },
  data() {
    return {
      objeto: {
        id: null,
        idExterno: null,
        nomExtensao: null,
        indAtivo: true,
        idNivelExtensao: null,
        idsCnpjsMatriz: null,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      resources: this.$api.extensao(this.$resource),
    };
  },
  computed: {
    tipoCadastroSemAcento() {
      return this.tipoCadastro.normalize('NFD').replace(/\s/g, '_').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    },
  },
  methods: {
    abrirDialogSalvar() {
      if (!this.$refs.form.validate()) return;
      this.abrirSalvar();
    },
    abrirSalvar() {
      this.$refs.confirmDialog.open();
    },
    cancelar() {
      this.limparFormulario();
      this.$emit('cancelar');
    },
    carregar() {
      const { id } = this;
      this.resources.obter({ id }).then((response) => {
        this.objeto = response.data;
      }, (err) => {
        this.$error(this, err);
        this.cancelar();
      });
    },
    iniciarEdicao(campo) {
      this.objeto = copyObject(campo);
      this.$emit('edit', campo);
    },
    limparFormulario() {
      this.objeto.indAtivo = false;
      this.resetarForm();
      this.$emit('limparId');
      setTimeout(() => {
        this.objeto.indAtivo = true;
      });
    },
    prepararObjeto() {
      this.objeto.id = this.id;
      this.objeto.idNivelExtensao = this.idNivelExtensao;
      this.objeto.idsCnpjsMatriz = this.idsCnpjsMatriz;
      if (!this.mostrarIdExterno) {
        this.objeto.idExterno = this.objeto.nomExtensao;
      }
      return this.objeto;
    },
    resetarForm() {
      this.$refs.form.reset();
    },
    salvar() {
      if (this.id) {
        this.resources.atualizar({ id: this.id }, this.prepararObjeto())
          .then(() => {
            this.$toast(this.$t('message.atualizado_confira_tabela'));
            this.limparFormulario();
            this.$emit('atualizarGrid');
          }, (err) => {
            this.$error(this, err);
          });
      } else {
        this.resources.gravar(this.prepararObjeto())
          .then((response) => {
            this.$toast(this.$t('message.adicionado_confira_tabela'));
            this.limparFormulario();
            this.$emit('atualizarGrid', response.data);
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    validarFormulario() {
      return this.$refs.form.validate();
    },
    limparValidacoes() {
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    if (this.id) {
      this.carregar();
    }
  },
};
</script>
<style lang="scss">
  .combo_opcoes .v-input__append-inner {
    display: none;
  }
</style>
