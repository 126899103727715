import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('apuracao_acao', 'pagamento');

const apuracaoAcaoPagamentoActions = {
  salvar: { method: 'POST', url: `${basePath}` },
  atualizar: { method: 'PUT', url: `${basePath}` },
  deletar: { method: 'DELETE', url: `${basePath}` },
  buscar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  buscarEvidencias: { method: 'GET', url: `${basePath}/buscar-arquivo` },
  buscaContasVinculadas: { method: 'GET', url: `${basePath}/{idApuracaoPagamento}/contas` },
  buscaContasVinculadasApuracao: { method: 'GET', url: `${basePath}/apuracao/{idApuracao}/contas` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoAcaoPagamentoActions);
