<template>
  <div>
    <dashboard-inicial-varejo/>
  </div>
</template>

<script type="text/javascript">

import DashboardInicialVarejo from './varejo/DashInicialVarejo';

export default {
  name: 'DashboardInicial',
  components: {
    DashboardInicialVarejo,
  },
};
</script>
