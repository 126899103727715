import getBasePath from '@/produto/common/functions/api-resource';

const basePath = getBasePath('api', 'notas/inconsistencia');

const notasActions = {
  listarNotasInconsistenciaAgrupado: { methods: 'GET', url: `${basePath}/consolidado` },
  listarNotasInconsistenciaDetalhado: { methods: 'GET', url: `${basePath}/detalhado?tamanhoPagina={size}&numeroPagina={page}` },
};

export default (resource) => resource(`${basePath}`, {}, notasActions);
