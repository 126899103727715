import getBasePath from '../functions/api-resource';

const basePath = getBasePath('api', 'metadados/entidade');

const marcaActions = {
  listarCliente: { method: 'GET', url: `${basePath}/cliente` },
  listarCarteiraCliente: { method: 'GET', url: `${basePath}/carteira_cliente` },
  listarProduto: { method: 'GET', url: `${basePath}/produto` },
  listarUsuario: { method: 'GET', url: `${basePath}/usuario` },
  listarAcaoTatica: { method: 'GET', url: `${basePath}/acao_tatica` },
  listarPlanejamentoMaterial: { method: 'GET', url: `${basePath}/planejamento-material` },
  listarRelatorio: { method: 'GET', url: `${basePath}/relatorio` },
  listarDivisao: { method: 'GET', url: `${basePath}/divisao` },
  listarFornecedor: { method: 'GET', url: `${basePath}/fornecedor` },
  listarApuracao: { method: 'GET', url: `${basePath}/apuracao` },
  definicaoOrcamento: { method: 'GET', url: `${basePath}/orcamento` },
  definicaoLinhaInvestimento: { method: 'GET', url: `${basePath}/linha_investimento` },
  definicaoCentroCusto: { method: 'GET', url: `${basePath}/ceco` },
  definicaoAcao: { method: 'GET', url: `${basePath}/acao` },
  definicaoContrato: { method: 'GET', url: `${basePath}/contrato` },
  definicaoFilial: { method: 'GET', url: `${basePath}/filial` },
  definicaoPagamento: { method: 'GET', url: `${basePath}/pagamento` },
};

export const marcaResource = (resource) => resource(`${basePath}`, {}, marcaActions);

export default marcaResource;
