<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title class="headline">{{ $t('title.editar') }}</v-card-title>
      <v-card-text class="justify">
        <span class="grey--text caption">
          {{ `${$tc('label.valor_original')}: ${itemSelecionado.valor}`}}
        </span>
        <div class="mt-4">
          <v-form lazy-validation ref="form">
            <v-text-field
              v-if="itemSelecionado.formato === 'text'"
              :label="$tc('label.novo_valor')"
              v-model="itemSelecionado.novoValor"
              :type="itemSelecionado.formato"
            >
            </v-text-field>
            <v-text-field
              v-else-if="itemSelecionado.formato === 'number'"
              :label="$tc('label.novo_valor')"
              v-model="itemSelecionado.novoValor"
              :type="itemSelecionado.formato"
            >
            </v-text-field>
            <v-switch
              v-else-if="itemSelecionado.formato === 'boolean'"
              v-model="itemSelecionado.novoValor"
              :label="$tc('label.ativar') + ' / ' + $tc('label.inativar')">
            </v-switch>
            <v-menu
              v-else-if="itemSelecionado.formato === 'data'"
              ref="data"
              :close-on-content-click="false"
              v-model="menuData"
              id="data"
              name="data"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="itemSelecionado.novoValor"
                  :label="$tc('label.nova_data')"
                  readonly
                  clearable
                  prepend-inner-icon="event"
                >
                </v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                color="primary"
                v-model="data"
                @input="inputData()"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" text color="grey"> {{ $t('label.cancelar') }} </v-btn>
        <v-btn @click="salvar()" text color="primary"> {{ $t('label.salvar') }} </v-btn>
      </v-card-actions>
</v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'JustificativaFluxo',
  props: {
    title: '',
  },
  data() {
    return {
      dialog: false,
      menuData: false,
      data: null,
      itemSelecionado: {},
    };
  },
  methods: {
    open(item) {
      this.dialog = true;
      this.itemSelecionado = item;
      if (this.itemSelecionado.formato === 'boolean') {
        this.itemSelecionado.novoValor = String(this.itemSelecionado.valor).toLocaleLowerCase() === 'true';
      }
    },
    close() {
      this.dialog = false;
      delete this.itemSelecionado.novoValor;
    },
    salvar() {
      if (this.itemSelecionado.formato === 'boolean' && typeof this.itemSelecionado.novoValor === 'undefined') {
        this.itemSelecionado.novoValor = false;
      }
      this.$emit('ATUALIZAR_CONFIGURACAO', this.itemSelecionado);
      this.dialog = false;
    },
    inputData() {
      this.itemSelecionado.novoValor = `${this.data} 00:00:00`;
      this.menuData = false;
    },
  },
};
</script>
