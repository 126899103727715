import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('usuario', 'controlador-trade/transferencia-posse');

const transferenciaPosseActions = {
  listarTemporarios: { method: 'GET', url: `${basePath}` },
  listarHistorico: { method: 'GET', url: `${basePath}/historico` },
  listarDivisoes: { method: 'GET', url: `${basePath}/divisao?nome={nome}&{&idNotIn*}` },
  transferir: { method: 'POST', url: `${basePath}` },
  atualizarTransferencia: { method: 'PUT', url: `${basePath}/{idTransferencia}` },
};

export default (resource) => resource(`${basePath}`, {}, transferenciaPosseActions);
