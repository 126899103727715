<template lang="html">
  <v-dialog v-model="showModal" max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ titulo }}
        </span>
      </v-card-title>
      <v-row class="pl-6">
        <v-col cols="7">
          <div class="pl-6" v-if="isAcao && dadosModalAcompanhamento">
            <span>{{$t('label.id_acao')}}: {{dadosModalAcompanhamento.idAcao}}</span>
          </div>
          <div class="pl-6" v-if="isAcao && dadosModalAcompanhamento">
            <span>{{$t('label.modalidade')}}: {{formataTextoModalidade(dadosModalAcompanhamento.modalidade)}}</span>
          </div>
          <div class="pl-6" v-if="isContrato && dadosModalAcompanhamento">
            <span>{{$t('label.id_contrato')}}: {{dadosModalAcompanhamento.idContrato}}</span>
          </div>
          <div class="pl-6" v-if="isApuracao && dadosModalAcompanhamento">
            <span>{{$t('label.modalidade')}}: {{formataTextoModalidade(dadosModalAcompanhamento.modalidade)}}</span>
          </div>
          <div class="pl-6" v-if="isApuracao && dadosModalAcompanhamento">
            <span>{{$t('label.id_acao')}}: {{dadosModalAcompanhamento.idAcao}}</span>
          </div>
          <div class="pl-6" v-if="isApuracao && dadosModalAcompanhamento">
            <span>{{$t('label.nds')}}: {{formatarValoresNotaDebito(dadosModalAcompanhamento.numNotaDebito)}}</span>
            <v-btn
            v-if="visualizaIconeModalNotaDebito(dadosModalAcompanhamento.countNotasDebito)"
            icon
            class="mx-0"
            @click.native="visualizarNotasDebito(dadosModalAcompanhamento)" >
              <v-icon>visibility</v-icon>
            </v-btn>
          </div>
          <div class="pl-6" v-if="exibeDataEmissao">
            <span>{{$t('label.data_emissao')}}: {{dadosModalAcompanhamento.emissao.dataEmissao}}</span>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="ml-2 mb-1"
                    v-on="on"
                    small
                    v-bind:style="{color: getCollorIcon(dadosModalAcompanhamento)}">
                    lens
                  </v-icon>
                </template>
                {{dadosModalAcompanhamento.emissao.regraDataEmissao}}
              </v-tooltip>
          </div>
        </v-col>
        <v-col cols="5" class="d-flex flex-column justify-center align-center">
          <v-btn v-if="isContrato && dadosModalAcompanhamento" color="primary" small @click.native="exportarHistoricoAprovacao(dadosModalAcompanhamento.idContrato, entidadeContrato.toLowerCase())">
            {{ $t('label.historico_de_aprovacoes') }}
            <v-icon small>download</v-icon>
          </v-btn>
          <v-btn v-if="isApuracao && dadosModalAcompanhamento" color="primary" small @click.native="exportarHistoricoAprovacao(dadosModalAcompanhamento.idApuracao, entidadeApuracao.toLowerCase())">
            {{ $t('label.historico_de_aprovacoes') }}
            <v-icon small>download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text>
        <v-timeline dense class="mx-3 my-1 pt-0">
          <v-timeline-item
              :color="colorPalette[0]"
              fill-dot
              small
              right
              v-if="fluxo.cabecalhoFluxo">

            <v-card class="elevation-2">
              <v-card-title class="title historico_header px-3 py-2">{{$t('label.solicitado_por') + ' ' + fluxo.cabecalhoFluxo.usuarioSolicitante.nome + ' '
                + fluxo.cabecalhoFluxo.usuarioSolicitante.sobrenome}}</v-card-title>
              <v-card-text>
                <v-row class="px-3 py-2">
                  <span><b class="pr-1">{{$t('label.descricao')}}:</b></span>
                  <span>{{fluxo.cabecalhoFluxo.descricao}}</span>
                </v-row>
                <v-row class="px-3 py-2">
                  <span><b class="pr-1">{{$tc('label.perfil', 1)}}:</b></span>
                  <span>{{fluxo.cabecalhoFluxo.usuarioSolicitante.nomePerfil}}</span>
                </v-row>
                <v-row class="px-3 py-2">
                  <span><b class="pr-1">{{$t('label.data_solicitacao')}}:</b></span>
                  <span>{{toDate(fluxo.cabecalhoFluxo.dtaSolicitacao)}}</span>
                </v-row>
                <v-row
                  v-if="fluxo && fluxo.cabecalhoFluxo && fluxo.cabecalhoFluxo.observacaoAcao"
                  class="px-3 py-2">
                  <span><b class="pr-1">{{$t('label.justificativa')}}:</b></span>
                  <span>{{ fluxo.cabecalhoFluxo.observacaoAcao }}</span>
                </v-row>
              </v-card-text>
            </v-card>

          </v-timeline-item>

          <v-timeline-item
            v-for="(item, index) in fluxo.passos"
            :color="styles(item.statusPasso)" fill-dot small right
            v-if="exibePasso(item)" :key="index">

            <template slot="icon">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }"></template>
                <span>
                  {{$tc(`status_entidade.${item.statusPasso.toLowerCase()}`, 1)}}
                </span>
              </v-tooltip>
            </template>

            <acompanhamento-item-passos-paralelos
              v-if="possuiPassosParalelos(item)"
              :key="`passo-pararelo-${item.id}`"
              :chave="`passo-pararelo-${item.id}`"
              :passo-paralelo-pai="item"
              :cliente="fluxo.cliente"
              :entidade="entidade"
              :fluxo="fluxo"
              :dados-modal-acompanhamento="dadosModalAcompanhamento"
              :id-entidade="fluxo.idEntidade">
            </acompanhamento-item-passos-paralelos>

            <acompanhamento-item
              v-else
              :key="`passo-${item.id}`"
              :chave="`passo-${item.id}`"
              :item="item"
              :is-passo-paralelo="false"
              :cliente="fluxo.cliente"
              :entidade="entidade"
              :id-entidade="fluxo.idEntidade">
            </acompanhamento-item>

          </v-timeline-item>
        </v-timeline>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" @click.native="fechar">{{ $t('label.fechar') }}</v-btn>
      </v-card-actions>
    </v-card>
    <modal-nota-debito
    ref="modalNotaDebito">
    </modal-nota-debito>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import { removeEmptyProperties, copyObject } from '@/produto/common/functions/helpers';
import exportacao from '@/produto/common/functions/exportacao';
import ModalNotaDebito from '@/produto/spa/apuracao-acao-lista/ModalNotaDebito';
import { countExportacaoHistoricoAprovacao } from '@/common/resources/magalu-relatorios';
import moment from 'moment';
import AcompanhamentoItem from './AcompanhamentoItem';
import AcompanhamentoItemPassosParalelos from './AcompanhamentoItemPassosParalelos';

export default {
  name: 'Acompanhamento',
  data() {
    return {
      showModal: false,
      countNotasExibicao: 4,
      entidadeContrato: 'CONTRATO',
      entidadeApuracao: 'APURACAO',
      colorPalette: [
        'teal accent-4', // Verde
        'red lighten-1', // Vermelho
        'orange darken-2', // Laranja
        'amber lighten-1', // Amarelo
        'blue-grey lighten-2', // Cinza
        'blue darken-1', // Azul
      ],
    };
  },
  props: {
    fluxo: Object,
    entidade: String,
    dadosModalAcompanhamento: Object,
  },
  components: {
    AcompanhamentoItem,
    AcompanhamentoItemPassosParalelos,
    ModalNotaDebito,
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    titulo() {
      const tituloBase = this.$t('label.acompanhamento_fluxo');
      return this.fluxo && this.fluxo.cabecalhoFluxo
        ? `${tituloBase}  - ${this.fluxo.cabecalhoFluxo.tipoFluxo}`
        : tituloBase;
    },
    isContrato() {
      return this.entidade === 'CONTRATO';
    },
    isApuracao() {
      return this.entidade === 'APURACAO';
    },
    isAcao() {
      return this.entidade === 'ACAO';
    },
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    passosFormatados() {
      const numSequenciaArray = this.fluxo.passos.map((passo) => passo.sequencia);
      const numPassosParalelos = numSequenciaArray.filter((numSequencia, idx) => numSequenciaArray.indexOf(numSequencia) !== idx);
      if (numPassosParalelos.length) {
        numPassosParalelos.forEach((seq) => {
          const passosParalelos = this.fluxo.passos
            .filter((passo) => passo.sequencia === seq && passo.usuariosAprovadores.usuarios.length)
            .sort(ordenarPassos);

          const passoParaleloFormatado = copyObject(this.fluxo.passos.find((passo) => passo.sequencia === seq));
          for (let i = 0; i < passoParaleloFormatado.usuariosAprovadores.usuarios.length; i += 1) {
            passoParaleloFormatado.usuariosAprovadores.usuarios[i].statusPassoParalelo = copyObject(passoParaleloFormatado.statusPasso);
          }

          const isTodosPassosParalelosAprovados = passosParalelos.every((passo) => passo.statusPasso === 'APROVADO');
          if (!isTodosPassosParalelosAprovados) {
            const passoNaoAprovado = passosParalelos.find((passo) => passo.statusPasso !== 'APROVADO');
            passoParaleloFormatado.statusPasso = passoNaoAprovado.statusPasso;
          }

          passosParalelos.filter((p) => p.id !== passoParaleloFormatado.id).forEach((passo) => {
            for (let i = 0; i < passo.usuariosAprovadores.usuarios.length; i += 1) {
              passo.usuariosAprovadores.usuarios[i].statusPassoParalelo = copyObject(passo.statusPasso);
            }
            passoParaleloFormatado.usuariosAprovadores.usuarios.push(passo.usuariosAprovadores.usuarios);
          });
        });
        return [];
      }
      return this.fluxo.passos;
    },
    exibeDataEmissao() {
      return this.isAmbienteMagalu && this.dadosModalAcompanhamento && this.dadosModalAcompanhamento.emissao;
    },
  },
  methods: {
    open() {
      this.showModal = true;
    },
    fechar() {
      this.showModal = false;
    },
    toDate(data) {
      const formatos = ['DD/MM/YYYY HH:mm:ss', moment.ISO_8601];
      return moment(data, formatos, true).format('DD-MM-YYYY HH:mm:ss');
    },
    exibePasso(passo) {
      return passo.statusPasso !== 'CONDICAO_NAO_ATENDIDA' && passo.statusPasso !== 'TRANSFERIDO';
    },
    styles(status) {
      let colorIndex = 0;
      if (status === 'ENCERRADO') {
        colorIndex = 5;
      } else if (status === 'EM_CADASTRO') {
        colorIndex = 4;
      } else if (status === 'EM_FLUXO') {
        colorIndex = 4;
      } else if (status === 'CANCELADO') {
        colorIndex = 3;
      } else if (status === 'AGUARDANDO_APROVACAO') {
        colorIndex = 2;
      } else if (status === 'REPROVADO') {
        colorIndex = 1;
      } else if (status === 'AGUARDANDO_EXECUCAO_METAS') {
        colorIndex = 6;
      } else if (status === 'AGUARDANDO_APURACAO') {
        colorIndex = 5;
      } else if (status === 'AGUARDANDO_PAGAMENTO') {
        colorIndex = 5;
      } else if (status === 'APURACAO_PREVIA') {
        colorIndex = 4;
      } else if (status === 'EM_ANALISE') {
        colorIndex = 3;
      } else if (status === 'EM_REIVINDICACAO') {
        colorIndex = 2;
      } else {
        colorIndex = 0;
      }

      return this.color || this.colorPalette[colorIndex];
    },
    visualizaIconeModalNotaDebito(countNotasDebito) {
      return countNotasDebito > this.countNotasExibicao;
    },
    visualizarNotasDebito(item) {
      setTimeout(() => this.$refs.modalNotaDebito.open(item), 2E2);
    },
    formatarValoresNotaDebito(numNotaDebito) {
      if (numNotaDebito === null || numNotaDebito === undefined) {
        return null;
      }
      const numNotas = numNotaDebito.split(',');
      if (numNotas.length > this.countNotasExibicao) {
        return `${numNotas.slice(0, 4).join(',')}...`;
      }
      return numNotaDebito;
    },
    formataTextoModalidade(modalidade) {
      let modalidadeFormatada = null;
      if (modalidade === 'SELLIN') {
        modalidadeFormatada = this.$t('label.sellin');
      } else if (modalidade === 'SELLOUT') {
        modalidadeFormatada = this.$t('label.sellout');
      } else if (modalidade === 'FATURAMENTO_FORNECEDOR') {
        modalidadeFormatada = this.$t('label.faturamento_fornecedor');
      } else if (modalidade === 'VALOR_FIXO') {
        modalidadeFormatada = this.$t('label.valor_fixo');
      }
      return modalidadeFormatada;
    },
    ordenarPassos(a, b) {
      if (a.statusPasso === 'APROVADO' && b.statusPasso !== 'APROVADO') return -1;
      if (a.statusPasso === 'APROVADO' && b.statusPasso === 'APROVADO') {
        if (a.nome.toUpperCase() < b.nome.toUpperCase()) return -1;
        if (a.nome.toUpperCase() === b.nome.toUpperCase()) return 0;
        if (a.nome.toUpperCase() > b.nome.toUpperCase()) return 1;
      }
      if (a.statusPasso !== 'APROVADO' && b.statusPasso === 'APROVADO') return 1;
      return 0;
    },
    possuiPassosParalelos(item) {
      return item.usuariosAprovadores !== undefined && item.usuariosAprovadores.passosParalelos
        && item.usuariosAprovadores.passosParalelos.length;
    },
    exportarHistoricoAprovacao(idEntidade, nomeEntidade) {
      const params = {
        idEntidade,
        nomeEntidade,
      };
      let quantidadeRegistros = 0;

      countExportacaoHistoricoAprovacao(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            if (nomeEntidade === this.entidadeContrato.toLowerCase()) {
              params.id_contrato = idEntidade;
            } else {
              params.id_apuracao = idEntidade;
            }
            this.requestExportacao(params, 'historico_fluxo_aprovacao', 'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    requestExportacao(params, tipoRelatorio, modulo = 'api') {
      const filtroTratado = removeEmptyProperties(params);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
    getCollorIcon(acao) {
      if (acao.emissao && acao.emissao.calculo_bonificacao === 'VALOR_FIXO') {
        return acao.emissao.status_acao === 'APROVADO' ? '#1abc9c' : '#95a5a6';
      }
      if (acao.emissao && acao.emissao.status_apuracao) {
        return acao.emissao.status_apuracao === 'APROVADO' ? '#1abc9c' : '#95a5a6';
      }
      return '#95a5a6';
    },
  },
  mounted() {
  },
};
</script>
