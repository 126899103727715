import { mapActions } from 'vuex';

export default {
  name: 'AnoFiscalTipoEvento',
  data() {
    return {
      resources: this.$api.origemVerba(this.$resource),
      idTipoVerba: null,
    };
  },
  computed: {
    mensagem: {
      get() {
        const anosFiscal = (this.$store.state.anoFiscal
          || this.$store.state.store.anoFiscal).anoFiscal;
        if (anosFiscal[this.idTipoVerba]) return anosFiscal[this.idTipoVerba].mensagem;
        return anosFiscal.mensagem;
      },
      set(valor) {
        this.alterarMensagem({ idTipoVerba: this.idTipoVerba, mensagem: valor });
      },
    },
    naoLimparMensagem: {
      get() {
        const anosFiscal = (this.$store.state.anoFiscal
          || this.$store.state.store.anoFiscal).anoFiscal;
        if (anosFiscal[this.idTipoVerba]) return anosFiscal[this.idTipoVerba].naoLimparMensagem;
        return anosFiscal.naoLimparMensagem;
      },
      set(valor) {
        this.alterarNaoLimparMensagem({
          idTipoVerba: this.idTipoVerba,
          naoLimparMensagem: valor,
        });
      },
    },
    executandoProcedimento() {
      return !!this.mensagem;
    },
  },
  methods: {
    ...mapActions('anoFiscal', ['alterarMensagem', 'alterarNaoLimparMensagem']),
    fecharAnoFiscal(id, idEntidade) {
      this.fechar({ id, idEntidade, entidade: 'ANO' });
    },
    fecharPeriodo(id, idEntidade) {
      this.fechar({ id, idEntidade, entidade: 'PERIODO' });
    },
    bloquearAnoFiscal(id, idEntidade) {
      this.bloquear({ id, idEntidade, entidade: 'ANO' });
    },
    bloquearPeriodo(id, idEntidade) {
      this.bloquear({ id, idEntidade, entidade: 'PERIODO' });
    },
    desbloquearAnoFiscal(id, idEntidade) {
      this.desbloquear({ id, idEntidade, entidade: 'ANO' });
    },
    desbloquearPeriodo(id, idEntidade) {
      this.desbloquear({ id, idEntidade, entidade: 'PERIODO' });
    },
    bloquear(acao) {
      acao.acao = 'BLOQUEIO';
      this.executarAcao(acao, this.$t('message.bloqueio_periodo_fiscal_enviado'));
    },
    desbloquear(acao) {
      acao.acao = 'DESBLOQUEIO';
      this.executarAcao(acao, this.$t('message.desbloqueio_periodo_fiscal_enviado'));
    },
    fechar(acao) {
      acao.acao = 'FECHAMENTO';
      this.executarAcao(acao, this.$t('message.fechamento_periodo_fiscal_enviado'));
    },
    executarAcao(acao, mensagem) {
      this.resources.executarAcao(acao, acao)
        .then(() => {
          this.mensagem = mensagem;
          this.naoLimparMensagem = true;
          this.$forceUpdate();
        }, (err) => {
          this.$error(this, err);
        });
    },
  },
};
