import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('usuario', 'usuario-unidade-negocio');

const usuarioUnidadeNegocioActions = {
  ...genericActions(basePath),
  ativarDesativar: { method: 'PUT', url: `${basePath}/{idUsuario}/ativacao` },
  listarAtivos: { method: 'GET', url: `${basePath}/lista-unidades-do-usuario` },
};

export default (resource) => resource(`${basePath}`, {}, usuarioUnidadeNegocioActions);
