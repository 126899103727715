<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :max-width="350"
    :min-width="350"
    right
    offset-x>
    <template v-slot:activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <tenant-card @TenantCard_selecionarTenant="selecionarTenant"></tenant-card>
  </v-menu>
</template>

<script>
import TenantCard from '@/produto/spa/tenant/TenantCard';

export default {
  name: 'TenantMenu',
  components: { TenantCard },
  data: () => ({
    menu: false,
  }),
  methods: {
    close() {
      this.menu = false;
    },
    selecionarTenant(tenantID) {
      this.$emit('TenantMenu_selecionarTenant', tenantID);
      this.close();
    },
  },
};
</script>
