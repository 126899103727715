export default {
  data() {
    return {
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
          },
        ],
      },
    };
  },
};
