<template lang="html">
  <div class="CRUDGenerico__Wrapper" v-if="canAccessPage">
    <v-row>
      <v-col cols="12" sm="3" v-if="!somenteLeitura" class="pt-0">
        <centro-custo-form
          ref="centroCustoForm"
          @FORMULARIO_CADASTRO_CENTRO_CUSTO_SALVAR="onNovoCentroCusto"
          @FORMULARIO_CADASTRO_CENTRO_CUSTO_CANCELAR="onCancelar" />
      </v-col>
      <v-col cols="12" :md="somenteLeitura ? 12 : 9" class="pt-0">
        <centro-custo-tabela
          ref="centroCustoTabela"
          class="CRUDGenerico__ContentTable"
          :title="$tc('label.centro_custo', 2)"
          @TABELA_EXPORTAR_DADOS="requestExportacao"
          @PESQUISA_CENTRO_CUSTO_EDITAR="onEditarCentroCusto"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import exportacao from '../../common/functions/exportacao';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import CRUDGenerico from '../../shared-components/generico/CRUDGenerico';
import CentroCustoForm from './CentroCustoForm';
import CentroCustoTabela from './CentroCustoTabela';

export default {
  name: 'CadastroCentroCrusto',
  extends: CRUDGenerico,
  data() {
    return {
      centroCustoResources: this.$api.centroCusto(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),
      resourceMetadado: this.$api.metadado(this.$resource),
    };
  },
  components: {
    CentroCustoForm,
    CentroCustoTabela,
  },
  computed: {
    ...generateComputed('ceco', [
      'canAccessCRUD',
      'canAccessPage',
    ]),
    ...mapGetters('metadados', [
      'getCentroCustoMetadado',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setCentroCustoMetadado',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    onCancelar() {
      this.$refs.centroCustoTabela.filtrar();
    },
    onEditarCentroCusto(centroCusto) {
      this.$refs.centroCustoForm.editar(centroCusto);
    },
    onNovoCentroCusto() {
      this.$refs.centroCustoTabela.filtrar();
    },
    popularMetadados() {
      if (this.getCentroCustoMetadado) {
        return;
      }
      this.setCentroCustoMetadado({
        resource: this.resourceMetadado.definicaoCentroCusto,
      });
    },
    requestExportacao(cb) {
      const params = {
        filtro: this.$refs.centroCustoTabela.getSearchQuery() || '',
      };
      exportacao.exportar(cb, 'centro_custo_pasta_trabalho', this, params);
    },
    requestPopular(page = null) {
      if (page && page.filter) {
        this.request(this.centroCustoResources.listarFiltrado, page);
        return;
      }
      this.request(this.centroCustoResources.listar, page);
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    } else {
      this.popularMetadados();
    }
  },
};
</script>
