<template>
  <v-card style="margin-bottom:1em" class="RelatorioAcompanhamentoCampanhasAdsFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.relatorioAcompanhamentoCampanhaAds', 1) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          v-if="camposFormulario !== null"
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :explode-hierarquia="true"
          :formulario-filtros="true"
          :ignora-inativos="false"
          :ignora-obrigatorios="false"
          :objeto="filtros"
          ref="container">
          <v-col slot="antes">
            <v-autocomplete
              id="combo-categorias"
              v-model="categoriaSelecionada"
              :items="categorias"
              :rules="[rules.required]"
              chips
              deletable-chips
              multiple
              autocomplete="off"
              return-object
              item-text="codNome"
              item-value="id"
              :label="`${$tc('label.categoria', 2)} *`"
              :item-disabled="isCategoriaDisabled"
              @change="handleCategoriaChange">
            </v-autocomplete>
          </v-col>
          <v-col v-if="habilitaTooltipCategoria" cols="12" md="1" sm="4" class="mb-5">
            <template>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">info</v-icon>
                </template>
                <template v-slot:default>
                  <ul>
                    <li v-for="categoria in categoriaSelecionada" :key="categoria.id">{{ categoria.nomeCategoria }}</li>
                  </ul>
                </template>
              </v-tooltip>
            </template>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '@/produto/common/functions/loading';
import { mapGetters } from 'vuex';
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import { verificarDadosExportacao, executarExportacao } from '@/common/resources/exportacao-acompanhamento-campanha-ads';
import RelatorioAcompanhamentoCampanhasAdsFiltros from './RelatorioAcompanhamentoCampanhasAdsFiltros';

export default {
  name: 'RelatorioAcompanhamentoCampanhaAds',
  mixins: [
    RelatorioAcompanhamentoCampanhasAdsFiltros,
  ],
  watch: {
    filtros: {
      handler() {
        this.umFiltroObrigatorioSelecionado = false;

        Object.values(this.filtros).every((value) => {
          if (value instanceof Array) {
            if (value && value.length) {
              this.umFiltroObrigatorioSelecionado = true;
              return false;
            }
          } else if (value) {
            this.umFiltroObrigatorioSelecionado = true;
            return false;
          }
          return true;
        });
        this.$refs.form.resetValidation();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
    isCategoriaDisabled() {
      return (categoria) => this.disableCategorias && categoria.id !== 0;
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      ordenacaoCampos: [
        'categoria',
        'status_nd',
        'data_inicio_emissao_nd',
        'data_fim_emissao_nd',
        'descricao_passo',
        'id_campanha_ads',
        'id_campanha_tradelinks',
        'numer_nd',
      ],
      rules: {
        required: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
      },
      mostrarFiltros: false,
      filtrosFixados: false,
      filtros: {},
      camposForm: [],
      categorias: [],
      categoriaSelecionada: [],
      disableCategorias: false,
      habilitaTooltipCategoria: false,
      camposFormulario: null,
    };
  },
  methods: {
    async buscaCategoria(autocomplete) {
      const categorias = await this.magaluRelatoriosResource.buscarCategoria({ autocomplete });
      const { data } = categorias;
      this.categorias = [
        {
          id: 0, codNome: 'TODAS', nomeCategoria: 'TODAS',
        },
        ...data,
      ];
    },
    handleCategoriaChange(selectedCategorias) {
      if (selectedCategorias.length > 0 && selectedCategorias.find((categoria) => categoria.id === 0)) {
        this.categoriaSelecionada = [selectedCategorias.find((categoria) => categoria.id === 0)];
        selectedCategorias = [selectedCategorias.find((categoria) => categoria.id === 0)];
        this.disableCategorias = true;
        this.habilitaTooltipCategoria = false;
      } else {
        this.disableCategorias = false;
      }
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    resetaFiltros() {
      this.filtros = {};
      this.categoriaSelecionada = [];
      this.disableCategorias = false;
    },
    aplicarFiltros() {
      const filtrosAplicados = this.$refs.container.getValoresCamposPadrao();

      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }

      const params = {
        ...filtrosAplicados,
        categorias: this.categoriaSelecionada.filter((c) => c.id !== 0).map((c) => c.nome),
      };

      let quantidadeRegistros = 0;

      verificarDadosExportacao(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$toast(this.$t('message.exportacao.em_andamento'));
            executarExportacao(params, this.$resource);
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio_emissao_nd || filtrosAplicados.data_fim_emissao_nd) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio_emissao_nd, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim_emissao_nd, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
      }

      return true;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-detalhamento-nd';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-detalhamento-nd';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtros;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
  async mounted() {
    this.camposFormulario = this.camposFormularioMagalu;
    await this.buscaCategoria();
  },
};
</script>
<style>
  .RelatorioAcompanhamentoCampanhasAdsFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioAcompanhamentoCampanhasAdsFiltros__Opcoes div.v-input__slot,
  .RelatorioAcompanhamentoCampanhasAdsFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioAcompanhamentoCampanhasAdsFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioAcompanhamentoCampanhasAdsFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
