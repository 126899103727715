<template lang="html">
  <div>
    <div class="NotFound__Wrapper">
      <span class="md-display-2" style="width: 100%">
        {{ $t('message.pagina_inexistente') }} <br/>{{ $t('message.redirecionando_para_home') }}
      </span>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  mounted() {
    window.setTimeout(() => {
      window.location.href = '';
    }, 3000);
  },
};
</script>

<style media="scss">
  .NotFound__Wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
  }
</style>
