const mutations = {
  setFiltroApuracaoContrato(state, filtroApuracaoContrato) {
    state.filtroApuracaoContrato = filtroApuracaoContrato;
  },
  setFiltroApuracaoContratoNivel1(state, filtroApuracaoContratoNivel1) {
    state.filtroApuracaoContratoNivel1 = filtroApuracaoContratoNivel1;
  },
  setFiltroApuracaoContratoNivel2(state, filtroApuracaoContratoNivel2) {
    state.filtroApuracaoContratoNivel2 = filtroApuracaoContratoNivel2;
  },
  setFiltroApuracaoContratoNivel3(state, filtroApuracaoContratoNivel3) {
    if (!state.filtroApuracaoContratoNivel3 || !filtroApuracaoContratoNivel3
      || state.filtroApuracaoContratoNivel3.id !== filtroApuracaoContratoNivel3.id
       || state.filtroApuracaoContratoNivel3.codApuracao
        !== filtroApuracaoContratoNivel3.codApuracao) {
      state.filtroApuracaoContratoNivel3 = filtroApuracaoContratoNivel3;
    }
  },
  setFiltroApuracaoAcao(state, filtroApuracaoAcao) {
    state.filtroApuracaoAcao = filtroApuracaoAcao;
  },
  setFiltroApuracaoAcaoNivel1(state, filtroApuracaoAcaoNivel1) {
    state.filtroApuracaoAcaoNivel1 = filtroApuracaoAcaoNivel1;
  },
  setFiltroApuracaoAcaoNivel2(state, filtroApuracaoAcaoNivel2) {
    state.filtroApuracaoAcaoNivel2 = filtroApuracaoAcaoNivel2;
  },
  setFiltroApuracaoAcaoNivel3(state, filtroApuracaoAcaoNivel3) {
    if (!state.filtroApuracaoAcaoNivel3 || !filtroApuracaoAcaoNivel3
      || state.filtroApuracaoAcaoNivel3.id !== filtroApuracaoAcaoNivel3.id
      || state.filtroApuracaoAcaoNivel3.cod_apuracao
      !== filtroApuracaoAcaoNivel3.cod_apuracao) {
      state.filtroApuracaoAcaoNivel3 = filtroApuracaoAcaoNivel3;
    }
  },
  setFiltroLiquidacaoAcao(state, filtroLiquidacaoAcao) {
    state.filtroLiquidacaoAcao = filtroLiquidacaoAcao;
  },
  setFiltroLiquidacaoAcaoNivel1(state, filtroLiquidacaoAcaoNivel1) {
    state.filtroLiquidacaoAcaoNivel1 = filtroLiquidacaoAcaoNivel1;
  },
  setFiltroLiquidacaoAcaoNivel2(state, filtroLiquidacaoAcaoNivel2) {
    state.filtroLiquidacaoAcaoNivel2 = filtroLiquidacaoAcaoNivel2;
  },
  setFiltroLiquidacaoAcaoNivel3(state, filtroLiquidacaoAcaoNivel3) {
    if (!state.filtroLiquidacaoAcaoNivel3 || !filtroLiquidacaoAcaoNivel3
      || state.filtroLiquidacaoAcaoNivel3.id !== filtroLiquidacaoAcaoNivel3.id
      || state.filtroLiquidacaoAcaoNivel3.codApuracao
      !== filtroLiquidacaoAcaoNivel3.codApuracao) {
      state.filtroLiquidacaoAcaoNivel3 = filtroLiquidacaoAcaoNivel3;
    }
  },
  setFiltroLiquidacaoContrato(state, filtroLiquidacaoContrato) {
    state.filtroLiquidacaoContrato = filtroLiquidacaoContrato;
  },
  setFiltroLiquidacaoContratoNivel1(state, filtroLiquidacaoContratoNivel1) {
    state.filtroLiquidacaoContratoNivel1 = filtroLiquidacaoContratoNivel1;
  },
  setFiltroLiquidacaoContratoNivel2(state, filtroLiquidacaoContratoNivel2) {
    state.filtroLiquidacaoContratoNivel2 = filtroLiquidacaoContratoNivel2;
  },
  setFiltroLiquidacaoContratoNivel3(state, filtroLiquidacaoContratoNivel3) {
    if (!state.filtroLiquidacaoContratoNivel3 || !filtroLiquidacaoContratoNivel3
      || state.filtroLiquidacaoContratoNivel3.id !== filtroLiquidacaoContratoNivel3.id
      || state.filtroLiquidacaoContratoNivel3.codApuracao
      !== filtroLiquidacaoContratoNivel3.codApuracao) {
      state.filtroLiquidacaoContratoNivel3 = filtroLiquidacaoContratoNivel3;
    }
  },
};

const actions = {
  setFiltroApuracaoContrato({ commit }, filtroApuracaoContrato) {
    commit('setFiltroApuracaoContrato', filtroApuracaoContrato);
  },
  setFiltroApuracaoContratoNivel1({ commit }, filtroApuracaoContratoNivel1) {
    commit('setFiltroApuracaoContratoNivel1', filtroApuracaoContratoNivel1);
  },
  setFiltroApuracaoContratoNivel2({ commit }, filtroApuracaoContratoNivel2) {
    commit('setFiltroApuracaoContratoNivel2', filtroApuracaoContratoNivel2);
  },
  setFiltroApuracaoContratoNivel3({ commit }, filtroApuracaoContratoNivel3) {
    commit('setFiltroApuracaoContratoNivel3', filtroApuracaoContratoNivel3);
  },
  setFiltroApuracaoAcao({ commit }, filtroApuracaoAcao) {
    commit('setFiltroApuracaoAcao', filtroApuracaoAcao);
  },
  setFiltroApuracaoAcaoNivel1({ commit }, filtroApuracaoAcaoNivel1) {
    commit('setFiltroApuracaoAcaoNivel1', filtroApuracaoAcaoNivel1);
  },
  setFiltroApuracaoAcaoNivel2({ commit }, filtroApuracaoAcaoNivel2) {
    commit('setFiltroApuracaoAcaoNivel2', filtroApuracaoAcaoNivel2);
  },
  setFiltroApuracaoAcaoNivel3({ commit }, filtroApuracaoAcaoNivel3) {
    commit('setFiltroApuracaoAcaoNivel3', filtroApuracaoAcaoNivel3);
  },
  setFiltroLiquidacaoAcao({ commit }, filtroLiquidacaoAcao) {
    commit('setFiltroLiquidacaoAcao', filtroLiquidacaoAcao);
  },
  setFiltroLiquidacaoAcaoNivel1({ commit }, filtroLiquidacaoAcaoNivel1) {
    commit('setFiltroLiquidacaoAcaoNivel1', filtroLiquidacaoAcaoNivel1);
  },
  setFiltroLiquidacaoAcaoNivel2({ commit }, filtroLiquidacaoAcaoNivel2) {
    commit('setFiltroLiquidacaoAcaoNivel2', filtroLiquidacaoAcaoNivel2);
  },
  setFiltroLiquidacaoAcaoNivel3({ commit }, filtroLiquidacaoAcaoNivel3) {
    commit('setFiltroLiquidacaoAcaoNivel3', filtroLiquidacaoAcaoNivel3);
  },
  setFiltroLiquidacaoContrato({ commit }, filtroLiquidacaoContrato) {
    commit('setFiltroLiquidacaoContrato', filtroLiquidacaoContrato);
  },
  setFiltroLiquidacaoContratoNivel1({ commit }, filtroLiquidacaoContratoNivel1) {
    commit('setFiltroLiquidacaoContratoNivel1', filtroLiquidacaoContratoNivel1);
  },
  setFiltroLiquidacaoContratoNivel2({ commit }, filtroLiquidacaoContratoNivel2) {
    commit('setFiltroLiquidacaoContratoNivel2', filtroLiquidacaoContratoNivel2);
  },
  setFiltroLiquidacaoContratoNivel3({ commit }, filtroLiquidacaoContratoNivel3) {
    commit('setFiltroLiquidacaoContratoNivel3', filtroLiquidacaoContratoNivel3);
  },
};

const getters = {
  filtroApuracaoContrato(state) {
    return state.filtroApuracaoContrato;
  },
  filtroApuracaoContratoNivel1(state) {
    return state.filtroApuracaoContratoNivel1;
  },
  filtroApuracaoContratoNivel2(state) {
    return state.filtroApuracaoContratoNivel2;
  },
  filtroApuracaoContratoNivel3(state) {
    return state.filtroApuracaoContratoNivel3;
  },
  filtroApuracaoAcao(state) {
    return state.filtroApuracaoAcao;
  },
  filtroApuracaoAcaoNivel1(state) {
    return state.filtroApuracaoAcaoNivel1;
  },
  filtroApuracaoAcaoNivel2(state) {
    return state.filtroApuracaoAcaoNivel2;
  },
  filtroApuracaoAcaoNivel3(state) {
    return state.filtroApuracaoAcaoNivel3;
  },
  filtroLiquidacaoAcao(state) {
    return state.filtroLiquidacaoAcao;
  },
  filtroLiquidacaoAcaoNivel1(state) {
    return state.filtroLiquidacaoAcaoNivel1;
  },
  filtroLiquidacaoAcaoNivel2(state) {
    return state.filtroLiquidacaoAcaoNivel2;
  },
  filtroLiquidacaoAcaoNivel3(state) {
    return state.filtroLiquidacaoAcaoNivel3;
  },
  filtroLiquidacaoContrato(state) {
    return state.filtroLiquidacaoContrato;
  },
  filtroLiquidacaoContratoNivel1(state) {
    return state.filtroLiquidacaoContratoNivel1;
  },
  filtroLiquidacaoContratoNivel2(state) {
    return state.filtroLiquidacaoContratoNivel2;
  },
  filtroLiquidacaoContratoNivel3(state) {
    return state.filtroLiquidacaoContratoNivel3;
  },
};

const state = {
  filtroApuracaoContrato: null,
  filtroApuracaoContratoNivel1: null,
  filtroApuracaoContratoNivel2: null,
  filtroApuracaoContratoNivel3: null,
  filtroApuracaoAcao: null,
  filtroApuracaoAcaoNivel1: null,
  filtroApuracaoAcaoNivel2: null,
  filtroApuracaoAcaoNivel3: null,
  filtroLiquidacaoAcao: null,
  filtroLiquidacaoAcaoNivel1: null,
  filtroLiquidacaoAcaoNivel2: null,
  filtroLiquidacaoAcaoNivel3: null,
  filtroLiquidacaoContrato: null,
  filtroLiquidacaoContratoNivel1: null,
  filtroLiquidacaoContratoNivel2: null,
  filtroLiquidacaoContratoNivel3: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
