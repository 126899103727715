import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'configuracao');

const configuracaoActions = {
  buscarCamposFiltro: { methods: 'GET', url: `${basePath}/campos/filtro` },
  buscarCamposGrid: { methods: 'GET', url: `${basePath}/campos/grid/{idTipoAcao}` },
  buscarConfigVigente: { methods: 'GET', url: `${basePath}/vigente/{idTipoAcao}` },
  buscarConfigSnapshot: { methods: 'GET', url: `${basePath}/snapshot/{idAcao}` },
  buscarHoldingsCliente: { methods: 'GET', url: `${basePath}/campos/cliente/holdings` },
  buscarLabelCampoCliente: { methods: 'GET', url: `${basePath}/campo-cliente/{idTipoAcao}` },
};

export default (resource) => resource(`${basePath}`, {}, configuracaoActions);
