import getBasePath from '../functions/api-resource';

const basePath = getBasePath('api', 'usuario-logado');

const divisaoActions = {
  divisao: { method: 'GET', url: `${basePath}/divisao` },
  hierarquiaDivisao: { method: 'GET', url: `${basePath}/divisao/hierarquia{?exceto*}` },
  regional: { method: 'GET', url: `${basePath}/regional` },
  cliente: { method: 'GET', url: `${basePath}/divisao/cliente` },
  clienteHierarquia: { method: 'GET', url: `${basePath}/divisao/hierarquia/clientes{?exceto*&idsDivisao*}` },
  tiposVerba: { method: 'GET', url: `${basePath}/tipos-verba` },
};

export default (resource) => resource(`${basePath}`, {}, divisaoActions);
