<template lang="html">
  <div v-if="canAccessPage">
    <v-container fluid>
      <v-row class="mb-3">
        <h3 class="headline mb-0">{{ $t('title.ano_fiscal') }}</h3>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" lg="4" v-for="(item, i) in tipos" :key="i" class="pa-3">
          <ano-fiscal-card :tipo-verba="item"
                           :habilitar-bloqueio="habilitarBloqueio"
                           :habilitar-fechamento="habilitarFechamento"
                           :habilitar-periodos-orcamento="habilitarPeriodosOrcamento">
          </ano-fiscal-card>
        </v-col>
        <v-subheader
          v-if="carregouTipos && (!tipos || tipos.length === 0)">
          {{ $t('message.necessario_parametrizacao_tipo_orcamento') }}
        </v-subheader>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AnoFiscalCard from './AnoFiscalCard';

export default {
  name: 'AnoFiscalPesquisar',
  components: {
    AnoFiscalCard,
  },
  props: {
    habilitarBloqueio: {
      type: Boolean,
      default: false,
    },
    habilitarFechamento: {
      type: Boolean,
      default: false,
    },
    habilitarPeriodosOrcamento: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tipos: [],
      carregouTipos: false,
      resourcesOrcamentoTipoVerba: this.$api.orcamentoTipoVerba(this.$resource),
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ANO_FISCAL').length;
    },
  },
  methods: {
    accessForbidden() {
      this.$router.push({ name: 'inicio' });
      this.$toast(this.$t('message.acesso_negado'));
    },
    pesquisarTipos() {
      this.resourcesOrcamentoTipoVerba.buscarAtivos()
        .then((res) => {
          this.tipos = res.data;
          this.carregouTipos = true;
        }, (err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.pesquisarTipos();
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
