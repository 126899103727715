import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'alcada');

const alcadaActions = {
  ...genericActions(basePath),
  listarTiposFluxo: { method: 'GET', url: `${basePath}/tipos-fluxo/?workflowTipo={nome}&colunaOrdenacao=nome` },
  listarAtivos: { method: 'GET', url: `${basePath}/ativos/tipos-fluxo/{id}` },
  ativarInativarRegistros: { method: 'POST', url: `${basePath}/ativarInativarRegistros` },

  listarWorkflow: { method: 'GET', url: `${basePath}/workflow/?descricao={descricao}&workflowTipo={descricao}` },
  obterAlcadaWorkflow: { method: 'GET', url: `${basePath}/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, alcadaActions);
