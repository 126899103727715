<template>
  <v-container grid-list-xl fluid>
    <v-row class="pa-3">
      <v-col cols="12" sm="6" md="3">
        <h3 class="headline mb-0">{{ $t('title.extrato') }}</h3>
      </v-col>
    </v-row>
    <v-row class="pa-3">
      <v-col cols="12" md="6">
        <extrato-conta-corrente-filtros
          @ExtratoContaCorrenteFiltros_contaSelecionada="preencherContaCorrente"
          @ExtratoContaCorrenteFiltros_contaDeselecionada="() => preencherContaCorrente(null, null)"
          @ExtratoContaCorrenteFiltros_pesquisar="pesquisar">
        </extrato-conta-corrente-filtros>
      </v-col>
      <v-col cols="12" md="6">
        <extrato-conta-corrente-card
          v-if="idsContasCorrentes"
          :ids-contas-correntes="idsContasCorrentes"
          :id-linha="idLinha">
        </extrato-conta-corrente-card>
      </v-col>
    </v-row>
    <v-row class="pa-3">
      <v-col cols="12">
        <extrato-conta-corrente-tabela
          v-if="idsContasCorrentesPesquisar && dataInicio && dataFim"
          :ids-contas-correntes="idsContasCorrentesPesquisar"
          :id-linha="idLinha"
          :data-inicio="dataInicio"
          :data-fim="dataFim">
        </extrato-conta-corrente-tabela>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import ExtratoContaCorrenteFiltros from './ExtratoContaCorrenteFiltros';
import ExtratoContaCorrenteCard from './ExtratoContaCorrenteCard';
import ExtratoContaCorrenteTabela from './ExtratoContaCorrenteTabela';

export default {
  name: 'ExtratoContaCorrente',
  components: {
    ExtratoContaCorrenteTabela,
    ExtratoContaCorrenteCard,
    ExtratoContaCorrenteFiltros,
  },
  data() {
    return {
      idsContasCorrentes: null,
      idLinha: null,
      idsContasCorrentesPesquisar: null,
      idLinhaPesquisar: null,
      dataInicio: null,
      dataFim: null,
    };
  },
  computed: {
    ...generateComputed('Extrato', [
      'canAccessPage',
    ]),
  },
  methods: {
    preencherContaCorrente(idsContasCorrentes, idLinha) {
      this.idsContasCorrentes = idsContasCorrentes;
      this.idLinha = idLinha;
    },
    pesquisar(idsContasCorrentes, dataInicio, dataFim, idLinha) {
      this.idsContasCorrentesPesquisar = null;
      setTimeout(() => {
        this.idsContasCorrentesPesquisar = idsContasCorrentes;
        this.dataInicio = dataInicio;
        this.dataFim = dataFim;
        this.idLinhaPesquisar = idLinha;
      });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.$router.push({ name: 'inicio' });
      }, 1E3);
    }
  },
};
</script>
