<template>
  <v-dialog v-model="dialog" dark fullscreen hide-overlay scrollable eager transition="dialog-bottom-transition" v-resize="onResize">
    <v-card style="background: rgba(0, 0, 0, 0.8);">
      <v-container fluid class="pa-0">
        <v-row align="center" class="pa-3 mx-0" style="position: absolute; width: 100%; background: linear-gradient(to bottom,rgba(0,0,0,0.65) 0%,transparent 100%);">
          <v-btn icon dark @click="close">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <div style="display: flex; height: 100%;" class="ml-1">
            <div>
              <img :src="type.icon" class="mr-2 CardFile--img"/>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="download">
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </v-row>
        <div id="scroll-target" class="row pt-12 overflow-y-auto mx-0 align-center" :style="`height: ${windowSize.y}px;`">
          <view-image
              :file="file"
              v-if="type.image && dialog"
              :extension="type.image"
              :uri="uri">
          </view-image>
          <view-pdf
            ref="viewPage"
            :file="file"
            v-if="type.pdf && dialog"
            :uri="uri"
            :scale="scale"
            @ViewPdf_pageCount="setTotalPage"
            @ViewPdf_visiblePage="setVisiblePage">
          </view-pdf>
        </div>
      </v-container>
      <v-card-actions class="ViewFile__card-actions" v-if="visiblePage">
        <v-toolbar dense style="background: rgba(0, 0, 0, 0.8); width: auto; margin-left: auto; margin-right: auto;">
          <span>{{ `${$tc('label.pagina', 1)}  ${visiblePage}  /  ${totalPage}` }}</span>
          <v-divider
            class="mx-4"
            vertical>
          </v-divider>
          <div class="v-toolbar__items">
            <v-btn text style="opacity: 1;" :disabled="disabledZoomRemove" @click="removeZoom">
              <v-icon>remove</v-icon>
            </v-btn>

            <v-btn text style="opacity: 1;" @click="maxZoom" v-if="disabledZoomRemove">
              <v-icon>zoom_in</v-icon>
            </v-btn>

            <v-btn text style="opacity: 1;" @click="minZoom" v-else>
              <v-icon>zoom_out</v-icon>
            </v-btn>

            <v-btn text style="opacity: 1;" :disabled="disabledZoomAdd" @click="addZoom">
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ViewImage from './ViewImage';
import ViewPdf from './pdf/ViewPdf';

export default {
  name: 'ViewFile',
  components: { ViewPdf, ViewImage },
  props: {
    file: {
      defaut: () => {},
    },
    type: {
      defaut: () => {},
    },
    uri: '',
  },
  data() {
    return {
      dialog: false,
      scrollFlag: false,
      totalPage: 0,
      visiblePage: 0,
      scaleMin: 1,
      scaleMax: 3,
      scale: 1,
      windowSize: {
        x: 0,
        y: 0,
      },
      scrollTarget: null,
    };
  },
  computed: {
    disabledZoomRemove() {
      return this.scale <= this.scaleMin;
    },
    disabledZoomAdd() {
      return this.scale >= this.scaleMax;
    },
  },
  methods: {
    maxZoom() {
      this.scale = this.scaleMax;
    },
    minZoom() {
      this.scale = this.scaleMin;
    },
    removeZoom() {
      this.scale -= 0.5;
    },
    addZoom() {
      this.scale += 0.5;
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    setTotalPage(totalPage) {
      this.totalPage = totalPage || 0;
    },
    setVisiblePage(visiblePage) {
      this.visiblePage = visiblePage || 0;
    },
    onScroll() {
      if (!this.scrollFlag) {
        this.scrollFlag = true;
        if (this.$refs.viewPage) {
          setTimeout(() => {
            this.$refs.viewPage.updateScrollBounds(this.scrollTarget.scrollTop);
            this.scrollFlag = false;
          }, 3E2);
        } else {
          this.scrollFlag = false;
        }
      }
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('ViewFile__close');
    },
    download() {
      this.$emit('download', this.file);
    },
  },
  mounted() {
    this.onResize();
    this.scrollTarget = document.getElementById('scroll-target');
    this.scrollTarget.addEventListener('scroll', this.onScroll, true);
  },
};
</script>

<style scoped>
  .ViewFile__card-actions {
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    bottom: 0%;
    max-width: 400px;
  }
</style>
