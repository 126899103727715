<template>
  <v-input
    :label="inputLabel"
    :rules="[rules.requiredNome, ...this.regrasValidacao]"
    :error-messages="validaCampo"
    v-model="valorInterno"
    class="MetadadosCampoDinamico__Dinheiro v-text-field"
    :class="{focused, dirty}"
    :required="campoObrigatorio"
    :disabled="campoDesabilitado"
  >
    <money
      v-model="valorInterno"
      :id="inputId"
      v-bind="money"
      @input="valorDinheiroAlterado"
      :disabled="campoDesabilitado">
    </money>
  </v-input>
</template>
<script>
import { Money } from 'v-money';

import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoDinheiro',
  props: {
    value: {
      type: [Number, String],
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  components: {
    Money,
  },
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
      },
    },
  },
  computed: {
    hasErrors() {
      if (!this.campoObrigatorio) {
        return false;
      }

      const val = this.valorInterno,
        hasErrors = (val === null || val === this.valorVazioDinheiro);
      return hasErrors;
    },
    validaCampo() {
      if (this.hasErrors) {
        return this.$t('message.campo_obrigatorio');
      }
      return null;
    },
  },
  data() {
    return {
      dirty: false,
      focused: false,
      valorInterno: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      valorVazioDinheiro: 0,
      rules: {
        requiredNome: () => {
          if (!this.campoObrigatorio || !this.hasErrors) {
            return true;
          }
          return this.$t('message.campo_obrigatorio');
        },
      },
    };
  },
  methods: {
    valorDinheiroAlterado(valor) {
      if (valor === this.value) return;
      this.$emit('input', valor);
      this.emiteValorAlterado(valor);
    },
  },
  mounted() {
    const input = document.getElementById(this.inputId);

    input.addEventListener('focus', () => {
      this.focused = true;
    }, false);

    input.addEventListener('keyup', () => {
      this.dirty = true;
    }, false);

    input.addEventListener('blur', () => {
      this.focused = false;
      this.dirty = true;
    }, false);
  },
};
</script>

<style>
.MetadadosCampoDinamico__Dinheiro.v-input--is-disabled .v-input__slot:before {
  border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38) 0,rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat;
}
.MetadadosCampoDinamico__Dinheiro:not(.dirty).error--text .v-input__slot:before {
  border-color: rgba(0,0,0,.42);
}
.MetadadosCampoDinamico__Dinheiro:not(.dirty).error--text input {
  caret-color: rgba(0,0,0,.42);
}
.MetadadosCampoDinamico__Dinheiro.dirty.error--text .v-input__slot:before {
  border-color: #b71c1c;
}
.MetadadosCampoDinamico__Dinheiro.dirty.error--text input {
  caret-color: #b71c1c;
}
.MetadadosCampoDinamico__Dinheiro:not(.focused){
  color: rgba(0,0,0,.42) !important;
}
.MetadadosCampoDinamico__Dinheiro:not(.dirty) div.v-messages  {
  display: none;
}
.MetadadosCampoDinamico__Dinheiro .v-input__slot {
  transition: background .3s cubic-bezier(.25,.8,.5,1);
}
.MetadadosCampoDinamico__Dinheiro .v-input__slot:before {
  border-color: currentColor;
  border-style: solid;
  border-width: thin 0 0;
}
.MetadadosCampoDinamico__Dinheiro.error--text:not(.dirty) .v-input__slot:after {
  border-color: rgba(0,0,0,.42) !important;
}
.MetadadosCampoDinamico__Dinheiro .v-input__slot:after {
  border-color: currentColor;
}
.MetadadosCampoDinamico__Dinheiro .v-input__slot:after {
  border-style: solid;
  border-width: thin 0;
  transform: scaleX(0);
}
.MetadadosCampoDinamico__Dinheiro .v-input__slot:before,
.MetadadosCampoDinamico__Dinheiro .v-input__slot:after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: .3s cubic-bezier(.25,.8,.5,1);
  width: 100%;
}
.MetadadosCampoDinamico__Dinheiro.focused .v-input__slot:after {
  transform: scaleX(1);
}
.MetadadosCampoDinamico__Dinheiro:not(.dirty) .v-input__slot .v-label {
  color: rgba(0,0,0,.54) !important;
}
.MetadadosCampoDinamico__Dinheiro .v-input__slot .v-label {
  position: absolute !important;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 6px;
  transform-origin: top left;
  white-space: nowrap;
}
.MetadadosCampoDinamico__Dinheiro .v-input__slot .v-label {
  max-width: 100%;
  transform: translateY(-18px) scale(.75);
}
.MetadadosCampoDinamico__Dinheiro .v-input__slot .v-label:not(.error--text) {
  color: currentColor !important;
}
.MetadadosCampoDinamico__Dinheiro.v-input input:invalid,
.MetadadosCampoDinamico__Dinheiro.v-input input:required {
  box-shadow: none;
}
.MetadadosCampoDinamico__Dinheiro.v-input input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0;
  max-width: 100%;
  width: 100%;
}

</style>
