import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('usuario', 'usuario-fornecedor');

const regiaoActions = {
  ...genericActions(basePath),
  listarGruposFornecedor: { method: 'GET', url: `${basePath}/grupos-fornecedor` },
};

export default (resource) => resource(`${basePath}`, {}, regiaoActions);
