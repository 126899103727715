<template lang="html">
  <div>
    <v-form ref="form" lazy-validation autocomplete="off">
      <div v-if="!modoVarejo">
        <v-card-title primary-title class="card-header">
          {{ $t('title.acesso_cliente') }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.acesso_cliente')}`" />
        </v-card-title>
        <v-row class="mx-4 mt-4">
          <v-col cols="12" sm="6" md="3" class="mr-3">
            <v-switch
              id="config_acao_acesso_cliente"
              :label="`${$t('message.permitir_cliente_criar_acao')}`"
              v-model="parametrizacao.indAcessoCliente" />
          </v-col>
        </v-row>
      </div>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.acesso_divisao') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.acesso_divisao')}`" />
      </v-card-title>
      <v-row class="ml-4 mr-4 mt-4">
        <v-col cols="12" md="4">
          <v-autocomplete
            id="config_acao_divisao"
            name="config_acao_divisao"
            v-model="divisao"
            :items="listaDivisoes"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nome"
            item-value="id"
            :label="`${$tc('label.divisao', 1)}`"
            @click:append="triggerDivisao"
            @click.native="buscarDivisaoAutocomplete"
            @change="setDivisao"
            clearable
            return-object
            :search-input.sync="searchInputDivisao">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table id="config_acao_tabela_divisao"
        :headers="headersDivisao"
        :items="parametrizacao.listaDivisao"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.acoes="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon class="mx-0" v-on="on" @click.stop="removerDivisao(item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.remover') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.nomeDivisao="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:item.nomEstruturaUsuario="{ item }">
          {{ item.nomEstruturaUsuario }}
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.acesso_estrutura_usuario') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.acesso_estrutura_usuario')}`" />
      </v-card-title>
      <v-row class="ml-4 mr-4 mt-4">
        <v-col cols="12" md="4">
          <v-autocomplete
            id="config_acao_estrutura"
            name="config_acao_estrutura"
            v-model="estrutura"
            :items="listaEstruturas"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nomEstruturaUsuario"
            item-value="id"
            :label="`${$tc('label.estrutura_usuario', 1)}`"
            @click:append="triggerEstrutura"
            @click.native="buscarEstruturaAutocomplete"
            @change="setEstrutura"
            clearable
            return-object
            :search-input.sync="searchInputEstrutura">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table id="config_acao_tabela_estrutura"
        :headers="headersEstrutura"
        :items="parametrizacao.listaEstrutura"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.acoes="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon class="mx-0" v-on="on" @click.stop="removerEstrutura(item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.remover') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.nomeEstrutura="{ item }">
          {{ item.nomEstruturaUsuario }}
        </template>
        <template v-slot:item.estruturaUsuarioPai="{ item }">
          {{ item.estruturaUsuarioPai }}
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header">
        {{ $tc('title.rateio_contabil_em_tipos_de_uso', 1) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.info_habilitar_opcao_rateio_tipos_de_uso')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col class="py-0" cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_habilita_rateio_contabil"
            dense
            hide-details
            :label="`${$t('label.habilita_rateio_contabil_acao')}`"
            v-model="parametrizacao.indHabilitarRateioContabil"/>
        </v-col>
      </v-row>

      <v-row justify="end" class="my-4">
        <v-btn id="config_acao_passo2_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_orcamento_passo2_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn id="config_acao_passo2_botao_proximo" color="primary" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import Passo2 from './Passo2';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoAcaoPasso2',
  props: {
    id: Number,
    modoVarejo: Boolean,
  },
  components: {
    TooltipAjuda,
  },
  watch: {
    id() {
      this.prepararPasso();
    },
    searchInputDivisao(val) {
      if (this.divisao && this.divisao.nome === val) {
        return;
      }

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarDivisaoAutocomplete(val);
      }, 500);
    },
    searchInputEstrutura(val) {
      if (this.estrutura && this.estrutura.nomEstruturaUsuario === val) {
        return;
      }

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarEstruturaAutocomplete(val);
      }, 500);
    },
  },
  data() {
    return {
      divisaoResources: this.$api.divisao(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),

      passo: 2,
      parametrizacao: new Passo2(),
      headersDivisao: [
        {
          text: this.$tc('label.divisao', 1), width: '30%', value: 'nomeDivisao', sortable: false,
        },
        {
          text: this.$tc('label.estrutura_usuario', 1), width: '30%', value: 'nomEstruturaUsuario', sortable: false,
        },
        {
          text: this.$tc('label.acoes', 1), value: 'acoes', sortable: false, width: '10%',
        },
      ],
      headersEstrutura: [
        {
          text: this.$tc('label.estrutura_usuario', 1), width: '30%', value: 'nomeEstrutura', sortable: false,
        },
        {
          text: this.$tc('label.estrutura_pai', 1), width: '30%', value: 'estruturaUsuarioPai', sortable: false,
        },
        {
          text: this.$tc('label.acoes', 1), value: 'acoes', sortable: false, width: '10%',
        },
      ],
      camposCalculoRoi: [
        {
          nome: this.$tc('label.habilitar_calculo_roi', 1), value: 'habilitaCalculoRoi', label: 'habilitaCalculoRoi', obrigatorio: false, habilitado: false,
        },
        {
          nome: this.$tc('label.permitir_usuario_cliente_visualizar_roi', 1), value: 'permiteClienteVisualizarRoi', label: 'permiteClienteVisualizarRoi', obrigatorio: false, habilitado: false,
        },
      ],
      listaDivisoes: [],
      listaEstruturas: [],
      divisao: {},
      estrutura: {},
      searchInputDivisao: null,
      searchInputEstrutura: null,
    };
  },
  methods: {
    buscarDivisaoAutocomplete(content) {
      if (typeof content !== 'string') {
        content = null;
      }

      this.divisaoResources.selecao({ autocomplete: content }).then((response) => {
        this.listaDivisoes = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    setDivisao(hit) {
      if (!hit) return;

      if (this.parametrizacao.listaDivisao.find((item) => item.idDivisao === hit.id)) {
        this.$toast(this.$t('errors.divisao.ja_adicionado'));
        return;
      }

      hit.idDivisao = hit.id;
      const div = { ...hit };
      div.id = null;
      this.parametrizacao.listaDivisao.push(div);
      this.divisao = {};
    },
    removerDivisao(item) {
      const index = this.parametrizacao.listaDivisao.indexOf(item);
      this.parametrizacao.listaDivisao.splice(index, 1);
    },
    triggerDivisao() {
      document.getElementById('config_acao_divisao').click();
    },
    buscarEstruturaAutocomplete(content) {
      if (typeof content !== 'string') {
        content = null;
      }

      this.estruturaUsuarioResources.selecao({ autocomplete: content }).then((response) => {
        this.listaEstruturas = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    setEstrutura(hit) {
      if (!hit) return;

      if (this.parametrizacao.listaEstrutura.find((item) => item.idEstruturaUsuario === hit.id)) {
        this.$toast(this.$t('errors.estrutura.ja_adicionado'));
        return;
      }

      hit.idEstruturaUsuario = hit.id;
      const est = { ...hit };
      est.id = null;
      this.parametrizacao.listaEstrutura.push(est);
      this.estrutura = {};
    },
    removerEstrutura(item) {
      const index = this.parametrizacao.listaEstrutura.indexOf(item);
      this.parametrizacao.listaEstrutura.splice(index, 1);
    },
    triggerEstrutura() {
      document.getElementById('config_acao_estrutura').click();
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_ACAO_CANCELAR');
    },
    proximoPasso() {
      this.$emit('PARAMETRIZACAO_ACAO_CONTINUAR', this.passo + 1, this.id);
      this.$emit('PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_ACAO_VOLTAR', this.passo - 1);
    },
    prepararPasso() {
      if (this.id) {
        this.obter(this.id);
      }
    },
    obter(id) {
      this.paramAcaoResources.obterPasso2({ id }, { id }).then((response) => {
        this.parametrizacao = new Passo2(id, response.data.indAcessoCliente,
          response.data.listaDivisao, response.data.listaEstrutura,
          response.data.indHabilitarRateioContabil);
      }, (err) => {
        this.$error(this, err);
      });
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .card-header {
    background-color: var(--v-primary-base);
    color: #ffffff;
  }
</style>
