<template>
  <v-card style="margin-bottom:1em" class="RelatorioComparativoNotasFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.comparativo_notas', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <v-row>
          <v-col cols="12">
            <metadados-container-layout
              :ordenacao-campos="ordenacaoCampos"
              :campos-formulario="camposFormulario"
              :explode-hierarquia="true"
              :formulario-filtros="true"
              :ignora-inativos="false"
              :objeto="filtrosComparativoNotas"
              :input-layout="inputLayout"
              ref="container">
              <v-col cols="12" sm="6" md="3" slot="depois">
                <v-btn @click="resetaFiltros" color="accent">
                  {{$t('label.limpar_filtros')}}
                </v-btn>
                <v-btn @click="filtrar" color="primary" class="ml-3">
                  {{$t('label.filtrar')}}
                </v-btn>
              </v-col>
            </metadados-container-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="tableNotasDebito"
              :headers="headers"
              :items="notas"
              :options.sync="pagination"
              :server-items-length="totalPage"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50],
              }">
              <template v-slot:item.num_lote="{ item }">
                {{ item.numLote }}
              </template>
              <template v-slot:item.mes_ano_conferencia="{ item }">
                <div style="white-space: nowrap">
                  {{ item.mesAnoConferencia }}
                </div>
              </template>
              <template v-slot:item.data_criacao_comparativo="{ item }">
                <div style="white-space: nowrap">
                  {{ item.dataCriacaoComparativo }}
                </div>
              </template>
              <template v-slot:item.qtd_notas_valores_diferentes="{ item }">
                {{ item.qtdNotasValoresDiferentes }}
              </template>
              <template v-slot:item.qtd_notas_nao_encontradas="{ item }">
                {{ item.qtdNotasNaoEncontradas }}
              </template>
              <template v-slot:item.acoes="{ item }">
                <v-btn v-if="possuiDiff(item)" icon class="mx-0" @click="exportarRelatorio(item)">
                  <v-icon>get_app</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '@/produto/common/functions/loading';
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioComparativoNotasFiltrosCampos from './RelatorioComparativoNotasFiltrosCampos';

export default {
  name: 'RelatorioComparativoNotas',
  mixins: [
    RelatorioComparativoNotasFiltrosCampos,
  ],
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      magaluRelatoriosResource: this.$api.magaluRelatorios(this.$resource),

      notas: [],
      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      lastPagination: {},

      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosComparativoNotas: {},
      ordenacaoCampos: [
        'data_inicio',
        'data_fim',
      ],

      headers: [
        { text: this.$tc('label.controle', 1), width: '5%', value: 'num_lote' },
        { text: this.$tc('label.data_conferencia', 1), width: '20%', value: 'mes_ano_conferencia' },
        { text: this.$tc('label.data_criacao', 1), width: '20%', value: 'data_criacao_comparativo' },
        { text: this.$tc('label.qtd_valores_diferentes', 1), width: '10%', value: 'qtd_notas_valores_diferentes' },
        { text: this.$tc('label.qtd_notas_faltantes', 1), width: '10%', value: 'qtd_notas_nao_encontradas' },
        {
          text: this.$tc('label.exportar', 1), width: '10%', value: 'acoes', sortable: false,
        },
      ],

      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.lastPagination.page) {
          this.lastPagination = this.pagination;
          return;
        }
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
      }
      return true;
    },
    resetaFiltros() {
      this.filtrosComparativoNotas = {};
    },
    filtrar() {
      const filtrosAplicados = this.$refs.container.getValoresCamposPadrao();

      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      const params = {
        ...filtrosAplicados,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.magaluRelatoriosResource.buscarComparativoNotas(params)
        .then((res) => {
          const { resposta, quantidadeRegistrosPagina } = res.data;

          this.totalPage = quantidadeRegistrosPagina;
          this.notas = resposta;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    exportarRelatorio(item) {
      const params = {
        ...item,
      };

      this.$emit('RelatorioComparativoNotas__AplicaFiltros',
        params,
        'notas_comparativo_magalu',
        'api');
    },
    possuiDiff(item) {
      return item.qtdNotasNaoEncontradas !== 0 || item.qtdNotasValoresDiferentes !== 0;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-comparativo-notas';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-comparativo-notas';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosComparativoNotas;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
};
</script>
<style>
  .RelatorioComparativoNotasFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioComparativoNotasFiltros__Opcoes div.v-input__slot,
  .RelatorioComparativoNotasFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioComparativoNotasFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioComparativoNotasFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
