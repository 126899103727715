import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('api', 'configuracao-tatico/planejamento');

const configuracaoWorkflowContratoActions = {
  atualizar: { method: 'POST', url: `${basePath}/atualizar` },
  salvar: { method: 'POST', url: `${basePath}/salvar` },
  pesquisar: { method: 'GET', url: `${basePath}/{chave}` },
};
export default (resource) => resource(`${basePath}`, {}, configuracaoWorkflowContratoActions);
