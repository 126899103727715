<template>
  <div class="CamposCustomizados__Wrapper">
    <v-row>
      <v-col cols="12" sm="3" class="pt-0">
        <campos-customizados-form
          ref="camposCustomizadosForm"
          :idCampoCustomizado="idCampoCustomizado"
          @atualizarGrid="atualizarGrid"
          @limparIdCampoCustomizado="limparIdCampoCustomizado"/>
      </v-col>
      <v-col cols="12" sm="9" class="pt-0">
        <campos-customizados-tabela
          ref="camposCustomizadosTabela"
          id="campos-customizados-tabela"
          :entidades="entidades"
          @edit="editarCampo"
          class="CamposCustomizados__ContentTable"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import CamposCustomizadosForm from './CamposCustomizadosForm';
import CamposCustomizadosTabela from './CamposCustomizadosTabela';

export default {
  name: 'CamposCustomizados',
  data() {
    return {
      entidades: [],
      idCampoCustomizado: null,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('CONFIG_CAMPOS_CUSTOMIZADOS')).length;
    },
  },
  components: {
    CamposCustomizadosForm,
    CamposCustomizadosTabela,
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    atualizarGrid() {
      this.$refs.camposCustomizadosTabela.filtrar();
    },
    buscarEntidades() {
      this.entidades = this.$refs.camposCustomizadosForm.buscarEntidades();
    },
    editarCampo(item) {
      this.idCampoCustomizado = item.id;
      this.$refs.camposCustomizadosForm.iniciarEdicao(item);
    },
    limparIdCampoCustomizado() {
      this.idCampoCustomizado = null;
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
    this.buscarEntidades();
  },
};
</script>
<style>
  .CamposCustomizados__ContentTable {
    width: 100% !important;
  }
</style>
