import getBasePath from '../functions/api-resource';

const basePath = getBasePath('api', 'representante');

// Actions são ações que o resource terá.
// No exemplo abaixo, criamos uma ação 'list', que é um GET
// na url que aponta para a raíz da API
const representanteActions = {
  listarAtivos: { method: 'GET', url: `${basePath}?email={email}&ativo=true` },
  listarHierarquia: { method: 'GET', url: `${basePath}?ativo=true&hierarquia=true` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  canaisVenda: { method: 'GET', url: `${basePath}/canais-venda?nome={nome}&colunaOrdenacao=nom_extensao` },
};

// Exportamos então o recurso, que não passa de uma função que recebe um
// Vue.$resource como parâmetro e retorna um resource customizado.
// Vue.$resource tem a seguinte assinatura:
// function Resource(url, params, actions, options)

export default (resource) => resource(`${basePath}`, {}, representanteActions);
