export default {
  data() {
    return {
      magaluRelatoriosResource: this.$api.magaluRelatorios(this.$resource),

      camposFormularioMagalu: {
        padrao: [
          {
            labelCampo: 'categoria',
            nomCampoId: 'id_categoria',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: this.$refs.container ? this.$refs.container.getValoresCamposPadrao().length === 0 : true,
            async: {
              fetchFunction: (autocomplete) => this.magaluRelatoriosResource
                .buscarCategoria({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'familia',
            nomCampoId: 'id_familia',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: this.$refs.container ? this.$refs.container.getValoresCamposPadrao().length === 0 : true,
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresCamposPadrao(),
                };
                return this.magaluRelatoriosResource.buscarFamilia(parametros);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
        ],
      },
      camposFormularioKabum: {
        padrao: [
          {
            labelCampo: 'categoria',
            nomCampoId: 'id_categoria',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: this.$refs.container ? this.$refs.container.getValoresCamposPadrao().length === 0 : true,
            async: {
              fetchFunction: (autocomplete) => this.magaluRelatoriosResource
                .buscarCategoria({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'familia',
            nomCampoId: 'id_familia',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: this.$refs.container ? this.$refs.container.getValoresCamposPadrao().length === 0 : true,
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresCamposPadrao(),
                };
                return this.magaluRelatoriosResource.buscarFamilia(parametros);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
        ],
      },
    };
  },
};
