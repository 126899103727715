<template>
  <div class="ExtensoesRecursivas__Wrapper">
    <v-row>
      <v-col cols="12" sm="3">
        <metadados-extensao-form
          ref="ExtensoesRecursivasForm"
          :id="idExtensao"
          :idNivelExtensao="idNivelExtensao"
          :somenteLeitura="somenteLeitura"
          :indRecursiva="item.indRecursiva"
          :tipoCadastro="$tc(`label.${item.label}`, 1)"
          :input-layout="inputLayout">
          <template slot="contentPreForm">
            <v-col cols="12">
              <v-select
                class="Form-text__invalid"
                id="tipo"
                name="tipo"
                item-text="nome"
                item-value="id"
                return-object
                clearable
                :items="tipos"
                :disabled="!!idExtensao || somenteLeitura"
                @input="carregarExtensoesPais"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :label="`${$tc('label.tipo', 1)} *`"
                v-model="objeto.tipo"
                :rules="[rules.required]">
              </v-select>
            </v-col>
          </template>
          <template slot="contentPosForm">
            <v-col cols="12">
              <v-autocomplete
                class="Form-text__invalid"
                id="pai"
                name="pai"
                v-model="objeto.pai"
                item-text="nomExtensao"
                item-value="id"
                :label="`${$tc('label.pai', 1)} ${montarDescricaoTipoLabel()} ${maiorIndice === (objeto.tipo && objeto.tipo.indice ? objeto.tipo.indice : objeto.indice) ? '' : '*'}`"
                :items="pais"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :filter="filtroPai"
                :search-input.sync="buscaListaPais"
                :rules="[rules.requiredPai]"
                :disabled="!!idExtensao || somenteLeitura"
                clearable
                @click.native="buscarPaiAutocomplete"
                return-object>
              </v-autocomplete>
            </v-col>
          </template>
          <template slot="button">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.native="cancelar" :disabled="somenteLeitura">{{ $t('label.cancelar') }}</v-btn>
              <v-btn @click.native="abrirDialogSalvar" color="primary" :disabled="somenteLeitura">{{ $t('label.salvar') }}</v-btn>
            </v-card-actions>
          </template>
        </metadados-extensao-form>
      </v-col>
      <v-col cols="12" sm="9">
        <tabela-recursiva
          ref="ExtensoesRecursivasTabela"
          id="extensoes-recursivas-tabela"
          :titlePage="item.indRecursiva ? `${$t('label.hierarquia_de')}: ${item.entidadeEstrangeira}` : item.entidadeEstrangeira"
          :idNivelExtensao="idNivelExtensao"
          @edit="editarCampo"
          :somenteLeitura="somenteLeitura"
          class="ExtensoesRecursivas__ContentTable"/>
      </v-col>
    </v-row>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: $tc(`label.${item.label}`, 1) })"
      :persistent="true"
      @agree="salvar">
    </confirm>
  </div>
</template>

<script type="text/javascript">
import MetadadosExtensaoForm from '../../../shared-components/metadadosExtensao/MetadadosExtensaoForm';
import TabelaRecursiva from './TabelaRecursiva';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'TabelaGenericaRecursiva',
  data() {
    return {
      idExtensao: null,
      inputLayout: {
        ml1: false,
        mt1: false,
        mt2: true,
      },
      maiorIndice: null,
      pais: [],
      objeto: {
        indAtivo: true,
      },
      objetoLabelSelecionado: {},
      tipos: [],
      buscaListaPais: null,
      extensaoResources: this.$api.extensao(this.$resource),
      nivelClienteResources: this.$api.nivelCliente(this.$resource),
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredPai: value => this.tipoValido() ? true : // eslint-disable-line
          (!this.tipoValido() && !value ? this.$t('message.campo_obrigatorio') : true), // eslint-disable-line
      },
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    idNivelExtensao: Number,
  },
  components: {
    TabelaRecursiva,
    Confirm,
    MetadadosExtensaoForm,
  },
  watch: {
    buscaListaPais(val) {
      if (this.timeoutCliente) {
        window.clearTimeout(this.timeoutCliente);
      }
      this.timeoutCliente = window.setTimeout(() => {
        this.buscarPaiAutocomplete(val);
      }, 500);
    },
  },
  methods: {
    abrirDialogSalvar() {
      if (!this.$refs.ExtensoesRecursivasForm.validarFormulario()) return;
      this.$refs.confirmDialog.open();
    },
    atualizarGrid() {
      this.$refs.ExtensoesRecursivasTabela.filtrar();
    },
    buscarMetadadosLabels() {
      return this.nivelClienteResources.labels().then((resultado) => {
        this.tipos = resultado.data;
        this.maiorIndice = Math.max(...this.tipos.map((tipo) => tipo.indice));
      });
    },
    cancelar() {
      this.limparFormulario();
    },
    carregarExtensoesPais(objeto, item = null) {
      if (!objeto) return;
      this.objetoLabelSelecionado = objeto;
      if (item && item.idPai) {
        this.pais = [{
          nomExtensao: item.nomExtensaoPai,
          id: item.idPai,
        }];
      }
    },
    filtroPai(item, queryText, itemText) {
      const codPai = item.idExterno;
      const searchText = queryText.toLowerCase();

      return codPai.toLowerCase().indexOf(searchText) > -1
        || itemText.toLowerCase().indexOf(searchText) > -1;
    },
    buscarPaiAutocomplete(busca = null) {
      const filtroDesAtributo = 'indiceLabel';
      const filtroDesAtributoValor = this.objetoLabelSelecionado.indice + 1;
      const params = {
        idNivelExtensao: this.idNivelExtensao,
        autocomplete: busca || this.buscaListaPais,
        limit: 30,
        tamanhoPagina: 30,
        page: 0,
        filtroDesAtributo,
        filtroDesAtributoValor,
      };

      this.extensaoResources.listarAtivos(params).then((resultado) => {
        this.pais = resultado.data.resposta;
      }, (err) => {
        this.$error(this, err);
      });
    },
    editarCampo(item) {
      this.idExtensao = item.id;
      item = this.setAtributos(item);
      this.$refs.ExtensoesRecursivasForm.iniciarEdicao(item);
    },
    limparFormulario() {
      this.$refs.ExtensoesRecursivasForm.limparFormulario();
      this.objetoLabelSelecionado = {};
      this.pais = [];
      this.idExtensao = null;
    },
    montarDesAtributos() {
      const desAtributos = {};

      if (this.objetoHasProperties(this.objetoLabelSelecionado)) {
        return desAtributos;
      }

      desAtributos.nomeLabel = this.objetoLabelSelecionado.nome;
      desAtributos.indiceLabel = this.objetoLabelSelecionado.indice;
      return desAtributos;
    },
    montarDescricaoTipoLabel() {
      if (this.pais && this.pais.length > 0) {
        return this.pais[0].desAtributos && this.pais[0].desAtributos.nomeLabel
          ? `(${this.pais[0].desAtributos.nomeLabel})` : '';
      }
      return '';
    },
    objetoHasProperties(objeto) {
      if (typeof (objeto) === 'number') {
        return false;
      }

      return Object.keys(objeto).length === 0
        && objeto.constructor === Object;
    },
    prepararObjeto() {
      const objetoComponente = this.$refs.ExtensoesRecursivasForm.prepararObjeto();
      this.objeto.pai = this.objeto.pai
        ? this.objeto.pai.id || this.objeto.pai : null;
      this.objeto.id = objetoComponente.id;
      this.objeto.idExterno = objetoComponente.idExterno;
      this.objeto.nomExtensao = objetoComponente.nomExtensao;
      this.objeto.indAtivo = objetoComponente.indAtivo;
      this.objeto.idNivelExtensao = this.idNivelExtensao;
      this.objeto.desAtributos = this.montarDesAtributos();
      return this.objeto;
    },
    salvar() {
      if (this.idExtensao) {
        this.extensaoResources.atualizarAutoRelacionamento({ id: this.id }, this.prepararObjeto())
          .then(() => {
            this.$toast(this.$t('message.atualizado_confira_tabela'));
            this.limparFormulario();
            this.atualizarGrid();
          }, (err) => {
            this.$error(this, err);
          });
      } else {
        this.extensaoResources.gravarAutoRelacionamento(this.prepararObjeto())
          .then(() => {
            this.$toast(this.$t('message.adicionado_confira_tabela'));
            this.limparFormulario();
            this.atualizarGrid();
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    setAtributos(item) {
      item.tipo = item.idMetadadosExtensaoRecursiva;
      item.pai = item.idPai;
      this.carregarExtensoesPais(this.tipos.filter((tipo) => tipo.id === item.tipo)[0], item);
      this.objeto.tipo = item.idMetadadosExtensaoRecursiva;
      this.objeto.indice = parseInt(item.indice, 10);
      this.objeto.pai = item.idPai;
      return item;
    },
    tipoValido() {
      if (this.objeto) {
        return this.maiorIndice === (this.objeto.tipo && this.objeto.tipo.indice
          ? this.objeto.tipo.indice : this.objeto.indice);
      }
      return true;
    },
  },
  mounted() {
    this.buscarMetadadosLabels();
  },
};
</script>
<style>
  .ExtensoesRecursivas__ContentTable {
    width: 100% !important;
  }
</style>
