function ehObjeto(objeto) {
  return objeto instanceof Object;
}

function objetoVazio(objeto) {
  return Object.keys(objeto).length === 0 && objeto.constructor === Object;
}

function ehArray(objeto) {
  return Array.isArray(objeto);
}

function parsearMetadados(itemEmUmaString, manyToMany) {
  if (manyToMany) {
    if (typeof itemEmUmaString === 'object') {
      return itemEmUmaString;
    }
    if (!itemEmUmaString) {
      return [];
    }
    return itemEmUmaString.split(',').map((umaEntidadeEmString) => {
      const arrayComAtributosDaEntidade = umaEntidadeEmString.split('|');
      return {
        id: parseInt(arrayComAtributosDaEntidade[0], 10),
        idExterno: arrayComAtributosDaEntidade[1],
        nomExtensao: arrayComAtributosDaEntidade[2],
      };
    });
  }

  if (!itemEmUmaString) {
    return {};
  }

  let arrayComAtributosDaEntidade = null;
  if (ehObjeto(itemEmUmaString)) {
    return itemEmUmaString;
  }

  arrayComAtributosDaEntidade = itemEmUmaString.split('|');
  return {
    id: parseInt(arrayComAtributosDaEntidade[0], 10),
    idExterno: arrayComAtributosDaEntidade[1],
    nomExtensao: arrayComAtributosDaEntidade[2],
  };
}

export const getNomesSeparadosPorVirgula = (nomeObjeto, atributo, instanciaEntidade) => {
  if (!instanciaEntidade[nomeObjeto]
  || (ehObjeto(instanciaEntidade[nomeObjeto]) && objetoVazio(instanciaEntidade[nomeObjeto]))
  || (ehArray(instanciaEntidade[nomeObjeto]) && instanciaEntidade[nomeObjeto].length === 0)) {
    return '';
  }

  if (ehArray(instanciaEntidade[nomeObjeto])) {
    return instanciaEntidade[nomeObjeto].map((obj) => obj[atributo]).join(', ');
  }
  return instanciaEntidade[nomeObjeto][atributo];
};

export const montarEstruturaDinamica = (dadosDaConsulta, metadados, instanciaEntidade) => {
  Object.keys(dadosDaConsulta).forEach((dados) => {
    Object.keys(metadados.mapaEntidades).forEach((entidade) => {
      if (dados === entidade) {
        instanciaEntidade[entidade] = parsearMetadados(dadosDaConsulta[entidade],
          metadados.mapaEntidades[entidade].manyToMany);
      }
    });
  });
  return instanciaEntidade;
};
