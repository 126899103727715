import ParametrizacaoOrcamento from '../../spa/parametrizacao/orcamento/ParametrizacaoOrcamento';
import ParametrizacaoOrcamentoList from '../../spa/parametrizacao/orcamento/ParametrizacaoOrcamentoList';
import ParametrizacaoOrcamentoForm from '../../spa/parametrizacao/orcamento/ParametrizacaoOrcamentoForm';

export default {
  path: 'parametrizacao-orcamento',
  component: ParametrizacaoOrcamento,
  children: [
    {
      path: '',
      name: 'parametrizacao-orcamento',
      component: ParametrizacaoOrcamentoList,
    },
    {
      path: 'novo',
      name: 'novoParametrizacaoOrcamento',
      component: ParametrizacaoOrcamentoForm,
    },
    {
      path: ':id/editar',
      name: 'editarParametrizacaoOrcamento',
      component: ParametrizacaoOrcamentoForm,
    },
  ],
};
