const mutations = {
  setControladores(state, controladores) {
    state.controladores = controladores;
  },
};

const actions = {
  carregarControladores({ commit }, resource) {
    return new Promise((resolve, reject) => {
      resource().then((response) => {
        const controladores = response.data || [];
        commit('setControladores', controladores);
        resolve();
      }).catch(reject);
    });
  },
};

const getters = {
  getControladores(state) {
    return state.controladores;
  },
};

const state = {
  controladores: [],
  controladoreSelecionado: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
