import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('api', 'conta-corrente-origem-verba');

const origemVerbaActions = {
  buscarAtivo: { method: 'GET', url: `${basePath}?ativo=true` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  ultimoAno: { method: 'GET', url: `${basePath}/{id}/ultimo-ano` },
  executarAcao: { method: 'PUT', url: `${basePath}/{id}/{entidade}/{idEntidade}/{acao}` },
};

export default (resource) => resource(`${basePath}`, {}, origemVerbaActions);
