<template>
  <div :key="divKey">
    <v-row>
      <div v-if="apuracaoPrevia">
        <v-col cols="12" v-if="existeCalculoRetroativo">
          <span class="text-subtitle-1 font-weight-bold" style="color:#F57C00;">{{$t('message.periodo_nao_finalizado_campanha_retroativa')}}</span>
        </v-col>
        <v-col cols="12" v-else>
          <span class="text-subtitle-1 font-weight-bold" style="color:#F57C00;">{{$t('message.periodo_nao_finalizado_vigencia_ativa')}}</span>
        </v-col>
      </div>
      <div v-if="aguardandoApuracao">
        <v-col cols="12" v-if="isSellin">
          <span class="text-subtitle-1 font-weight-bold" style="color:#F57C00;">{{$t('message.apuracao_previa_finalizada_sellin')}}</span>
        </v-col>
        <v-col cols="12" v-else-if="isSellout">
          <span class="text-subtitle-1 font-weight-bold" style="color:#F57C00;">{{$t('message.apuracao_previa_finalizada_sellout')}}</span>
        </v-col>
      </div>
          </v-row>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="cabecalho"
            :items="fornecedoresNd"
            :key="tableKey"
            hide-default-footer
            disable-pagination
            :items-per-page="-1"
            :no-data-text="msgSemDadosTabela">
            <template v-slot:[`item.exportar`]="{ item }">
              <tbody>
                <td class="justify-center px-0" style="width: 1%; text-align: center;">
                  <v-tooltip bottom v-if="podeExportarNota(item)">
                    <template v-slot:activator="{ on }">
                      <v-btn icon class="mx-0" v-on="on" @click="exportarNotas(item)">
                        <v-icon>get_app</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.exportar') }}</span>
                  </v-tooltip>
                </td>
              </tbody>
            </template>
            <template v-slot:[`item.num_nota_debito`]="{ item }">
              <div v-if="isMultiplasNds && somenteLeitura && item.listaMultiplasNds.length">
                <v-btn icon class="mx-0" @click.stop="abrirModalDividirRecebimento()">
                  <v-icon>visibility</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="!isStatusCanceladoOuReprovado"
                      v-on="on"
                      icon
                      @click="imprimirNota(item)">
                      <v-icon>receipt</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc('label.imprimir_nd', 2) }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="!isMultiplasNds && item.verbaManual && item.verbaManual > 0">
                {{ item.numNotaDebito }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="!isStatusCanceladoOuReprovado"
                      v-on="on"
                      icon
                      @click="imprimirNota(item)">
                      <v-icon>receipt</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc('label.imprimir_nd', 1) }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.cod_fornecedor`]="{ item }">
              <div style="white-space: nowrap">
                {{ item.codFornecedor }}
              </div>
            </template>
            <template v-slot:[`item.cnpj`]="{ item }">
              <div style="white-space: nowrap">
                {{ item.cnpj }}
              </div>
            </template>
            <template v-slot:[`item.des_razao_social`]="{ item }">
              <div style="white-space: nowrap">
                {{ item.desRazaoSocial }}
              </div>
            </template>
            <template v-if="!apuracaoCancelado" v-slot:[`item.descricao`]="{ item }">
              <td>
                  <span style="white-space: nowrap">
                    {{ item.descricao | truncate(50) }}
                  </span>
                  <v-btn
                      icon
                      @click="abrirModalDescricaoNd(item)">
                    <v-icon>description</v-icon>
                  </v-btn>
              </td>
            </template>
            <template v-slot:[`item.valorReal`]="{ item }">
              <div style="white-space: nowrap">
                {{ getMoney(item.valorReal) }}
              </div>
            </template>
            <template v-slot:[`item.valorApuradoReal`]="{ item }">
              <div style="white-space: nowrap">
                {{ getMoney(item.valorApuradoReal) }}
              </div>
            </template>
            <template v-slot:[`item.valorCalculado`]="{ item }">
              <div style="white-space: nowrap">
                {{ getMoney(item.valorCalculado) }}
              </div>
            </template>
            <template v-slot:[`item.verbaManual`]="{ item }">
              <td>
                <div style="white-space: nowrap" v-if="somenteLeitura || !podeEditarValorNd">
                  {{ getMoney(item.verbaManual) }}
                </div>
                <input-money
                  v-else
                  :min="0"
                  :disabled="isIniciandoFluxo"
                  v-model="item.verbaManual"
                  class="v-text-field"
                  @change="(novoValor) => valorNdAlterado(novoValor, item)"/>
              </td>
              <td>
                <v-btn
                  icon
                  v-if="item.justificativa && (getMoney(item.verbaManual) !== getMoney(item.valorCalculado))"
                  align="right"
                  @click="abrirModalJustificativa(item)">
                  <v-icon>description</v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:[`body.append`]="{ headers }">
              <tr>
                <td :colspan="headers.length - 1"></td>
                <td class="nowrap"><span class="text-h7"  style="font-weight:bold;font-style:italic;">{{ getMoney(valorTotal) }}</span></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-if="(apuracaoEmAnalise || isStatusCanceladoOuReprovado) && apuracao.justificativa">
      <v-col cols="6">
        <v-textarea
          rows="1"
          auto-grow
          :label="$t('label.justificativa')"
          :value="apuracao.justificativa"
          disabled
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels class="px-6 pb-6" v-model="panel">
          <apuracao-acao-arquivos
            :id="`apuracao-acao-arquivos${apuracao.id}`"
            :apuracao="apuracao"
            col-xs="12"
            col-sm="6"
            col-md="4"
            col-lg="3"
            @ApuracaoAcaoArquivos_itensAlterados="listaArquivosAlterados"
            @ApuracaoAcaoArquivos_uploadSucesso="uploadSucesso"
            @ApuracaoAcaoArquivos_arquivosRemovidos="arquivosRemovidos"
            @ApuracaoAcaoArquivos_possuiArquivos="possuiArquivos">
          </apuracao-acao-arquivos>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="ma-0" v-if="fornecedoresNd.length && isMultiplasNds" v-show="!isValoresNdsIgualTotalApuracao">
      <v-spacer></v-spacer>
      <span class="font-weight-bold" style="color:#EF5350;" v-if="isValoresNdsAbaixoTotalApuracao">
        {{$t('message.multiplas_nds.valor_nds_nao_atingiu_total')}}
      </span>
      <span class="font-weight-bold" style="color:#EF5350;" v-if="isValoresNdsExcedemTotalApuracao">
        {{$t('message.multiplas_nds.valor_nds_excede_total_faturamento_fornecedot')}}
      </span>
    </v-row>
    <v-row class="ma-0">
      <v-spacer></v-spacer>
      <v-btn
        v-if="podeAlterarValor && podeEditarValorNd"
        color="primary"
        @click="salvar()">
        {{ $t('label.salvar') }}
      </v-btn>
      <v-btn
        v-if="isMultiplasNds && podeSolicitarAprovacao"
        @click="abrirModalDividirRecebimento()"
        class="ml-3"
        color="primary">
        {{ $t('label.dividir_recebimento') }}
      </v-btn>
      <v-btn
        v-if="podeSolicitarAprovacao"
        :disabled="fornecedoresNd.length && isMultiplasNds && !isValoresNdsIgualTotalApuracao"
        @click="confirmarSolicitacaoAprovacao()"
        class="ml-3"
        :dark="!fornecedoresNd.length || !isMultiplasNds || isValoresNdsIgualTotalApuracao"
        color="red">
      {{ $t('label.solicitar_aprovacao') }}
      </v-btn>
      <v-btn @click="abrirJustificativaAprovar()"
        class="mr-3"
        dark
        color="primary"
        v-show="podeAprovar">
        {{ $t('label.aprovar') }}
      </v-btn>
      <v-btn @click="abrirJustificativaReprovar()"
        class="mr-3"
        dark
        color="red"
        v-show="podeAprovar">
        {{ $t('label.reprovar') }}
      </v-btn>
      <v-btn @click="abrirJustificativaAnalise()"
        class="mr-3"
        dark
        color="tertiary lighten-1"
        v-show="podeAprovar">
        {{ $tc('label.enviar_analise', 1) }}
      </v-btn>
    </v-row>
    <confirm
      ref="modalConfirmacao"
      :message="msgConfirmacao"
      :persistent="true"
      hide-disagree
      :agree-label="$t('label.ok')"/>
    <confirm
      ref="modalConfirmacaoSolicitarAprovacao"
      :message="mensagemSolicitarAprovacao"
      :persistent="true"
      @agree="abrirRecomendacaoAnexo()"
      :agree-label="$t('label.sim')"
      :disagree-label="$t('label.nao')"/>
    <confirm
      ref="modalRecomendacaoAnexo"
      :message="$tc('message.recomendacao_inserir_anexo', 1)"
      :persistent="true"
      @agree="salvarAntesDeSolicitar()"
      :agree-label="$t('label.ok')"
      hide-disagree/>
    <planejamento-acao-form-usuario-cliente
      ref="modalUsuarioCliente"
      :title="$tc('title.selecao_aprovador_fornecedor', 1)"
      :passoCliente="passoCliente"
      @selecionarUsuarioCliente="selecionaAprovadorPassoClienteFornecedor"/>
    <modal-descricao-nd
      ref="modalDescricaoNd"
      :nota-debito="notaDebito"
      :somente-leitura="somenteLeitura"
      @alterarDescricao="alterarDescricao">
    </modal-descricao-nd>
    <modal-justificativa
      ref="modalJustificativa"
      :nota-debito="notaDebito"
      :somente-leitura="somenteLeitura">
    </modal-justificativa>
    <modal-multiplas-nds
      ref="modalMultiplasNds"
      :apuracao="apuracao"
      :somente-leitura="somenteLeitura"
      @ModalGerarNdApuracaoFornecedores__fecharModalMultiplasNds="setaListaMultiplasNds">
    </modal-multiplas-nds>
    <apuracao-acao-lista-modal-gerar-nd-apuracao-fornecedores-fluxo
      ref="modalAprovacaoJustificativa"
      :validar-justificativa="justificativaObrigatoria"
      :salvar-justificativa="actionJustificativa"
      :texto-acao="textoAcao"
      :id-acao="idAcao"
      :id-apuracao="idApuracao"
      :is-aprovacao="isAprovacao"/>
  </div>
</template>

<script>
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import { getMoney, getNumber } from '../../common/functions/helpers';
import { forceLoading, forceHideLoading } from '../../common/functions/loading';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import getBasePath from '../../common/functions/api-resource';
import download from '../../common/resources/downloads';
import InputMoney from '../../shared-components/inputs/InputMoney';
import ApuracaoAcaoArquivos from './ApuracaoAcaoArquivos';
import PlanejamentoAcaoFormUsuarioCliente from '../planejamento-acao/form/PlanejamentoAcaoFormUsuarioCliente';
import ModalDescricaoNd from './ModalDescricaoNd';
import ModalJustificativa from './ModalJustificativa';
import exportacao from '../../common/functions/exportacao';
import ModalMultiplasNds from './ModalMultiplasNds';
import ApuracaoAcaoListaModalGerarNdApuracaoFornecedoresFluxo from './ApuracaoAcaoListaModalGerarNdApuracaoFornecedoresFluxo';

export default {
  name: 'ApuracaoAcaoListaModalGerarNdApuracaoFornecedores',
  props: {
    apuracao: Object,
    buscarApuracao: Function,
  },
  data() {
    return {
      apuracaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      panel: 0,
      tableKey: 1,
      divKey: 1,

      fornecedoresNd: [],
      solicitanteFluxo: null,
      msgConfirmacao: null,
      passoCliente: {},

      cabecalho: [],
      notaDebito: null,
      msgSemDadosTabela: this.$tc('label.tabela_sem_conteudo'),
      isIniciandoFluxo: false,
      mensagemSolicitarAprovacao: this.$tc('message.multiplas_nds.confirma_solicitar_aprovacao', 1),
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      isAprovacao: false,
      textoAcao: '',
    };
  },
  watch: {
    apuracao: {
      handler() {
        if (!this.aguardandoApuracao && !this.apuracaoPrevia && !this.apuracaoEmAnalise) {
          this.buscarFornecedoresNd();
        }
      },
      deep: true,
    },
  },
  components: {
    PlanejamentoAcaoFormUsuarioCliente,
    Confirm,
    InputMoney,
    ApuracaoAcaoArquivos,
    ModalJustificativa,
    ModalDescricaoNd,
    ModalMultiplasNds,
    ApuracaoAcaoListaModalGerarNdApuracaoFornecedoresFluxo,
  },
  filters: {
    truncate(value, limit) {
      if (value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    idAcao() {
      return this.apuracao.idAcao;
    },
    idApuracao() {
      return this.apuracao.id;
    },
    valorTotal() {
      return this.fornecedoresNd.reduce((a, b) => a + b.verbaManual, 0);
    },
    isStatusAprovado() {
      return this.apuracao.status === 'APROVADO';
    },
    isStatusCanceladoOuReprovado() {
      return this.apuracaoCancelado || this.apuracaoReprovado;
    },
    podeSolicitarAprovacao() {
      return this.existeValorDefinido
          && (this.aguardandoApuracao || this.apuracaoEmAnalise)
          && this.solicitanteFluxo
          && !this.existeValorNegativo;
    },
    podeAlterarValor() {
      return (this.aguardandoApuracao || this.apuracaoEmAnalise) && this.canEdit;
    },
    somenteLeitura() {
      return !this.podeAlterarValor;
    },
    apuracaoEmAnalise() {
      return (this.apuracao && this.apuracao.status === 'EM_ANALISE');
    },
    apuracaoReprovado() {
      return (this.apuracao && this.apuracao.status === 'REPROVADO');
    },
    aguardandoApuracao() {
      return (this.apuracao && this.apuracao.status === 'AGUARDANDO_APURACAO');
    },
    aguardandoAprovacao() {
      return (this.apuracao && this.apuracao.status === 'AGUARDANDO_APROVACAO');
    },
    apuracaoPrevia() {
      return (this.apuracao && this.apuracao.status === 'APURACAO_PREVIA');
    },
    isSellin() {
      return (this.apuracao && this.apuracao.modalidade === 'SELLIN');
    },
    isSellout() {
      return (this.apuracao && this.apuracao.modalidade === 'SELLOUT');
    },
    apuracaoCancelado() {
      return (this.apuracao && this.apuracao.status === 'CANCELADO');
    },
    existeValorDefinido() {
      return this.fornecedoresNd.map((f) => f.verbaManual)
        .reduce((a, b) => a + b, 0) > 0;
    },
    existeValorNegativo() {
      return this.fornecedoresNd.filter((f) => f.verbaManual < 0).length;
    },
    permissaoExportarNFSellout() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles
        .filter((el) => el === 'VISUALIZAR_INFORMACOES_SELLOUT').length > 0;
    },
    podeEditarValorNd() {
      return this.apuracao.indNdEditavel;
    },
    isMultiplasNds() {
      return this.apuracao.qtdNds && this.apuracao.qtdNds === 'MULTIPLAS';
    },
    existeCalculoRetroativo() {
      return this.apuracao && this.apuracao.indCalculaRetroativo;
    },
    valorAlocado() {
      if (!this.fornecedoresNd[0].listaMultiplasNds || !this.fornecedoresNd[0].listaMultiplasNds.length) {
        return 0;
      }
      return Number(this.fornecedoresNd[0].listaMultiplasNds.reduce((a, b) => a + b.valorNd, 0).toFixed(2));
    },
    valorPendente() {
      return this.fornecedoresNd[0].verbaManual - this.valorAlocado;
    },
    isValoresNdsIgualTotalApuracao() {
      return this.fornecedoresNd[0].verbaManual > 0 && this.valorPendente === 0;
    },
    isValoresNdsExcedemTotalApuracao() {
      return this.fornecedoresNd[0].verbaManual > 0 && this.valorPendente < 0;
    },
    isValoresNdsAbaixoTotalApuracao() {
      return this.fornecedoresNd[0].verbaManual > 0 && this.valorPendente > 0;
    },
    podeAprovar() {
      return this.apuracao && this.aguardandoAprovacao && this.aprovadorFluxo;
    },
  },
  methods: {
    getMoney,
    getNumber,
    salvar() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const apuracao = {};
      apuracao.id = this.apuracao.id;
      apuracao.valorRealizado = this.getValorRealizado();
      apuracao.vlrConfApuracao = this.getVlrConfApuracao();

      this.atualizarNd(() => this.$toast(this.$t('message.salvar_nd_previa_sucesso')));
      this.apuracaoAcaoResource.atualizar(apuracao);
    },
    salvarAntesDeSolicitar() {
      this.atualizarNd(this.solicitarAprovacao, true);
    },
    podeExportarNota(nota) {
      return (nota.calculoBonificacao === 'SELLOUT' && nota.possuiNotas
            && this.statusPermitidosExportarSellout(nota.status))
        || ((!nota.calculoBonificacao || nota.calculoBonificacao !== 'SELLOUT') && nota.possuiNotas);
    },
    statusPermitidosExportarSellout(status) {
      return ['APROVADO', 'AGUARDANDO_APURACAO', 'EM_ANALISE', 'AGUARDANDO_APROVACAO', 'CANCELADO'].includes(status);
    },
    atualizarNd(callback, isSolicitandoAprovacao = false) {
      if (this.validarFornecedores()) {
        this.$toast(this.$t('errors.apuracao.verificar_justificativa_alteracao_verba_manual'));
        return;
      }
      const nds = [];
      this.fornecedoresNd.forEach((fornecedor) => {
        const params = {
          idNotaDebitoPrevia: fornecedor.idNotaDebitoPrevia,
          verbaManual: fornecedor.verbaManual || 0.0,
          valorCalculado: fornecedor.valorCalculado,
          idApuracao: fornecedor.idApuracao,
          justificativa: this.validarJustificativaFornecedor(fornecedor),
        };
        fornecedor.justificativa = this.validarJustificativaFornecedor(fornecedor);
        nds.push(params);
      });
      forceLoading();
      this.apuracaoAcaoResource.atualizarNdPrevia(nds)
        .then(() => {
          this.apuracaoEditada(this.apuracao.id, true);
          callback();
          if (!isSolicitandoAprovacao) {
            forceHideLoading();
            this.$emit('ApuracaoAcaoListaModalGerarNdApuracaoFornecedores__recarregarApuracoes');
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    validarFornecedores() {
      return this.fornecedoresNd.find((nd) => !nd.justificativa && (getMoney(nd.valorCalculado) !== getMoney(nd.verbaManual))
                                              && !nd.justificativa.length);
    },
    validarJustificativaFornecedor(fornecedor) {
      return getMoney(fornecedor.valorCalculado) !== getMoney(fornecedor.verbaManual) ? fornecedor.justificativa : '';
    },
    buscarFornecedoresNd() {
      return this.apuracaoListagemResource.buscarFornecedoresNd({ idApuracao: this.apuracao.id })
        .then((res) => {
          if (this.apuracaoCancelado) {
            this.fornecedoresNd = res.data.filter((f) => f.valorCalculado > 0);
            if (!this.fornecedoresNd.length) {
              this.msgSemDadosTabela = this.$tc('label.tabela_sem_conteudo_status_cancelado');
            }
          } else if (!this.aguardandoApuracao && !this.apuracaoPrevia && !this.apuracaoEmAnalise) {
            this.fornecedoresNd = res.data.filter((f) => f.verbaManual > 0);
            this.emitirValorTotalNdAlterado();
          } else {
            this.fornecedoresNd = res.data;
          }
        });
    },
    imprimirNota(item) {
      const {
          idRecebimento,
          idsRecebimentos,
          idAcao,
          idNotaDebitoPrevia,
        } = item,
        numeroND = item.numNotaDebito;

      if (!idRecebimento && !idsRecebimentos) {
        this.imprimirNDPrevia(item);
        return;
      }

      if (!idRecebimento && idsRecebimentos && item.qtdNds === 'MULTIPLAS') {
        const basePath = getBasePath('job', 'recebimento/relatorio');
        const resource = download(this.$http);

        resource.downloadGet(basePath, {
          param: '',
          url: `nota-debito-multiplas-nds/${idAcao}/${idNotaDebitoPrevia}`,
        }).catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
          forceHideLoading();
        });
      } else {
        const basePath = getBasePath('job', 'recebimento/relatorio');
        const resource = download(this.$http);

        resource.downloadGet(basePath, {
          param: `?numNota=${numeroND}`,
          url: `nota-debito/${idRecebimento}`,
        }).catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
          forceHideLoading();
        });
      }
    },
    imprimirNDPrevia(item) {
      const isAgrupadoFornecedorPagador = item.agrupadoFornecedorPagador ? item.agrupadoFornecedorPagador : false;
      const { idAcao, idApuracao, idFornecedor } = item,
        basePath = getBasePath('job', 'recebimento/relatorio'),
        resource = download(this.$http);

      resource.downloadGet(basePath, {
        param: '',
        url: `nota-debito-por-fornecedor/preview/${idAcao}/${idApuracao}/${idFornecedor}/${isAgrupadoFornecedorPagador}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
        forceHideLoading();
      });
    },
    solicitarAprovacao() {
      const apu = {};
      apu.id = this.apuracao.id;
      apu.valorRealizado = this.getValorRealizado();
      apu.vlrConfApuracao = this.getVlrConfApuracao();
      this.apuracaoAcaoResource.atualizar(apu)
        .then(() => {
          this.aprovadorFornecedor();
        })
        .catch((err) => {
          forceHideLoading();
          this.$error(this, err);
        });
    },
    aprovadorFornecedor() {
      const idApuracao = this.apuracao.id;
      const resource = this.workflowApuracaoAcaoResource.buscaPassosFornecedor;
      forceHideLoading();
      resource({ idApuracao })
        .then((response) => {
          const passosCliente = response.data;
          if (passosCliente.length === 0) {
            forceLoading();
            const promises = [];
            if (this.isMultiplasNds) {
              promises.push(this.gerarNumeroNdMultiplasNds());
            }
            promises.push(this.iniciarFluxo());
            Promise.all(promises)
              .then()
              .catch((err) => {
                forceHideLoading();
                this.$error(this, err);
              });
          } else if (passosCliente.length > 1) {
            this.msgConfirmacao = this.$t('errors.acordo.multiplos_passos_fornecedor');
            setTimeout(() => this.$refs.modalConfirmacao.open());
          } else if (!passosCliente[0].usuarios.length) {
            this.msgConfirmacao = this.$t('message.nao_tem_usuario_fornecedor_aprovador');
            setTimeout(() => this.$refs.modalConfirmacao.open());
          } else {
            // Implementado suporte para apenas 1 passo cliente/fornecedor
            const [passoCli] = passosCliente;
            this.passoCliente = passoCli;
            this.$refs.modalUsuarioCliente.open();
          }
        })
        .catch((err) => {
          forceHideLoading();
          this.$error(this, err);
        });
    },
    selecionaAprovadorPassoClienteFornecedor(idPasso, idUsuario) {
      const idApuracao = this.apuracao.id;
      const params = {
        idApuracao,
        idPasso,
        idUsuario,
      };
      const resource = this.workflowApuracaoAcaoResource.associaUsuarioFornecedorAprovador;
      forceLoading();
      resource(params, params)
        .then(() => {
          const promises = [];
          if (this.isMultiplasNds) {
            promises.push(this.gerarNumeroNdMultiplasNds());
          }
          promises.push(this.iniciarFluxo());
          Promise.all(promises)
            .then()
            .catch((err) => {
              this.$error(this, err);
            });
        })
        .catch((err) => this.$error(this, err));
    },
    gerarNumeroNdMultiplasNds() {
      const nds = [];
      this.fornecedoresNd.forEach((fornecedor) => {
        const params = {
          idNotaDebitoPrevia: fornecedor.idNotaDebitoPrevia,
        };
        nds.push(params);
      });
      this.apuracaoAcaoResource.gerarNumeroNdMultiplasNds(nds)
        .then();
    },
    getVlrConfApuracao() {
      const valores = this.fornecedoresNd.map((f) => f.verbaManual);
      return valores.reduce((a, b) => a + b, 0);
    },
    getValorRealizado() {
      const valores = this.fornecedoresNd.map((f) => f.valorBase);
      return valores.reduce((a, b) => a + b, 0);
    },
    confirmarSolicitacaoAprovacao() {
      if (this.isMultiplasNds) {
        this.mensagemSolicitarAprovacao = this.$tc('message.multiplas_nds.confirma_solicitar_aprovacao', 1);
      } else {
        this.mensagemSolicitarAprovacao = this.$tc('message.deseja_solicitar_aprovacao_apuracao', 1);
      }
      this.$refs.modalConfirmacaoSolicitarAprovacao.open();
    },
    abrirModalDividirRecebimento() {
      this.$refs.modalMultiplasNds.open(this.fornecedoresNd[0]);
    },
    abrirRecomendacaoAnexo() {
      if (!this.apuracao.temEvidencia) {
        this.$refs.modalRecomendacaoAnexo.open();
      } else {
        this.salvarAntesDeSolicitar();
      }
    },
    iniciarFluxo() {
      this.isIniciandoFluxo = true;
      const idApuracao = this.apuracao.id;
      this.workflowApuracaoAcaoResource.iniciaFluxo({ idApuracao }, { idApuracao })
        .then(() => {
          forceHideLoading();
          this.$emit('ApuracaoAcaoListaModalGerarNdApuracaoFornecedores__recarregarApuracoes');
          this.$toast(this.$t('message.solicitacao_aprovacao'));
          this.recarregarApuracaoPromises()
            .then(() => {
              this.isIniciandoFluxo = false;
            })
            .catch((err) => {
              forceHideLoading();
              this.$error(this, err);
            });
        })
        .catch((err) => {
          forceHideLoading();
          this.$error(this, err);
          this.$toast(this.$t('errors.fluxo.passo_elegivel_obrigatorio'));
        });
    },
    verificarSolicitante() {
      const idApuracao = this.apuracao.id;
      if (this.aguardandoApuracao || this.apuracaoEmAnalise) {
        this.workflowApuracaoAcaoResource.solicitante({ idApuracao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
            this.$forceUpdate();
          });
      }
    },
    arquivoRemovido() {
      if (this.$refs.listFiles.files.length === 1) {
        this.$emit('ApuracaoAcaoArquivos_arquivosRemovidos');
      }
    },
    uploadSucesso() {
      if (this.$refs.listFiles) {
        setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      }
      this.apuracao.temEvidencia = true;
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
      this.$forceUpdate();
    },
    arquivosRemovidos() {
      this.apuracao.temEvidencia = false;
    },
    listaArquivosAlterados(payload) {
      this.apuracao.temEvidencia = payload;
    },
    possuiArquivos(files) {
      if (files && files.length > 0) {
        this.apuracao.temEvidencia = true;
      }
    },
    emitirValorTotalNdAlterado() {
      const valorTotal = this.fornecedoresNd.map((fornecedor) => fornecedor.verbaManual).reduce((a, b) => a + b, 0);
      this.$emit('ApuracaoAcaoListaModalGerarNdApuracaoFornecedores__emitirValorAlterado', valorTotal);
    },
    valorNdAlterado(novoValor, item) {
      this.emitirValorTotalNdAlterado();
      this.adicionarJustificativa(novoValor, item);
    },
    adicionarJustificativa(novoValor, item) {
      if (getMoney(item.valorCalculado) !== getMoney(novoValor)) {
        const fornecedor = this.fornecedoresNd.find((f) => f.idFornecedor === item.idFornecedor);
        fornecedor.valorAlteradoManualmente = true;
        const isNovoValor = true;
        this.apuracaoEditada(item.idApuracao, false);
        this.abrirModalJustificativa(item, isNovoValor);
      }
    },
    apuracaoEditada(idApuracao, isSalvo = false) {
      this.$emit('ApuracaoAcaoListaModalGerarNdApuracaoFornecedores__apuracaoEditada', idApuracao, isSalvo);
    },
    abrirModalJustificativa(item, isNovoValor = false) {
      this.notaDebito = item;
      setTimeout(() => this.$refs.modalJustificativa.open(isNovoValor), 2E2);
    },
    abrirModalDescricaoNd(item) {
      this.notaDebito = item;
      this.$refs.modalDescricaoNd.open(item);
    },
    alterarDescricao(descricao) {
      const nds = [];
      const params = {
        idNotaDebitoPrevia: this.notaDebito.idNotaDebitoPrevia,
        descricao,
      };

      nds.push(params);
      this.apuracaoAcaoResource.atualizarNdPrevia(nds)
        .then(() => {
          const fornecedorNd = this.fornecedoresNd.find((f) => f.idNotaDebitoPrevia === this.notaDebito.idNotaDebitoPrevia);
          fornecedorNd.descricao = descricao;
          this.$toast(this.$t('message.descricao_nd_alterada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    montarCabecalho() {
      this.cabecalho = [
        {
          text: '', value: 'exportar', sortable: false, width: '5%', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.numero_nd', 1), value: 'num_nota_debito', sortable: false, width: '10%', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.cod_fornecedor', 1), value: 'cod_fornecedor', sortable: false, width: '8%', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.cnpj', 1), value: 'cnpj', sortable: false, width: '10%', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.razao_social', 1), value: 'des_razao_social', sortable: false, width: '17%',
        },
      ];

      if (this.aguardandoApuracao || this.aguardandoAprovacao || this.apuracaoReprovado || this.apuracaoEmAnalise) {
        this.cabecalho.push({
          text: this.$tc('label.descricao_nd', 1), value: 'descricao', sortable: false, width: '15%',
        });
      }
      this.cabecalho.push({
        text: this.$tc('label.recebimento_real', 1), value: 'valorReal', sortable: false, width: '10%',
      });
      if (this.apuracao.isVmaxPorAgrupamento) {
        this.cabecalho.push({
          text: this.$tc('label.recebimento_calculado', 1), value: 'valorApuradoReal', sortable: false, width: '10%',
        });
      } else {
        this.cabecalho.push({
          text: this.$tc('label.recebimento_calculado', 1), value: 'valorCalculado', sortable: false, width: '10%',
        });
      }
      this.cabecalho.push({
        text: this.$tc('label.valor_nd', 1), value: 'verbaManual', sortable: false, width: '15%',
      });
    },
    exportarNotas(item) {
      if (!item.possuiNotas) {
        this.$toast(this.$t('message.sem_dados_notas_fiscais_apuracao'));
        return;
      }
      const params = {
        id_acao: Number(item.idAcao),
        id_apuracao: Number(item.idApuracao),
      };
      if (!item.agrupadoFornecedorPagador) {
        params.id_fornecedor = item.idFornecedor;
      }
      if (item.calculoBonificacao === 'SELLOUT') {
        if (this.permissaoExportarNFSellout) {
          exportacao.exportar(null, 'notas_sellout_memoria_apuracao_acao', this, params);
        } else {
          exportacao.exportar(null, 'notas_sellout_memoria_apuracao_acao_notas_resumo', this, params);
        }
      } else {
        exportacao.exportar(null, 'notas_sellin_memoria_apuracao_acao', this, params);
      }
    },
    setaListaMultiplasNds(listaMultiplasNds) {
      this.fornecedoresNd[0].listaMultiplasNds = [...listaMultiplasNds];
      this.atualizarKeys();
    },
    recarregarApuracaoPromises() {
      const promises = [];
      promises.push(this.buscarApuracao());
      promises.push(this.buscarFornecedoresNd());
      return Promise.all(promises);
    },
    recarregarApuracaoAposFluxo() {
      const promises = [];
      promises.push(this.buscarApuracao());
      promises.push(this.verificarUsuarioAprovador());
      Promise.all(promises)
        .then(this.atualizarKeys())
        .catch((err) => {
          forceHideLoading();
          this.$error(this, err);
        });
    },
    verificarUsuarioAprovador() {
      const idApuracao = this.apuracao.id;
      if (this.aguardandoAprovacao) {
        this.workflowApuracaoAcaoResource.aprovador({ idApuracao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            } else {
              this.aprovadorFluxo = false;
            }
            this.$forceUpdate();
          });
      }
    },
    abrirJustificativaAprovar() {
      this.justificativaObrigatoria = false;
      this.actionJustificativa = this.aprovar;
      this.isAprovacao = true;
      this.textoAcao = this.$tc('label.aprovar', 1);
      this.$refs.modalAprovacaoJustificativa.open();
    },
    aprovar(justificativa) {
      const idApuracao = this.apuracao.id;
      this.workflowApuracaoAcaoResource.aprovarPasso({ idApuracao },
        { observacao: justificativa })
        .then(() => {
          this.recarregarApuracaoAposFluxo();
          this.$toast(this.$t('message.apuracao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaReprovar() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.reprovar;
      this.isAprovacao = false;
      this.textoAcao = this.$tc('label.reprovar', 1);
      this.$refs.modalAprovacaoJustificativa.open();
    },
    reprovar(justificativa) {
      const idApuracao = this.apuracao.id;
      this.workflowApuracaoAcaoResource.reprovar({ idApuracao },
        { observacao: justificativa })
        .then(() => {
          this.recarregarApuracaoAposFluxo();
          this.$toast(this.$t('message.apuracao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaAnalise() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.enviarAnalise;
      this.isAprovacao = false;
      this.textoAcao = this.$tc('label.enviar_analise', 1);
      this.$refs.modalAprovacaoJustificativa.open();
    },
    enviarAnalise(justificativa) {
      const idApuracao = this.apuracao.id;
      this.workflowApuracaoAcaoResource.enviarAnalise({ idApuracao },
        { observacao: justificativa })
        .then(() => {
          this.recarregarApuracaoAposFluxo();
          this.$toast(this.$t('message.apuracao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarKeys() {
      this.tableKey += 1;
      this.divKey += 1;
      forceHideLoading();
    },
  },
  mounted() {
    forceLoading();
    this.montarCabecalho();
    this.buscarFornecedoresNd()
      .then(() => {
        const promises = [];
        promises.push(this.verificarSolicitante());
        promises.push(this.verificarUsuarioAprovador());
        Promise.all(promises);
        forceHideLoading();
      })
      .catch((err) => {
        forceHideLoading();
        this.$error(this, err);
      });
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
