<template lang="html">
  <div class="CRUDGenerico__Wrapper" v-if="canAccessPage">
    <v-row>
      <v-col cols="12" sm="3" class="pt-0" v-if="!somenteLeitura">
        <alcada-form
          ref="alcadaForm"
          @FORMULARIO_CADASTRO_ALCADA_SALVAR="onNovoAlcada"
          @FORMULARIO_CADASTRO_ALCADA_CANCELAR="onCancelar" />
      </v-col>
      <v-col cols="12" :md="somenteLeitura ? 12 : 9" class="pt-0">
        <alcada-tabela
          ref="alcadaTabela"
          class="CRUDGenerico__ContentTable"
          :title="$tc('label.alcada', 2)"
          @PESQUISA_ALCADA_EDITAR="onEditarAlcada"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '../../common/functions/roles-computed-generator';
import CRUDGenerico from '../../shared-components/generico/CRUDGenerico';
import AlcadaForm from './AlcadaForm';
import AlcadaTabela from './AlcadaTabela';

export default {
  name: 'CadastroAlcada',
  extends: CRUDGenerico,
  data() {
    return {
      alcadaResources: this.$api.alcada(this.$resource),
    };
  },
  components: {
    AlcadaForm,
    AlcadaTabela,
  },
  computed: {
    ...generateComputed('alcada', [
      'canAccessCRUD',
      'canAccessPage',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    onCancelar() {
      this.$refs.alcadaTabela.filtrar();
    },
    onEditarAlcada(alcada) {
      this.$refs.alcadaForm.editar(alcada);
    },
    onNovoAlcada() {
      this.$refs.alcadaTabela.filtrar();
    },
    filtrar(searchQuery) {
      this.searchQuery = searchQuery;
      this.requestPopular();
    },
    requestPopular(page = null) {
      if (page && page.filter) {
        this.request(this.alcadaResources.listarFiltrado, page);
        return;
      }
      this.request(this.alcadaResources.listar, page);
    },
    requestAdicionar(objeto, cb = null) {
      if (!this.canAccessCRUD) {
        return;
      }
      this.requestAdicionarGenerico(this.alcadaResources.gravar, objeto, cb);
    },
    requestAtivarInativar(index) {
      if (!this.canAccessCRUD) {
        return null;
      }
      const row = this.tableRows[index],
        { id } = row;
      return this.requestAtivarInativarGenerico(row,
        id,
        this.alcadaResources,
        this.requestPopular);
    },
    requestAtualizar(objeto) {
      if (!this.canAccessCRUD) {
        return;
      }
      if (objeto.objFluxo) {
        objeto.idWorkflowTipo = objeto.objFluxo.idWorkflowTipo;
        objeto.fluxo = objeto.objFluxo.fluxo;
        delete objeto.objFluxo;
      }
      if (objeto.objEstruturaUsuario) {
        objeto.idEstruturaUsuario = objeto.objEstruturaUsuario.idEstruturaUsuario;
        objeto.nomEstruturaUsuario = objeto.objEstruturaUsuario.nomEstruturaUsuario;
        delete objeto.objEstruturaUsuario;
      }
      const payload = this.prepareRequestObject(copyObject(objeto));

      this.alcadaResources.atualizar(payload)
        .then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.atualizar(objeto);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    request(requisicao, page = null, cb = null) {
      const pagination = page || { size: 10, page: 1 };
      this.carregando = true;

      requisicao(pagination)
        .then((response) => {
          this.tableRows = response.data.resposta;
          const qtdeRegistros = response.data.quantidadeRegistrosPagina;
          this.paginas = Math.ceil(qtdeRegistros / pagination.size);

          this.carregando = false;
          if (cb) {
            cb();
          }
        })
        .catch((err) => {
          this.$error(this, err);
          this.carregando = false;
        });
    },
    prepareRequestObject(objeto) {
      const cleanPayload = {};
      cleanPayload.id = objeto.id;
      cleanPayload.descricao = objeto.descricao;
      cleanPayload.idEstruturaUsuario = objeto.idEstruturaUsuario;
      cleanPayload.nomEstruturaUsuario = objeto.nomEstruturaUsuario;
      cleanPayload.idWorkflowTipo = objeto.idWorkflowTipo;
      cleanPayload.indAtivo = objeto.indAtivo;
      cleanPayload.vlrLimiteFinal = objeto.vlrLimiteFinal;
      cleanPayload.vlrLimiteInicial = objeto.vlrLimiteInicial;
      cleanPayload.objFluxo = objeto.objFluxo;
      cleanPayload.objEstruturaUsuario = objeto.objEstruturaUsuario;
      return cleanPayload;
    },
    // requestPopular(page = 1, cb = null) {
    //   this.$refs.alcadaTabela.clearCheckbox();
    //   this.carregando = true;
    //   const filtro = {};
    //   filtro.page = page;
    //   filtro.size = this.limitePagina;
    //   filtro.nome = this.searchQuery;
    //   this.alcadaResources.listar(filtro).then((response) => {
    //     this.tableRows = response.data.resposta;
    //     const qtdeRegistros = response.data.quantidadeRegistrosPagina;
    //     this.paginas = Math.ceil(qtdeRegistros / filtro.size);
    //     this.carregando = false;
    //     if (cb) {
    //       cb();
    //     }
    //   })
    //   .catch((err) => {
    //     this.carregando = false;
    //     this.$toast(err.data.error);
    //   });
    // },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    } else {
      // this.popularMetadados();
    }
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    } else {
      this.requestPopular();
    }
  },
};
</script>
