<template lang="html">
  <v-app light>
    <transition name="slide-fade"
      mode="out-in">
      <router-view></router-view>
    </transition>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'app',
  methods: {
    ...mapActions('roles', [
      'setToken',
    ]),
  },
  mounted() {
    this.setToken(this.$auth.token);
  },
};
</script>

<style lang="css">
  @import '~@/assets/css/main.css';

  #app {
    background-color: #EEEEEE;
    height: 100%;
  }

  .slide-fade-enter-active {
    transition: all .1s ease;
  }
  .slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-active {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
