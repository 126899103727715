import getBasePath from '../functions/api-resource';

const basePath = getBasePath('api', 'cliente/{idCliente}/representante');

// Actions são ações que o resource terá.
// No exemplo abaixo, criamos uma ação 'list', que é um GET
// na url que aponta para a raíz da API
const representanteClienteActions = {
  ativar: { method: 'PUT', url: `${basePath}/{id}/ativacao/true` },
  buscar: { method: 'GET', url: `${basePath}` },
  inativar: { method: 'PUT', url: `${basePath}/{id}/ativacao/false` },
  gravar: { method: 'POST', url: `${basePath}` },
};

// Exportamos então o recurso, que não passa de uma função que recebe um
// Vue.$resource como parâmetro e retorna um resource customizado.
// Vue.$resource tem a seguinte assinatura:
// function Resource(url, params, actions, options)

export default (resource) => resource(`${basePath}`, {}, representanteClienteActions);
