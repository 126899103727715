import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'alerta-manutencao');

const alertaManutencaoActions = {
  ...genericActions(basePath),
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}&filtro={filter}` },
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  listarAlertasParaExibir: { method: 'GET', url: `${basePath}/alertas-para-exibir` },
  salvaUsuarioLogAlertaManutencao: { method: 'POST', url: `${basePath}/salvar-usuario-log-alerta` },
};

export default (resource) => resource(`${basePath}`, {}, alertaManutencaoActions);
