import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('api', 'produto');

const produtoActions = {
  ...genericActions(basePath),
  ativar: { method: 'PUT', url: `${basePath}/cadastro/ativacao/{id}/true` },
  atualizar: { method: 'PUT', url: `${basePath}/cadastro` },
  ativarMassa: { method: 'PUT', url: `${basePath}/cadastro/ativacao-massa` },
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  buscarExtensoesRelacionadas: { method: 'GET', url: `${basePath}/extensao-relacionada?nome={nome}&indAtivo=true&idNivelExtensao={idNivelExtensao}&colunaOrdenacao=nom_extensao` },
  gravar: { method: 'POST', url: `${basePath}/cadastro` },
  listarFiltrado: { method: 'GET', url: `${basePath}?colunaOrdenacao=nom_produto&tamanhoPagina={size}&numeroPagina={page}` },
  listarAutocompletePagina: { method: 'GET', url: `${basePath}?colunaOrdenacao=nom_produto` },
  listarAutocomplete: { method: 'GET', url: `${basePath}?tamanhoPagina=6&numeroPagina=1&autocomplete={autocomplete}&indAtivo=true` },
  selecaoPlanilha: { method: 'GET', url: `${basePath}/selecao/planilha` },
  selecao: { method: 'GET', url: `${basePath}/selecao{?exceto*&contendo*}` },
};

export default (resource) => resource(`${basePath}`, {}, produtoActions);

export const buscarProdutosSelecao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'selecao', parametros).doGet();

export const buscarExtensoesProduto = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar-extensoes', parametros).doGet();
