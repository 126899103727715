<template lang="html">
  <div v-if="canAccessPage">
    <v-card style="margin-bottom:1em">
      <v-card-title>
        <h3 class="headline mb-0">{{ $tc('title.parametrizacao_cliente', 1) }}</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              id="config_cliente_carteira"
              name="config_cliente_carteira"
              item-text="texto"
              item-value="valor"
              clearable
              v-model="parametrizacao.carteira.valor"
              @change="limparVinculos"
              :items="carteiras"
              :label="`${$tc('label.carteira_cliente', 1)}`">
            </v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4" v-if="parametrizacao.carteira.valor === 'IMPLICITA'">
            <v-autocomplete
              id="config_cliente_vinculo"
              name="config_cliente_vinculo"
              item-text="texto"
              item-value="valor"
              multiple
              v-model="vinculosSelecionados"
              :items="vinculosAssociados"
              :label="`${$tc('label.vinculo_carteira_cliente', 1)}`">
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" v-if="parametrizacao.carteira.valor === 'IMPLICITA'">
            <v-select
              id="config_cliente_controlador"
              name="config_cliente_controlador"
              item-text="texto"
              item-value="valor"
              v-model="parametrizacao.controlador.valor"
              :items="controladores"
              :label="`${$tc('label.controlador_carteira_cliente', 1)}`"
              disabled>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="config_visao_usuario"
              name="config_visao_usuario"
              item-text="descricao"
              item-value="label"
              return-object
              multiple
              v-model="extensoesSelecionadas"
              :items="extensoesCliente"
              :label="`${$tc('label.visao_usuario_cliente', 1)}`">
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="config_cliente_salvar" color="primary" @click="salvar">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ParametrizacaoCliente',
  data() {
    return {
      parametrizacaoClienteResources: this.$api.parametrizacaoCliente(this.$resource),
      nivelCarteiraClienteResources: this.$api.nivelCarteiraCliente(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),

      carteiras: [
        { valor: 'IMPLICITA', texto: this.$tc('label.implicita', 1) },
        { valor: 'EXPLICITA', texto: this.$tc('label.explicita', 1) },
      ],
      vinculos: [],
      controladores: [
        { valor: 'FUNCIONARIO', texto: this.$tc('label.funcionario', 1) },
        { valor: 'DIVISAO', texto: this.$tc('label.divisao', 1) },
      ],
      vinculosAssociados: [],
      vinculosSelecionados: [],
      extensoesSelecionadas: [],
      parametrizacao: {
        vinculos: [],
        controlador: {},
        carteira: {},
      },
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('metadados', [
      'getClienteMetadado',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'PARAM_CLIENTE_CRUD').length;
    },
    extensoesCliente() {
      if (!this.getClienteMetadado) return [];

      const { mapaEntidades } = this.getClienteMetadado;

      return Object.keys(mapaEntidades).map((k) => {
        const extensao = {
          label: k,
          descricao: mapaEntidades[k].entidadeEstrangeira,
          idNivelExtensao: mapaEntidades[k].idNivelExtensao,
          indiceRecursao: mapaEntidades[k].indiceRecursao,
        };
        return extensao;
      });
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setClienteMetadado',
    ]),
    limparVinculos() {
      this.vinculosSelecionados = [];
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    buscar() {
      this.buscarConfig();
    },
    buscarConfig() {
      this.parametrizacaoClienteResources.buscarTodas()
        .then((response) => {
          if (response.data) {
            response.data.forEach(this.preencherDadosTela);
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    preencherDadosTela(config) {
      if (config.chave === 'CARTEIRA') {
        this.parametrizacao.carteira = config;
      } else if (config.chave === 'CONTROLADOR_CARTEIRA_CLIENTE') {
        this.parametrizacao.controlador = config;
      } else if (config.chave === 'VINCULO_CARTEIRA_CLIENTE') {
        this.vinculosSelecionados.push(config.valor);
      } else if (config.chave === 'VISAO_USUARIO_CLIENTE') {
        this.extensoesSelecionadas.push({
          label: config.valor,
          idNivelExtensao: config.valorUm ? parseInt(config.valorUm, 10) : null,
          indiceRecursao: config.valorDois ? parseInt(config.valorDois, 10) : null,
        });
      }
    },
    buscarCarteiraClienteVinculosDisponiveis() {
      this.nivelCarteiraClienteResources.vinculos()
        .then((response) => {
          if (response.data) {
            this.vinculosAssociados = response.data;
            for (let i = 0; i < this.vinculosAssociados.length; i += 1) {
              this.vinculosAssociados[i].texto = this.$tc(`label.${this.vinculosAssociados[i].texto}`, 1);
            }
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    salvar() {
      const configs = [this.parametrizacao.carteira, this.parametrizacao.controlador];
      for (let i = 0; i <= this.vinculosSelecionados.length - 1; i += 1) {
        const vinculo = {
          chave: 'VINCULO_CARTEIRA_CLIENTE',
          indAtivo: true,
          indExibir: true,
          valor: this.vinculosSelecionados[i],
        };

        configs.push(vinculo);
      }
      for (let i = 0; i <= this.extensoesSelecionadas.length - 1; i += 1) {
        const vinculo = {
          chave: 'VISAO_USUARIO_CLIENTE',
          indAtivo: true,
          indExibir: true,
          valor: this.extensoesSelecionadas[i].label,
          valorUm: this.extensoesSelecionadas[i].idNivelExtensao,
          valorDois: this.extensoesSelecionadas[i].indiceRecursao,
        };

        configs.push(vinculo);
      }
      this.parametrizacaoClienteResources.salvar({ }, configs)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    teste() {
      const { log } = console;
      log('teste');
    },
    preencherMetadados() {
      this.setClienteMetadado({
        resource: this.metadadoResource.listarCliente,
      });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
    this.preencherMetadados();
    this.buscarCarteiraClienteVinculosDisponiveis();
    this.buscar();
  },
};
</script>
