<template lang="html">
  <div>
    <div class="md-title">
      {{$tc('label.detalhe', 2)}}
    </div>
    <v-row justify="end">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="registros"
          hide-default-footer
          :no-data-text="$t('message.nao_ha_conteudo')">
          <template slot="items" slot-scope="props">
            <td v-if="props.item.label">{{ $t(`label.${props.item.label.toLowerCase()}`) }}</td>
            <td v-if="props.item.value"> {{props.item.value}} </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    headers: Array,
    registros: Array,
  },
};
</script>
