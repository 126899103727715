import Relatorio from '../../spa/relatorios/Relatorio';
import RelatorioLista from '../../spa/relatorios/RelatorioLista';

export default {
  path: 'reports',
  component: Relatorio,
  children: [
    {
      path: '',
      name: 'reports',
      component: RelatorioLista,
    },
  ],
};
