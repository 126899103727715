<template>
  <v-card class="WorkflowForm">
    <v-card-title>
      {{$tc('title.workflow', 1)}}
    </v-card-title>
    <v-subheader>{{$t('label.preencha_campos_abaixo')}}</v-subheader>
    <v-container grid-list-xl fluid>
      <v-form ref="form" lazy-validation autocomplete="off">
        <v-row>
          <v-col id="workflow_tipo" cols="12" md="4">
            <v-select
              :rules="[rules.requiredSelect]"
              :label="`${$t('label.tipo_fluxo')} *`"
              :items="tiposWorkflow"
              item-text="nome"
              item-value="id"
              v-model="workflow.idTipoFluxo"
              @input="buscarCondicionais"
              tabindex="1"
              required
            ></v-select>
          </v-col>
          <v-col id="workflow_descricao" cols="12" md="4">
            <v-text-field
              :rules="[rules.required]"
              :label="`${$t('label.fluxo_descricao')} *`"
              v-model="workflow.descricaoFluxo"
              maxlength="100"
              counter
              tabindex="2"
            ></v-text-field>
          </v-col>
          <v-col id="workflow_solicitante" cols="12" md="4">
            <v-select
              :rules="[rules.requiredSelect]"
              :label="`${$t('label.selecione_solicitante')} *`"
              :items="tiposSolicitante"
              item-text="texto"
              item-value="valor"
              v-model="tipoSolicitante"
              tabindex="3"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" v-if="solicitanteUsuario">
            <v-autocomplete
              id="usuario-solicitante"
              class="custom-autocomplete"
              required
              :rules="[rules.requiredMultiple]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.solicitante', 1)} *`"
              v-model="workflow.usuarioSolicitante.usuarios"
              :items="usuariosSolicitantes"
              item-text="nome"
              cache-items
              return-object
              :search-input.sync="usuarioSolicitanteInput"
              @click:append="triggerUsuarioSolicitante"
              @click.native="buscarUsuarioSolicitante"
              multiple
              chips
              deletable-chips>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="4" v-if="solicitanteGrupo">
            <v-autocomplete
              id="grupo-solicitante"
              class="custom-autocomplete"
              required
              :rules="[rules.requiredMultiple]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.perfil', 1)} *`"
              v-model="workflow.usuarioSolicitante.grupos"
              :items="gruposSolicitantes"
              item-text="nome"
              cache-items
              return-object
              :search-input.sync="grupoSolicitanteInput"
              @click:append="triggerGruposSolicitantes"
              @click.native="buscarGrupoSolicitantes"
              multiple
              chips
              deletable-chips>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" v-if="solicitanteGrupo">
            <v-autocomplete
              id="subgrupo-solicitante"
              class="custom-autocomplete"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.estrutura_usuario', 1)}`"
              v-model="workflow.usuarioSolicitante.subgrupos"
              :items="subGruposSolicitantes"
              item-text="nome"
              cache-items
              return-object
              @click:append="triggerSubGrupoSolicitante"
              @click.native="buscarSubGrupoSolicitante"
              multiple
              chips
              deletable-chips>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="4" v-if="selecionaTipoAcao">
            <v-autocomplete
              id="tipo-acao"
              class="custom-autocomplete"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.tipo_acao', 1)}`"
              v-model="tiposAcaoSelecionados"
              :items="tiposAcao"
              item-text="nome"
              item-value="id"
              :search-input.sync="tipoAcaoInput"
              @click:append="triggerTipoAcao"
              @click.native="buscarTipoAcao"
              multiple
              chips
              deletable-chips>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="4">
            <v-autocomplete
              id="grupos-fornecedores"
              class="custom-autocomplete"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.grupo_fornecedor', 1)}`"
              v-model="gruposFornecedoresSelecionados"
              :items="listaGruposFornecedores"
              item-text="nome"
              item-value="id"
              :search-input.sync="gruposFornecedoresInput"
              @click:append="triggerGruposFornecedores"
              @click.native="buscarGruposFornecedores"
              multiple
              chips
              deletable-chips>
            </v-autocomplete>
          </v-col>
        </v-row>

        <workflow-form-passos
          v-if="habilitaPassosWorkflow"
          :passos-fluxo="workflow.passosFluxo"
          :condicionais="condicionaisOrdenadas"
          :habilita-switch="habilitaSwitch"
          :habilita-switch-email="habilitaSwitchEmail" />
        <workflow-justificativa
          ref="modalJustificativa"
          :obrigatorio="justificativaObrigatoria"
          :somente-leitura="justificativaSomenteLeitura"
          :salvarJustificativa="preencherJustificativa" />
      </v-form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click.native="voltar">{{ $t('label.cancelar') }}</v-btn>
      <v-btn @click.native="salvarWorkflow" color="primary">{{ $t('label.salvar') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import WorkflowFormPassos from './WorkflowFormPassos';
import WorkflowJustificativa from './WorkflowJustificativa';
import { copyObject } from '../../common/functions/helpers';

export default {
  components: {
    WorkflowFormPassos,
    WorkflowJustificativa,
  },
  computed: {
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
    idEdicao() {
      return (this.$route.params.id)
        ? this.$route.params.id
        : null;
    },
    selecionaTipoAcao() {
      const tipo = this.tiposWorkflow
        .filter((t) => t.id === this.workflow.idTipoFluxo)[0];
      if (tipo) {
        // this.habilitaSwitch = tipo.chave === 'CONTRATO';
        // this.habilitaSwitchEmail = tipo.chave === 'PLANEJAMENTO_ACAO';
        return ['PLANEJAMENTO_ACAO', 'APURACAO_ACAO', 'PAGAMENTO'].indexOf(tipo.chave) >= 0;
      }
      return false;
    },
    habilitaSwitch() {
      const tipo = this.tiposWorkflow
        .filter((t) => t.id === this.workflow.idTipoFluxo)[0];
      if (tipo) {
        return tipo.chave === 'CONTRATO';
      }
      return false;
    },
    habilitaSwitchEmail() {
      const tipo = this.tiposWorkflow
        .filter((t) => t.id === this.workflow.idTipoFluxo)[0];
      if (tipo) {
        return tipo.chave === 'PLANEJAMENTO_ACAO';
      }
      return false;
    },
    condicionaisOrdenadas() {
      function compare(a, b) {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }
      return this.condicionais.sort(compare);
    },
  },
  watch: {
    tipoSolicitante() {
      this.solicitanteUsuario = false;
      this.solicitanteGrupo = false;
      setTimeout(() => {
        this.solicitanteUsuario = this.tipoSolicitante === 'usuario';
        this.solicitanteGrupo = this.tipoSolicitante === 'grupo';
        this.usuariosSolicitantes = [...this.workflow.usuarioSolicitante.usuarios];
        this.gruposSolicitantes = [...this.workflow.usuarioSolicitante.grupos];
        this.subGruposSolicitantes = [...this.workflow.usuarioSolicitante.subgrupos];
        this.$forceUpdate();
      }, 1E2);
    },
    usuarioSolicitanteInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarUsuarioSolicitante(val);
      }, 500);
    },
    grupoSolicitanteInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarGrupoSolicitantes(val);
      }, 500);
    },
    subGrupoSolicitanteInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarSubGrupoSolicitante(val);
      }, 500);
    },
    tipoAcaoInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarTipoAcao(val);
      }, 500);
    },
    gruposFornecedoresInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarGruposFornecedores(val);
      }, 500);
    },
  },
  data() {
    return {
      resourceFluxoAprovacoes: this.$api.fluxoAprovacao(this.$resource),
      usuarioResources: this.$api.usuario(this.$resource),
      perfilResources: this.$api.perfil(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),

      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredSelect: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredMultiple: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
      },

      tiposWorkflow: [],
      workflow: {
        usuarioSolicitante: {
          usuarios: [],
          grupos: [],
          subgrupos: [],
        },
        passosFluxo: [],
      },
      tipoSolicitante: null,
      habilitaPassosWorkflow: false,
      // habilitaSwitch: false,
      // habilitaSwitchEmail: false,

      tiposSolicitante: [
        {
          valor: 'usuario',
          texto: this.$t('label.workflow_solicitante_usuario'),
        },
        {
          valor: 'grupo',
          texto: this.$t('label.workflow_solicitante_grupo'),
        },
      ],

      solicitanteUsuario: false,
      solicitanteGrupo: false,
      usuariosSolicitantes: [],
      gruposSolicitantes: [],
      subGruposSolicitantes: [],
      condicionais: [],
      tiposAcao: [],

      usuarioSolicitanteInput: null,
      grupoSolicitanteInput: null,
      subGrupoSolicitanteInput: null,
      tipoAcaoInput: null,
      gruposFornecedoresInput: null,

      tiposAcaoSelecionados: [],

      justificativaPreenchida: false,
      justificativaWorkflow: null,
      justificativaObrigatoria: false,
      justificativaSomenteLeitura: false,
      listaGruposFornecedores: [],
      gruposFornecedoresSelecionados: [],
    };
  },
  methods: {
    extrairIds(array) {
      if (!array.length) {
        return null;
      }
      return array.map((el) => {
        const t = { id: el.id };
        return t;
      });
    },
    extrairIdsConcatenados(array) {
      if (!array.length) {
        return null;
      }
      let ids = '';
      array.forEach((el) => {
        if (!ids) {
          ids = el.id;
          return;
        }
        ids += `,${el.id}`;
      });
      return ids;
    },
    extrarIdsObjetos(object, keys) {
      Object.keys(object).forEach((el) => {
        if (keys.indexOf(el) !== -1) {
          object[el] = this.extrairIds(object[el]);
        }
      });
      return object;
    },
    resetarConfig(objetoConfiguracao, tipoConfiguracao) {
      if (tipoConfiguracao === 'usuario') {
        objetoConfiguracao.grupos = [];
        objetoConfiguracao.subgrupos = [];
      } else {
        objetoConfiguracao.usuarios = [];
      }
    },
    formatarComposicaoSolicitante() {
      return [
        ...this.tiposAcaoSelecionados.map((idComposicao) => ({ mnemonico: 'TIPO_ACAO', idComposicao })),
        ...this.gruposFornecedoresSelecionados.map((idComposicao) => ({ mnemonico: 'GRUPO_FORNECEDOR', idComposicao })),
      ];
    },
    prepararPayload() {
      const payload = copyObject(this.workflow),
        keys = ['usuarios', 'grupos', 'subgrupos'];

      this.resetarConfig(payload.usuarioSolicitante, this.tipoSolicitante);
      this.extrarIdsObjetos(payload.usuarioSolicitante, keys);
      payload.composicaoSolicitante = this.formatarComposicaoSolicitante();

      payload.passosFluxo.forEach((el) => {
        this.resetarConfig(el.usuariosAprovadores, el.tipoAprovador);
        this.extrarIdsObjetos(el.usuariosAprovadores, keys);
        if (el.tipoAprovador === 'usuario') {
          el.usuariosAprovadores.grupos = [];
          el.usuariosAprovadores.subgrupos = [];
        } else {
          el.usuariosAprovadores.usuarios = [];
        }
        if (el.perfilDestinatarios && el.perfilDestinatarios.length) {
          el.perfilDestinatarios = (this.extrairIdsConcatenados(el.perfilDestinatarios)).toString();
        }
      });

      if (this.justificativaWorkflow != null) {
        payload.justificativa = this.justificativaWorkflow;
      }

      if (!payload.id) {
        delete payload.id;
      }

      return payload;
    },
    salvarWorkflow() {
      if (!this.$refs.form.validate()) return;
      if (this.idEdicao && !this.justificativaPreenchida) {
        this.abrirJustificativa(true);
        return;
      }
      const payload = this.prepararPayload(),
        id = this.idEdicao,
        resource = (id)
          ? this.resourceFluxoAprovacoes.atualizar
          : this.resourceFluxoAprovacoes.gravar,
        requestParam = (id) ? { id } : {};
      resource(requestParam, payload)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.voltar();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    voltar() {
      this.$router.push({ name: 'workflow' });
    },
    buscarCondicionais() {
      this.resourceFluxoAprovacoes.buscarCondicionais({ id: this.workflow.idTipoFluxo })
        .then((response) => {
          this.condicionais = [
            ...response.data.map((condicional) => ({
              id: condicional.id,
              nome: this.$tc(`label.${condicional.nome.toLowerCase()}`, 1),
              chave: condicional.nome,
            })),
          ];
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarTipos() {
      this.resourceFluxoAprovacoes.buscarTipos()
        .then((response) => {
          this.tiposWorkflow = response.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarWorkflow(id) {
      this.resourceFluxoAprovacoes.buscaUnica({ id })
        .then((response) => {
          this.workflow = response.data;
          this.setTipoAprovador();
          this.setSolicitante();
          this.buscarPerfil();

          this.buscarCondicionais();
          this.buscarDadosComposicao();
        })
        .catch((err) => {
          this.$error(this, err);
        })
        .finally(() => {
          setTimeout(() => {
            this.habilitaPassosWorkflow = true;
          }, 1E2);
        });
    },
    buscarDadosComposicao() {
      const { composicaoSolicitante } = this.workflow;
      if (!composicaoSolicitante || composicaoSolicitante.length === 0) {
        return;
      }

      const idsTipoAcao = composicaoSolicitante.filter((c) => c.mnemonico === 'TIPO_ACAO')
        .map((c) => c.idComposicao);

      const idsGruposFornecedores = composicaoSolicitante.filter((c) => c.mnemonico === 'GRUPO_FORNECEDOR')
        .map((c) => c.idComposicao);

      if (idsTipoAcao.length > 0) {
        this.buscarTipoAcaoWorkflow(idsTipoAcao);
      }
      if (idsGruposFornecedores.length > 0) {
        this.buscarGruposFornecedoresWorkflow(idsGruposFornecedores);
      }
    },
    buscarGruposFornecedoresWorkflow(idsGruposFornecedores) {
      const value = {
        idNivelExtensao: 3,
      };
      idsGruposFornecedores.forEach((idGrupoFornecedor) => {
        value.idExtensao = idGrupoFornecedor;
        return this.extensaoResources.listarAtivos(value).then((resultado) => {
          resultado.data.resposta.forEach((el) => {
            let nomeFornecedor = el.id.toString().concat(' - ').concat(el.idExterno);
            if (this.isTenantEpoca) {
              nomeFornecedor = el.idExterno.toString().concat(' - ').concat(el.nomExtensao);
            }
            const item = { id: el.id, nome: nomeFornecedor };
            this.listaGruposFornecedores.push(item);
            this.gruposFornecedoresSelecionados.push(item.id);
          });
        });
      });
    },
    buscarTipoAcaoWorkflow(idsTipoAcao) {
      idsTipoAcao.forEach((id) => {
        this.tipoAcaoResource.buscarTipoAcao({ id }).then((r) => {
          const tipoAcao = r.data;
          if (tipoAcao.id) {
            this.tiposAcao.push(tipoAcao);
            this.tiposAcaoSelecionados.push(tipoAcao.id);
          }
        });
      });
    },
    buscarPerfil() {
      const params = {
        limit: 200,
        page: 0,
      };
      this.perfilResources.selecao(params)
        .then((r) => {
          const perfis = r.data;
          this.preparaPerfilDestinatarios(perfis);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    preparaPerfilDestinatarios(perfis) {
      const obj = [];
      if (this.workflow && this.workflow.passosFluxo && this.workflow.passosFluxo.length) {
        this.workflow.passosFluxo.forEach((passo) => {
          if (passo.indEnviaEmailAnalise && passo.perfilDestinatarios) {
            passo.perfilDestinatarios.split(',').forEach((id) => {
              obj.push(perfis.find((p) => p.id === parseInt(id, 10)));
            });
            if (obj && obj.length) {
              passo.perfilDestinatarios = obj.map((perfil) => ({ id: perfil.id, nome: perfil.nomPerfil }));
            }
          }
        });
      }
    },
    setTipoAprovador() {
      this.workflow.passosFluxo.map((el) => {
        el.tipoAprovador = el.usuariosAprovadores.usuarios.length
          ? 'usuario'
          : 'grupo';
        return el;
      });
    },
    setSolicitante() {
      this.tipoSolicitante = this.workflow.usuarioSolicitante.usuarios.length
        ? 'usuario'
        : 'grupo';

      this.usuariosSolicitantes = [...this.workflow.usuarioSolicitante.usuarios];
      this.gruposSolicitantes = [...this.workflow.usuarioSolicitante.grupos];
      this.subGruposSolicitantes = [...this.workflow.usuarioSolicitante.subgrupos];
    },
    triggerUsuarioSolicitante() {
      this.triggerClick('usuario-solicitante');
    },
    buscarUsuarioSolicitante(nome) {
      nome = this.formataInput(nome);
      this.usuarioResources.listarAtivosResumido({ nome })
        .then((r) => {
          this.usuariosSolicitantes = r.data.resposta;
          if (this.usuariosSolicitantes) {
            this.usuariosSolicitantes.forEach((u) => {
              u.nome = u.nomeSobrenome;
            });
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    triggerGruposSolicitantes() {
      this.triggerClick('grupo-solicitante');
    },
    buscarGrupoSolicitantes(nome) {
      nome = this.formataInput(nome);
      this.perfilResources.listarAtivos({ nome })
        .then((r) => {
          this.gruposSolicitantes = r.data.resposta
            .map((grupo) => ({ id: grupo.id, nome: grupo.nomPerfil }));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    triggerSubGrupoSolicitante() {
      this.triggerClick('subgrupo-solicitante');
    },
    buscarSubGrupoSolicitante(nome) {
      const nomeInput = this.formataInput(nome);
      const params = {
        nome: nomeInput,
        tamanhoPagina: 50000,
      };
      this.estruturaUsuarioResources.listarAtivos(params)
        .then((r) => {
          this.subGruposSolicitantes = r.data.resposta
            .map((subGrupo) => ({ id: subGrupo.id, nome: subGrupo.nomEstruturaUsuario }));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formataInput(input) {
      return typeof input === 'string' ? input : null;
    },
    triggerGruposFornecedores() {
      this.triggerClick('grupos-fornecedores');
    },
    triggerTipoAcao() {
      this.triggerClick('tipo-acao');
    },
    buscarTipoAcao() {
      this.tipoAcaoResource.buscarAtivos({ incluirAcoesContrato: true })
        .then((r) => {
          this.tiposAcao = r.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    triggerClick(id) {
      setTimeout(() => document.getElementById(id).click());
    },
    abrirJustificativa(obrigatorio) {
      this.justificativaObrigatoria = obrigatorio;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.$refs.modalJustificativa.open();
    },
    preencherJustificativa(justificativa) {
      if (justificativa !== '') {
        this.justificativaWorkflow = justificativa;
        this.justificativaPreenchida = true;
        this.salvarWorkflow();
        return;
      }

      this.justificativaPreenchida = false;
      this.$toast(this.$t('errors.justificativa.obrigatoria'));
    },
    buscarGruposFornecedores() {
      const value = {
        tamanhoPagina: 100000,
        idNivelExtensao: 3,
      };
      return this.extensaoResources.listarAtivos(value).then((resultado) => {
        resultado.data.resposta.forEach((el) => {
          let nomeFornecedor = el.id.toString().concat(' - ').concat(el.idExterno);
          if (this.isTenantEpoca) {
            nomeFornecedor = el.idExterno.toString().concat(' - ').concat(el.nomExtensao);
          }
          const item = { id: el.id, nome: nomeFornecedor };
          this.listaGruposFornecedores.push(item);
        });
      });
    },
  },
  mounted() {
    this.buscarTipos();

    const id = this.idEdicao;
    if (id) {
      this.buscarWorkflow(id);
    } else {
      this.habilitaPassosWorkflow = true;
    }
  },
};
</script>
<style>
  .WorkflowForm {
    margin-bottom: 1em;
  }
  .WorkflowForm__titulo {
    width: 100%;
  }
</style>
