<template>
  <div>
    <dashboard-fornecedor
      v-if="ehUsuarioFornecedor && canAccessDashFornecedor"/>
    <dashboard-funcionario-varejo
      v-else/>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import { generateComputed } from '../../../common/functions/roles-computed-generator';

import DashboardFuncionarioVarejo from './dashboard-funcionario/DashFuncionarioVarejo';
import DashboardFornecedor from './dashboard-fornecedor/DashFornecedor';

export default {
  name: 'Dashboard',
  components: {
    DashboardFuncionarioVarejo,
    DashboardFornecedor,
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...generateComputed('ACESSO_FORNECEDOR', [
      'getAllRoles',
    ]),
    canAccessDashFornecedor() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('ACESSO_FORNECEDOR')).length;
    },
    ehUsuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
  },
};
</script>
