import AlertaManutencao from '../../spa/alerta-manutencao/AlertaManutencao';
import AlertaManutencaoLista from '../../spa/alerta-manutencao/AlertaManutencaoLista';
import AlertaManutencaoForm from '../../spa/alerta-manutencao/AlertaManutencaoForm';

export default {
  path: 'alerta-manutencao',
  component: AlertaManutencao,
  children: [
    {
      path: '',
      name: 'alerta-manutencao',
      component: AlertaManutencaoLista,
    },
    {
      path: '/novo',
      name: 'novoAlertaManutencao',
      component: AlertaManutencaoForm,
    },
    {
      path: ':id/editar',
      name: 'editarAlertaManutencao',
      component: AlertaManutencaoForm,
    },
    {
      path: ':id/ver',
      name: 'verAlertaManutencao',
      component: AlertaManutencaoForm,
      props: {
        somenteLeitura: true,
      },
    },
  ],
};
