<template>
 <div class="RelatorioExportacoes">
    <v-spacer></v-spacer>
    <relatorio-exportacao-acao-pagamento
      v-if="acessoRelatorioAcaoPgto"
      @RelatorioExportacaoAcaoPagamento__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacao-provisoes
      v-if="acessoRelatorioProvisao"
      @RelatorioExportacaoProvisoes__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacao-status-acoes
      v-if="acessoRelatorioStatusAcao"
      @RelatorioExportacaoStatusAcoes__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacao-holdings-bloqueadas
      v-if="acessoRelatorioHoldingsBloqueadas"
      @RelatorioExportacaoHoldingsBloqueadas__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-exportacoes-customizadas />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RelatorioExportacoesCustomizadas from '@/spa/relatorios/exportacoes/RelatoriosExportacoesCustomizadas';
import { removeEmptyProperties } from '../../../common/functions/helpers';
import exportacao from '../../../common/functions/exportacao';
import RelatorioExportacaoAcaoPagamento from './RelatorioExportacaoAcaoPagamento';
import RelatorioExportacaoProvisoes from './RelatorioExportacaoProvisoes';
import RelatorioExportacaoStatusAcoes from './RelatorioExportacaoStatusAcoes';
import RelatorioExportacaoHoldingsBloqueadas from './RelatorioExportacaoHoldingsBloqueadas';

export default {
  data() {
    return {
      filtros: null,
      bloquearExportacao: false,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    acessoRelatorioAcaoPgto() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_ACAO_PGTO') !== -1).length > 0;
    },
    acessoRelatorioProvisao() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_PROVISAO') !== -1).length > 0;
    },
    acessoRelatorioSaldo() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_SALDO') !== -1).length > 0;
    },
    acessoRelatorioStatusAcao() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_STATUS_ACAO') !== -1).length > 0;
    },
    acessoRelatorioHoldingsBloqueadas() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_HOLDING_BLOQ') !== -1).length > 0;
    },
  },
  components: {
    RelatorioExportacaoAcaoPagamento,
    RelatorioExportacaoProvisoes,
    RelatorioExportacaoStatusAcoes,
    RelatorioExportacaoHoldingsBloqueadas,
    RelatorioExportacoesCustomizadas,
  },
  methods: {
    aplicaFiltros(filtrosAplicados, tipoRelatorio, modulo) {
      if (!filtrosAplicados) {
        return;
      }
      this.filtros = {
        ...filtrosAplicados,
      };
      this.requestExportacao(this.filtros, tipoRelatorio, modulo);
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'liquidacao') {
      const filtroTratado = removeEmptyProperties(filtros);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
  },
};
</script>
<style>
.RelatorioExportacoes {
  padding-bottom: 20px;
}
.RelatorioExportacoes__Modal .headline {
  width: 100%;
  text-align: center;
}
.RelatorioExportacoes__Lista {
  margin-top: 20px;
}
</style>
