<template lang="html">
  <div class="CRUDGenerico__Wrapper" v-if="canAccessPage">
    <v-row v-if="carregouMetadados">
      <v-col cols="12" sm="3" v-if="!somenteLeitura" class="pt-0">
        <filial-form
          ref="filialForm"
          @FORMULARIO_CADASTRO_FILIAL_SALVAR="onNovaFilial"
          @FORMULARIO_CADASTRO_FILIAL_CANCELAR="onCancelar" />
      </v-col>
      <v-col cols="12" :md="somenteLeitura ? 12 : 9" class="pt-0">
        <filial-tabela
          ref="filialTabela"
          class="CRUDGenerico__ContentTable"
          :title="$tc('label.filial', 2)"
          :somente-leitura="!canEdit"
          @PESQUISA_FILIAL_EDITAR="onEditarFilial"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import CRUDGenerico from '../../shared-components/generico/CRUDGenerico';
import FilialForm from './FilialForm';
import FilialTabela from './FilialTabela';

export default {
  name: 'CadastroFilial',
  extends: CRUDGenerico,
  data() {
    return {
      filialResources: this.$api.filial(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      resourceMetadado: this.$api.metadado(this.$resource),
      parametrizacaoClienteResources: this.$api.parametrizacaoCliente(this.$resource),

      configCarteira: null,
    };
  },
  components: {
    FilialForm,
    FilialTabela,
  },
  computed: {
    ...generateComputed('Filial', [
      'canEdit',
      'canGet',
      'canAccessPage',
    ]),
    ...mapGetters('metadados', [
      'getFilialMetadado',
    ]),
    somenteLeitura() {
      return !this.canEdit && this.canGet;
    },
    carregouMetadados() {
      const metadados = this.getFilialMetadado;
      return metadados != null;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setFilialMetadado',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    popularMetadados() {
      if (this.getFilialMetadado) {
        return;
      }
      this.setFilialMetadado({
        resource: this.resourceMetadado.definicaoFilial,
      });
    },
    onCancelar() {
      this.$refs.filialTabela.filtrar();
    },
    onEditarFilial(filial) {
      this.$refs.filialForm.editar(filial);
    },
    onNovaFilial() {
      this.$refs.filialTabela.filtrar();
    },
    requestPopular(page = null) {
      if (page && page.filter) {
        this.request(this.filialResources.listarFiltrado, page);
        return;
      }
      this.request(this.filialResources.listar, page);
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    } else {
      this.popularMetadados();
    }
  },
};
</script>
