<template>
  <v-row id="view-pdf" align="center" class="mx-0 ViewPdf__row" v-resize="() => updateScrollBounds()">
    <v-col cols="12" class="pa-0">
      <pdf-page
      v-for="page in pages"
      :page="page"
      :scale="scale"
      :scrollTop="scrollTop"
      :clientHeight="clientHeight"
      :key="page.pageNumber"
      @visiblePage="setVisiblePage"/>
    </v-col>
  </v-row>
</template>

<script>
import PdfPage from './PdfPage';

export default {
  name: 'ViewPdf',
  components: { PdfPage },
  props: {
    file: {
      defaut: () => {},
    },
    uri: '',
    scale: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      filePdf: null,
      pdf: undefined,
      pages: [],
      reloadHeight: true,
      scrollTop: 0,
      clientHeight: 0,
      visiblePage: 0,
    };
  },
  computed: {
    pageCount() {
      return this.pdf ? this.pdf.numPages : 0;
    },
  },
  watch: {
    pdf(pdf) {
      this.pages = [];
      const promises = [...Array(pdf.numPages).keys()]
        .map((number) => pdf.getPage(number + 1));
      Promise.all(promises).then((pages) => {
        this.pages = pages;
      });
    },
    pageCount(val) {
      this.$emit('ViewPdf_pageCount', val);
    },
    visiblePage(val) {
      this.$emit('ViewPdf_visiblePage', val);
    },
  },
  methods: {
    setVisiblePage(visiblePage) {
      this.visiblePage = visiblePage;
      if (this.reloadHeight) {
        this.reloadHeight = false;
        this.updateScrollBounds();
      }
    },
    updateScrollBounds(scrollTop) {
      if (this.$el) {
        const { clientHeight } = this.$el;
        this.scrollTop = scrollTop || this.scrollTop;
        this.clientHeight = clientHeight;
      }
    },
    downloadView() {
      const file = this.file.name;
      this.$http.get(`${this.uri}/view?file=${file}&mediaType=application/pdf`)
        .then((response) => {
          if (response.data) {
            this.filePdf = atob(response.data);
            setTimeout(this.renderPdf, 1E2);
          }
        });
    },
    renderPdf() {
      import('pdfjs-dist/webpack').then((pdfjs) => {
        pdfjs
          .getDocument({ data: this.filePdf })
          .then((pdf) => {
            this.pdf = pdf;
          });
      });
    },
  },
  mounted() {
    this.downloadView();
    this.updateScrollBounds();
  },
};
</script>
<style>
  .ViewPdf__row {
    min-height: 90%;
    max-height: 100%;
  }
</style>
