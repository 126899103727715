<template lang="html">
  <div v-if="canAccessPage">
    <v-row>
      <v-col cols="12" sm="3" v-if="!somenteLeitura" class="pt-0">
        <tipo-investimento-form
          ref="tipoInvestimentoForm"
          @FORMULARIO_SALVAR="onNovo"
          @FORMULARIO_CANCELAR="onCancelar" />
      </v-col>
      <v-col cols="12" :md="somenteLeitura ? 12 : 9" class="pt-0">
        <tipo-investimento-tabela
          ref="tipoInvestimentoTabela"
          class="CRUDGenerico__ContentTable"
          :title="$tc('label.tipo_investimento', 2)"
          @PESQUISA_EDITAR="onEditar"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '../../common/functions/roles-computed-generator';
import TipoInvestimentoForm from './TipoInvestimentoForm';
import TipoInvestimentoTabela from './TipoInvestimentoTabela';

export default {
  name: 'TipoInvestimento',
  data() {
    return {
      tipoInvestimentoResources: this.$api.tipoInvestimento(this.$resource),
    };
  },
  components: {
    TipoInvestimentoForm,
    TipoInvestimentoTabela,
  },
  computed: {
    ...generateComputed('tipo linha', [
      'canAccessCRUD',
      'canAccessPage',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    onCancelar() {
      this.$refs.tipoInvestimentoTabela.filtrar();
    },
    onEditar(item) {
      this.$refs.tipoInvestimentoForm.editar(item);
    },
    onNovo() {
      this.$refs.tipoInvestimentoTabela.filtrar();
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
