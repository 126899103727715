export const buscaAutocomplete = (autocomplete, callback) => {
  if (typeof autocomplete !== 'string') {
    autocomplete = '';
  }
  return callback(autocomplete);
};

export const triggerSelecao = (id) => {
  setTimeout(() => document.getElementById(id).click());
};
