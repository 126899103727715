<template lang="html">
  <div>
    <v-card class="card-size">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="searchQuery"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="lista"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.acoes="{ item }">
          <v-tooltip bottom v-if="canAccessCRUD">
            <template v-slot:activator="{ on }">
              <v-btn icon class="mx-0" v-on="on" @click.stop="onEditar(item)">
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.editar') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.nom_unidade="{ item }">
          {{ item.nomUnidade }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  data() {
    return {
      unidadeMedidaResources: this.$api.unidadeMedida(this.$resource),
      lista: [],
      esperar: false,
      headers: [
        { text: this.$tc('label.sigla', 1), value: 'sigla', sortable: true },
        { text: this.$tc('label.nome', 1), value: 'nom_unidade', sortable: true },
      ],
      pagination: {},
      totalPage: 0,
      searchQuery: '',
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  props: {
    title: String,
  },
  computed: {
    ...generateComputed('unid medida', [
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    buscar() {
      const params = {
        nome: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.unidadeMedidaResources.listar(params).then((response) => {
        this.lista = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    getSearchQuery() {
      return this.searchQuery;
    },
    onEditar(unidade) {
      this.$emit('PESQUISA_UNIDADE_EDITAR', unidade);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  mounted() {
    if (!this.somenteLeitura) {
      this.headers.unshift({
        text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
      });
    }
  },
};
</script>
