<template lang="html">
  <div>
    <v-form ref="form" lazy-validation>
      <v-card-title primary-title class="card-header">
        {{ $tc('title.inclusao_acao', 2) }} *
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="3">
          <v-radio-group v-model="parametrizacao.tipoInclusaoAcao"
            id="config_contrato_inclusao_acao"
            :rules="[rules.requiredLista]"
            @change="alterarCamposPadrao()"
          > <v-radio
              v-if="isTenantEpoca"
              :label="$t('label.carta_parceria_epoca')"
              value='CARTA_PARCERIA_EPOCA'>
            </v-radio>
            <v-radio :label="$t('label.carta_parceria_samsung')" value="CARTA_PARCERIA_SAMSUNG"></v-radio>
            <v-radio :label="$t('label.carta_parceria')" value="CARTA_PARCERIA"></v-radio>
            <v-radio :label="$t('label.contrato_padrao_tradelinks')" value="CONTRATO_PADRAO"></v-radio>
            <v-radio :label="$t('label.carta_parceria_familia')" value="CARTA_PARCERIA_NIVEL_FAMILIA"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header">
        {{ $tc('title.campo_padrao', 2) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.campo_padrao')}`" />
      </v-card-title>
      <v-data-table id="config_contrato_tabela_lista_padrao"
        :headers="headers"
        :items="parametrizacao.listaPadrao"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.obrigatorio="{ item }">
          {{ item.obrigatorio ? $t('label.sim') : $t('label.nao')}}
        </template>
        <template v-slot:item.opcoes="{ item }">
          <v-switch
            id="config_acao_desc_automatica"
            :label="$tc('label.preenchimento_automatico', 1)"
            v-model="parametrizacao.indDescricaoAutomatica"
            v-if="item.label === 'descricao'"/>
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header">
        {{ $tc('title.origem_contrato', 1) }} *
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.origem_acao')}`" />
      </v-card-title>
      <v-row class="mx-4" v-for="(c, index)  in parametrizacao.listaFornecedor"
              :key="`passo2-lista-fornecedor-${index}`">
        <v-col cols="12" sm="6" md="3">
          <v-switch
            dense
            hide-details
            :label="$t(`label.habilitar_acao_${c.label}`)"
            v-model="c.habilitado"
            :rules="c.label === 'fornecedor_pagador' ? [] : [rules.requiredOrigemAcao]"
            @change="resetarValidacaoOrigemAcao" />
        </v-col>
        <v-col cols="12" sm="6" md="3" v-if="c.label === 'fornecedor_pagador'">
          <v-switch
              :label="$t('label.obrigatorio')"
              v-model="c.obrigatorio" />
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.produto', 1) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.produto')}`" />
      </v-card-title>
      <v-data-table id="config_contrato_tabela_produto"
        :headers="headersProduto"
        :items="parametrizacao.listaProduto"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.aplicar_se_a="{ item }">
          {{ item.aplicarse ? item.aplicarse : ' - ' }}
        </template>
        <template v-slot:item.obrigatorio="{ item }">
          <v-checkbox v-model="item.obrigatorio" hide-details :disabled="item.excecao" @change="alteraValidadeNoPasso3(item)" class="ma-0 pa-0"></v-checkbox>
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.valor_contrato', 1) }} *
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.valor')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="3">
          <v-radio-group v-model="parametrizacao.valor"
            id="config_contrato_valor"
            :rules="[rules.requiredLista]"
          >
            <v-radio :label="$t('label.nao_se_aplica')" value="N/A"></v-radio>
            <v-radio :label="$t('label.informado_manualmente')" value="MANUAL"></v-radio>
            <v-radio :label="$t('label.calculado_automaticamente')" value="AUTOMATICO"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-if="parametrizacao.valor === 'AUTOMATICO'"
            id="config_contrato_valor_template"
            class="Form-text__invalid"
            name="config_contrato_valor_template"
            item-text="text"
            item-value="value"
            v-model="parametrizacao.template"
            :items="templates"
            :rules="[rules.required]"
            :label="$t(`label.template_calculo`)"
            required
            clearable>
          </v-select>
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('label.campo_customizado', 2) }}
        <v-spacer />
        <div style="cursor: pointer" class="mr-2">
          <span v-html="`${$t('label.nao_encontrou_campo_clique_aqui')}`" @click="novoCampoCustomizado"/>
        </div>
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.campos_customizados')}`" />
      </v-card-title>
      <v-data-table
        id="config_contrato_tabela_campos_customizados"
        :headers="headersCamposDinamicos"
        :items="parametrizacao.listaDinamica"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.obrigatorio="{ item }">
          <v-checkbox v-model="item.obrigatorio" hide-details @change="alteraValidadeNoPasso3(item)" class="ma-0 pa-0"></v-checkbox>
        </template>
      </v-data-table>

      <v-row justify="end" class="my-4">
        <v-btn id="config_contrato_passo2_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_contrato_passo2_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn color="primary" id="config_contrato_passo2_botao_proximo" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>

      <v-dialog v-model="dialogCampoCustomizado" persistent max-width="800px">
        <campos-customizados-form
          ref="campoCustomizado"
          :desabilitarSelecaoEntidade="true"
          @atualizarGrid="atualizarGridCamposCustomizados"
          @cancelar="cancelarCamposCustomizados">
          <div slot="button">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.native="cancelarCamposCustomizados">{{ $t('label.cancelar') }}</v-btn>
              <v-btn @click.native="validarSalvarCamposCustomizados" color="primary">{{ $t('label.salvar') }}</v-btn>
            </v-card-actions>
          </div>
        </campos-customizados-form>
      </v-dialog>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import CamposCustomizadosForm from '../../campos-customizados/CamposCustomizadosForm';
import listaPadraoList from './listaPadrao';
import Passo2 from './Passo2';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoContratoPasso2',
  props: {
    id: Number,
  },
  components: {
    CamposCustomizadosForm,
    TooltipAjuda,
  },
  data() {
    return {
      passo: 2,
      camposCustomizadosResources: this.$api.camposCustomizados(this.$resource),
      nivelClienteResources: this.$api.nivelCliente(this.$resource),
      nivelProdutoResources: this.$api.nivelProduto(this.$resource),
      paramContratoResources: this.$api.parametrizacaoContrato(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),

      dialogCampoCustomizado: false,

      vinculoCarteira: [],

      headers: [
        { text: this.$tc('label.campo', 1), value: 'nome', width: '50%' },
        {
          text: this.$tc('label.obrigatorio', 1), value: 'obrigatorio', width: '25%', sortable: false,
        },
        {
          text: '', value: 'opcoes', width: '40%', sortable: false,
        },
      ],
      headersProduto: [
        {
          text: this.$tc('label.campo', 1), value: 'nome', width: '15%', sortable: false,
        },
        {
          text: this.$tc('label.aplicar_se_a', 1), value: 'aplicar_se_a', width: '15%', sortable: false,
        },
        {
          text: this.$tc('label.obrigatorio', 1), value: 'obrigatorio', sortable: false, width: '10%',
        },
      ],
      headersCamposDinamicos: [
        { text: this.$tc('label.campo', 1), value: 'nomCampo' },
        { text: this.$tc('label.tipo', 1), value: 'tipoCampo' },
        { text: this.$tc('label.obrigatorio', 1), value: 'obrigatorio', sortable: false },
      ],
      listaCliente: [
        {
          nome: this.$tc('label.cliente', 1), value: 'cliente', label: 'cliente', obrigatorio: true, habilitado: false,
        },
        {
          nome: this.$tc('label.holding', 1), value: 'holding', label: 'holding', obrigatorio: true, habilitado: false,
        },
        {
          nome: this.$tc('label.cliente_pagador', 1), value: 'cliente_pagador', label: 'cliente_pagador', obrigatorio: false, habilitado: false,
        },
        {
          nome: this.$tc('label.campo_cliente', 1), value: 'campo_cliente', label: 'campo_cliente', obrigatorio: true, habilitado: false,
        },
      ],
      listaFornecedor: [
        {
          nome: this.$tc('label.grupo_fornecedor', 1), value: 'grupo_fornecedor', label: 'grupo_fornecedor', obrigatorio: true, habilitado: false,
        },
        {
          nome: this.$tc('label.fornecedor', 1), value: 'fornecedor', label: 'fornecedor', obrigatorio: true, habilitado: false,
        },
        {
          nome: this.$tc('label.fornecedor_pagador', 1), value: 'fornecedor_pagador', label: 'fornecedor_pagador', obrigatorio: false, habilitado: false,
        },
      ],
      listaProduto: [
        {
          nome: this.$tc('label.produto', 1), value: 'produto', obrigatorio: false, label: 'produto', indCampoProduto: true, excecao: false,
        },
      ],
      listaPadrao: listaPadraoList(this),
      listaPadraoPasso2: [],
      parametrizacao: new Passo2(),
      tiposNivelProduto: [],
      tiposNivelCliente: [],
      fluxos: [],
      templates: [
        { text: `${this.$tc('label.crescimento', 1)}`, value: 'CRESCIMENTO' },
        { text: `${this.$tc('label.soma_valor_monetario_acoes', 1)}`, value: 'SOMA_VALOR_MONETARIO_ACOES' },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || '',
        requiredFocoAcao: () => (!!this.parametrizacao.listaCliente
          && this.parametrizacao.listaCliente.filter((c) => c.habilitado === true && c.label !== 'cliente_pagador').length > 0)
          || '',
        requiredOrigemAcao: () => (!!this.parametrizacao.listaFornecedor
          && this.parametrizacao.listaFornecedor.filter((f) => f.habilitado === true && f.label !== 'fornecedor_pagador').length > 0)
          || '',
      },
      campoValorBanco: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getClienteMetadado',
    ]),
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
    isFluxoOrcamento() {
      if (this.fluxos) {
        return this.fluxos.findIndex((fluxo) => fluxo === 'ORCAMENTO') > -1;
      }
      return false;
    },
    isFluxoLiquidacao() {
      if (this.fluxos) {
        return this.fluxos.findIndex((fluxo) => fluxo === 'LIQUIDACAO') > -1;
      }
      return false;
    },
    camposCliente() {
      const { mapaEntidades } = this.getClienteMetadado;
      return Object.values(mapaEntidades)
        .map((c) => ({
          id: c.idNivelExtensao,
          descricao: c.entidadeEstrangeira,
          label: c.label,
          mnemonico: c.mnemonico,
          indiceRecursao: c.indiceRecursao,
        })).filter((c) => c.label !== 'unidadenegocio');
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setClienteMetadado',
    ]),
    invalidarCampoCliente(campo) {
      if (!campo.habilitado) {
        campo.campoCliente = null;
      }
    },
    setFluxos(fluxo) {
      this.fluxos = fluxo.split(',');
    },
    setListaCarteiraCliente(listaCarteiraCliente = []) {
      this.listaCarteiraCliente = listaCarteiraCliente
        .filter((campo) => campo.mnemonico !== 'UNIDADE_NEGOCIO' && campo.habilitado);
    },
    adicionaSeForNovo(objeto, item) {
      let hasItem = false;
      objeto.forEach((itemObj) => {
        if (itemObj.label === item.label) {
          hasItem = true;
        }
      });
      if (!hasItem) objeto.push(item);
    },
    atualizarGridCamposCustomizados(id) {
      this.carregarCampoDinamicoEspecifico(id)
        .then(() => {
          this.dialogCampoCustomizado = false;
        });
    },
    buscarMetadadosNivelProduto() {
      return this.nivelProdutoResources.acao().then((resultado) => {
        this.tiposNivelProduto = resultado.data;
        this.maiorIndice = Math.max(...this.tiposNivelProduto.map((tipo) => tipo.indice));

        this.tiposNivelProduto.forEach((nivel) => {
          const item = {
            nome: nivel.descricao,
            obrigatorio: false,
            excecao: false,
            label: nivel.label,
            aplicarse: this.exibirPai(nivel),
            indCampoProduto: true,
          };

          this.adicionaSeForNovo(this.listaProduto, item);
        });
      });
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_CONTRATO_CANCELAR');
    },
    cancelarCamposCustomizados() {
      this.dialogCampoCustomizado = false;
    },
    carregarCamposDinamicos() {
      return this.camposCustomizadosResources.listarPorEntidadeContrato()
        .then((response) => {
          this.parametrizacao.listaDinamica = response.data;
          this.parametrizacao.listaDinamica.forEach((l) => {
            l.nome = l.nomCampo;
            l.desAtributos = l.desAtributos && l.desAtributos.value
              ? JSON.parse(l.desAtributos.value) : {};
            l.idCampoDinamico = l.id;
            l.label = l.labelCampo;
            l.obrigatorio = l.vlrObrigatorio;
            l.edicaoAprovado = false;
            delete l.id;
          });
        }, (err) => {
          this.$error(this, err);
        });
    },
    carregarCampoDinamicoEspecifico(id) {
      const param = { id, page: 1, size: 10 };
      return this.camposCustomizadosResources.listar(param).then((response) => {
        const resultado = response.data.resposta;
        if (resultado && resultado.length > 0) {
          const novoCampoDinamico = resultado[0];
          novoCampoDinamico.nome = novoCampoDinamico.nomCampo;
          novoCampoDinamico.desAtributos = novoCampoDinamico.desAtributos
            && novoCampoDinamico.desAtributos.value
            ? JSON.parse(novoCampoDinamico.desAtributos.value) : {};
          novoCampoDinamico.idCampoDinamico = novoCampoDinamico.id;
          novoCampoDinamico.label = novoCampoDinamico.labelCampo;
          novoCampoDinamico.obrigatorio = novoCampoDinamico.vlrObrigatorio;
          delete novoCampoDinamico.id;
          this.parametrizacao.listaDinamica.push(novoCampoDinamico);
          Passo2.setListaDinamica(this.parametrizacao.listaDinamica);
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    definirLista(listaPrincipal, lista) {
      lista.forEach((l) => {
        const original = listaPrincipal.filter((el) => el.label === l.label)[0];
        if (!original) {
          return;
        }
        original.habilitado = true;
        original.obrigatorio = l.obrigatorio;
        original.excecao = l.excecao;
        original.edicaoAprovado = l.edicaoAprovado;
        original.fluxos = l.fluxos;

        if (original.label === 'campo_cliente') {
          const [campoCliente] = this.camposCliente.filter((c) => {
            const { id, indiceRecursao } = c;
            return id === l.idNivelExtensao
              && indiceRecursao === l.indiceRecursao;
          });
          original.campoCliente = campoCliente;
        }
      });
    },
    exibirPai(nivel) {
      if (nivel.indice == null || nivel.indice === this.maiorIndice) {
        return ' - ';
      }
      return this.tiposNivelProduto
        .filter((item) => item.indice === (nivel.indice + 1))[0].descricao;
    },
    getListaCliente() {
      return this.parametrizacao.listaCliente;
    },
    exibirFocoAcao(item) {
      if (item.label === 'cliente_pagador') {
        if (this.isFluxoOrcamento) {
          item.habilitado = false;
          return false;
        }
        return true;
      }
      return true;
    },
    exibirFocoAcaoObrigatorio(item) {
      if (item.label === 'cliente_pagador') {
        if (this.isFluxoOrcamento) {
          return false;
        }
        return true;
      }
      return false;
    },
    isFocoAcaoDesabilitado(item) {
      if (item.label === 'cliente_pagador') {
        if (this.isFluxoLiquidacao) {
          item.habilitado = true;
          return true;
        }
        return false;
      }
      return false;
    },
    resetarValidacaoFocoAcao() {
      if (this.parametrizacao.listaCliente.filter((c) => c.habilitado).length > 0) {
        this.$refs.form.resetValidation();
        this.$refs.form.validate();
      }
    },
    resetarValidacaoOrigemAcao() {
      if (this.parametrizacao.listaFornecedor.filter((f) => f.habilitado).length > 0) {
        this.$refs.form.resetValidation();
        this.$refs.form.validate();
      }
    },
    alteraValidadeNoPasso3(item) {
      if (!item.obrigatorio && !item.excecao) {
        item.PLANEJAMENTO = false;
        item.fluxos.splice(item.fluxos.findIndex((fluxo) => fluxo === 'PLANEJAMENTO'), 1);
      }
    },
    novoCampoCustomizado() {
      this.dialogCampoCustomizado = true;
      setTimeout(() => {
        this.$refs.campoCustomizado.habilitarApenasUmaEntidade('CONTRATO');
      }, 1000);
    },
    obter(id) {
      this.paramContratoResources.obterPasso2({ id }, { id }).then((response) => {
        this.definirLista(this.listaFornecedor, response.data.listaFornecedor);
        this.definirLista(this.listaProduto, response.data.listaProduto);
        this.definirLista(this.listaPadrao, response.data.listaPadrao);
        this.definirLista(this.parametrizacao.listaDinamica, response.data.listaDinamica);
        this.definirListaCarteira(response.data.listaCarteiraCliente, this.vinculoCarteira);

        this.listaPadraoPasso2 = response.data.listaPadrao;

        // Valor
        let valor = 'N/A';
        const labelValor = response.data.listaPadrao.filter((p) => p.label === 'valor');
        if (labelValor && labelValor.length) {
          const [campoValor] = labelValor[0];
          this.campoValorBanco = campoValor;
          if (response.data.indCalculoAutomatico) {
            valor = 'AUTOMATICO';
          } else {
            valor = 'MANUAL';
          }
        }

        this.parametrizacao = new Passo2(this.id,
          this.listaCliente,
          this.listaFornecedor,
          this.listaProduto,
          this.listaPadrao,
          this.parametrizacao.listaDinamica,
          this.vinculoCarteira,
          response.data.indCalculoAutomatico,
          response.data.indDescricaoAutomatica,
          response.data.template,
          valor,
          response.data.tipoInclusaoAcao);
      }, (err) => {
        this.$error(this, err);
      });
    },
    definirListaCarteira(listaBanco, listaPadrao) {
      if (!listaBanco) return;
      listaBanco.forEach((v) => {
        const extCarteira = listaPadrao.find((e) => e.mnemonico === v.mnemonico);
        if (extCarteira) {
          extCarteira.fluxos = v.fluxos;
          extCarteira.habilitado = v.habilitado;
          extCarteira.id = v.id;
        }
      });
    },
    prepararPasso() {
      Promise.all([
        this.carregarCamposDinamicos(),
        this.buscarMetadadosNivelProduto(),
        this.buscarVinculosCarteira(),
      ])
        .then(() => {
          if (this.id) {
            this.obter(this.id);
          } else {
            this.listaPadraoPasso2 = listaPadraoList(this);

            this.parametrizacao = new Passo2(this.id,
              this.listaCliente,
              this.listaFornecedor,
              this.listaProduto,
              this.listaPadrao,
              this.parametrizacao.listaDinamica,
              this.vinculoCarteira);
          }
        });
    },
    buscarVinculosCarteira() {
      return this.paramContratoResources.buscarVinculosCarteira()
        .then((resultado) => {
          this.vinculoCarteira = resultado.data;
          this.vinculoCarteira.forEach((v) => {
            v.fluxos = ['PLANEJAMENTO'];
            v.indCampoCarteira = true;
            v.nome = `${this.$tc('label.vinculo_industria', 1)} - ${v.descricao}`;
            if (v.mnemonico === 'UNIDADE_NEGOCIO') {
              v.habilitado = true;
            }
          });
        });
    },
    proximoPasso() {
      if (this.$refs.form.validate()) {
        this.parametrizacao.indCalculoAutomatico = false;
        if (this.parametrizacao.valor !== 'N/A') {
          if (this.parametrizacao.valor === 'AUTOMATICO') {
            this.parametrizacao.indCalculoAutomatico = true;
          }
          const listaValor = this.parametrizacao.listaPadrao.filter((p) => p.label === 'valor');
          if (!listaValor || !listaValor.length) {
            this.parametrizacao.listaPadrao.push(
              {
                nome: this.$tc('label.valor', 1),
                obrigatorio: true,
                label: 'valor',
                habilitaGrid: true,
                habilitaFiltro: false,
                desativaFiltro: true,
                desativaGrid: true,
                habilitaEdicao: false,
                edicaoAprovado: false,
                fluxos: this.campoValorBanco ? this.campoValorBanco.fluxos : [],
              },
            );
          }
        } else {
          const listaValor = this.parametrizacao.listaPadrao.filter((p) => p.label === 'valor');
          if (listaValor && listaValor.length) {
            const idx = this.parametrizacao.listaPadrao.indexOf(listaValor[0]);
            this.parametrizacao.listaPadrao.splice(idx, 1);
          }
        }
        this.$emit('PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
        this.$emit('PARAMETRIZACAO_CONTRATO_CONTINUAR', this.passo + 1, this.id);
      } else {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
      }
    },
    validarSalvarCamposCustomizados() {
      this.$refs.campoCustomizado.abrirDialogSalvar();
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_CONTRATO_VOLTAR', this.passo - 1);
    },
    getListaPadrao() {
      return this.listaPadraoPasso2;
    },
    alterarCamposPadrao() {
      if (this.parametrizacao.tipoInclusaoAcao === 'CARTA_PARCERIA_SAMSUNG') {
        this.parametrizacao.indDescricaoAutomatica = true;

        const campoUnidadeNegocio = this.listaPadraoPasso2.find((campo) => campo.label === 'unidadenegocio');
        if (campoUnidadeNegocio) {
          campoUnidadeNegocio.obrigatorio = false;
        }
      } else {
        this.parametrizacao.indDescricaoAutomatica = false;
        this.listaPadraoPasso2 = listaPadraoList(this);
      }
    },
  },
  mounted() {
    this.setClienteMetadado({
      resource: this.metadadoResource.listarCliente,
    });
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .card-header {
    background-color: var(--v-primary-base);
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
