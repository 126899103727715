import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/orcamento');

const workflowOrcamento = {
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idOrcamento}` },
  statusPasso: { method: 'GET', url: `${basePath}/{idOrcamento}/passos/status` },
  aprovador: { method: 'GET', url: `${basePath}/{idOrcamento}/passos/aprovador` },
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idOrcamento}` },
  status: { method: 'GET', url: `${basePath}/{idOrcamento}/status` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idOrcamento}/passos/analise` },
  aprovar: { method: 'PUT', url: `${basePath}/{idOrcamento}/passos/aprovar` },
  reprovar: { method: 'PUT', url: `${basePath}/{idOrcamento}/passos/reprovar` },
  cancelar: { method: 'PUT', url: `${basePath}/{idOrcamento}/cancelar` },
  existeFluxoCadastrado: { method: 'GET', url: `${basePath}/{idOrcamento}/fluxo-cadastrado` },
};

export const pendenciasFluxoOrcamento = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();

export default (resource) => resource(`${basePath}`, {}, workflowOrcamento);
