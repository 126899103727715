import TransferenciaPosse from '../../spa/transferencia-posse/TransferenciaPosse';
import TransferenciaPosseAbas from '../../spa/transferencia-posse/TransferenciaPosseAbas';

export default {
  path: 'transferencia-posse',
  component: TransferenciaPosse,
  children: [
    {
      path: '',
      name: 'transferencia-posse',
      component: TransferenciaPosseAbas,
    },
  ],

};
