<template lang="html">
  <div>
    <v-card class="card-size">
      <v-card-title>
        <h2 class="headline mb-0" v-text="title"></h2>
      </v-card-title>
      <v-container fluid class="py-0">
        <v-row justify="start">
          <v-col cols="12" sm="6" md="3" class="mr-3 py-0">
            <v-text-field
              id="tipo-investimento-campo-pesquisar"
              v-model="searchQuery"
              @input="filtrar"
              append-icon="search"
              :label="$t('label.pesquisar')"
              single-line
              clearable
              hide-details>
            </v-text-field>
          </v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon
                id="tipo-investimento-botao-exportar"
                class="mx-0 mt-5"
                v-on="on"
                @click="exportar">
                <v-icon>get_app</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.exportar') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon
                id="tipo-investimento-botao-ativar-inativar"
                v-show="!somenteLeitura && selected.length > 0"
                class="mx-0 mt-5"
                v-on="on"
                @click="ativarDesativarRegistros">
                <v-icon>block</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.ativar_inativa') }}</span>
          </v-tooltip>
        </v-row>
      </v-container>
      <v-data-table
        :headers="headers"
        :items="tipos"
        v-model="selected"
        show-select
        class="text-sm-left"
        :item-class="onItemClass"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.acoes="{ item }">
          <v-tooltip bottom v-if="!somenteLeitura">
            <template v-slot:activator="{ on }">
              <v-btn icon class="mx-0" v-on="on" @click="onEditar(item)">
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.editar') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.id_externo="{ item }">
          {{ item.idExterno }}
        </template>
        <template v-slot:item.ind_ativo="{ item }">
          {{ item.indAtivo ? $t('label.sim') : $t('label.nao')}}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '../../common/functions/roles-computed-generator';
import exportacao from '../../common/resources/exportacoes';

export default {
  data() {
    return {
      tipoInvestimentoResources: this.$api.tipoInvestimento(this.$resource),
      tipos: [],
      esperar: false,
      headers: [
        {
          text: '', value: 'acoes', width: '2%', sortable: false,
        },
        { text: this.$tc('label.codigo', 1), value: 'id_externo', sortable: true },
        { text: this.$tc('label.nome', 1), value: 'nome', sortable: true },
        { text: this.$tc('label.ativo', 1), value: 'ind_ativo', sortable: true },
      ],
      pagination: {},
      totalPage: 0,
      searchQuery: '',
      selected: [],
    };
  },
  props: {
    title: String,
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    filteredList() {
      return [];
    },
    ...generateComputed('tipo_linha', [
      'canAccessCRUD',
      'canAccessPage',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    onItemClass(...args) {
      const [item] = args;
      return !item.indAtivo ? 'TableRow--disabled' : '';
    },
    buscar() {
      if (this.searchQuery) {
        this.pagination.page = 1;
      }

      const params = {
        texto: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.tipoInvestimentoResources.listar(params).then((response) => {
        this.tipos = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    onEditar(item) {
      this.$emit('PESQUISA_EDITAR', item);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    mudarOrdenacao(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.tipos.slice();
    },
    ativarDesativarRegistros() {
      this.requestAtivarInativar();
    },
    requestAtivarInativar() {
      if (this.selected && this.selected.length > 0) {
        this.tipoInvestimentoResources.ativarMassa(this.selected)
          .then(() => {
            this.selected = [];
            this.$toast(this.$t('message.alteracao_massa_sucesso'));
            this.filtrar();
          })
          .catch((err) => this.$error(this, err));
      }
    },
    exportar() {
      const filtros = {};
      const limit = 500;
      const timeout = 120000;
      const resource = exportacao(this.$http, timeout);
      resource.download('tipo_investimento_simples',
        {
          limit,
          parametrosConsulta: filtros,
        })
        .then(() => {
          this.$toast(this.$t('message.exportacao_download'));
        })
        .catch(() => {
          if (cb) {
            cb();
          }
          this.$toast('Erro ao exportar. Tente novamente.');
        });
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
<style src="../../shared-components/generico/CRUDGenerico.css" lang="css"></style>
