<template lang="html">
  <div>
    <div class="md-title">
      {{$tc('label.erro', 2)}}
    </div>
    <v-row justify="end">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="registros"
          :no-data-text="$t('message.nao_ha_conteudo')">
          <template slot="items" slot-scope="props">
            <td v-if="props.item.linha">{{ props.item.linha }}</td>
            <td v-if="props.item.descricao"> {{ $t(`errors.importacao.${props.item.descricao.toLowerCase()}`, {param: props.item.param}) }} </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    headers: Array,
    registros: Array,
  },
};
</script>
