import getBasePath from '../../functions/api-resource';
import genericActions from '../generic-resource';

const basePath = getBasePath('api', 'carteira-cliente');

const areaAtuacaoActions = {
  ...genericActions(basePath),
  obter: { method: 'GET', url: `${basePath}/{id}` },
  obterClientes: { method: 'GET', url: `${basePath}/{id}/cliente` },
  obterDivisoes: { method: 'GET', url: `${basePath}/{id}/divisao` },
  pesquisar: { method: 'GET', url: `${basePath}` },
  pesquisarPorDivisao: { method: 'GET', url: `${basePath}/divisao{?idsCarteiraNotIn*&idsDivisao*}` },
};

export default (resource) => resource(`${basePath}`, {}, areaAtuacaoActions);
