import SSO from '../../spa/sso/SSO';

const GrupoForm = () => import('../../spa/sso/grupo/GrupoForm');
const GrupoList = () => import('../../spa/sso/grupo/GrupoList');

export default {
  path: 'sso-grupo',
  component: SSO,
  children: [
    {
      path: '',
      name: 'sso-grupo',
      component: GrupoList,
    },
    {
      path: 'novo',
      name: 'novoGrupo',
      component: GrupoForm,
      props: {
        somenteLeitura: false,
      },
    },
    {
      path: ':id',
      name: 'verGrupo',
      component: GrupoForm,
      props: {
        somenteLeitura: true,
      },
    },
    {
      path: ':id/editar',
      name: 'editarGrupo',
      component: GrupoForm,
      props: {
        somenteLeitura: false,
      },
    },
  ],
};
