<template>
  <v-dialog v-model="dialog" width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $tc('title.parametrizacao_acao', 1) }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="parametrizacoesAcao"
          hide-default-footer
          disable-pagination
          :items-per-page="-1"
          class="elevation-1">
          <template v-slot:item.acoes="{ item }" v-if="canAccessPage">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click.stop="abrirParametrizacaoAcao(item.idAcaoTipo)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.editar') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { generateComputed } from '../../../common/functions/roles-computed-generator';

export default {
  name: 'ListaParametrizacaoAcao',
  props: {
    parametrizacoesAcao: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: '', value: 'acoes', sortable: false },
        { text: this.$t('label.nome'), value: 'acaoTipo' },
        { text: this.$t('label.descricao'), value: 'descricao' },
      ],
    };
  },
  computed: {
    ...generateComputed('Param Acao', [
      'canAccessPage',
    ]),
  },
  methods: {
    open() {
      this.dialog = true;
      this.$forceUpdate();
    },
    abrirParametrizacaoAcao(id) {
      this.$router.push({ name: 'editarParametrizacaoAcao', params: { id } });
    },
  },
};
</script>
