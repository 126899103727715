import Usuario from '../../spa/usuario/CadastroUsuario';

const UsuarioFuncionarioForm = () => import('../../spa/usuario/UsuarioFuncionarioForm');
const UsuarioFuncionarioList = () => import('../../spa/usuario/UsuarioFuncionarioList');

export default {
  path: 'usuario-funcionario',
  component: Usuario,
  children: [
    {
      path: '',
      name: 'usuario-funcionario',
      component: UsuarioFuncionarioList,
    },
    {
      path: ':id/editar',
      name: 'editarUsuarioFuncionario',
      component: UsuarioFuncionarioForm,
      props: {
        somenteLeitura: false,
      },
    },
    {
      path: 'novo',
      name: 'novoUsuarioFuncionario',
      component: UsuarioFuncionarioForm,
    },
    {
      path: ':id',
      name: 'verUsuarioFuncionario',
      component: UsuarioFuncionarioForm,
      props: {
        somenteLeitura: true,
      },
    },
  ],
};
