const mutations = {
  popularImportacoesAndamento(state, importacao) {
    if (importacao) {
      state.andamento = importacao;
    }
  },
  popularImportacoesFinalizados(state, importacao) {
    if (importacao) {
      state.finalizados = importacao;
    }
  },
  popularImportacoesErros(state, importacao) {
    if (importacao) {
      state.erros = importacao;
    }
  },
  popularTiposImportacao(state, { tiposImportacao, roles = [] }) {
    if (!tiposImportacao) return;

    const lista = tiposImportacao.filter((t) => !t.role || roles.indexOf(t.role) !== -1);
    lista.forEach((el) => {
      el.arquivosSuportados = el.arquivosSuportados.split(',')
        .map((a) => `.${a}`).join(',');
    });
    state.tiposImportacao = lista.sort((a, b) => {
      if (a.id > b.id) {
        return 1;
      }
      return -1;
    });
  },
};

const actions = {
  popularImportacoesAndamento({ commit }, resource) {
    return resource()
      .then((response) => {
        commit('popularImportacoesAndamento', response.data);
      })
      .catch((err) => {
        throw err;
      });
  },
  popularImportacoesFinalizados({ commit }, resource) {
    return resource()
      .then((response) => {
        commit('popularImportacoesFinalizados', response.data);
      })
      .catch((err) => {
        throw err;
      });
  },
  popularImportacoesErros({ commit }, resource) {
    return resource()
      .then((response) => {
        commit('popularImportacoesErros', response.data);
      })
      .catch((err) => {
        throw err;
      });
  },
  popularTiposImportacao({ commit }, { resource, roles }) {
    return resource()
      .then((response) => {
        const tiposImportacao = response.data;
        commit('popularTiposImportacao', { tiposImportacao, roles });
      })
      .catch((err) => {
        throw err;
      });
  },
};

const getters = {
  getImportacoesAndamento(state) {
    return state.andamento;
  },
  getImportacoesFinalizados(state) {
    return state.finalizados;
  },
  getImportacoesErros(state) {
    return state.erros;
  },
  getAtualImportacao(state) {
    return state.atual;
  },
  getTiposImportacao(state) {
    return state.tiposImportacao;
  },
};

const state = {
  andamento: [],
  finalizados: [],
  erros: [],
  tiposImportacao: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
