<template lang="html">
  <div class="Importation__Wrapper" v-if="canImport">
    <v-row>
      <v-col md="3" sm="12">
        <importacao-lateral
          :tipos="getTiposImportacaoFiltrados"
          @IMPORTACAO_LATERACAO_UPLOAD="uploadRealizado"
          />
      </v-col>
      <v-col md="9" sm="12">
        <v-tabs
          centered
          @change="popularImportacoes"
          v-model="tabSelecionada"
          color="accent"
          background-color="rgba(0,0,0,0)">
          <v-tab href="#tab-importacoes-andamento">
            {{ $t('label.em_andamento') }}
          </v-tab>

          <v-tab href="#tab-importacoes-finalizadas">
            {{ $tc('label.finalizado', 2) }}
          </v-tab>

          <v-tab class="white-text" href="#tab-importacoes-erros">
            {{ $t('label.com_erros') }}
          </v-tab>

          <v-tabs-items v-model="tabSelecionada">
            <v-tab-item id="tab-importacoes-andamento">
              <importacao-tabela :title="$t('label.importacoes_andamento')"
                :headers="cabecalhoImportacoesAndamento"
                :registros="importacoesAndamento"
                :isErros="false">
              </importacao-tabela>
            </v-tab-item>

            <v-tab-item id="tab-importacoes-finalizadas">
              <importacao-tabela :title="$t('label.importacoes_finalizadas')"
                :headers="cabecalhoImportacoesFinalizados"
                :registros="importacoesFinalizadas"
                :isErros="true"
                @IMPORTACAOTABELA_OPEN_DIALOG_ERROS="getImportacoesDetalhesFinalizados">
              </importacao-tabela>
            </v-tab-item>

            <v-tab-item id="tab-importacoes-erros">
              <importacao-tabela :title="$t('label.importacoes_erros')"
                :headers="cabecalhoImportacoes"
                :registros="importacoesErros"
                :isErros="true"
                @IMPORTACAOTABELA_OPEN_DIALOG_ERROS="getImportacoesDetalhesErros">
              </importacao-tabela>
            </v-tab-item>

          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>

    <generic-modal
      ref="modalErros"
      :title="$tc('title.erro', 2)"
      v-if="importacaoDetalheErros">
      <div slot="body" style="width: 100%;">
        <importacao-dialog-erros
          style="max-height:400px"
          :registros="importacaoDetalheErros"
          :headers="tableHeaderDetalheErros"
          :source="idErro"
          @IMPORTACAODIALOGERROS_CLOSE_DIALOG="closeDialog('modalErros')">
        </importacao-dialog-erros>
      </div>
      <div slot="footer">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary" text
            @click.native="closeDialog('modalErros')">
            {{ $t('label.fechar') }}
          </v-btn>
        </v-card-actions>
      </div>
    </generic-modal>

    <generic-modal ref="modalFinalizada" v-if="importacaoDetalheFinalizados && importacaoDetalheErros">
      <div slot="body">
        <importacao-finalizada-dialog
          :table-rows-finalizado="importacaoDetalheFinalizados"
          :table-header-finalizado="tableHeaderDetalheFinalizados"
          :table-rows-erros="importacaoDetalheErros"
          :table-header-erros="tableHeaderDetalheErros"
          @IMPORTACAO_FINALIZADA_DIALOG_CLOSE="closeDialog('modalFinalizada')"/>
      </div>
      <div slot="footer">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary" text
            @click.native="closeDialog('modalFinalizada')">
            {{ $t('label.fechar') }}
          </v-btn>
        </v-card-actions>
      </div>
    </generic-modal>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ImportacaoLateral from '@/spa/importacao/ImportacaoLateral';
import GenericModal from '../../shared-components/generico/GenericModal';

import ImportacaoTabela from './ImportacaoTabela';
import ImportacaoDialogErros from './ImportacaoDialogErros';
import ImportacaoFinalizadaDialog from './ImportacaoFinalizadaDialog';

export default {
  name: 'Importacao',
  data() {
    return {
      idErro: -1,
      importacaoResources: this.$api.importacoes(this.$resource),
      tabSelecionada: null,
      cabecalhoImportacoes: [
        { text: 'Tipo', value: 'desImportacao', sortable: false },
        { text: 'Nome', value: 'nomArquivoOrigem', sortable: false },
        { text: 'Data', value: 'dtaCriacao', sortable: false },
        { text: '', value: 'desResultado', sortable: false },
      ],
      cabecalhoImportacoesFinalizados: [
        { text: 'Tipo', value: 'desImportacao', sortable: false },
        { text: 'Nome', value: 'nomArquivoOrigem', sortable: false },
        { text: 'Data', value: 'dtaCriacao', sortable: false },
        { text: '', value: 'desResultado', sortable: false },
      ],
      cabecalhoImportacoesAndamento: [
        { text: 'Tipo', value: 'desImportacao', sortable: false },
        { text: 'Nome', value: 'nomArquivoOrigem', sortable: false },
        { text: 'Status', value: 'indStatus', sortable: false },
      ],
      tableHeaderDetalheErros: [
        {
          text: 'Linha', value: 'linha', sortable: false, width: '100',
        },
        { text: 'Descrição', value: 'descricao', sortable: false },
      ],
      tableHeaderDetalheFinalizados: [
        { text: 'Ação', value: 'label', sortable: false },
        { text: 'Registros', value: 'value', sortable: false },
      ],
      importacaoDetalheErros: [],
      importacaoDetalheFinalizados: [],
    };
  },
  computed: {
    ...mapGetters('importacao', {
      importacoesAndamento: 'getImportacoesAndamento',
      importacoesFinalizadas: 'getImportacoesFinalizados',
      importacoesErros: 'getImportacoesErros',
      tiposImportacao: 'getTiposImportacao',
    }),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canImport() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.endsWith('_IMPORTAR') || el.endsWith('_CRUD')).length;
    },
    getTiposImportacaoFiltrados() {
      return this.tiposImportacao.filter((tipo) => tipo.nome !== 'importacao_campanha_produto')
        .sort((a, b) => a.descricao - b.descricao);
    },
  },
  components: {
    ImportacaoTabela,
    ImportacaoLateral,
    GenericModal,
    ImportacaoDialogErros,
    ImportacaoFinalizadaDialog,
  },
  methods: {
    ...mapActions('importacao', [
      'popularImportacoesAndamento',
      'popularImportacoesFinalizados',
      'popularImportacoesErros',
    ]),
    accessForbidden() {
      if (!this.canImport) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    uploadRealizado() {
      setTimeout(() => {
        this.popularImportacoes('tab-importacoes-andamento');
      }, 3000);
    },
    openDialog(ref) {
      this.$refs[ref].open();
    },
    closeDialog(ref) {
      this.$refs[ref].close();
    },
    getImportacoesDetalhesErros(row) {
      this.importacaoDetalheErros = this.separateErros(row.desResultado);
      this.openDialog('modalErros');
    },
    getImportacoesDetalhesFinalizados(row) {
      this.importacaoDetalheFinalizados = this.separateDetalhes(row.desResultado, row.desImportacao);
      this.importacaoDetalheErros = this.separateErros(row.desResultado);
      this.openDialog('modalFinalizada');
    },
    separateErros(item) {
      const resultado = JSON.parse(item.value);
      let erros = [];
      if (resultado.erros) {
        erros = resultado.erros.map((e) => {
          const erroMap = e.split(' ');
          const linha = parseInt(erroMap[0], 10);
          const param = erroMap[2] ? erroMap[2].replace(/_/g, ' ').replace(/\|/g, ', ') : '';
          let complemento = '';
          if (erroMap[3]) {
            complemento = erroMap[3].slice(1, erroMap[3].length - 1)
              .split(',')
              .map((complementoErro) => this.$t(`errors.importacao.${complementoErro}`)).join('; ');
          }
          const erroPrincipal = this.$t(`errors.importacao.${erroMap[1]}`, { param });

          const descricao = complemento !== '' ? `${erroPrincipal}; ${complemento}` : erroPrincipal;
          return { linha, descricao };
        });

        erros.sort(
          (e1, e2) => {
            if (e1.linha > e2.linha) {
              return 1;
            }
            if (e2.linha > e1.linha) {
              return -1;
            }
            if (e1.descricao > e2.descricao) {
              return 1;
            }
            if (e2.descricao > e1.descricao) {
              return -1;
            }
            return 0;
          },
        );
      }
      return erros;
    },
    separateDetalhes(item, tipoImportacao) {
      const resultado = JSON.parse(item.value);
      if (['SellOut', 'SellIn'].includes(tipoImportacao)) {
        return [{
          label: this.$t('label.registros_inseridos'),
          value: resultado.registros_inseridos ? resultado.registros_inseridos[0] : '0',
        },
        {
          label: this.$t('label.registros_duplicados'),
          value: resultado.registros_deletados ? resultado.registros_deletados[0] : '0',
        }];
      }
      return [{
        label: this.$t('label.registros_inseridos'),
        value: resultado.registros_inseridos ? resultado.registros_inseridos[0] : '0',
      },
      {
        label: this.$t('label.registros_atualizados'),
        value: resultado.registros_atualizados ? resultado.registros_atualizados[0] : '0',
      }];
    },
    popularImportacoes(param) {
      this.tabSelecionada = param;

      switch (this.tabSelecionada) {
        case 'tab-importacoes-finalizadas':
          this.popularImportacoesFinalizados(this.importacaoResources.listarFinalizado)
            .catch(() => {
              this.$toast(this.$t('message.erro_buscar_importacoes'));
            });
          break;
        case 'tab-importacoes-erros':
          this.popularImportacoesErros(this.importacaoResources.listarErro)
            .catch(() => {
              this.$toast(this.$t('message.erro_buscar_importacoes'));
            });
          break;
        default:
          this.popularImportacoesAndamento(this.importacaoResources.listarAndamento)
            .catch(() => {
              this.$toast(this.$t('message.erro_buscar_importacoes'));
            });
          break;
      }
    },
  },
  mounted() {
    if (!this.canImport) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>

<style lang="css">
  .Importation__Wrapper .md-tabs .md-tab {
    padding: 16px 0;
  }

  .Importation__Wrapper .md-gutter-8:not(.md-column)>.md-layout,
  .Importation__Wrapper .md-gutter-16:not(.md-column)>.md-layout {
    margin: 0;
  }

  .Importation__SideMenu {
    max-width: 400px;
  }

  .Importation__TableWrapper > *,
  .Importation__ContentTable > * {
    outline: none;
  }

  .Importation__TableWrapper,
  .Importation__ContentTable  {
    width: 100%;
  }
</style>
