const mutations = {
  alterarParametroExibicaoMateriais(state, exibirMateriais) {
    state.parametros.exibirMateriais = exibirMateriais;
  },
};

const actions = {
  carregarParametroExibicaoMateriais({ commit }, resource) {
    return new Promise((resolve, reject) => {
      resource().then((response) => {
        commit('alterarParametroExibicaoMateriais', response.data);
        resolve();
      }).catch(reject);
    });
  },
};

const getters = {
  parametros(state) {
    return state.parametros;
  },
};

const state = {
  parametros: {
    exibirMateriais: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
