import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('api', 'ano-fiscal');

const anoFiscalActions = {
  pesquisarAno: { method: 'GET', url: `${basePath}` },
  pesquisar: { method: 'GET', url: `${basePath}/relatorio` },
  pesquisarTrimestres: { method: 'GET', url: `${basePath}/trimestre` },
  pesquisarPeriodo: { method: 'GET', url: `${basePath}/periodo` },
};

export default (resource) => resource(`${basePath}`, {}, anoFiscalActions);
