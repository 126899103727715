<template>
  <filtro-rapido v-model="abrirFiltro"
    ref="filtrosRapidos"
    :campos-formulario="camposFormulario"
    :metadados="getAcaoMetadado"
    :filtro-padrao="filtroPadrao"
    :ordenacao-campos="ordenacaoCampos"
    :temporary="true"
    :hide-overlay="true"
    :ignora-inativos="false"
    @FiltroRapido__AplicaFiltros="(p) => $emit('DashboardInicialFiltro__AplicaFiltros', p)"
    @FiltroRapido__HabilitaIconBadge="(value) => $emit('FiltroRapido__HabilitaIconBadge', value)">
  </filtro-rapido>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '../../../shared-components/filtro-rapido/FiltroRapido';

export default {
  name: 'DashboardInicialFiltro',
  props: {
    value: false,
  },
  components: {
    FiltroRapido,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      configuracaoResource: this.$api.dashboardInicialConfiguracao(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      magaluRelatoriosResource: this.$api.magaluRelatorios(this.$resource),

      abrirFiltro: false,
      ordenacaoCampos: null,
      filtroPadrao: {},

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarTodosTiposAcao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'id_campanha',
            nomCampoId: 'id_campanha',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'visao',
            tipoCampo: 'LISTA_CHECKBOX',
            desAtributos: {
              lista: [
                { texto: `${this.$tc('label.contrato', 1)}`, valor: 'visao_contrato' },
                { texto: `${this.$tc('label.acao_tatica', 1)}`, valor: 'visao_acao' },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'LISTA_CHECKBOX',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioEntidadesControlador({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'nota_debito',
            nomCampoId: 'num_nota_debito',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'categoria',
            nomCampoId: 'id_categoria',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: this.$refs.container ? this.$refs.container.getValoresCamposPadrao().length === 0 : true,
            async: {
              fetchFunction: (autocomplete) => this.magaluRelatoriosResource.buscarCategoria({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro()
        .then((res) => {
          this.ordenacaoCampos = res.data;
          this.$emit('DashboardInicialFiltro__FiltrosCarregados');
        }).catch((err) => {
          this.$toast(err);
        });
    },
    preencherFiltroPadrao() {
      this.filtroPadrao.data_inicio = this.moment().startOf('year').format('YYYY-MM-DD');
      this.filtroPadrao.data_fim = this.moment().endOf('year').format('YYYY-MM-DD');
    },

  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.carregaCamposFiltros();
  },
  beforeMount() {
    this.preencherFiltroPadrao();
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
};
</script>
