import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'filial');

const filialActions = {
  ...genericActions(basePath),
  ativarMassa: { method: 'PUT', url: `${basePath}/ativacao-massa` },
};

export default (resource) => resource(`${basePath}`, {}, filialActions);
