<template>
  <div>
    <v-row justify="space-between" class="MetadadosContainerFiltroLayout__group__header" @click="exibirGrupo(grupo)">
      <div class="subheading MetadadosContainerFiltroLayout__group__header__text">
        {{ $tc(`label.${nomeCampo}`, 2) }}
      </div>
    </v-row>
    <v-radio-group :mandatory="true" @change="emiteValorAlterado">
      <template v-for="item in itens">
        <v-radio :key="item.texto"
          :label="item.texto"
          :value="item.valor">
        </v-radio>
      </template>
    </v-radio-group>
  </div>
</template>
<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoRadio',
  data() {
    return {
      itens: [],
      objetoInterno: null,
      textoItem: null,
      valorItem: null,
    };
  },
  props: {
    metadados: {
      type: Object,
    },
    nomeCampo: {
      type: String,
      required: true,
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.objetoInterno = alterado;
      },
    },
  },
  methods: {
    carregarItens() {
      const { desAtributos } = this.metadados;
      if (!desAtributos) {
        throw new Error('DesAtributos não pode ser null!');
      }

      const { textoItem, valorItem, lista } = desAtributos;
      if (textoItem) {
        this.textoItem = textoItem;
        this.valorItem = valorItem;
        this.itens = lista;
      } else {
        this.textoItem = 'texto';
        this.valorItem = 'valor';

        this.itens = lista.map((item) => {
          const formatado = {};
          formatado[this.textoItem] = item;
          formatado[this.valorItem] = item;
          return formatado;
        });
      }
    },
  },
  mounted() {
    this.carregarItens();
  },
};
</script>
<style>
  .MetadadosContainerFiltroLayout__group__header__text {
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 1rem;
  }
  .MetadadosContainerFiltroLayout__group__header {
    align-items: center;
    cursor: pointer;
  }
</style>
