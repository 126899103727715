import getBasePath from './api-resource';

let dicionario = {};
const basePath = getBasePath('api', 'dicionario');

const setDicionario = (el) => {
  dicionario[el.label] = el.depara;
};

export default {
  mapa() {
    return localStorage.dicionario ? JSON.parse(localStorage.dicionario) : {};
  },
  carregar() {
    return new Promise((resolve, reject) => {
      const xhttp = new XMLHttpRequest();
      xhttp.open('GET', `${basePath}`, true);
      xhttp.setRequestHeader('Authorization', `Bearer ${sessionStorage.token}`);
      xhttp.setRequestHeader('x-tenant-id', window.localStorage.getItem('xTenantId'));
      xhttp.send();
      xhttp.onreadystatechange = function () { // eslint-disable-line
        if (this.readyState === 4) {
          if (this.status === 200) {
            const response = JSON.parse(xhttp.response);
            resolve(response);
          }
        } else if (this.status && this.status === 403) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ status: this.status, message: 'Usuário sem permissão para acessar o sistema!' });
        }
      };
    });
  },
  carrega($http) {
    const ultimaData = localStorage.dicionarioData
      ? new Date(localStorage.dicionarioData)
      : new Date();

    return new Promise((resolve, reject) => {
      if (!localStorage.dicionarioData || Math.abs(ultimaData - new Date()) > 60000) {
        $http.get(basePath)
          .then((resp) => {
            const response = JSON.parse(resp.bodyText);
            response.forEach(setDicionario);
            localStorage.setItem('dicionario', JSON.stringify(dicionario));
            localStorage.setItem('dicionarioData', new Date());
            resolve();
          })
          .catch(reject);
      } else {
        dicionario = localStorage.dicionario ? JSON.parse(localStorage.dicionario) : {};
        resolve();
      }
    });
  },
};
