<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="40%">
    <v-card>
      <v-card-title class="headline">{{ $t('title.altere_descricao_nd') }}</v-card-title>
      <v-card-text class="justify">
        <v-form
          ref="form"
          lazy-validation>
          <v-text-field
              id="descricao_nd"
              :rules="[rules.required]"
              :label="`${$tc('label.descricao_nd', 1)} *`"
              :disabled="somenteLeitura"
              v-model="descricao"
              required>
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="cancel" color="primary darken-1" text @click.native="close">
          {{$t('label.cancelar') }}
        </v-btn>
        <v-btn v-if="!somenteLeitura" id="ok" color="primary darken-1" text @click.native="alterarDescricao">
          {{ $t('label.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalDescricaoNd',
  props: {
    notaDebito: {},
    somenteLeitura: false,
  },
  data() {
    return {
      dialog: false,
      descricao: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    open(notaDebito) {
      this.descricao = notaDebito.descricao;
      this.dialog = true;
    },
    alterarDescricao() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.close();
      this.$emit('alterarDescricao', this.descricao);
      if (this.callback) {
        this.callback(true);
      }
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
