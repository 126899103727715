export default {
  methods: {
    getValoresAutocomplete() {
      const padrao = this.getValoresCamposPadrao();
      const valoresPadrao = Object.keys(padrao)
        .filter((key) => key.startsWith('id'))
        .map((key) => {
          const valor = {};
          valor[key] = padrao[key];
          return valor;
        })
        .reduce((obj1, obj2) => ({ ...obj1, ...obj2 }), {});

      const valores = {
        ...this.getValoresDependencias(),
        ...valoresPadrao,
      };

      return valores;
    },
    getDependenciasRecursivas(dependente) {
      const listaDependencias = [];
      const metadados = this.metadadosEntidade,
        dependencias = Object.values(metadados.mapaEntidades);
      let referencia = dependente;

      while (referencia !== null) {
        const dependencia = dependencias
          .filter((dependenciaRec) => { // eslint-disable-line no-loop-func
            const { label } = dependenciaRec;
            return referencia === label;
          })[0];

        if (dependencia) {
          listaDependencias.push(dependencia);
          const [ref] = dependencia.dependencias;
          referencia = ref;
        } else {
          referencia = null;
        }
      }

      return listaDependencias;
    },
    getValoresDependenciasRecursivas(dependente) {
      if (!dependente) return [];

      const listaDependencias = this.getDependenciasRecursivas(dependente),
        valores = [],
        { objeto } = this;

      listaDependencias
        .filter((entidade) => {
          const { label } = entidade;
          return objeto[label] && Object.keys(objeto[label]).length !== 0;
        })
        .forEach((entidade) => {
          const { label, idNivelExtensao, indiceRecursao } = entidade;
          const addVal = (v) => {
            const { id } = v;
            valores.push({ id, idNivelExtensao, indiceRecursao });
          };

          const val = objeto[label];
          if (Array.isArray(val)) {
            val.forEach((v) => addVal(v));
          } else {
            addVal(val);
          }
        });

      if (valores.length === 0) {
        return [];
      }

      let menorIndiceRecursao = Number.MAX_SAFE_INTEGER;
      valores.forEach((valor) => {
        if (valor.indiceRecursao < menorIndiceRecursao) {
          menorIndiceRecursao = valor.indiceRecursao;
        }
      });
      return valores.filter((v) => v.indiceRecursao === menorIndiceRecursao);
    },
    getQuantidadeFiltrosSelecionados() {
      return this.ordenacaoCampos
        .map((campo) => {
          const objeto = this.objeto[campo] || this.objeto[`id_${campo}`];
          if (!objeto) {
            return 0;
          }
          if (Array.isArray(objeto)) {
            return objeto.length;
          }
          return 1;
        })
        .reduce((a, c) => a + c, 0);
    },
    /**
     * Retorna os nomes das dependencias da entidade
     * Ex:
     *  entidade -> PRODUTO
     *  dependencias -> ['marca', 'unidadenegocio', 'familia']
     */
    getNomesDependencias() {
      return this.dependenciasDiretas().map((dependencia) => dependencia.label);
    },
    /**
     * Retorna os valores das dependencidas entidade ou de todas
     *
     * Ex:
     *  entidade -> PRODUTO
     *  valores -> { id_marca: 1010, id_unidadenegocio: [5,10], id_familia: 2910 }
     */
    getValoresDependencias() {
      const valores = {},
        { objeto } = this;

      this.dependenciasDiretas()
        .filter((dependencia) => {
          const { label } = dependencia,
            valor = objeto[label];
          return valor != null && Object.keys(valor).length !== 0;
        })
        .filter((entidade) => {
          const { label } = entidade;
          return this.ordenacaoCampos.length === 0
            || this.ordenacaoCampos.indexOf(label) >= 0;
        })
        .forEach((dependencia) => {
          const { campo, label, manyToMany } = dependencia;
          valores[campo] = !manyToMany
            ? objeto[label].id
            : Object.values(objeto[label]).map((v) => v.id);
        });
      return valores;
    },
    /**
     * Retorna um objeto com os valores dos campos dinamicos
     */
    getValoresCamposDinamicos() {
      const valores = {};
      this.metadadosCamposDinamicos
        .filter((campoDinamico) => {
          const { labelCampo } = campoDinamico;
          return this.objeto[labelCampo] != null;
        })
        .forEach((campoDinamico) => {
          const { labelCampo } = campoDinamico,
            valor = this.objeto[labelCampo];
          valores[labelCampo] = valor;
        });
      return valores;
    },
    /**
     * Retorna um objeto com os valores padroes de um formulario.
     * Ou seja, nem extensao nem campo dinamico
     */
    getValoresCamposPadrao() {
      const valores = {};
      const campos = this.camposFormulario.padrao;

      campos
        .filter((campo) => campo.async != null)
        .filter((campo) => {
          const { labelCampo } = campo;
          return this.objeto[labelCampo] != null;
        })
        .forEach((campo) => {
          const { itemValue } = campo.async;
          const { nomCampoId, labelCampo } = campo;
          const objSelecao = this.objeto[labelCampo];

          if (campo.tipoCampo === 'MULTIPLA_ESCOLHA') {
            valores[nomCampoId] = objSelecao
              .map((item) => item[itemValue]);
          } else if (campo.tipoCampo === 'LISTA_CHECKBOX') {
            valores[nomCampoId] = objSelecao;
          } else {
            valores[nomCampoId] = objSelecao[itemValue];
          }
        });

      campos
        .filter((campo) => !campo.async)
        .filter((campo) => {
          const { labelCampo } = campo;
          return this.objeto[labelCampo] != null;
        })
        .forEach((campo) => {
          const { labelCampo } = campo;
          valores[labelCampo] = this.objeto[labelCampo];
        }, this);

      return valores;
    },
  },
};
