function formatarEndereco(strings, logradouro, numero, complemento, bairro, cidade, estado, cep) {
  let endereco = logradouro;

  if (numero) {
    endereco += strings[1] + numero;
  }

  if (complemento) {
    endereco += strings[2] + complemento;
  }

  if (bairro) {
    endereco += strings[3] + bairro;
  }

  if (cidade) {
    endereco += strings[4] + cidade;
  }

  if (estado) {
    endereco += strings[5] + estado;
  }

  if (cep) {
    endereco += strings[6] + cep;
  }

  return endereco;
}

const mutations = {
  alterarInformacoesDoUsuario(state, dados) {
    state.usuario.id = dados.id;
    state.usuario.ehCliente = dados.usuarioCliente;
    state.usuario.email = dados.email;
    state.usuario.estruturaUsuario = dados.estruturaUsuario;
    state.usuario.idEstruturaUsuario = dados.idEstruturaUsuario;
    state.usuario.nomUsuario = dados.nomUsuario;
    state.usuario.perfil = dados.perfil;
    state.usuario.idPerfil = dados.idPerfil;
    state.usuario.aprovadorAcordo = dados.aprovadorAcordo;
    state.usuario.tipo = dados.tipo;
    state.usuario.indUsuarioExternoMaster = dados.indUsuarioExternoMaster;
    state.usuario.idGrupoFornecedor = dados.idGrupoFornecedor;
    state.usuario.endereco = formatarEndereco`${dados.logradouro}, ${dados.numero} - ${dados.complemento} - ${dados.bairro}, ${dados.cidade} - ${dados.estado}, ${dados.cep}`;
  },
};

const actions = {
  carregarInformacoesUsuario({ commit }, resource) {
    return new Promise((resolve, reject) => {
      resource().then((response) => {
        commit('alterarInformacoesDoUsuario', response.data);
        resolve();
      }).catch(reject);
    });
  },
};

const getters = {
  usuarioLogado(state) {
    return state.usuario;
  },
};

const state = {
  usuario: {
    ehCliente: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
