<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="40%">
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('label.numero_notas_debito') }}</span>
        </v-card-title>
        <v-card-text v-if="valor.num_nota_debito">
          {{ valor.num_nota_debito }}
        </v-card-text>
        <v-card-text v-if="valor.numNotaDebito">
          {{ valor.numNotaDebito }}
        </v-card-text>
        <v-card-text v-if="valor.numNotasDebito">
          {{ valor.numNotasDebito }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text
                 @click="close()">{{$t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalNotaDebito',
  props: {
    notaDebito: {
      type: Object,
      default: () => ({}),
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      valor: '',
    };
  },
  methods: {
    open(valor) {
      this.valor = valor;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
