<template lang="html">
  <v-tabs
    v-model="tabSelecionada"
    class="relatorio-list__tabs"
    grow
    color="accent"
    background-color="rgba(0,0,0,0)">

    <v-tab class="white-text" href="#tab-relatorio-exportacoes">
      {{ $t('label.exportacoes') }}
    </v-tab>

      <v-tab-item id="tab-relatorio-exportacoes">
        <relatorio-exportacoes ref="tab-relatorio-exportacoes"/>
      </v-tab-item>

    </v-tabs>
  </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import RelatorioExportacoes from './exportacoes/RelatoriosExportacoes';

export default {
  data() {
    return {
      tabSelecionada: null,
    };
  },
  components: {
    RelatorioExportacoes,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
  },
  methods: {
    verificaAcesso() {
      const ok = this.getAllRoles.filter((rel) => rel.startsWith('REL_').length > 0);
      if (!ok) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
  },
  mounted() {
    this.verificaAcesso();
  },
};
</script>

<style scoped>
@import '../../assets/vendors/amcharts/plugins/export/export.css';
</style>
<style lang="scss">
.relatorio-list__tabs a.v-tab {
  color: var(--v-accent) !important;
}
.relatorio-list__tabs .v-tabs-items {
  background: none;
}
.relatorio-list__tabs div.v-slide-group__wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

</style>
