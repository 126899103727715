<template>
    <div>
      <v-container fluid grid-list-md text-xs-center id="pendencias-totalizadores">
        <v-row align="center" justify="center" fill-height>
          <v-col cols="12" class="tamanho-cards" v-for="card in totalizadoresOrdenados" :key="card.titulo">
            <v-card :style="{'background-color': `${card.cor}`}">
              <v-card-title style="white-space: nowrap">
                <v-row class="headline white--text">
                  <v-col offset="1" cols="9" class="text-center pt-0">{{ $tc(card.titulo, 1)}}</v-col>
                  <v-col cols="2" class="text-right pt-0" v-if="card.status === 'PENDENCIAS_NDS' && possuiPermissaoApuracaoMassivaAcoesApuracoes">
                    <v-btn v-show="exibirBotaoDetalhesPendenciaNd" icon density="default" @click="abrirModalPendencias(card)">
                        <v-icon>launch</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="2" class="text-right pt-0" v-if="card.status === 'PENDENCIAS_CONTRATO' && possuiPermissaoApuracaoMassivaContratos">
                    <v-btn v-show="exibirBotaoDetalhesPendenciaContrato" icon density="default" @click="abrirModalPendencias(card)">
                        <v-icon>launch</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="2" class="text-right pt-0" v-if="card.status === 'PENDENCIAS_ANALISE' && possuiPermissaoPendenciasDeAnalise">
                    <v-btn v-show="exibirBotaoDetalhesPendenciaAnalise" icon density="default" @click="abrirModalPendencias(card)">
                        <v-icon>launch</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pt-6">
                <v-row class="headline white--text">
                  <v-col cols="12" class="text-center pa-0" v-if="card.status === 'PENDENCIAS_NDS'  && !loadingPendenciasAcoesEContratos">{{ quantidadeAcoesApuracoes }}</v-col>
                  <v-col cols="12" class="text-center pa-0" v-if="card.status === 'PENDENCIAS_CONTRATO'  && !loadingPendenciasAcoesEContratos">{{ quantidadeContratos }}</v-col>
                  <v-col cols="12" class="text-center pa-0" v-if="card.status === 'PENDENCIAS_ANALISE' && !loadingPendenciasAnalise">{{ quantidadeEmAnalise }}</v-col>
                  <v-col cols="12" class="text-center pt-0 pb-0 text-xs-center accent--text" v-if="card.status === 'PENDENCIAS_NDS' && loadingPendenciasAcoesEContratos"><v-progress-circular :indeterminate="true"></v-progress-circular></v-col>
                  <v-col cols="12" class="text-center pt-0 pb-0 text-xs-center accent--text" v-if="card.status === 'PENDENCIAS_CONTRATO' && loadingPendenciasAcoesEContratos"><v-progress-circular :indeterminate="true"></v-progress-circular></v-col>
                  <v-col cols="12" class="text-center pt-0 pb-0 text-xs-center accent--text" v-if="card.status === 'PENDENCIAS_ANALISE' && loadingPendenciasAnalise"><v-progress-circular :indeterminate="true"></v-progress-circular></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
<script>
import { mapGetters } from 'vuex';
import TooltipAjudaLista from '../../../shared-components/TooltipAjudaLista';
import { pendenciasFluxoAcaoEmAnalise } from '../../../common/resources/workflow-acao';

export default {
  name: 'DashboardCardsPendenciasTotalizadores',
  components: {
    TooltipAjudaLista,
    pendenciasFluxoAcaoEmAnalise,
  },
  props: {
    possuiPermissaoApuracaoMassivaAcoesApuracoes: Boolean,
    possuiPermissaoApuracaoMassivaContratos: Boolean,
    possuiPermissaoPendenciasDeAnalise: Boolean,
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),

      lista: [],
      statusPadroes: {
        RECEBIMENTO: ['status_entidade.aprovado'],
        PENDENTE_APURACAO: ['status_entidade.apuracao_previa', 'status_entidade.aguardando_apuracao',
          'status_entidade.aguardando_aprovacao', 'status_entidade.em_analise'],
        REJEITADA: ['status_entidade.cancelado', 'status_entidade.reprovado'],
      },
      quantidadeAcoesApuracoes: 0,
      quantidadeContratos: 0,
      quantidadeEmAnalise: 0,
      loadingPendenciasAnalise: false,
      loadingPendenciasAcoesEContratos: false,
      listaPendenciasEmAnalise: null,
      exibirBotaoDetalhesPendenciaNd: false,
      exibirBotaoDetalhesPendenciaContrato: false,
      exibirBotaoDetalhesPendenciaAnalise: false,
    };
  },
  mounted() {
    this.buscaTotalizadores();
    this.buscarQuantidadePendenciasEmAnalise();
  },
  computed: {
    totalizadoresOrdenados() {
      function compare(a, b) {
        if (a.ordenacao < b.ordenacao) {
          return -1;
        }
        if (a.ordenacao > b.ordenacao) {
          return 1;
        }
        return 0;
      }
      return this.lista.sort(compare);
    },
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
  },
  methods: {
    abrirModalPendencias(card) {
      if (card.status === 'PENDENCIAS_ANALISE') {
        this.$emit('abrirModalPendencias', card, this.listaPendenciasEmAnalise);
      } else {
        this.$emit('abrirModalPendencias', card);
      }
    },
    buscaTotalizadores() {
      this.loadingPendenciasAcoesEContratos = true;
      this.workflowAcaoResource.buscarQuantidadePendenciasTotalizadores()
        .then((response) => {
          const dadosRetornados = response.data[0];
          this.quantidadeAcoesApuracoes = dadosRetornados.quantidadeacoesapuracoes;
          this.quantidadeContratos = dadosRetornados.quantidadecontratos;
          this.loadingPendenciasAcoesEContratos = false;
          this.exibirBotaoDetalhesPendenciaNd = true;
          this.exibirBotaoDetalhesPendenciaContrato = true;
          this.tratarCards();
          this.tratarCores();
        })
        .catch((err) => {
          if (err.data) {
            this.$toast(err.data.error);
          }
        });
    },
    buscarQuantidadePendenciasEmAnalise() {
      const params = {
        isCount: true,
      };
      this.loadingPendenciasAnalise = true;
      pendenciasFluxoAcaoEmAnalise(params, this.$resource)
        .then((res) => {
          const { data } = res;
          this.listaPendenciasEmAnalise = data;
          this.quantidadeEmAnalise = data.length;
          this.exibirBotaoDetalhesPendenciaAnalise = true;
          this.loadingPendenciasAnalise = false;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    tratarCores() {
      this.lista.forEach((item) => {
        if (item.status === 'PENDENCIAS_CONTRATO') {
          item.cor = 'rgb(26, 188, 156)';
          item.ordenacao = 2;
        } else if (item.status === 'PENDENCIAS_ANALISE') {
          item.cor = 'rgb(230, 126, 34)';
          item.ordenacao = 3;
        } else if (item.status === 'PENDENCIAS_NDS') {
          item.cor = 'rgb(231, 76, 60)';
          item.ordenacao = 1;
        }
      });
    },

    tratarCards() {
      const cards = [];
      if (this.possuiPermissaoApuracaoMassivaContratos) {
        cards.push({
          status: 'PENDENCIAS_CONTRATO',
          titulo: this.$tc('label.pendencia_contrato', 1),
        });
      }
      if (this.possuiPermissaoPendenciasDeAnalise) {
        cards.push({
          status: 'PENDENCIAS_ANALISE',
          titulo: this.$tc('label.pendencia_analise', 1),
        });
      }
      if (this.possuiPermissaoApuracaoMassivaAcoesApuracoes) {
        cards.push({
          status: 'PENDENCIAS_NDS',
          titulo: this.$tc('label.pendencia_nds', 1),
        });
      }

      this.lista = cards;
    },
  },
};
</script>

<style>
  #pendencias-totalizadores .tamanho-cards {
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
    padding: 12px;
  }

  @media screen and (max-width: 1420px) {
    #pendencias-totalizadores .tamanho-cards {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      width: 100%;
      padding: 12px;
    }
  }

  @media screen and (max-width: 1060px) {
    #pendencias-totalizadores .tamanho-cards {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      padding: 12px;
    }
  }
</style>
