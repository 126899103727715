import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('usuario', 'transferencia-definitiva');

const transferenciaPosseActions = {
  listarFuncionarios: { method: 'GET', url: `${basePath}/funcionario` },
  listarCarteiras: { method: 'GET', url: `${basePath}/carteira-cliente?{&id_regiao*}{&id_cliente*}{&id_holding*}` },
  listarResponsaveis: { method: 'GET', url: `${basePath}/responsavel-faturamento?{&id_regiao*}{&id_cliente*}{&id_holding*}` },
  orcamento: { method: 'GET', url: `${basePath}/orcamentos?{&marcas*}` },
  transferir: { method: 'POST', url: `${basePath}` },
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
};

export default (resource) => resource(`${basePath}`, {}, transferenciaPosseActions);
