<template>
  <v-card style="margin-bottom:1em">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.notas_sellin', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :explode-hierarquia="true"
          :formulario-filtros="true"
          :ignora-inativos="false"
          :objeto="filtrosNotasSellin"
          ref="container">
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-menu
              ref="data_fim"
              :close-on-content-click="false"
              v-model="menuDataConferencia"
              id="data_fim"
              name="data_fim"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="dataConferenciaFormatada"
                  :label="`${$t('label.data_conferencia')} *`"
                  readonly
                  clearable
                  prepend-inner-icon="event"
                >
                </v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                color="primary"
                type="month"
                v-model="dataConferencia"
                @input="inputDataConferencia()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-menu
              ref="data_fim"
              :close-on-content-click="false"
              v-model="menuDataEmissao"
              id="data_fim"
              name="data_fim"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="dataEmissaoFormatada"
                  :label="`${$t('label.data_emissao')} *`"
                  readonly
                  clearable
                  prepend-inner-icon="event"
                >
                </v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                color="primary"
                type="month"
                v-model="dataEmissao"
                @input="inputDataEmissao()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-menu
              ref="data_fim"
              :close-on-content-click="false"
              v-model="menuDataIntegracao"
              id="data_fim"
              name="data_fim"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="dataIntegracaoFormatada"
                  :label="$t('label.data_integracao')"
                  readonly
                  clearable
                  prepend-inner-icon="event"
                >
                </v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                color="primary"
                type="month"
                v-model="dataIntegracao"
                @input="inputDataIntegracao()"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '@/produto/common/functions/loading';
import { countExportacaoNotasSellin } from '@/common/resources/magalu-relatorios'; // eslint-disable-line
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoNotasSellinFiltrosCampos from './RelatorioExportacaoNotasSellinFiltrosCampos';
import { formatDateMonthYYYY } from '../../../../produto/common/functions/date-utils';

export default {
  name: 'RelatorioExportacaoNotasSellin',
  mixins: [
    RelatorioExportacaoNotasSellinFiltrosCampos,
  ],
  watch: {
    filtrosNotasSellin: {
      handler() {
        this.$refs.form.resetValidation();
      },
      deep: true,
    },
  },
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      ordenacaoCampos: [
        'data_conferencia',
        'data_emissao',
        'data_integracao',
        'grupo_fornecedor',
        'ind_devolucao_retroativa',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosNotasSellin: {},
      limiteRegistrosExportacao: 50000,
      dataConferenciaFormatada: null,
      dataConferencia: null,
      menuDataConferencia: false,
      dataEmissaoFormatada: null,
      dataEmissao: null,
      menuDataEmissao: false,
      dataIntegracaoFormatada: null,
      dataIntegracao: null,
      menuDataIntegracao: false,
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroValido() {
      // valida se algum filtro foi selecionado
      return (this.dataConferencia && this.dataConferenciaFormatada) || (this.dataEmissao && this.dataEmissaoFormatada);
    },
    resetaFiltros() {
      this.filtrosNotasSellin = {};
    },
    aplicarFiltros() {
      const filtrosAplicados = this.$refs.container.getValoresCamposPadrao();

      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroValido()) {
        this.$toast(this.$t('message.selecione_um_filtro_obrigatorio'));
        return;
      }
      const params = {
        ...filtrosAplicados,
      };
      if (!params.ultima_alteracao) {
        delete params.ultima_alteracao;
      }
      if (this.dataConferencia && this.dataConferenciaFormatada) {
        params.data_conferencia = this.dataConferencia;
      }
      if (this.dataEmissao && this.dataEmissaoFormatada) {
        params.data_emissao = this.dataEmissao;
      }
      if (this.dataIntegracao && this.dataIntegracaoFormatada) {
        params.data_integracao = this.dataIntegracao;
      }
      if (!params.ind_devolucao_retroativa) {
        delete params.ind_devolucao_retroativa;
      }
      let quantidadeRegistros = 0;

      countExportacaoNotasSellin(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > this.limiteRegistrosExportacao) {
            this.$toast(this.$t('errors.registros.excede_limite_dados', { limite: this.limiteRegistrosExportacao }));
          } else if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoNotasSellin__AplicaFiltros',
              params,
              'relatorio_notas_sellin',
              'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-notas-sellin';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-notas-sellin';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosNotasSellin;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inputDataConferencia() {
      this.dataConferenciaFormatada = formatDateMonthYYYY(this.dataConferencia);
      this.menuDataConferencia = false;
    },
    inputDataEmissao() {
      this.dataEmissaoFormatada = formatDateMonthYYYY(this.dataEmissao);
      this.menuDataEmissao = false;
    },
    inputDataIntegracao() {
      this.dataIntegracaoFormatada = formatDateMonthYYYY(this.dataIntegracao);
      this.menuDataIntegracao = false;
    },
  },
};
</script>
