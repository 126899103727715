import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'centro-custo');

const centroCustoActions = {
  ...genericActions(basePath),
  listarAtivos: { method: 'GET', url: `${basePath}?indAtivo=true` },
  ativarMassa: { method: 'PUT', url: `${basePath}/ativacao-massa` },
  selecaoPlanilha: { method: 'GET', url: `${basePath}/selecao/planilha?{&idsPeriodosOrcamentarios*}` },
};

export default (resource) => resource(`${basePath}`, {}, centroCustoActions);
