<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
    transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="headline">{{ titulo }}</v-card-title>
      <v-card-text>
        <v-form ref="formCancelar" lazy-validation v-if="!isAguardandoAprovacao">
          <v-row>
            <v-col cols="12">
              <v-textarea
                id="ApuracaoAcaoCancelar_justificativa"
                index="1"
                ref="textareaJustificativa"
                name="ApuracaoAcaoCancelar_justificativa"
                v-model="justificativa"
                auto-grow
                autofocus
                rows="1"
                :rules="[rules.required]"
                :label="`${$t('label.justificativa')} *`"
                :counter="250"
                maxlength="250">
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="close">{{ $t('label.nao') }}</v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">{{ $t('label.sim') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cancelarApuracao } from '../../common/resources/apuracao/apuracao-acao-edicao';

export default {
  name: 'ApuracaoCancelar',
  props: {
    apuracao: Object,
    abrirModal: false,
    titulo: String,
    mensagemSucesso: String,
  },
  data() {
    return {
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      dialog: false,
      dtaInicio: '',
      justificativa: '',
      idApuracao: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    isAguardandoAprovacao() {
      return this.apuracao.status === 'AGUARDANDO_APROVACAO';
    },
  },
  watch: {
    dialog(val) {
      if (val && !this.isAguardandoAprovacao) {
        this.preencherVariaveis();
        setTimeout(() => this.$refs.textareaJustificativa.focus(), 5E2);
      }
    },
    abrirModal() {
      this.dialog = this.abrirModal;
    },
  },
  methods: {
    close() {
      this.$emit('ApuracaoCancelar__fechar');
    },
    agree() {
      const { idApuracao } = this;
      if (this.isAguardandoAprovacao) {
        const observacao = this.$t('message.cancelar_apuracao_aguardando_aprovacao');
        this.workflowApuracaoAcaoResource.cancelar({ idApuracao }, { observacao })
          .then(() => {
            this.cancelamentoFinalizado();
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      } else if (this.$refs.formCancelar.validate()) {
        const param = {
          idApuracao,
          justificativa: this.justificativa,
        };
        cancelarApuracao(param, this.$resource)
          .then(() => {
            this.cancelamentoFinalizado();
          })
          .catch((err) => {
            this.$toast(err, 'snack', 3E3);
          });
      }
    },
    preencherVariaveis() {
      this.dtaInicio = this.apuracao.dtInicioVigencia;
      this.idApuracao = this.apuracao.id;
      this.justificativa = '';
    },
    cancelamentoFinalizado() {
      if (this.isAguardandoAprovacao) {
        this.$emit('ApuracaoAcaoCancelar__recarregar', 'AGUARDANDO_APURACAO', this.idApuracao, this.justificativa);
      } else {
        this.$emit('ApuracaoAcaoCancelar__recarregar', 'CANCELADO', this.idApuracao, this.justificativa);
      }
      this.close();
      this.$toast(this.mensagemSucesso);
    },
  },
  mounted() {
    this.dialog = this.abrirModal;
    this.preencherVariaveis();
  },
};
</script>
