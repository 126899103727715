const NotasDebitoConsulta = () => import('../../spa/notas-debito/consulta/NotasDebitoConsulta');
const NotasDebitoConsultaLista = () => import('../../spa/notas-debito/consulta/NotasDebitoConsultaLista');

export default {
  path: 'notas-debito-consulta',
  component: NotasDebitoConsulta,
  children: [
    {
      path: '',
      name: 'notas-debito-consulta',
      component: NotasDebitoConsultaLista,
    },
    {
      path: ':idAcao',
      name: 'ver-notas-debito-consulta',
      component: NotasDebitoConsultaLista,
    },
  ],
};
