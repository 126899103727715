const mutations = {
  setMetadado(state, { owner, metadado }) {
    if (state.hasOwnProperty(owner)) {
      state[owner] = metadado;
    }
  },
};

const actions = {
  setMetadado({ commit }, {
    owner,
    resource,
    params = null,
    callback,
  }) {
    if (owner && resource) {
      resource(params)
        .then((res) => {
          const metadado = res.body;
          commit('setMetadado', { owner, metadado });
          if (callback) {
            callback();
          }
        });
    }
  },
  setClienteMetadado({ commit }, { resource, params, callback }) {
    actions.setMetadado({ commit }, {
      owner: 'cliente',
      resource,
      params,
      callback,
    });
  },
  setUsuarioMetadado({ commit }, { resource, params }) {
    actions.setMetadado({ commit }, {
      owner: 'usuario',
      resource,
      params,
    });
  },
  setProdutoMetadado({ commit }, { resource, params }) {
    actions.setMetadado({ commit }, {
      owner: 'produto',
      resource,
      params,
    });
  },
  setAcaoTaticaMetadado({ commit }, { resource, params }) {
    actions.setMetadado({ commit }, {
      owner: 'acaoTatica',
      resource,
      params,
    });
  },
  setRelatorioMetadado({ commit }, { resource, params }) {
    actions.setMetadado({ commit }, {
      owner: 'relatorio',
      resource,
      params,
    });
  },
  setCarteiraClienteMetadado({ commit }, { resource, params }) {
    actions.setMetadado({ commit }, {
      owner: 'carteiraCliente',
      resource,
      params,
    });
  },
  setDivisaoMetadado({ commit }, { resource, params }) {
    actions.setMetadado({ commit }, {
      owner: 'divisao',
      resource,
      params,
    });
  },
  setCentroCustoMetadado({ commit }, { resource, params }) {
    actions.setMetadado({ commit }, {
      owner: 'ceco',
      resource,
      params,
    });
  },
  setOrcamentoMetadado({ commit }, { resource, params, callback }) {
    actions.setMetadado({ commit }, {
      owner: 'orcamento',
      resource,
      params,
      callback,
    });
  },
  setLinhaInvestimentoMetadado({ commit }, { resource, params, callback }) {
    actions.setMetadado({ commit }, {
      owner: 'linhaInvestimento',
      resource,
      params,
      callback,
    });
  },
  setAcaoMetadado({ commit }, { resource, params, callback }) {
    actions.setMetadado({ commit }, {
      owner: 'acao',
      resource,
      params,
      callback,
    });
  },
  setApuracaoMetadado({ commit }, { resource, params, callback }) {
    actions.setMetadado({ commit }, {
      owner: 'apuracao',
      resource,
      params,
      callback,
    });
  },
  setContratoMetadado({ commit }, { resource, params, callback }) {
    actions.setMetadado({ commit }, {
      owner: 'contrato',
      resource,
      params,
      callback,
    });
  },
  setFornecedorMetadado({ commit }, { resource, params, callback }) {
    actions.setMetadado({ commit }, {
      owner: 'fornecedor',
      resource,
      params,
      callback,
    });
  },
  setFilialMetadado({ commit }, { resource, params, callback }) {
    actions.setMetadado({ commit }, {
      owner: 'filial',
      resource,
      params,
      callback,
    });
  },
  setPagamentoMetadado({ commit }, { resource, params, callback }) {
    actions.setMetadado({ commit }, {
      owner: 'pagamentoManual',
      resource,
      params,
      callback,
    });
  },
};

const getters = {
  getClienteMetadado(state) {
    return state.cliente;
  },
  getProdutoMetadado(state) {
    return state.produto;
  },
  getUsuarioMetadado(state) {
    return state.usuario;
  },
  getAcaoTaticaMetadado(state) {
    return state.acaoTatica;
  },
  getRelatorioMetadado(state) {
    return state.relatorio;
  },
  getCarteiraClienteMetadado(state) {
    return state.carteiraCliente;
  },
  getDivisaoMetadado(state) {
    return state.divisao;
  },
  getCentroCustoMetadado(state) {
    return state.ceco;
  },
  getOrcamentoMetadado(state) {
    return state.orcamento;
  },
  getLinhaInvestimentoMetadado(state) {
    return state.linhaInvestimento;
  },
  getAcaoMetadado(state) {
    return state.acao;
  },
  getApuracaoMetadado(state) {
    return state.apuracao;
  },
  getContratoMetadado(state) {
    return state.contrato;
  },
  getFornecedorMetadado(state) {
    return state.fornecedor;
  },
  getFilialMetadado(state) {
    return state.filial;
  },
  getPagamentoMetadado(state) {
    return state.pagamentoManual;
  },
};

const state = {
  cliente: null,
  produto: null,
  usuario: null,
  acaoTatica: null,
  relatorio: null,
  carteiraCliente: null,
  divisao: null,
  ceco: null,
  orcamento: null,
  linhaInvestimento: null,
  acao: null,
  apuracao: null,
  contrato: null,
  fornecedor: null,
  filial: null,
  pagamentoManual: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
