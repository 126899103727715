<template lang="html">
  <v-dialog v-model="dialog" :persistent="!mdClickOutsideToClose" scrollable>
    <v-card>
      <slot name="header">
        <v-card-title v-if="title">
          <span class="headline" v-text="title"></span>
        </v-card-title>
      </slot>
      <v-card-text>
        <slot name="body"></slot>
      </v-card-text>
      <slot name="footer">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text v-if="close" @click.native="defaultClose">{{ $t('label.cancelar') }}</v-btn>
          <v-btn color="primary" text v-if="open" @click.native="defaultSave">{{ $t(`label.${saveLabel}`) }}</v-btn>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    save: Function,
    saveLabel: {
      type: String,
      default: 'salvar',
    },
    mdOpenFrom: String,
    mdCloseTo: String,
    mdEscToClose: {
      type: Boolean,
      default: true,
    },
    mdClickOutsideToClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.onClose();
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.onOpen();
    },
    close() {
      this.dialog = false;
    },
    defaultClose() {
      this.$emit('GENERICMODAL_DEFAULT_CLOSE');
      this.close();
    },
    defaultSave() {
      this.$emit('GENERICMODAL_DEFAULT_SAVE');
      if (!this.save) {
        this.close();
      } else {
        this.save();
      }
    },
    onOpen() {
      this.$emit('GENERICMODAL_OPEN');
    },
    onClose() {
      this.$emit('GENERICMODAL_CLOSE');
    },
  },
};
</script>

<style src="./GenericModal.css" lang="css" scoped></style>
