import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/contrato');

const workflowContratoActions = {
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idContrato}` },
  buscaPassosClientes: { method: 'GET', url: `${basePath}/{idContrato}/passos/cliente` },
  buscaPassosFornecedor: { method: 'GET', url: `${basePath}/{idContrato}/passos/fornecedor` },
  buscaPassosGerente: { method: 'GET', url: `${basePath}/{idContrato}/passos/gerente` },
  associaUsuarioClienteAprovador: { method: 'POST', url: `${basePath}/{idContrato}/passos/{idPasso}/cliente/usuario/{idUsuario}` },
  associaUsuarioFornecedorAprovador: { method: 'POST', url: `${basePath}/{idContrato}/passos/{idPasso}/fornecedor/usuario/{idUsuario}` },
  associaUsuariosCondicionalAprovador: { method: 'POST', url: `${basePath}/passos/associar-usuarios-condicional` },
  associaUsuarioFornecedorAprovadorRepresentacaoConjunto: { method: 'POST', url: `${basePath}/passos/associar-usuarios-representacao-conjunto` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idContrato}` },
  aprovador: { method: 'GET', url: `${basePath}/{idContrato}/passos/aprovador` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idContrato}/passos/aprovar` },
  reprovar: { method: 'PUT', url: `${basePath}/{idContrato}/passos/reprovar` },
  cancelar: { method: 'PUT', url: `${basePath}/{idContrato}/cancelar` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idContrato}/passos/analise` },
  enviarAnaliseContratoPorInativacaoFornecedor: { method: 'PUT', url: `${basePath}/{idContrato}/passos/{observacao}/analise-contrato-por-inativacao-fornecedor` },
  status: { method: 'GET', url: `${basePath}/{idContrato}/status` },
  buscaFlagSubstituicaoContrato: { method: 'GET', url: `${basePath}/{idPassoAtual}/buscaFlagSubstituicaoContrato` },
  statusPasso: { method: 'GET', url: `${basePath}/{idContrato}/passos/status` },
  existeFluxoCadastrado: { method: 'GET', url: `${basePath}/{idContrato}/fluxo-cadastrado` },
  buscarFluxoTrade: { method: 'GET', url: `${basePath}/fluxo-trade` },
  nomeFornecedorAprovador: { method: 'GET', url: `${basePath}/fornecedor-aprovador/{id}/{tipoFornecedor}` },
  nomeGerenteAprovador: { method: 'GET', url: `${basePath}/gerente-aprovador/{id}` },
  verificaSeUsuarioAprovadorContratoComplementoInformacoes: { method: 'GET', url: `${basePath}/fluxo/usuario-informa-complemento-informacoes` },
  salvarPrazoPagamento: { method: 'PUT', url: `${basePath}/fluxo/salvar-prazo-pagamento/{idContrato}` },
  buscarPassosFluxo: { method: 'GET', url: `${basePath}/fluxo/passos/buscar` },
  buscarContratosRenovacao: { method: 'GET', url: `${basePath}/{idContrato}/contratos-renovacao` },
  buscarFiltroPassosFluxo: { method: 'GET', url: `${basePath}/filtro/passos/buscar` },
};
export default (resource) => resource(`${basePath}`, {}, workflowContratoActions);

export const pendenciasFluxoContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();

export const pendenciasContratos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar-pendencias-contratos', parametros).doGet();
