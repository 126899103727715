<template lang="html">
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" :editable="e1 > 1" edit-icon="$vuetify.icons.complete" step="1">{{ $t('label.geral') }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" :editable="e1 > 2" edit-icon="$vuetify.icons.complete" step="2">{{ $tc('label.cadastro', 1) }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" :editable="e1 > 3" edit-icon="$vuetify.icons.complete" step="3">{{ $tc('label.configuracao', 2) }}</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step v-if="fluxoFechamento" :complete="e1 > 4" :editable="e1 > 4" edit-icon="$vuetify.icons.complete" step="4">{{ $tc('label.fechamento', 1) }}</v-stepper-step>

      <v-divider v-if="fluxoFechamento"></v-divider>

      <v-stepper-step :complete="e1 > passoOrdenacao" :editable="e1 > passoOrdenacao" edit-icon="$vuetify.icons.complete" :step="passoOrdenacao">{{ $tc('label.ordenacao', 1) }}</v-stepper-step>

      <v-divider ></v-divider>

      <v-stepper-step :complete="e1 > passoGrid" :editable="e1 > passoGrid" edit-icon="$vuetify.icons.complete" :step="passoGrid">{{ $tc('label.grid_filtros', 1) }}</v-stepper-step>

      <v-divider ></v-divider>

    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <parametrizacao-contrato-passo-1
          :id="id"
          :copy="copy"
          ref="passo1"
          @PARAMETRIZACAO_CONTRATO_CANCELAR="cancelar"
          @PARAMETRIZACAO_CONTRATO_CONTINUAR="continuar"
          @PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO="atualizarObjeto"
          @PARAMETRIZACAO_CONTRATO_DEFINIR_PASSOS="definirPassoFluxoTrade"
        >
        </parametrizacao-contrato-passo-1>
      </v-stepper-content>

      <v-stepper-content step="2">
        <parametrizacao-contrato-passo-2
          :id="id"
          ref="passo2"
          @PARAMETRIZACAO_CONTRATO_CANCELAR="cancelar"
          @PARAMETRIZACAO_CONTRATO_CONTINUAR="continuar"
          @PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO="atualizarObjeto"
          @PARAMETRIZACAO_CONTRATO_VOLTAR="voltar"
        >
        </parametrizacao-contrato-passo-2>
      </v-stepper-content>

      <v-stepper-content step="3">
        <parametrizacao-contrato-passo-3
          :id="id"
          ref="passo3"
          @PARAMETRIZACAO_CONTRATO_CANCELAR="cancelar"
          @PARAMETRIZACAO_CONTRATO_CONTINUAR="continuar"
          @PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO="atualizarObjeto"
          @PARAMETRIZACAO_CONTRATO_VOLTAR="voltar"
        >
        </parametrizacao-contrato-passo-3>
      </v-stepper-content>

      <v-stepper-content step="4" v-if="fluxoFechamento">
        <parametrizacao-contrato-passo-4
          :id="id"
          ref="passo4"
          @PARAMETRIZACAO_CONTRATO_CANCELAR="cancelar"
          @PARAMETRIZACAO_CONTRATO_CONTINUAR="continuar"
          @PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO="atualizarObjeto"
          @PARAMETRIZACAO_CONTRATO_VOLTAR="voltar"
        >
        </parametrizacao-contrato-passo-4>
      </v-stepper-content>

      <v-stepper-content :step="passoOrdenacao">
        <parametrizacao-contrato-passo-5
          :id="id"
          :passo="passoOrdenacao"
          ref="passo5"
          @PARAMETRIZACAO_CONTRATO_CANCELAR="cancelar"
          @PARAMETRIZACAO_CONTRATO_CONTINUAR="continuar"
          @PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO="atualizarObjeto"
          @PARAMETRIZACAO_CONTRATO_VOLTAR="voltar"
        >
        </parametrizacao-contrato-passo-5>
      </v-stepper-content>

      <v-stepper-content :step="passoGrid">
        <parametrizacao-contrato-passo-6
          :id="id"
          :passo="passoGrid"
          ref="passo6"
          @PARAMETRIZACAO_CONTRATO_CANCELAR="cancelar"
          @PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO="atualizarObjeto"
          @PARAMETRIZACAO_CONTRATO_VOLTAR="voltar"
          @PARAMETRIZACAO_CONTRATO_CONCLUIR="concluirPassos"
        >
        </parametrizacao-contrato-passo-6>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script type="text/javascript">
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import ParametrizacaoContratoPasso1 from './ParametrizacaoContratoPasso1';
import ParametrizacaoContratoPasso2 from './ParametrizacaoContratoPasso2';
import ParametrizacaoContratoPasso3 from './ParametrizacaoContratoPasso3';
import ParametrizacaoContratoPasso4 from './ParametrizacaoContratoPasso4';
import ParametrizacaoContratoPasso5 from './ParametrizacaoContratoPasso5';
import ParametrizacaoContratoPasso6 from './ParametrizacaoContratoPasso6';

export default {
  name: 'ParametrizacaoContratoForm',
  data() {
    return {
      paramContratoResources: this.$api.parametrizacaoContrato(this.$resource),

      e1: 1,
      id: null,
      objetoContrato: {},
      fluxoFechamento: false,
      fluxoPagamento: false,
      fluxoOrcamento: false,
      passoOrdenacao: 4,
      passoGrid: 5,
      copy: '',
    };
  },
  components: {
    ParametrizacaoContratoPasso1,
    ParametrizacaoContratoPasso2,
    ParametrizacaoContratoPasso3,
    ParametrizacaoContratoPasso4,
    ParametrizacaoContratoPasso5,
    ParametrizacaoContratoPasso6,
  },
  computed: {
    ...generateComputed('PARAM_CONTRATO', [
      'canAccessPage',
    ]),
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    cancelar() {
      this.$router.push({ name: 'parametrizacao-contrato' });
    },
    continuar(proximoPasso, id) {
      this.e1 = proximoPasso;

      if (id) {
        this.id = id;
      }

      if (proximoPasso === 2) {
        this.definirPassoFluxoTrade();
        this.$refs.passo2.setFluxos(this.objetoContrato.passo1.fluxo);
        this.$refs.passo2
          .setListaCarteiraCliente(this.objetoContrato.passo1.listaCarteiraCliente);
      }
      if (proximoPasso === 3) {
        const listaPadraoPasso2 = this.$refs.passo2.getListaPadrao();

        if (this.id) {
          this.pegarCoeficienteCalculoNaListaPadrao(listaPadraoPasso2);
        } else {
          this.adicionarCoeficienteCalculo();
        }

        const todosCampos = [
          ...this.objetoContrato.passo2.listaPadrao,
          ...this.objetoContrato.passo2.listaDinamica,
          ...this.objetoContrato.passo2.listaCliente
            .filter((campo) => campo.habilitado),
          ...this.objetoContrato.passo2.listaFornecedor
            .filter((campo) => campo.habilitado),
          ...this.objetoContrato.passo2.listaProduto,
        ];

        const fluxos = this.objetoContrato.passo1.fluxo,
          camposDinamicos = this.objetoContrato.passo2.listaDinamica;

        this.$refs.passo3.setCamposConfiguracao(todosCampos, fluxos);
        this.$refs.passo3.setCamposDinamicos(camposDinamicos);
        this.$refs.passo3.setFluxosTrade(fluxos);
        this.$refs.passo3.setTipoInclusaoAcao(this.objetoContrato.passo2.tipoInclusaoAcao);
      }
      if (proximoPasso === this.passoOrdenacao) {
        this.$refs.passo5.setLista(this.montarLista());
      }
      if (proximoPasso === this.passoGrid) {
        this.$refs.passo6.setLista(this.montarListaGridFiltros());
      }
      this.scrollToTop();
    },
    adicionarCoeficienteCalculo() {
      this.objetoContrato.passo2.listaPadrao.push(
        {
          nome: this.$tc('label.habilitar_coeficiente_calculo'),
          obrigatorio: true,
          label: 'coeficiente_calculo',
          habilitaGrid: true,
          habilitaFiltro: false,
          desativaFiltro: true,
          desativaGrid: true,
          habilitaEdicao: false,
          edicaoAprovado: false,
        },
      );
    },
    pegarCoeficienteCalculoNaListaPadrao(listaPadraoPasso2) {
      const labelValor = listaPadraoPasso2.filter((p) => p.label === 'coeficiente_calculo');
      if (labelValor && labelValor.length) {
        labelValor[0].nome = this.$tc('label.habilitar_coeficiente_calculo');
        if (this.objetoContrato.passo2.listaPadrao.indexOf(labelValor[0]) === -1) {
          this.objetoContrato.passo2.listaPadrao.push(labelValor[0]);
        }
      } else {
        this.adicionarCoeficienteCalculo();
      }
    },
    definirPassoFluxoTrade() {
      const fluxos = this.objetoContrato.passo1.fluxo.split(',');
      if (fluxos.indexOf('APURACAO') !== -1) {
        this.fluxoApuracao = true;
      }
      if (fluxos.indexOf('LIQUIDACAO') !== -1) {
        this.fluxoPagamento = true;
      }
      if (fluxos.indexOf('ORCAMENTO') !== -1) {
        this.fluxoOrcamento = true;
      }

      this.passoOrdenacao = 2 + (fluxos.length - 1);
      this.passoGrid = 3 + (fluxos.length - 1);
    },
    montarLista() {
      let lista = [];
      lista = lista.concat(this.objetoContrato.passo2.listaPadrao);
      lista = lista.concat(this.objetoContrato.passo2.listaCliente
        .filter((item) => item.habilitado));
      lista = lista.concat(this.objetoContrato.passo2.listaFornecedor
        .filter((item) => item.habilitado));
      lista = lista.concat(this.objetoContrato.passo2.listaProduto
        .filter((item) => item.PLANEJAMENTO && !item.excecao));
      lista = lista.concat(this.objetoContrato.passo2.listaCarteiraCliente
        .filter((item) => item.mnemonico !== 'UNIDADE_NEGOCIO' && item.habilitado));
      lista = lista.concat(this.objetoContrato.passo2.listaDinamica
        .filter((item) => item.PLANEJAMENTO));

      const index = lista.findIndex((el) => el.label === 'coeficiente_calculo');
      if (index > -1) {
        lista.splice(index, 1);
      }
      return lista;
    },
    montarListaGridFiltros() {
      const lista = this.montarLista();
      return [
        ...lista,
        this.criarItemLista(
          this.$tc('label.data_inicio', 1),
          'data_inicio',
          lista.length + 1,
        ),
        this.criarItemLista(
          this.$tc('label.data_fim', 1),
          'data_fim',
          lista.length + 1,
        ),
        this.criarItemLista(
          this.$tc('label.periodo_planejamento', 1),
          'periodo_planejamento',
          lista.length + 1,
        ),
        this.criarItemLista(
          this.$tc('label.valor_consumido', 1),
          'valor_consumido',
          lista.length + 1,
        ),
        this.criarItemLista(
          this.$tc('label.id_contrato', 1),
          'id_contrato',
          lista.length + 1,
        ),
      ];
    },
    criarItemLista(nome, label, ordem) {
      return {
        nome,
        label,
        apenas_ordenacao: false,
        habilita_filtro: false,
        habilita_grid: false,
        desativa_filtro: false,
        desativa_grid: true,
        ordenacao: ordem,
      };
    },
    atualizarObjeto(passo, objeto) {
      if (!this.fluxoFechamento && passo >= 4) {
        passo += 1;
      }
      this.objetoContrato[`passo${passo}`] = { ...objeto };
    },
    voltar(passo) {
      this.e1 = passo;
      this.scrollToTop();
    },
    limparIDsCopy(objetoContrato) {
      if (objetoContrato.passo1 && objetoContrato.passo1.id) {
        objetoContrato.passo1.id = null;
        if (objetoContrato.passo1.tiposAcao.length > 0) {
          objetoContrato.passo1.tiposAcao.map((item) => {
            item.idContratoTipo = null;
            return {
              ...item,
            };
          });
        }
      }
      if (objetoContrato.passo2 && objetoContrato.passo2.id) {
        objetoContrato.passo2.id = null;
      }
      if (objetoContrato.passo3 && objetoContrato.passo3.id) {
        objetoContrato.passo3.id = null;
        if (objetoContrato.passo3.listaFluxosTrade.length > 0) {
          objetoContrato.passo3.listaFluxosTrade.map((item) => {
            item.idContratoTipo = null;
            return {
              ...item,
            };
          });
        }
      }
      if (objetoContrato.passo4 && objetoContrato.passo4.id) {
        objetoContrato.passo4.id = null;
      }
      if (objetoContrato.passo5 && objetoContrato.passo5.id) {
        objetoContrato.passo5.id = null;
      }
      if (objetoContrato.passo6 && objetoContrato.passo6.id) {
        objetoContrato.passo6.id = null;
      }
    },
    concluirPassos(passo, objeto) {
      this.atualizarObjeto(passo, objeto);
      if (this.copy) {
        this.limparIDsCopy(this.objetoContrato);
      }
      // salvar edição
      if (this.id && !this.copy) {
        this.paramContratoResources.atualizar(this.objetoContrato).then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$router.push({ name: 'parametrizacao-contrato' });
        })
          .catch((err) => {
            this.$error(this, err);
          });
      } else {
        this.paramContratoResources.gravar(this.objetoContrato).then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$router.push({ name: 'parametrizacao-contrato' });
        })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    } else if (this.$route.params.copy && this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.copy = this.$route.params.copy;
    } else if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
    }
  },
};
</script>
