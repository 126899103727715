export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'fornecedor',
            nomCampoId: 'id_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => {
                const params = {
                  autocomplete,
                  indInativo: true,
                };
                return this.planejamentoAcaoResource.buscarFornecedores(params);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
        ],
      },
    };
  },
};
