function create(message, location = 'bottom', duration = 5E3, cb = null, cbmessage = '', cbbutton = null) {
  this.$store.dispatch('toast/addToast', {
    message,
    location,
    duration,
    cb,
    cbmessage,
    cbbutton,
  });
}

function plugin(Vue) {
  Vue.prototype.$toast = create;
}

if (typeof window !== 'undefined' && window.Vue) { // eslint-disable-line no-undef
  window.Vue.use(plugin); // eslint-disable-line no-undef
}

// module.exports = plugin;
export default plugin;
