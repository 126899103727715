import Usuario from '../../spa/usuario/CadastroUsuario';

const UsuarioForm = () => import('../../spa/usuario/UsuarioForm');
const UsuarioList = () => import('../../spa/usuario/UsuarioList');

export default {
  path: 'usuario',
  component: Usuario,
  children: [
    {
      path: '',
      name: 'usuario',
      component: UsuarioList,
    },
    {
      path: ':id/editar',
      name: 'editarUsuario',
      component: UsuarioForm,
      props: {
        somenteLeitura: false,
      },
    },
    {
      path: 'novo',
      name: 'novoUsuario',
      component: UsuarioForm,
    },
    {
      path: ':id',
      name: 'verUsuario',
      component: UsuarioForm,
      props: {
        somenteLeitura: true,
      },
    },
  ],
};
