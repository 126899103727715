import coreResources from '@/produto/common/resources/core-resources';
import magaluRelatorios from './magalu-relatorios';
import notasDebitoIntegracao from './notas-debito-integracao';
import notasReprocessamento from './notas-reprocessamento';
import notasInconsistencia from './notas-inconsistencia';
import notasSellinDevolucao from './notas-sellin-devolucao';
import solicitacaoExportacaoAssincrona from './solicitacao-exportacao-assincrona';
import solicitacaoExportacaoAssincronaRealizado from './solicitacao-exportacao-assincrona-realizado';
import workflowAcaoMagalu from './workflow-acao-redirecionamento';
import workflowApuracaoAcaoMagalu from './workflow-apuracao-acao-redirecionamento';
import tipoUso from './tipo-uso';

function plugin(Vue) {
  Vue.prototype.$api = {
    ...coreResources,
    magaluRelatorios,
    notasReprocessamento,
    notasInconsistencia,
    notasSellinDevolucao,
    solicitacaoExportacaoAssincrona,
    solicitacaoExportacaoAssincronaRealizado,
    workflowAcaoMagalu,
    workflowApuracaoAcaoMagalu,
    tipoUso,
    notasDebitoIntegracao,
  };
}

if (typeof window !== 'undefined' && window.Vue) { // eslint-disable-line no-undef
  window.Vue.use(plugin); // eslint-disable-line no-undef
}

// module.exports = plugin;
export default plugin;
