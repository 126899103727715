export default class Passo5Vencimento {
  constructor(id = null, vencimento = false, referenciaVencimento, prazoVencimento = 0,
    notificacaoVencimento = false, prazoNotificacao = 0, destinatarios = [], tiposVerba = []) {
    const objetoPadrao = {
      chave: null,
      valor: null,
      idAcaoTipo: null,
    };

    this.id = id;
    this.vencimento = { ...vencimento || Passo5Vencimento.setVencimento(objetoPadrao) };
    this.vencimento.valor = this.vencimento.valor === 'true';
    this.referenciaVencimento = {
      ...referenciaVencimento
      || Passo5Vencimento.setReferenciaVencimento(objetoPadrao),
    };
    this.prazoVencimento = {
      ...prazoVencimento
      || Passo5Vencimento.setPrazoVencimento(objetoPadrao),
    };
    this.prazoVencimento.valor = Number(this.prazoVencimento.valor);
    this.notificacaoVencimento = {
      ...notificacaoVencimento
      || Passo5Vencimento.setNotificacaoVencimento(objetoPadrao),
    };
    this.notificacaoVencimento.valor = this.notificacaoVencimento.valor === 'true';
    this.prazoNotificacao = {
      ...prazoNotificacao
      || Passo5Vencimento.setPrazoNotificacao(objetoPadrao),
    };
    this.prazoNotificacao.valor = Number(this.prazoNotificacao.valor);
    this.destinatarios = destinatarios;
    this.tiposVerba = tiposVerba;
  }

  static setVencimento(objeto) {
    objeto.chave = 'HABILITAR_VENCIMENTO';
    objeto.valor = false;
    return objeto;
  }

  static setReferenciaVencimento(objeto) {
    objeto.chave = 'DATA_REFERENCIA_VENCIMENTO';
    objeto.valor = 'INICIO_VIGENCIA';
    return objeto;
  }

  static setPrazoVencimento(objeto) {
    objeto.chave = 'PRAZO_VENCIMENTO';
    objeto.valor = 0;
    return objeto;
  }

  static setNotificacaoVencimento(objeto) {
    objeto.chave = 'HABILITAR_NOTIFICACAO_VENCIMENTO';
    objeto.valor = false;
    return objeto;
  }

  static setPrazoNotificacao(objeto) {
    objeto.chave = 'PRAZO_NOTIFICACAO';
    objeto.valor = 0;
    return objeto;
  }
}
