<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-card-title>
          <span class="headline">{{ $t('title.transferir_de') }}</span>
        </v-card-title>
        <v-container grid-list-md class="py-0">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('label.tipo_de_fluxo')" :readonly="true" v-model="passo.tipo"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field :label="$t('label.tarefa_aprovacao')" :readonly="true" v-model="passo.tarefaAprovacao"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="passo.usuarioAprovador">
              <v-text-field :label="$t('label.usuario_aprovador')" :readonly="true" v-model="passo.usuarioAprovador"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="passo.solicitante">
              <v-text-field :label="$t('label.solicitante')" :readonly="true" v-model="passo.solicitante"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>

        <v-card-title>
          <span class="headline">{{ $t('title.para') }}</span>
        </v-card-title>

        <v-container grid-list-md class="py-0" v-if="ehCliente">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                :readonly="true"
                :items="tipoAprovadores"
                v-model="tipoAprovador"
                :label="$t('label.selecione_aprovadores')"
                item-text="descricao"
                item-value="value">
              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="usuariosCliente"
                v-model="idUsuarioCliente"
                required
                :rules="[rules.required]"
                clearable
                :label="`${$t('label.workflow_buscar_usuarios')}*`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nome"
                item-value="id">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-container grid-list-md class="py-0" v-else>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                :items="tipoAprovadores"
                v-model="tipoAprovador"
                :label="$t('label.selecione_aprovadores')"
                item-text="descricao"
                item-value="value">
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" v-if="ehUsuario">
              <v-autocomplete
                :items="usuarios"
                v-model="usuariosAprovadores"
                multiple
                required
                :rules="[rules.requiredArray]"
                clearable
                return-object
                :label="`${$t('label.workflow_buscar_usuarios')}*`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nomeSobrenome"
                item-value="id">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" v-if="ehGrupo">
              <v-autocomplete
                :items="perfis"
                v-model="grupos"
                multiple
                required
                :rules="[rules.requiredArray]"
                clearable
                return-object
                :label="`${$t('label.workflow_buscar_grupos')}*`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nomPerfil"
                item-value="id">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" v-if="ehGrupo">
              <v-autocomplete
                :items="estruturas"
                v-model="subgrupos"
                multiple
                clearable
                return-object
                :label="$t('label.workflow_buscar_subgrupos')"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nomEstruturaUsuario"
                item-value="id">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="accent" @click="transferir">{{ $t('label.confirmar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TransferirModal',
  props: {
    filtros: null,
    passo: null,
  },
  data() {
    return {
      fluxoExecucaoResource: this.$api.workflowPassos(this.$resource),
      perfilResources: this.$api.perfil(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      usuarioResources: this.$api.usuario(this.$resource),
      usuarioClienteResources: this.$api.usuarioCliente(this.$resource),
      dialog: false,
      tipoAprovador: null,
      perfis: [],
      valid: true,
      estruturas: [],
      usuarios: [],
      usuariosCliente: [],
      idUsuarioCliente: null,
      usuariosAprovadores: null,
      grupos: null,
      subgrupos: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredArray: (value) => (!!value && !!value.length) || this.$t('message.campo_obrigatorio'),
      },
      tipoAprovadores: [
        {
          descricao: this.$t('label.workflow_solicitante_usuario'),
          value: 'usuario',
        },
        {
          descricao: this.$t('label.workflow_solicitante_grupo'),
          value: 'grupo',
        },
      ],
    };
  },
  computed: {
    ehUsuario() {
      return this.tipoAprovador === 'usuario';
    },
    ehGrupo() {
      return this.tipoAprovador === 'grupo';
    },
    ehCliente() {
      return !!this.passo.passocliente;
    },
  },
  methods: {
    transferir() {
      if (!this.$refs.form.validate()) return;
      if (this.passo.passocliente && this.passo.id) {
        this.transferirPassoCliente();
      } else if (this.passo.id) {
        this.transferirPasso();
      } else {
        this.transferirPassos();
      }
    },
    transferirPassos() {
      this.fluxoExecucaoResource.transferirPassos(
        {
          idTipo: this.filtros.tipo.id,
        }, this.preencherParametrosPassos(),
      )
        .then(this.transferidoComSucesso)
        .catch(this.erroRequisicao);
    },
    transferirPasso() {
      this.fluxoExecucaoResource.transferirPasso(
        {
          idPasso: this.passo.id,
          idFluxo: this.passo.idFluxoExecucao,
        }, this.preencherParametrosPasso(),
      )
        .then(this.transferidoComSucesso)
        .catch(this.erroRequisicao);
    },
    preencherParametrosPasso() {
      const parametros = {};
      parametros.usuarios = this.usuariosAprovadores || [];
      parametros.grupos = this.grupos || [];
      parametros.subgrupos = this.subgrupos || [];
      return parametros;
    },
    preencherParametrosPassos() {
      const parametros = this.preencherParametrosPasso();
      if (this.passo.idAprovador && this.passo.idAprovador) {
        parametros.idAprovador = this.passo.idAprovador;
      }
      if (this.filtros.solicitante && this.filtros.solicitante.id) {
        parametros.idSolicitante = this.filtros.solicitante.id;
      }
      parametros.idTipo = this.filtros.tipo.id;
      parametros.idUsuarioCliente = this.idUsuarioCliente;
      parametros.passo = this.passo.tarefaAprovacao;
      parametros.passoCliente = this.passo.passocliente;
      return parametros;
    },
    transferirPassoCliente() {
      this.fluxoExecucaoResource.transferirPassoCliente(
        {
          idPasso: this.passo.id,
          idAcordo: this.passo.idAcordo,
          idUsuarioCliente: this.idUsuarioCliente,
        }, {},
      )
        .then(this.transferidoComSucesso)
        .catch(this.erroRequisicao);
    },
    transferidoComSucesso() {
      this.$emit('filtrar', this.filtros);
      this.$toast(this.$t('message.aprovacao_transferida'));
      this.close();
    },
    erroRequisicao(err) {
      this.$error(this, err);
    },
    limparCampo() {
      this.grupos = null;
      this.subgrupos = null;
      this.usuariosAprovadores = null;
      this.idUsuarioCliente = null;
      this.valid = true;
      setTimeout(() => {
        if (this.ehCliente) {
          this.tipoAprovador = 'usuario';
        } else {
          this.tipoAprovador = null;
        }
      }, 1E2);
    },
    open() {
      this.limparCampo();
      setTimeout(() => this.buscarUsuarioClientePasso(), 1E2);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    buscarUsuarioClientePasso() {
      if (!this.ehCliente) return;
      if (this.passo.id) {
        this.buscarAprovadorPorPasso(this.passo.id);
      } else {
        this.buscarAprovadorPorUsuario(this.passo.idAprovador);
      }
    },
    buscarAprovadorPorUsuario(id) {
      this.usuarioClienteResources.obterUsuariosMesmaConfiguracaoUsuarioId({ id })
        .then((response) => {
          this.usuariosCliente = response.data;
        })
        .catch(this.erroRequisicao);
    },
    buscarAprovadorPorPasso(idPassoExecucao) {
      this.fluxoExecucaoResource.aprovadoresPassosClientes({ idPassoExecucao })
        .then((response) => {
          this.usuariosCliente = response.data;
        })
        .catch(this.erroRequisicao);
    },
    buscarUsuario() {
      this.usuarioResources.listarAtivos(
        {
          nome: '',
          tamanhoPagina: 50000,
          tipo: 'FUNCIONARIO',
        },
      )
        .then((response) => {
          this.usuarios = response.data.resposta;
        })
        .catch(this.erroRequisicao);
    },
    buscarPerfil() {
      this.perfilResources.listarAtivos(
        {
          nome: '',
          indAtivo: true,
          tamanhoPagina: 50000,
        },
      )
        .then((response) => {
          this.perfis = response.data.resposta;
        })
        .catch(this.erroRequisicao);
    },
    buscarEstruturas() {
      this.estruturaUsuarioResources.listarAtivos(
        {
          nome: '',
          indAtivo: true,
          tamanhoPagina: 50000,
        },
      )
        .then((response) => {
          this.estruturas = response.data.resposta;
        })
        .catch(this.erroRequisicao);
    },
  },
  mounted() {
    this.buscarPerfil();
    this.buscarEstruturas();
    this.buscarUsuario();
  },
};
</script>
