export const env = process.env.NODE_ENV;
export const API_URL = process.env.VUE_APP_API_URL || '';
export const JOB_URL = process.env.VUE_APP_JOB_URL || '';
export const APURACAO_URL = process.env.VUE_APP_APURACAO_URL || '';
export const LIQUIDACAO_URL = process.env.VUE_APP_LIQUIDACAO_URL || '';
export const PLANEJAMENTO_URL = process.env.VUE_APP_PLANEJAMENTO_URL || '';
export const USUARIO_URL = process.env.VUE_APP_USUARIO_URL || '';
export const WORKFLOW_URL = process.env.VUE_APP_WORKFLOW_URL || '';

export const nonProdEnvStr = [
  'development',
  'testing',
  'homolog',
];

export const resourceTable = {
  api: {
    dev: '/api/',
    prod: `${API_URL}/tradelinks_cadastro/api/`,
  },
  job: {
    dev: '/job/',
    prod: `${JOB_URL}/tradelinks_job/api/`,
  },
  public: {
    dev: '/public/',
    prod: `${API_URL}/tradelinks_cadastro/public/`,
  },
  planejamento_acao: {
    dev: '/planejamento_acao/',
    prod: `${PLANEJAMENTO_URL}/tradelinks_planejamento_acao/api/`,
  },
  usuario: {
    dev: '/usuario/',
    prod: `${USUARIO_URL}/tradelinks_usuario/api/`,
  },
  workflow: {
    dev: '/workflow/',
    prod: `${WORKFLOW_URL}/tradelinks_workflow/api/`,
  },
  recebimento: {
    dev: '/recebimento/',
    prod: `${LIQUIDACAO_URL}/tradelinks_liquidacao_acao/api/recebimento/`,
  },
  pagamento: {
    dev: '/pagamento/',
    prod: `${LIQUIDACAO_URL}/tradelinks_liquidacao_acao/api/pagamento/`,
  },
  liquidacao: {
    dev: '/liquidacao/',
    prod: `${LIQUIDACAO_URL}/tradelinks_liquidacao_acao/api/`,
  },
  apuracao_acao: {
    dev: '/apuracao_acao/',
    prod: `${APURACAO_URL}/tradelinks_apuracao_acao/api/`,
  },
};
// (process.env.NODE_ENV && ['development', 'testing'].indexOf(process.env.NODE_ENV) !== -1)

export default (type, api = '') => {
  if (!type) {
    throw Error('[api-resource] Error! type is not defined.');
  }

  if (!resourceTable.hasOwnProperty(type)) {
    throw Error('[api-resource] Error! type is defined BUT'
      + ` it was not found on resourceTable: ${type}`);
  }

  const auxEnv = nonProdEnvStr.indexOf(env) !== -1
    ? 'dev'
    : 'prod';

  return `${(resourceTable[type])[auxEnv]}${api}`;
};
