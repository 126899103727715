<script>
export default {
  props: {
    page: {
      type: Object, // instance of PDFPageProxy returned from pdf.getPage
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    scrollTop: {
      type: Number,
      default: 0,
    },
    clientHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      elementTop: 0,
      elementHeight: 0,
    };
  },
  created() {
    this.viewport = this.page.getViewport(this.scale);
  },
  mounted() {
    this.updateElementBounds();
  },
  beforeDestroy() {
    this.destroyPage(this.page);
  },
  render(h) {
    const { canvasAttrs: attrs } = this;
    return h('canvas', { attrs });
  },
  watch: {
    page(page, oldPage) {
      this.destroyPage(oldPage);
    },
    isElementVisible(isElementVisible) {
      if (isElementVisible) {
        this.drawPage();
        this.$emit('visiblePage', this.page.pageNumber);
      }
    },
    scale() {
      this.destroyRenderTask();
      this.viewport = this.page.getViewport(this.scale);
      this.drawPage();
      this.updateElementBounds();
    },
    scrollTop: 'updateElementBounds',
    clientHeight: 'updateElementBounds',
  },
  computed: {
    isElementVisible() {
      const {
        elementTop, elementBottom, scrollTop, scrollBottom,
      } = this;
      if (!elementBottom) return false;

      return elementTop < scrollBottom && elementBottom > scrollTop;
    },
    elementBottom() {
      return this.elementTop + this.elementHeight;
    },
    scrollBottom() {
      return this.scrollTop + this.clientHeight;
    },
    canvasAttrs() {
      let { width, height } = this.viewport;
      [width, height] = [width, height].map((dim) => Math.ceil(dim));

      const style = this.canvasStyle;

      return {
        width,
        height,
        style,
        class: 'pdf-page mb-3',
      };
    },
    canvasStyle() {
      const { width: actualSizeWidth, height: actualSizeHeight } = this.actualSizeViewport;
      const pixelRatio = window.devicePixelRatio || 1;
      const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight]
        .map((dim) => Math.ceil(dim / pixelRatio));
      return `width: ${pixelWidth}px; height: ${pixelHeight}px;`;
    },
    actualSizeViewport() {
      return this.viewport.clone({ scale: this.scale });
    },
  },
  methods: {
    drawPage() {
      if (this.renderTask) return;

      const { viewport } = this;
      const canvasContext = this.$el.getContext('2d');
      const renderContext = { canvasContext, viewport };

      this.renderTask = this.page.render(renderContext);
      this.renderTask.then(() => this.$emit('rendered', this.page))
        .catch(this.destroyRenderTask);
    },
    destroyPage(page) {
      if (!page) return;
      page._destroy();// eslint-disable-line
      if (this.renderTask) this.renderTask.cancel();
    },
    destroyRenderTask() {
      if (!this.renderTask) return;
      this.renderTask.cancel();
      delete this.renderTask;
    },
    updateElementBounds() {
      const { offsetTop, offsetHeight } = this.$el;
      this.elementTop = offsetTop;
      this.elementHeight = offsetHeight;
    },
  },
};
</script>
<style>
  .pdf-page {
    display: block;
    margin: 0 auto;
  }
</style>
