import ParametrizacaoAcao from '../../spa/parametrizacao/acao/ParametrizacaoAcao';
import ParametrizacaoAcaoList from '../../spa/parametrizacao/acao/ParametrizacaoAcaoList';
import ParametrizacaoAcaoForm from '../../spa/parametrizacao/acao/ParametrizacaoAcaoForm';

export default {
  path: 'parametrizacao-acao',
  component: ParametrizacaoAcao,
  children: [
    {
      path: '',
      name: 'parametrizacao-acao',
      component: ParametrizacaoAcaoList,
    },
    {
      path: 'novo',
      name: 'novoParametrizacaoAcao',
      component: ParametrizacaoAcaoForm,
    },
    {
      path: ':id/editar',
      name: 'editarParametrizacaoAcao',
      component: ParametrizacaoAcaoForm,
    },
    {
      path: '#/copiar',
      name: 'copiarParametrizacaoAcao',
      component: ParametrizacaoAcaoForm,
    },
  ],
};
