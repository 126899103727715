<template>
  <v-slide-y-transition mode="out-in" v-if="canAccessPage">
    <router-view></router-view>
  </v-slide-y-transition>
</template>
<script>
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'CarteiraCliente',
  methods: {
    accessForbidden() {
      this.$router.push({ name: 'inicio' });
      this.$toast('Acesso negado');
    },
  },
  computed: {
    ...generateComputed('Carteira', [
      'canAccessPage',
    ]),
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
