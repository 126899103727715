<template>
  <v-card class="elevation-2" :disabled="emAnalise(passoParaleloPai)" :key="`${chave}`">
    <v-main class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col class="title pb-0 mb-0">
          <span v-html="`${ $tc('label.passo', 1) } ${ passoParaleloPai.sequencia } - `" />{{ $t('label.aprovadores') }}
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col class="py-0 my-0">
          <span class="warning--text" v-html="`${$t('message.passo_representacao_conjunto')}`" />
        </v-col>
      </v-row>
    </v-main>
    <v-card-text class="historico_body">
      <v-timeline dense class="mx-3 my-1 pt-0 passo-paralelo">
        <v-timeline-item
          v-for="(item, index) in passoParaleloPai.usuariosAprovadores.passosParalelos"
          :color="styles(item.statusPasso)" fill-dot small right
          v-if="exibePasso(item)" :key="`passoParaleloTimeline-${chave}-${index}`" :chave="`passoParaleloTimeline-${chave}-${index}`">

          <template slot="icon">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on" style="cursor: default;"></span>
              </template>
              <span>
                {{$tc(`status_entidade.${item.statusPasso.toLowerCase()}`, 1)}}
              </span>
            </v-tooltip>
          </template>

          <acompanhamento-item
            :key="`passoParaleloItem-${chave}`"
            :chave="`passoParaleloItem-${chave}`"
            :item="item"
            :is-passo-paralelo="true"
            :cliente="fluxo.cliente"
            :entidade="entidade"
            :id-entidade="fluxo.idEntidade">
          </acompanhamento-item>

        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import PopoverLista from '../PopoverLista';
import AcompanhamentoItem from './AcompanhamentoItem';

export default {
  components: {
    PopoverLista,
    AcompanhamentoItem,
  },
  name: 'AcompanhamentoItemPassoParalelo',
  props: {
    passoParaleloPai: {
      type: Object,
      default: () => ({}),
    },
    entidade: String,
    idEntidade: Number,
    fluxo: Object,
    dadosModalAcompanhamento: Object,
    chave: String,
  },
  data() {
    return {
      resourceFluxo: this.$api.fluxoAprovacao(this.$resource),
      colorPalette: [
        'teal accent-4', // Verde
        'red lighten-1', // Vermelho
        'orange darken-2', // Laranja
        'amber lighten-1', // Amarelo
        'blue-grey lighten-2', // Cinza
        'blue darken-1', // Azul
      ],
    };
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ehContrato() {
      return this.entidade === 'CONTRATO';
    },
    ehRepresentanteLegal() {
      return this.entidade === 'REPRESENTANTE_LEGAL';
    },
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
  },
  methods: {
    aguardandoAprovacao(passo) {
      return passo.statusPasso === 'AGUARDANDO_APROVACAO';
    },
    emAnalise(passo) {
      return !passo || passo.statusPasso === 'EM_ANALISE';
    },
    transferido(passo) {
      return passo.statusPasso === 'TRANSFERIDO';
    },
    exibePasso(passo) {
      return passo.statusPasso !== 'CONDICAO_NAO_ATENDIDA' && passo.statusPasso !== 'TRANSFERIDO';
    },
    isPassoFornecedor(item) {
      return item.tipoFornecedor !== undefined;
    },
    nomePerfilUsuarioAprovador(item) {
      if (item.usuarioAprovador !== undefined) {
        return item.usuarioAprovador.grupo;
      }
      if (item.usuariosAprovadores !== undefined && item.usuariosAprovadores.grupos.length > 0) {
        return item.usuariosAprovadores.grupos[0].nome;
      }
      if (item.tipoFornecedor) {
        return 'Fornecedor';
      }
      return '';
    },
    reenviarEmailAprovacao(item) {
      const parametros = {
        idPasso: item.id,
        entidade: this.entidade,
      };
      this.resourceFluxo.reenviarEmailAprovacao(parametros, {})
        .then(() => {
          this.$toast(this.$t('message.email_reenviado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formatarStatus(status) {
      return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    },
    styles(status) {
      let colorIndex = 0;
      if (status === 'ENCERRADO') {
        colorIndex = 5;
      } else if (status === 'EM_CADASTRO') {
        colorIndex = 4;
      } else if (status === 'EM_FLUXO') {
        colorIndex = 4;
      } else if (status === 'CANCELADO') {
        colorIndex = 3;
      } else if (status === 'AGUARDANDO_APROVACAO') {
        colorIndex = 2;
      } else if (status === 'REPROVADO') {
        colorIndex = 1;
      } else if (status === 'AGUARDANDO_EXECUCAO_METAS') {
        colorIndex = 6;
      } else if (status === 'AGUARDANDO_APURACAO') {
        colorIndex = 5;
      } else if (status === 'AGUARDANDO_PAGAMENTO') {
        colorIndex = 5;
      } else if (status === 'APURACAO_PREVIA') {
        colorIndex = 4;
      } else if (status === 'EM_ANALISE') {
        colorIndex = 3;
      } else if (status === 'EM_REIVINDICACAO') {
        colorIndex = 2;
      } else {
        colorIndex = 0;
      }

      return this.color || this.colorPalette[colorIndex];
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss">
  .passo-paralelo.theme--light.v-timeline::before{
    background: none !important;
  }
</style>
