import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/apuracao-acao');

const workflowApuracaoAcaoActions = {
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idApuracao}` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idApuracao}` },
  aprovador: { method: 'GET', url: `${basePath}/{idApuracao}/passos/aprovador` },
  buscaPassosFornecedor: { method: 'GET', url: `${basePath}/{idApuracao}/passos/fornecedor` },
  associaUsuarioFornecedorAprovador: { method: 'POST', url: `${basePath}/{idApuracao}/passos/{idPasso}/fornecedor/usuario/{idUsuario}` },
  reprovar: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/reprovar` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/aprovar` },
  aprovacaoMassiva: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/{observacao}/{idTipoUso}/{idAcao}/{justificativaTipoUso}/aprovacao-massiva{?dataContabilizacao*}` },
  reprovacaoMassiva: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/{observacao}/{idTipoUso}/{idAcao}/{justificativaTipoUso}/reprovacao-massiva` },
  enviarAnaliseDadosMassivos: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/{observacao}/{idTipoUso}/{idAcao}/{justificativaTipoUso}/analise-dados-massivos` },
  enviarAnaliseApuracaoPorInativacaoFornecedor: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/{observacao}/analise-apuracao-por-inativacao-fornecedor` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/analise` },
  statusPasso: { method: 'GET', url: `${basePath}/{idApuracao}/passos/status` },
  existeFluxoCadastrado: { method: 'GET', url: `${basePath}/{idApuracao}/fluxo-cadastrado` },
  cancelar: { method: 'PUT', url: `${basePath}/{idApuracao}/cancelar` },
  nomeFornecedorAprovador: { method: 'GET', url: `${basePath}/fornecedor-aprovador/{id}/{tipoFornecedor}` },
  salvarApuracaoAcaoContratoTipoUso: { method: 'PUT', url: `${basePath}/fluxo/salvar-apuracao-acao-contrato-tipo-uso/{idApuracao}` },
};
export default (resource) => resource(`${basePath}`, {}, workflowApuracaoAcaoActions);

export const pendencias = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();
