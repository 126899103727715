import Home from '@/produto/spa/Home';
import NotFound from '@/produto/spa/NotFound';
import coreRoutes from '@/produto/config/router/core-router';

import { hideLoading } from '@/produto/common/functions/loading';
import tipoUso from './tipo-uso';

const configureGuards = (router) => {
  router.afterEach((route) => {
    if (route.params.menu) {
      hideLoading();
    }
  });
};

export const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home,
    redirect: '/dashboard-inicial',
    children: [
      ...coreRoutes,
      tipoUso,
    ],
  },
  {
    path: '*',
    component: NotFound,
  },
];

export const VueRouterObject = {
  routes,
  saveScrollPosition: true,
};

export default (VueRouter) => {
  const vueRouter = new VueRouter(VueRouterObject);
  configureGuards(vueRouter);
  return vueRouter;
};
