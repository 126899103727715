import getBasePath from '../functions/api-resource';

const basePath = getBasePath('recebimento', 'nota-debito/consulta-configuracao');

const notaDebitoActions = {
  buscarCamposFiltro: { methods: 'GET', url: `${basePath}/campos/filtro` },
  buscarCamposFiltroFornecedor: { methods: 'GET', url: `${basePath}/campos/filtro-fornecedor` },
};

export default (resource) => resource(`${basePath}`, {}, notaDebitoActions);
