<template lang="html">
  <div class="CRUDGenerico__Wrapper" v-if="canAccessPage">
    <v-card style="min-height: 86vh;">
      <v-card-title>
        <h3 class="headline mb-0">{{ $tc('title.parametrizacao_acao', 1) }}</h3>
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="search"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="10"
        :no-data-text="$t('label.tabela_sem_conteudo')">
        <template v-slot:item.acoes="{ item }">
          <v-menu bottom
            id="more_vert"
            origin="center center"
            transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :id="'more_vert_' + item.id"
                icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="editItem(item)">
                <v-list-item-action>
                  <v-icon>edit</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click.stop="copyItem(item)">
                <v-list-item-action>
                  <v-icon>library_add</v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  {{ $t('label.copiar') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="validaAlteracaoStatus(item)">
                <v-list-item-action>
                  <v-icon>block</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.ativar_inativa', 1) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.template="{ item }">
          <span v-if="item.template">{{ $tc(`configuracao.TEMPLATE_APURACAO.${item.template}`) }}</span>
          <span v-else>{{ $t('label.nao_aplica') }}</span>
        </template>
        <template v-slot:item.indExclusivoContrato="{ item }">
          {{ item.indExclusivoContrato ? $t('label.sim') : $t('label.nao')}}
        </template>
        <template v-slot:item.indAtivo="{ item }">
          {{ item.indAtivo ? $t('label.sim') : $t('label.nao')}}
        </template>
      </v-data-table>
    </v-card>

    <v-card-actions>
      <v-row>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn fab
              bottom right fixed
              class="v-btn__fab"
              color="primary"
              v-on="on"
              @click.native="openNewForm">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>{{$t('label.novo_tipo')}}</span>
        </v-tooltip>
      </v-row>
    </v-card-actions>

    <confirm ref="dialogAtivarInativar"
      :persistent="true"
      :message="$t('message.tem_certeza_alterar_status', {param: acaoTipo.nome})"
      :title="$t('label.atencao')"
      :agree-label="$t('label.sim')"
      :disagree-label="$t('label.nao')"
      @agree="confirmarAlteracaoStatus()"
      />

    <confirm
      ref="impossibilitadoAtivarInativarDialog"
      max-width="500"
      :agree-label="$t('label.ok')"
      :hide-disagree="true"
      :persistent="true"/>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ParametrizacaoAcao',
  data() {
    return {
      resources: this.$api.parametrizacaoAcao(this.$resource),
      esperar: false,
      search: '',
      headers: [
        {
          text: '', value: 'acoes', sortable: false, align: 'center', width: '2%',
        },
        { text: this.$tc('label.nome', 1), value: 'nome' },
        { text: this.$tc('label.descricao', 1), value: 'descricao' },
        { text: this.$tc('label.template', 1), value: 'template' },
        { text: this.$tc('label.exclusivo_contrato', 1), value: 'indExclusivoContrato' },
        { text: this.$tc('label.fluxo_trademarketing', 1), value: 'fluxos' },
        { text: this.$tc('label.ativo', 1), value: 'indAtivo' },
      ],
      lista: [],
      acaoTipo: {},
      isTipoAcaoAgrupadoraEmContrato: false,
      possuiConfiguracaoHerdada: false,
    };
  },
  components: {
    Confirm,
  },
  computed: {
    ...generateComputed('Param Acao', [
      'canAccessPage',
    ]),
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    editItem(item) {
      this.$router.push({ name: 'editarParametrizacaoAcao', params: { id: item.id } });
    },
    copyItem(item) {
      this.$router.push({ name: 'copiarParametrizacaoAcao', params: { id: item.id, copy: 'COPY' } });
    },
    buscar() {
      const params = {
        filtro: this.search,
      };

      this.resources.buscar(params).then((response) => {
        this.lista = response.data;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    openNewForm() {
      this.$router.push({ name: 'novoParametrizacaoAcao' });
    },
    ativarInativar(item) {
      this.acaoTipo = item;
      this.$refs.dialogAtivarInativar.open();
    },
    confirmarAlteracaoStatus() {
      const { id } = this.acaoTipo;
      this.resources.ativarInativar({ id }, { id }).then(() => {
        this.acaoTipo.indAtivo = !this.acaoTipo.indAtivo;
        this.$toast(this.$t('message.atualizado_com_sucesso'));
      }, (err) => {
        this.$error(this, err);
      });
    },
    validaAlteracaoStatus(item) {
      this.validaHerancaDeConfiguracao(item).then(() => {
        this.verificaSeTipoAcaoAgrupadoraEmContrato(item).then(() => {
          if (!this.possuiConfiguracaoHerdada && !this.isTipoAcaoAgrupadoraEmContrato) {
            this.ativarInativar(item);
          }
        }, (err) => {
          this.$error(this, err);
        });
      }, (err) => {
        this.$error(this, err);
      });
    },
    validaHerancaDeConfiguracao(item) {
      const { id } = item;
      return this.resources.verificarSeConfiguracaoHerdada({ id }, { id }).then((response) => {
        if (response.data && response.data.tiposAcaoDependentes) {
          const content = this.$t('message.tipo_acao_agrupadora_vinculada',
            { tipoAcaoHerancaConfig: response.data.tiposAcaoDependentes });
          setTimeout(() => this.$refs.impossibilitadoAtivarInativarDialog
            .openWithParams(
              '',
              content,
              null,
            ));
          this.possuiConfiguracaoHerdada = true;
        }
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificaSeTipoAcaoAgrupadoraEmContrato(item) {
      const { id } = item;
      return this.resources.verificarSeTipoAcaoAgrupadoraEmContrato({ id }, { id })
        .then((response) => {
          if (response.data && response.data.isTipoAcaoAgrupadoraEmContrato) {
            const content = this.$t('message.tipo_acao_agrupadora_contrato_inativar',
              { contratosTipoAcaoAgrupadora: response.data.contratosTipoAcaoAgrupadora });
            setTimeout(() => this.$refs.impossibilitadoAtivarInativarDialog
              .openWithParams(
                '',
                content,
                null,
              ));
            this.isTipoAcaoAgrupadoraEmContrato = true;
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.filtrar();
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
