export default {
  data() {
    return {
      notaDebitoResource: this.$api.notasDebitoConsulta(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      magaluRelatoriosResource: this.$api.magaluRelatorios(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      perfilResource: this.$api.perfil(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'descricao_passo',
            nomCampoId: 'descricao_passo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.workflowAcaoMagaluResource.buscarPassosDescricaoContrato({ autocomplete }),
              itemValue: 'descricao',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('status_entidade.em_cadastro', 1)}`, valor: 'EM_CADASTRO' },
                  { texto: `${this.$tc('status_entidade.aguardando_aprovacao', 1)}`, valor: 'AGUARDANDO_APROVACAO' },
                  { texto: `${this.$tc('status_entidade.aprovado', 1)}`, valor: 'APROVADO' },
                  { texto: `${this.$tc('status_entidade.cancelado', 1)}`, valor: 'CANCELADO' },
                  { texto: `${this.$tc('status_entidade.reprovado', 1)}`, valor: 'REPROVADO' },
                  { texto: `${this.$tc('status_entidade.em_analise', 1)}`, valor: 'EM_ANALISE' },
                  { texto: `${this.$tc('status_entidade.encerrado', 1)}`, valor: 'ENCERRADO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarTodosTiposAcao({ somenteAcoesContrato: true, autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'categoria',
            nomCampoId: 'id_categoria',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: this.$refs.container ? this.$refs.container.getValoresCamposPadrao().length === 0 : true,
            async: {
              fetchFunction: (autocomplete) => this.magaluRelatoriosResource
                .buscarCategoria({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
        ],
      },
    };
  },
};
