export default {
  data() {
    return {
      agendamentoResource: this.$api.agendamento(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'job',
            nomCampoId: 'id_agendamento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.agendamentoResource.listar(),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
        ],
      },
    };
  },
};
