<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h2 style="font-size: 18px; width: 100%" class="mb-0">{{ ehEdicao ? $t('title.editar_tipo_investimento') : $t('title.cadastrar_novo_tipo_investimento') }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="tipo_investimento_codigo"
              name="tipo_investimento_codigo"
              v-model="tipoInvestimento.idExterno"
              :label="`${$t('label.codigo')} *`"
              :counter="10"
              maxlength="10"
              :rules="[rules.required]"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="tipo_investimento_nome"
              name="tipo_investimento_nome"
              v-model="tipoInvestimento.nome"
              :label="`${$t('label.nome')} *`"
              :counter="50"
              maxlength="50"
              :rules="[rules.required]"
              required>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="tipo-investimento-btn-cancelar" @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn id="tipo-investimento-btn-salvar" @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>
  </v-form>
</template>

<script>
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'TipoInvestimentoForm',
  data() {
    return {
      tipoInvestimentoResources: this.$api.tipoInvestimento(this.$resource),
      dialog: {
        content: '',
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      tipoInvestimento: {},
    };
  },
  watch: {},
  components: {
    Confirm,
  },
  computed: {
    ehEdicao() {
      return this.tipoInvestimento.id;
    },
  },
  methods: {
    cancel() {
      this.limparFormulario();
      this.$emit('FORMULARIO_CANCELAR');
    },
    editar(item) {
      this.tipoInvestimento = item;
    },
    limparFormulario() {
      this.tipoInvestimento = {};
      this.$refs.form.reset();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.dialog.content = this.$t('message.salvar');
      this.$refs.confirmDialog.open();
    },
    save() {
      let salvarOuAtualizar;
      let inclusao = true;
      if (this.tipoInvestimento.id) {
        inclusao = false;
        salvarOuAtualizar = this.tipoInvestimentoResources.atualizar(this.tipoInvestimento);
      } else {
        salvarOuAtualizar = this.tipoInvestimentoResources.gravar(this.tipoInvestimento);
      }

      salvarOuAtualizar.then(() => this.limparFormulario())
        .then(() => this.$toast(this.$t((inclusao ? 'message.adicionado_confira_tabela' : 'message.atualizado_confira_tabela'))))
        .then(() => {
          this.$emit('FORMULARIO_SALVAR', { ...this.tipoInvestimento });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {},
};
</script>
