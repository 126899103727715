import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/acao');

const workflowAcaoActions = {
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idAcao}` },
  fluxosUsuarioSolicitante: { method: 'GET', url: `${basePath}/fluxos-usuario-solicitante` },
  buscaPassosClientes: { method: 'GET', url: `${basePath}/{idAcao}/passos/cliente` },
  buscaPassosFornecedor: { method: 'GET', url: `${basePath}/{idAcao}/passos/fornecedor` },
  associaUsuarioClienteAprovador: { method: 'POST', url: `${basePath}/{idAcao}/passos/{idPasso}/cliente/usuario/{idUsuario}` },
  associaUsuarioFornecedorAprovador: { method: 'POST', url: `${basePath}/{idAcao}/passos/{idPasso}/fornecedor/usuario/{idUsuario}` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idAcao}` },
  aprovador: { method: 'GET', url: `${basePath}/{idAcao}/passos/aprovador` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idAcao}/passos/aprovar` },
  aprovacaoMassiva: { method: 'PUT', url: `${basePath}/{idAcao}/passos/{observacao}/{idTipoUso}/{justificativaTipoUso}/aprovacao-massiva{?dataContabilizacao*}` },
  reprovacaoMassiva: { method: 'PUT', url: `${basePath}/{idAcao}/passos/{observacao}/{idTipoUso}/{justificativaTipoUso}/reprovacao-massiva` },
  enviarAnaliseDadosMassivos: { method: 'PUT', url: `${basePath}/{idAcao}/passos/{observacao}/{idTipoUso}/{justificativaTipoUso}/analise-dados-massivos` },
  enviarAnaliseAcaoPorInativacaoFornecedor: { method: 'PUT', url: `${basePath}/{idAcao}/passos/{observacao}/analise-acao-por-inativacao-fornecedor` },
  reprovar: { method: 'PUT', url: `${basePath}/{idAcao}/passos/reprovar` },
  cancelar: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idAcao}/passos/analise` },
  status: { method: 'GET', url: `${basePath}/{idAcao}/status` },
  statusPasso: { method: 'GET', url: `${basePath}/{idAcao}/passos/status` },
  nomeFornecedorAprovador: { method: 'GET', url: `${basePath}/fornecedor-aprovador/{id}/{tipoFornecedor}` },
  buscarJustificativa: { method: 'GET', url: `${basePath}/justificativa/{idAcao}` },
  buscarSolicitantes: { method: 'GET', url: `${basePath}/usuarios-solicitantes` },
  buscaPassosDiretor: { method: 'GET', url: `${basePath}/{idAcao}/passos/diretor` },
  associaUsuariosCondicionalAprovador: { method: 'POST', url: `${basePath}/passos/associar-usuarios-condicional` },
  buscarFiltroPassosFluxo: { method: 'GET', url: `${basePath}/filtro/passos/buscar` },
  buscarpendenciasFluxoTotalizadores: { method: 'GET', url: `${basePath}/buscar-pendencias-totalizadores` },
  buscarClienteAprovador: { method: 'GET', url: `${basePath}/cliente/aprovador` },
  validarAcaoAgrupadoraEApuracoes: { method: 'GET', url: `${basePath}/buscar-acao-agrupadora-e-apuracoes` },
  buscarQuantidadePendenciasTotalizadores: { method: 'GET', url: `${basePath}/buscar-quantidade-pendencias-totalizadores` },
};
export default (resource) => resource(`${basePath}`, {}, workflowAcaoActions);

export const pendenciasFluxoAcao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();

export const pendenciasAcaoApuracao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar-pendencias-acao-apuracao', parametros).doGet();

export const pendenciasAcaoApuracaoPaginada = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar-pendencias-acao-apuracao-paginada', parametros).doGet();

export const pendenciasFluxoAcaoEmAnalise = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias-analise', parametros).doGet();

export const salvar = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'passos/ignorar', parametros).doPut(parametros);

export const buscarPerfilPassoAprovador = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'perfil_aprovador', parametros).doGet();

export const enviarEmailAvisoInativacaoFornecedor = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'enviar-email-aviso-inativacao-fornecedor', parametros).doPut(parametros);
