import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/ajuste_verba');

const workflow = {
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idAjuste}` },
  statusPasso: { method: 'GET', url: `${basePath}/{idAjuste}/passos/status` },
  aprovador: { method: 'GET', url: `${basePath}/{idAjuste}/passos/aprovador` },
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idTipoVerba}` },
  status: { method: 'GET', url: `${basePath}/{idAjuste}/status` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idAjuste}/passos/analise` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idAjuste}/passos/aprovar` },
  reprovar: { method: 'PUT', url: `${basePath}/{idAjuste}/passos/reprovar` },
  cancelar: { method: 'PUT', url: `${basePath}/{idAjuste}/cancelar` },
  existeFluxoCadastrado: { method: 'GET', url: `${basePath}/{idAjuste}/fluxo-cadastrado` },
};

export const pendenciasFluxoAjusteVerba = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();

export default (resource) => resource(`${basePath}`, {}, workflow);
