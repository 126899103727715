<template>
  <v-text-field
    :id="inputId"
    :label="inputLabel"
    v-model="valorInterno"
    prefix="%"
    :disabled="campoDesabilitado"
    :required="campoObrigatorio"
    :rules="[rules.requiredNome, rules.numeroValido, rules.intervaloValido]"
    @input="numeroAlterado"
    type="Number"
  ></v-text-field>
</template>
<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoPercentual',
  props: {
    value: {
      type: [Number],
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
      },
    },
  },
  data() {
    return {
      valorInterno: null,
      rules: {
        requiredNome: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.valorInterno;
          return !!(val) || msg;
        },
        numeroValido: () => {
          if (!this.valorInterno) return true;

          const msg = this.$t('message.numero_invalido');
          const val = this.valorInterno;
          return (!!(val) && !Number.isNaN(val.toString())) || msg;
        },
        intervaloValido: () => {
          if (!this.valorInterno) return true;

          const max = 100,
            min = 0;
          const msg = this.$t('message.intervalo_numerico_percentual', { min, max });
          const val = this.valorInterno;
          return (!!(val) && val <= max && val >= min) || msg;
        },
      },
    };
  },
  methods: {
    numeroAlterado(valor) {
      this.emiteValorAlterado(Number(valor));
    },
  },
};
</script>
