export default {
  props: {
    metadados: {
      type: Object,
      required: true,
    },
    ignoraObrigatorios: {
      type: Boolean,
      default: false,
    },
    objetoContainer: {
      type: Object,
      default: () => ({}),
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    ignoraDesabilitados: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputId() {
      return `MetadadosCampoDinamico-${this.metadados.labelCampo}`;
    },
    campoObrigatorio() {
      return !this.ignoraObrigatorios && this.metadados.vlrObrigatorio;
    },
    mascara() {
      return this.metadados.mascara;
    },
    existeMascara() {
      return this.metadados && this.metadados.mascara && this.metadados.mascara.length;
    },
    inputLabel() {
      let inputLabel;
      if (this.metadados.nomCampo) {
        inputLabel = this.metadados.nomCampo;
      } else {
        inputLabel = this.$tc(`label.${this.metadados.labelCampo}`, 1);
      }
      return inputLabel + (this.campoObrigatorio ? ' *' : '');
    },
    campoDesabilitado() {
      if (this.somenteLeitura) {
        return true;
      }

      if (this.ignoraDesabilitados) {
        return false;
      }

      const { desAtributos } = this.metadados;
      if (!desAtributos) {
        return false;
      }

      let desabilitado = desAtributos ? desAtributos.desabilitado : false;
      desabilitado = desabilitado
        || this.desabilitadoPorDependencia;

      return desabilitado;
    },
    desabilitadoPorDependencia() {
      const { desAtributos } = this.metadados;
      if (!desAtributos) {
        return false;
      }
      const { dependencias } = desAtributos;
      if (!dependencias || !Array.isArray(dependencias)) {
        return false;
      }

      const falhas = dependencias.filter((dependencia) => {
        const vlrDependencia = this.objetoContainer[dependencia];
        return !vlrDependencia || vlrDependencia === ''
          || Object.keys(vlrDependencia).length === 0;
      });

      return falhas.length > 0;
    },
    regrasValidacao() {
      const { desAtributos } = this.metadados;
      if (!desAtributos) {
        return [];
      }

      const { regrasValidacao } = desAtributos;
      if (!regrasValidacao || !Array.isArray(regrasValidacao)) {
        return [];
      }

      return regrasValidacao;
    },
    tipoSelecao() {
      const { tipoSelecao } = this.metadados;
      if (!tipoSelecao) {
        return 'date';
      }
      return tipoSelecao;
    },
  },
  methods: {
    emiteValorAlterado(valor) {
      this.$emit('MetadadosCampoDinamico_valorAlterado', valor);
      this.$emit('input', valor);
    },
    refresh() {
      this.$forceUpdate();
    },
  },
};
