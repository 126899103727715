<template>
  <v-form v-model="valid" ref="form">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4">
          <v-select
            v-model="filtros.tipo"
            :items="tipos"
            item-text="nome"
            item-value="id"
            return-object
            :rules="[rules.required]"
            @change="buscarTarefas"
            required
            :label="$t('label.tipo_de_fluxo')">
          </v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="filtros.passo"
            :items="passos"
            item-text="descricao"
            item-value="descricao"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :rules="[rules.required]"
            return-object
            @change="preencherFiltroCliente"
            required
            :label="$t('label.tarefa_aprovacao')">
          </v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-if="passoCliente"
            v-model="filtros.aprovador"
            clearable
            :items="aprovadores"
            item-text="aprovador"
            item-value="id"
            :rules="[rules.required]"
            return-object
            required
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :label="$t('label.usuario_aprovador')">
          </v-select>
          <v-select
            v-if="!passoCliente"
            v-model="filtros.aprovador"
            clearable
            :items="aprovadores"
            return-object
            item-text="aprovador"
            item-value="id"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :label="$t('label.usuario_aprovador')">
          </v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="filtros.solicitante"
            clearable
            :items="solicitantes"
            item-text="solicitante"
            return-object
            item-value="id"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :label="$t('label.solicitante')">
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="limparFiltros">{{ $t('label.limpar_filtros') }}</v-btn>
        <v-btn text color="accent" @click="filtrar">{{ $t('label.aplicar') }}</v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'FiltrosGestaoAprovacao',
  data() {
    return {
      resourceFluxo: this.$api.fluxoAprovacao(this.$resource),
      resourceFluxoExecucao: this.$api.workflowPassos(this.$resource),
      tipos: [],
      passos: [],
      solicitantes: [],
      aprovadores: [],
      filtros: {
        tipo: null,
        solicitante: null,
        aprovador: null,
      },
      valid: false,
      passoCliente: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    requestTipos() {
      this.resourceFluxo.buscarTipos()
        .then((response) => {
          this.tipos = response.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarTarefas(tipo) {
      this.limparPassos();

      if (!tipo) {
        this.passos = [];
        return;
      }

      this.resourceFluxo.buscarDescricoes({ idTipo: tipo.id, status: 'AGUARDANDO_APROVACAO' })
        .then((response) => {
          this.passos = response.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    preencherFiltroCliente(hit) {
      this.limparSolicitante();
      this.limparAprovadores();
      if (!hit) {
        this.limparPassos();
        return;
      }
      this.passoCliente = hit.passocliente;

      this.buscarSolicitante({
        idTipo: this.filtros.tipo.id,
        passo: hit.descricao,
      });

      this.buscarAprovadores({
        idTipo: this.filtros.tipo.id,
        passo: hit.descricao,
        passoCliente: this.passoCliente,
      });
    },
    buscarAprovadores(parametros) {
      this.resourceFluxoExecucao.aprovadoresPasso(parametros)
        .then((response) => {
          this.aprovadores = response.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarSolicitante(parametros) {
      this.resourceFluxoExecucao.solicitantesPasso(parametros)
        .then((response) => {
          this.solicitantes = response.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    limparAprovadores() {
      this.filtros.aprovador = null;
      this.aprovadores = [];
    },
    limparSolicitante() {
      this.filtros.solicitante = null;
      this.solicitantes = [];
    },
    limparPassos() {
      this.passoCliente = false;
      this.filtros.passo = null;
    },
    filtrar() {
      if (this.$refs.form.validate()) {
        this.$emit('filtrar', this.filtros);
      }
    },
    limparFiltros() {
      this.$refs.form.reset();
    },
  },
  mounted() {
    this.requestTipos();
  },
};
</script>
