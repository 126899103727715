export default {
  data() {
    return {
      notaDebitoResource: this.$api.notasDebitoConsulta(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      magaluRelatoriosResource: this.$api.magaluRelatorios(this.$resource),

      camposFormularioMagalu: {
        padrao: [
          {
            labelCampo: 'status_nd',
            nomCampoId: 'status',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  {
                    texto: this.$t('status_entidade.aguardando_aprovacao'),
                    valor: 'AGUARDANDO_APROVACAO',
                  },
                  {
                    texto: this.$tc('status_entidade.em_cadastro', 1),
                    valor: 'EM_CADASTRO',
                  },
                  {
                    texto: this.$tc('status_entidade.aprovado', 1),
                    valor: 'APROVADO',
                  },
                  {
                    texto: this.$tc('status_entidade.reprovado', 1),
                    valor: 'REPROVADO',
                  },
                  {
                    texto: this.$tc('status_entidade.cancelado', 1),
                    valor: 'CANCELADO',
                  },
                  {
                    texto: this.$tc('status_entidade.em_analise', 1),
                    valor: 'EM_ANALISE',
                  },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'data_inicio_emissao_nd',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim_emissao_nd',

            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'descricao_passo',
            nomCampoId: 'descricao_passo',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => this.workflowAcaoMagaluResource.buscarPassosDescricao({ autocomplete }),
              itemValue: 'descricao',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'id_campanha_ads',
            nomCampoId: 'idCampanhaAds',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'id_campanha_tradelinks',
            nomCampoId: 'idCampanha',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'numer_nd',
            nomCampoId: 'numNotaDebito',
            tipoCampo: 'NUMERICO',
          },
        ],
      },
    };
  },
};
