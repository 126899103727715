const Liquidacao = () => import('../../../spa/liquidacao-contrato/Liquidacao');
const LiquidacaoListNivel1 = () => import('../../../spa/liquidacao-contrato/LiquidacaoListNivel1');
const LiquidacaoListNivel2 = () => import('../../../spa/liquidacao-contrato/LiquidacaoListNivel2');
const PagamentoDetalhes = () => import('../../../spa/liquidacao-contrato/pagamento/PagamentoDetalhes');

export default {
  path: 'pagamento-contrato',
  component: Liquidacao,
  children: [
    {
      path: '',
      name: 'pagamento-contrato',
      component: LiquidacaoListNivel1,
    },
    {
      path: 'contrato/:idContrato',
      name: 'listarPagamentosContratoNivel2Contrato',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'status/:status',
      name: 'listarPagamentosContratoNivel2Status',
      component: LiquidacaoListNivel2,
    },
    {
      path: 'status/:status/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoContratoStatus',
      component: PagamentoDetalhes,
    },
    {
      path: 'contrato/:idContrato/acao/:idAcao/pagamento/:id',
      name: 'detalharPagamentoContratoContrato',
      component: PagamentoDetalhes,
    },
  ],
};
