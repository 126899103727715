<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-card class="card-size">
      <v-card-title>
        {{ ehEdicao ? $t('title.editar') : $t('title.cadastrar') }} {{ $t('label.tipo_uso') }}
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
            id="tipo_uso_tipo_uso"
            name="tipo_uso_tipo_uso"
            v-model="tipoUso.nome"
            :filter="filtroTipoUso"
            :items="listaTiposUso"
            :search-input.sync="buscaListaTiposUso"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :placeholder="$tc('label.digite_para_buscar', 1)"
            :label="`${$tc('label.tipo_uso', 1)} *`"
            @click:append="triggerTipoUso"
            @click.native="clickTipoUso"
            item-text="nome"
            item-value="nome"
            :rules="[rules.requiredSelect]"
            chips
            deletable-chips
            clearable
            return-object>
          </v-autocomplete>
            <v-autocomplete
              id="tipo_uso_tipo_acao"
              name="tipo_uso_tipo_acao"
              v-model="tipoUso.idAcaoTipo"
              :filter="filtroTipoAcao"
              :items="listaTiposAcao"
              :search-input.sync="buscaListaTiposAcao"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :placeholder="$tc('label.digite_para_buscar', 1)"
              @click:append="triggerTipoAcao"
              @click.native="clickTipoAcao"
              item-text="nome"
              item-value="id"
              :rules="[rules.requiredTipoAcao]"
              :label="`${$tc('label.tipo_acao', 1)} *`"
              chips
              deletable-chips
              clearable>
            </v-autocomplete>
            <v-text-field
              id="tipo_uso_cod_origem"
              name="tipo_uso_cod_origem"
              v-model="tipoUso.codOrigem"
              class="Form-text__invalid"
              :label="`${$tc('label.cod_origem', 1)} *`"
              :counter="100"
              maxlength="100"
              auto-grow
              rows="1"
              :rules="[rules.required]">
            </v-text-field>
            <v-text-field
              id="tipo_uso_cod_evento"
              name="tipo_uso_cod_evento"
              v-model="tipoUso.codEvento"
              class="Form-text__invalid"
              :label="`${$tc('label.cod_evento', 1)} *`"
              :counter="100"
              maxlength="100"
              auto-grow
              rows="1"
              :rules="[rules.required]">
            </v-text-field>
            <v-text-field
              id="tipo_uso_texto_juridico"
              name="tipo_uso_texto_juridico"
              v-model="tipoUso.textoJuridico"
              class="Form-text__invalid"
              :label="`${$tc('label.texto_juridico', 1)} *`"
              :counter="300"
              maxlength="300"
              auto-grow
              rows="1"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>
  </v-form>
</template>

<script>
import Confirm from '@/produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'TipoUsoForm',
  data() {
    return {
      tipoAcaoResources: this.$api.tipoAcao(this.$resource),
      tipoUsoResources: this.$api.tipoUso(this.$resource),

      tipoUso: {
        id: null,
        idAcaoTipo: null,
        nome: null,
        codOrigem: null,
        codEvento: null,
        textoJuridico: null,
      },
      listaTiposAcao: [],
      listaTiposUso: [],
      buscaListaTiposAcao: null,
      buscaListaTiposUso: null,

      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredSelect: (value) => (!!value) || this.$t('message.campo_obrigatorio'),
        requiredTipoAcao: () => !!this.tipoUso.idAcaoTipo || this.$t('message.campo_obrigatorio'),
      },
      dialog: {
        content: '',
      },
    };
  },
  watch: {
    buscaListaTiposAcao(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.buscarTiposAcaoAutocomplete(val);
      }, 500);
    },
    buscaListaTiposUso(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.buscarTiposUsoAutocomplete(val);
      }, 500);
    },
  },
  components: {
    Confirm,
  },
  methods: {
    cancel() {
      this.limparFormulario();
    },
    carregarTipoUso(tipoUso) {
      const codigo = tipoUso.idTipoUso;
      return this.tipoUsoResources.buscar({ codigo }, { codigo })
        .then((res) => {
          this.tipoUso = res.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    editar(tipoUso) {
      this.carregarTipoUso(tipoUso)
        .then(() => this.buscarTiposAcaoAutocomplete(null, 0))
        .catch((err) => {
          this.$error(this, err);
        });
    },
    limparFormulario() {
      this.tipoUso = {};
      this.$refs.form.reset();
    },
    filtroTipoAcao(item, queryText, itemText) {
      if (!queryText.trim()) {
        return false;
      }
      const tipoAcaoDescricao = item.descricao;
      const searchText = queryText.toLowerCase();

      return itemText.toLowerCase().indexOf(searchText) > -1
        || tipoAcaoDescricao.toLowerCase().indexOf(searchText) > -1;
    },
    triggerClick(id) {
      document.getElementById(id).click();
    },
    triggerTipoAcao() {
      this.triggerClick('tipo_uso_tipo_acao');
    },
    clickTipoAcao() {
      this.buscarTiposAcaoAutocomplete(this.buscaListaTiposAcao);
    },
    buscarTiposAcaoAutocomplete(busca = null, limiteBusca = 60) {
      const param = {
        autocomplete: busca,
        limit: limiteBusca,
        page: 0,
        cadastroTipoUso: true,
      };

      if (this.tipoUso.id) {
        param.idTipoUso = this.tipoUso.id;
      }

      return this.tipoAcaoResources.selecao(param).then((response) => {
        this.listaTiposAcao = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    filtroTipoUso(item, queryText, itemText) {
      if (!queryText.trim()) {
        return false;
      }
      const tipoUsoNome = item.nome;
      const searchText = queryText.toLowerCase();

      return itemText.toLowerCase().indexOf(searchText) > -1
        || tipoUsoNome.toLowerCase().indexOf(searchText) > -1;
    },
    triggerTipoUso() {
      this.triggerClick('tipo_uso_tipo_uso');
    },
    clickTipoUso() {
      this.buscarTiposUsoAutocomplete(this.buscaListaTiposUso);
    },
    buscarTiposUsoAutocomplete(busca = null, limiteBusca = 60) {
      const param = {
        autocomplete: busca,
        limit: limiteBusca,
        page: 0,
      };

      return this.tipoUsoResources.selecao(param).then((response) => {
        this.listaTiposUso = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.dialog.content = this.$t('message.salvar');
      this.$refs.confirmDialog.open();
    },
    save() {
      let salvarOuAtualizar;
      let inclusao = true;

      this.tipoUso.nome = this.tipoUso.nome.nome
        ? this.tipoUso.nome.nome : this.tipoUso.nome;

      if (this.tipoUso.id != null) {
        inclusao = false;
        salvarOuAtualizar = this.tipoUsoResources.atualizar(this.tipoUso);
      } else {
        salvarOuAtualizar = this.tipoUsoResources.gravar(this.tipoUso);
      }

      salvarOuAtualizar
        .then(() => this.limparFormulario())
        .then(() => this.$toast(
          this.$t(
            inclusao
              ? 'message.adicionado_confira_tabela'
              : 'message.atualizado_confira_tabela',
          ),
        ))
        .then(() => {
          this.$emit('FORMULARIO_CADASTRO_TIPO_USO_SALVAR', this.tipoUso, this.clearForm);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  computed: {
    ehEdicao() {
      return this.tipoUso.id;
    },
  },
  mounted() {
    const promises = [];
    promises.push(this.buscarTiposUsoAutocomplete(null, 0));
    promises.push(this.buscarTiposAcaoAutocomplete(null, 0));
    Promise.all(promises)
      .then()
      .catch((err) => {
        this.$error(this, err);
      });
  },
};
</script>
<style lang="scss">
  .combo_opcoes .v-input__append-inner {
    display: none;
  }
</style>
