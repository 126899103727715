<template>
  <v-dialog v-model="showModal" max-width="800">
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ title }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-select
            id="select_aprovador_cliente"
            name="select_aprovador_cliente"
            item-text="nome"
            item-value="id"
            clearable
            v-model="selecao"
            :rules="[rules.required]"
            :items="passoCliente.usuarios"
            :label="`${$tc('label.usuario', 1)} *`">
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
          <v-btn color="primary darken-1" text @click.native="confirma">{{ $t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  name: 'PlanejamentoAcaoFormUsuarioCliente',
  data() {
    return {
      showModal: false,
      selecao: undefined,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  props: {
    passoCliente: Object,
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    open() {
      this.selecao = undefined;
      this.showModal = true;
    },
    cancela() {
      this.showModal = false;
    },
    confirma() {
      if (!this.$refs.form.validate()) return;
      this.$emit('selecionarUsuarioCliente', this.passoCliente.idPasso, this.selecao);
      this.showModal = false;
    },
  },
  mounted() {
  },
};
</script>
