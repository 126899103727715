import getBasePath from '../../functions/api-resource';
import resourceBuilder from '../../functions/metadados-resource-builder';

const basePath = getBasePath('apuracao_acao', 'contrato');

const apuracaoActions = {
  buscarApuracao: { method: 'GET', url: `${basePath}/listagem/carregamento{/idApuracao}` },
  concluir: { method: 'PUT', url: `${basePath}{/idContrato}/acao/{idAcao}/apuracao{/idApuracao}/concluir` },
  buscarDataApuracaoAcaoPorStatus: { method: 'GET', url: `${basePath}{/idContrato}/status/{status}/datas` },
  buscarApuracaoFinalizar: { method: 'GET', url: `${basePath}/apuracao/{idApuracao}/finalizar` },
  buscarRealizadoDetalhamento: { method: 'GET', url: `${basePath}/realizado-detalhamento/{idApuracao}` },
  buscarNDsGeradas: { method: 'GET', url: `${basePath}/nds-geradas/{idApuracao}` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);

export const listarApuracoesContratoPorStatus = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/por-status', parametros).doGet();

export const listarApuracoesPorContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/por-contrato', parametros).doGet();

export const listarApuracoesContratoNivel2 = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/nivel2', parametros).doGet();

export const listarApuracoesContratoNivel2Simplificado = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/nivel2/simplificado', parametros).doGet();

export const buscarTotalApuradoPorContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'apuracao/total', parametros).doGet();

export const buscarApuracaoFornecedorPorContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'fornecedor/apuracoes', parametros).doGet();

export const buscarApuracaoPorContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'apuracao/finalizar', parametros).doGet();

export const finalizarApuracaoLote = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'finalizar', parametros).doPut(parametros);

export const finalizarApuracaoLoteFornecedor = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'finalizar/fornecedor', parametros).doPut(parametros);

export const finalizarApuracoesIndustriaLote = (parametros, resource, body) => resourceBuilder.buildPUT(resource, basePath, 'finalizar/industria', parametros).doPut(parametros, body);

export const buscarDetalhesApuracaoContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'fornecedor/apuracoes/detalhes', parametros).doGet();

export const salvarApuracaoDataContabilizacao = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'salvar-acao-data-contabilizacao', parametros).doPut(parametros);

export const salvarApuracaoInformacoesComplementares = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'salvar-apuracao-informacoes-complementares', parametros).doPut(parametros);

export const buscarTotalizadoresPeriodoApuracao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'totalizadores/periodo-apuracao', parametros).doGet();

export const buscarRealizadoDetalhamentoCategoriaAgrupada = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'realizado-detalhamento-categoria-agrupada', parametros).doGet();

export const buscarFamiliasSelecionadas = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar-familias-selecionadas', parametros).doGet();

export const buscarFornecedorPagador = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'fornecedor/pagador', parametros).doGet();

export const salvarApuracaoInformacoesNotaDebito = (parametros, resource) => resourceBuilder.buildPOST(resource, basePath, 'salvar-apuracao-informacoes-nota-debito', parametros).doPost(parametros);

export const buscarAcaoExternaFornecedor = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar-acao-externa', parametros).doGet();

export const salvarAcaoExternaFornecedor = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'salvar-acao-externa', parametros).doPut(parametros);
