import errorMessage from '../common/functions/message';
import {
  showLoading,
  hideLoading,
} from '../common/functions/loading';

const warnPropRewrite = (msg) => {
  if (process && process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    console.warn(`[interceptors.js - Response REWRITE] ${msg}`); // eslint-disable-line
  }
};

export default (Vue) => {
  Vue.http.interceptors.push((request, next) => {
    showLoading();
    next((response) => {
      if (response && response.body !== null && response.body !== undefined) {
        hideLoading();
      }
      return response;
    });
  });
  Vue.http.interceptors.push((request, next) => {
    let controlador = window.sessionStorage.getItem('controladorTrade');

    if (controlador) {
      controlador = JSON.parse(controlador);
      if (controlador.idFuncionarioTitular) {
        request.headers.append('idFuncionario', controlador.idFuncionarioTitular.toString());
      }
      if (controlador.idDivisaoTitular) {
        request.headers.append('idDivisao', controlador.idDivisaoTitular.toString());
      }
    }
    next();
  });
  Vue.http.interceptors.push((request, next) => {
    request.headers.append('x-tenant-id', window.sessionStorage.getItem('xTenantId'));
    next();
  });
  Vue.http.interceptors.push((request, next) => {
    next((response) => {
      if (response.status >= 400) {
        response.data = {
          error: errorMessage(response.data, Vue.config.lang),
        };
        warnPropRewrite('Alterando a resposta quando existe erro. Mensagem vai sumir em producao');
      }
      return response;
    });
  });
};
