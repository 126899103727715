const mutations = {
  addToast(state, toast) {
    state.toasts.push({
      position: state.toasts.length,
      toast,
    });
  },
  removeToast(state, position) {
    state.toasts.splice(state.toasts.findIndex((e) => e.position === position), 1);
  },
};

const actions = {
  addToast({ commit }, toast) {
    commit('addToast', toast);
  },
  removeToast({ commit }, position) {
    commit('removeToast', position);
  },
};

const getters = {
  getToast(state) {
    return state.toasts[0];
  },
};

const state = {
  toasts: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
