import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'parametrizacao-contrato');

const parametrizacaoContratoActions = {
  ...genericActions(basePath),
  buscar: { method: 'GET', url: `${basePath}` },
  obterPasso1: { method: 'GET', url: `${basePath}/{id}/passo1` },
  obterPasso2: { method: 'GET', url: `${basePath}/{id}/passo2` },
  obterPasso3: { method: 'GET', url: `${basePath}/{id}/passo3` },
  obterPasso4: { method: 'GET', url: `${basePath}/{id}/passo4` },
  obterPasso5: { method: 'GET', url: `${basePath}/{id}/passo5` },
  obterPasso6: { method: 'GET', url: `${basePath}/{id}/passo6` },
  obterTiposAcaoDoContrato: { method: 'GET', url: `${basePath}/{idContrato}/tipos-acao` },
  listarEntidadeProduto: { method: 'GET', url: `${basePath}/entidade-produto{?labels*}` },
  buscarVinculosCarteira: { method: 'GET', url: `${basePath}/passo2/carteira` },
};

export default (resource) => resource(`${basePath}`, {}, parametrizacaoContratoActions);
