<template>
  <div>
    <v-row>
      <v-col cols="12" sm="3" class="pt-0">
        <metadados-extensao-form
          ref="NivelFornecedorForm"
          :id="id"
          :somenteLeitura="somenteLeitura"
          :id-nivel-extensao="objeto.idNivelExtensao"
          :ids-cnpjs-matriz="objeto.idsCnpjsMatriz"
          :tipoCadastro="'grupo_fornecedor'"
          @atualizarGrid="atualizarGrid"
          @limparId="limparId">
          <template slot="contentPosForm">
            <v-col cols="12">
              <v-select
                class="Form-text__invalid"
                id="tipo"
                name="tipo"
                clearable
                item-text="desNivel"
                item-value="id"
                :items="extensoes"
                :disabled="somenteLeitura"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :rules="[rules.required]"
                :label="`${$tc('label.tipo', 1)} *`"
                v-model="objeto.idNivelExtensao">
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                id="cnpj_matriz"
                name="cnpj_matriz"
                v-model="objeto.idsCnpjsMatriz"
                :filter="filtroCnpj"
                :items="listaCnpj"
                :search-input.sync="buscaListaCnpj"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :placeholder="$tc('label.digite_para_buscar', 1)"
                :label="$tc('label.fornecedor_pagador', 1)"
                @click:append="triggerCnpj"
                @click.native="clickCnpj"
                item-text="descricao"
                item-value="id"
                chips
                deletable-chips
                clearable
                multiple>
              </v-autocomplete>
            </v-col>
          </template>
        </metadados-extensao-form>
      </v-col>
      <v-col cols="12" sm="9" class="pt-0">
        <nivel-fornecedor-tabela
          ref="NivelFornecedorTabela"
          id="nivel-fornecedor-tabela"
          @edit="editarCampo"
          :somenteLeitura="somenteLeitura"
          :niveisExtensoes="niveisExtensoes"
          class="NivelFornecedor__ContentTable"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { skipLoading } from '../../common/functions/loading';
import { copyObject } from '../../common/functions/helpers';
import MetadadosExtensaoForm from '../../shared-components/metadadosExtensao/MetadadosExtensaoForm';
import NivelFornecedorTabela from './NivelFornecedorTabela';

export default {
  name: 'NivelFornecedor',
  watch: {
    buscaListaCnpj(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.carregarFornecedores(val);
      }, 500);
    },
  },
  data() {
    return {
      extensaoResources: this.$api.extensao(this.$resource),
      extensoes: [],
      id: null,
      idNivelExtensao: null,
      niveisExtensoes: [],
      objeto: {
        idNivelExtensao: null,
        idsCnpjsMatriz: null,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      fornecedorResources: this.$api.fornecedor(this.$resource),
      listaCnpj: [],
      buscaListaCnpj: null,
    };
  },
  components: {
    MetadadosExtensaoForm,
    NivelFornecedorTabela,
  },
  computed: {
    ...generateComputed('nivel_fornecedor', [
      'canEdit',
      'canGet',
      'canAccessPage',
    ]),
    somenteLeitura() {
      return !this.canEdit && this.canGet;
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    atualizarGrid() {
      this.$refs.NivelFornecedorTabela.filtrar();
    },
    buscarNiveisExtensaoFornecedor() {
      const params = {
        entidade: 'FORNECEDOR',
      };
      return this.extensaoResources.listarNiveisExtensaoEntidade(params)
        .then((resultado) => {
          this.niveisExtensoes = resultado.data;
          this.buscarExtensoesRelacionadasAoFornecedor();
        });
    },
    buscarExtensoesRelacionadasAoFornecedor() {
      const params = {
        idsNiveisExtensao: this.niveisExtensoes,
      };
      this.extensaoResources.listarNiveisExtensao(params)
        .then((resultado) => {
          this.extensoes = resultado.data;
        });
    },
    editarCampo(item) {
      this.id = item.id;
      this.$refs.NivelFornecedorForm.iniciarEdicao(item);
      this.objeto = copyObject(item);
      this.carregarFornecedores(null, 10000);
    },
    limparId() {
      this.id = null;
    },
    carregarFornecedores(busca = null, limiteBusca = 50) {
      const parametros = {
        filtro: busca,
        numeroPagina: 0,
        indAtivo: true,
        tamanhoPagina: limiteBusca,
      };
      if (busca == null) {
        skipLoading();
      }
      this.fornecedorResources.pesquisar(parametros).then((res) => {
        this.listaCnpj = res.data.resposta;
      });
    },
    triggerCnpj() {
      document.getElementById('cnpj_matriz').click();
    },
    clickCnpj() {
      this.carregarFornecedores(this.buscaListaCnpj);
    },
    filtroCnpj(item, queryText, itemText) {
      if (!queryText.trim()) {
        return false;
      }
      const tipoUsoNome = item.desNomeFantasia;
      const cnpj = item.desCnpj;
      const searchText = queryText.toLowerCase();

      return itemText.toLowerCase().indexOf(searchText) > -1
        || tipoUsoNome.toLowerCase().indexOf(searchText) > -1
        || cnpj.replace(/[./-]/g, '').indexOf(searchText.replace(/[./-]/g, '')) > -1;
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
    this.buscarNiveisExtensaoFornecedor();
  },
};
</script>
<style>
  .NivelFornecedor__ContentTable {
    width: 100% !important;
  }
</style>
