<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :max-width="350"
    left
    offset-x>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" dark color="primary" v-if="$vuetify.breakpoint.mdAndUp" class="elevation-0">
        <b>{{usuario}}</b>
        <v-icon right dark>keyboard_arrow_down</v-icon>
      </v-btn>
      <v-btn v-on="on" text dark icon v-else>
        <v-icon>account_box</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list class="py-0">
        <v-list-item>
          <v-list-item-avatar color="accent" class="white--text headline">
            {{ usuario.substring(0,1).toUpperCase() }}
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title><span v-if="estruturaUsuario">{{estruturaUsuario}} - </span>{{usuario}}</v-list-item-title>
            <v-list-item-subtitle>{{mail}}</v-list-item-subtitle>
            <v-list-item-subtitle><span class='text--primary'>{{$tc('label.perfil', 1)}}: </span>{{perfil}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list v-if="exibirControladores" class="py-0">
          <template v-if="exibeTooltipControladores">
            <v-row dense class="ml-2 mt-2 mb-2">
              {{ `${$tc('label.divisao', 2)} :` }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="ml-2">info</v-icon>
                </template>
                <template v-slot:default>
                  <ul>
                    <li v-for="controlador in controladores" :key="controlador.id">{{ controlador.descricao }}</li>
                  </ul>
                </template>
              </v-tooltip>
            </v-row>
          </template>
          <template v-else>
            <v-list-item
              v-for="(item, index) in controladores"
              :key="index">
              <v-list-item-title class="grey--text text--darken-1">{{ item.descricao }}</v-list-item-title>
            </v-list-item>
          </template>
      </v-list>

      <v-divider v-if="exibirControladores"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="logout">
          <v-icon dark>power_settings_new</v-icon>
          <span>{{$tc('label.logout', 2)}}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UsuarioToolbar',
  props: {
    usuario: String,
    mail: String,
    estruturaUsuario: String,
    perfil: String,
  },
  data: () => ({
    menu: false,
    message: false,
    hints: true,
    exibeTooltipControladores: false,
  }),
  computed: {
    ...mapGetters('controlador', [
      'getControladores',
      'getControladoreSelecionado',
    ]),
    controladores() {
      return this.getControladores || [];
    },
    temControladores() {
      return !!this.controladores.length;
    },
    exibirControladores() {
      if (this.controladores && this.controladores.length > 5) {
        this.exibeTooltipControladores = true;
      }

      return this.temControladores
        && (this.controladores.length > 1
          || (this.controladores.length === 1 && !!this.controladores[0].idDivisaoTitular));
    },
  },
  methods: {
    selecionar(item) {
      this.menu = false;
      this.$emit('UsuarioToolBar_selecionar', item);
    },
    logout() {
      this.menu = false;
      const auth = this.$auth || window.auth;
      window.sessionStorage.clear();
      window.localStorage.removeItem('xTenantId');
      const redirectUri = window.location.origin;
      auth.logout({ redirectUri });
    },
  },
};
</script>

<style scoped>

</style>
