<template lang="html">
  <v-card :elevation="elevation" :color="color">
    <slot name="header" v-if="showHeader">
      <v-card-title>{{title}}</v-card-title>
    </slot>
    <v-card-text>
      <span>{{subtitle}}</span>
      <slot name="content"></slot>
    </v-card-text>
    <slot name="button" v-if="showButton">
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="cancel" @click.native="cancelAction">{{ cancelLabel }}</v-btn>
        <v-btn v-if="save" @click.native="saveAction" color="primary">{{ saveLabel }}</v-btn>
      </v-card-actions>
    </slot>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    elevation: {
      type: String,
      default: '0',
    },
    color: {
      type: String,
      default: undefined,
    },
    subtitle: {
      type: String,
      default() { return this.$t('label.preencha_campos_abaixo'); },
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    save: {
      type: Function,
      default() { return () => ({}); },
    },
    cancel: {
      type: Function,
      default() { return () => ({}); },
    },
    cancelLabel: {
      type: String,
      default() {
        return this.$t('label.cancelar');
      },
    },
    saveLabel: {
      type: String,
      default() {
        return this.$t('label.salvar');
      },
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    cancelAction() {
      if (this.cancel) {
        this.cancel();
      }
      this.$emit('REGISTERFORM_CANCEL_ACTION');
    },
    saveAction() {
      if (this.save) {
        this.save();
      }
      this.$emit('REGISTERFORM_SAVE_ACTION');
    },
  },
};
</script>

<style src="./RegisterForm.css" lang="css" scoped></style>
