<template lang="html">
  <v-sheet
      rounded
      class="mx-3 my-n2 text-center py-2"
      elevation="1"
      :color="corStatus"
      dark
      height="30"
      :width="calcularLargura">
    <span>{{ $tc(`status_entidade.${status.toLowerCase()}`, 1) }}</span>
  </v-sheet>
</template>

<script>
export default {
  name: 'StatusDescricao',
  props: {
    color: String,
    colorPalette: {
      type: Array,
      default() {
        return [
          '#1abc9c', // Verde
          '#e74c3c', // Vermelho
          '#e67e22', // Laranja
          '#f1c40f', // Amarelo
          '#95a5a6', // Cinza
          '#1E88E5', // Azul
          '#7e57c2', // Roxo
        ];
      },
    },
    status: {
      type: String,
      default: 'EM_CADASTRO',
    },
    larguraAutomatica: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    defaultWidth: 200,
  }),
  computed: {
    corStatus() {
      let colorIndex = 0;
      if (this.status === 'ENCERRADO') {
        colorIndex = 6;
      } else if (this.status === 'EM_CADASTRO') {
        colorIndex = 4;
      } else if (this.status === 'CANCELADO') {
        colorIndex = 6;
      } else if (this.status === 'AGUARDANDO_APROVACAO') {
        colorIndex = 2;
      } else if (this.status === 'REPROVADO') {
        colorIndex = 1;
      } else if (this.status === 'AGUARDANDO_EXECUCAO_METAS') {
        colorIndex = 6;
      } else if (this.status === 'AGUARDANDO_APURACAO') {
        colorIndex = 5;
      } else if (this.status === 'APURACAO_PREVIA') {
        colorIndex = 4;
      } else if (this.status === 'EM_ANALISE') {
        colorIndex = 3;
      } else if (this.status === 'EM_REIVINDICACAO') {
        colorIndex = 2;
      } else if (this.status === 'CONCLUIDO') {
        colorIndex = 5;
      } else {
        colorIndex = 0;
      }
      return this.color || this.colorPalette[colorIndex];
    },
    calcularLargura() {
      if (this.larguraAutomatica) {
        const statusLength = this.status.length;
        return (100 + (statusLength * 5)).toString();
      }
      return this.defaultWidth;
    },
  },
};
</script>
