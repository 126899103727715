import getBasePath from '../functions/api-resource';

const basePath = getBasePath('usuario', 'funcionario');

const funcionarioActions = {
  hierarquia: { method: 'GET', url: `${basePath}/hierarquia` },
  hierarquiaFilha: { method: 'GET', url: `${basePath}/hierarquia-filha` },
  autocomplete: { method: 'GET', url: `${basePath}/autocomplete` },
  selecao: { method: 'GET', url: `${basePath}/selecao` },
};

export default (resource) => resource(`${basePath}`, {}, funcionarioActions);
