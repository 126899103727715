<template lang="html">
  <div>
    <v-container fluid>
      <v-row justify="end" class="pb-2">
        <tooltip-ajuda :color="'black'" :mensagem="`${$t('message.ajuda.parametrizacao_orcamento.grid_filtros')}`" />
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pr-2">
          <v-card-title primary-title class="card-header">
            {{ $tc('label.resumo', 1) }}
          </v-card-title>
          <v-data-table
            id="config_orcamento_tabela_lista_outros"
            :headers="headersResumo"
            :items="parametrizacao.listaResumo"
            disable-pagination
            :items-per-page="-1"
            hide-default-footer
            :no-data-text="$t('message.nenhum_registro')">
            <template v-slot:item.habilitaGrid="{ item }">
              <v-switch
                  color="primary"
                  id="config_orcamento_passo5_grid"
                  :disabled="item.desativaGrid"
                  :label="`${$t('label.grid')}`"
                  v-model="item.habilitaGrid" hide-details/>
            </template>
            <template v-slot:item.habilitaFiltro="{ item }">
              <v-switch
                  color="primary"
                  id="config_orcamento_passo5_filtro"
                  :disabled="item.desativaFiltro"
                  :label="`${$tc('label.filtro', 1)}`"
                  v-model="item.habilitaFiltro" hide-details/>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" sm="6" class="pl-2">
          <v-card-title primary-title class="card-header">
            {{ $tc('label.linha', 1) }}
          </v-card-title>
          <v-data-table
            id="config_orcamento_tabela_lista_outros"
            :headers="headersLinha"
            :items="parametrizacao.listaLinha"
            hide-default-footer
            disable-pagination
            :items-per-page="-1"
            :no-data-text="$t('message.nenhum_registro')">
            <template v-slot:item.habilitaFiltro="{ item }">
              <v-switch
                color="primary"
                id="config_orcamento_passo5_filtro_linha"
                :disabled="item.desativaFiltro"
                :label="`${$tc('label.filtro', 1)}`"
                v-model="item.habilitaFiltro" hide-details/>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row justify="end" class="mt-4">
        <v-btn id="config_orcamento_passo5_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn id="config_orcamento_passo5_botao_voltar" color="primary" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn id="config_orcamento_passo5_botao_proximo" color="error" @click="concluir" class="mr-3">{{ $t('label.concluir') }}</v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script type="text/javascript">
import Passo5 from './Passo5';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoOrcamentoPasso5',
  data() {
    return {
      passo: 5,
      headersResumo: [
        { text: this.$tc('label.campo', 1), width: '30%', value: 'nome' },
        { text: this.$tc('label.grid', 1), width: '30%', value: 'habilitaGrid' },
        { text: this.$tc('label.filtro', 1), width: '30%', value: 'habilitaFiltro' },
      ],
      headersLinha: [
        { text: this.$tc('label.campo', 1), width: '30%', value: 'nome' },
        { text: this.$tc('label.filtro', 1), width: '30%', value: 'habilitaFiltro' },
      ],
      listaLinha: [],
      listaResumo: [],
      paramOrcamentoResources: this.$api.parametrizacaoOrcamento(this.$resource),
      parametrizacao: new Passo5(),
    };
  },
  props: {
    id: Number,
  },
  components: {
    TooltipAjuda,
  },
  watch: {
    id() {
      this.prepararPasso();
    },
  },
  methods: {
    cancelar() {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_CANCELAR');
    },
    concluir() {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_CONCLUIR', this.passo, this.parametrizacao);
    },
    definirLista(listaOriginal, listaModificada) {
      listaModificada.forEach((itemListaNova) => {
        listaOriginal.forEach((item) => {
          if (item.label === itemListaNova.label) {
            item.nome = itemListaNova.nome;
            item.habilitaFiltro = item.habilitaFiltro || itemListaNova.habilita_filtro
              ? (item.habilitaFiltro || itemListaNova.habilita_filtro) : false;
            item.habilitaGrid = item.habilitaGrid || itemListaNova.habilita_grid
              ? (item.habilitaGrid || itemListaNova.habilita_grid) : false;
            item.desativaFiltro = itemListaNova.desativa_filtro
              ? itemListaNova.desativa_filtro : false;
            item.desativaGrid = itemListaNova.desativa_grid
              ? itemListaNova.desativa_grid : false;
            item.tipoCampo = itemListaNova.tipoCampo;

            if (this.isDataHora(item) || this.isNumerico(item) || this.isTexto(item)) {
              item.habilitaFiltro = false;
              item.desativaFiltro = true;
            }
          }
        });
      });
    },
    isDataHora(item) {
      return item.tipoCampo && (item.tipoCampo === 'DATA' || item.tipoCampo === 'HORA');
    },
    isNumerico(item) {
      return item.tipoCampo && (item.tipoCampo === 'NUMERICO' || item.tipoCampo === 'PERCENTUAL');
    },
    isTexto(item) {
      return item.tipoCampo && item.tipoCampo === 'TEXTO';
    },
    obterValoresDoBanco(objetoBanco, lista) {
      objetoBanco.forEach((itemBanco) => {
        lista.forEach((item) => {
          if (item.label === itemBanco.label) {
            item.habilitaFiltro = item.habilitaFiltro
              ? item.habilitaFiltro : itemBanco.habilitaFiltro;
            item.habilitaGrid = item.habilitaGrid ? item.habilitaGrid : itemBanco.habilitaGrid;
          }
        });
      });
      return lista;
    },
    prepararPasso() {
      if (this.id) {
        this.parametrizacao.id = this.id;
        const params = { id: this.id, contexto: 'RESUMO' };
        this.paramOrcamentoResources.obterPasso5(params)
          .then((response) => {
            this.parametrizacao.listaResumo = response.data;
            this.listaResumo = Object.assign([], response.data);
          }, (err) => {
            this.$error(this, err);
          });

        params.contexto = 'LINHA';
        this.paramOrcamentoResources.obterPasso5(params)
          .then((response) => {
            this.parametrizacao.listaLinha = response.data;
            this.listaLinha = Object.assign([], response.data);
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    setLista(nomeLista, lista) {
      if (!lista) return;
      this.parametrizacao[nomeLista] = Object.assign([], lista);
      this.parametrizacao[nomeLista] = this.obterValoresDoBanco(
        this[nomeLista], this.parametrizacao[nomeLista],
      );
      this.definirLista(this.parametrizacao[nomeLista], lista);
      this.$forceUpdate();
    },
    setListaLinha(listaLinha) {
      this.setLista('listaLinha', listaLinha);
    },
    setListaResumo(listaResumo) {
      this.setLista('listaResumo', listaResumo);
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_ORCAMENTO_VOLTAR', this.passo - 1);
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .card-header {
      background-color: var(--v-primary-base);
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
