export default class Passo6 {
  constructor(id, nomeTipoVerba, indLiquidacaoParcial = false,
    indIntegracaoEnvio = false, indIntegracaoRetorno = false, criacaoPagamento,
    indStatusCriacaoLiquidado = false, camposDinamicosPagamentoManual = []) {
    this.id = id;
    this.nomeTipoVerba = nomeTipoVerba;
    this.indLiquidacaoParcial = indLiquidacaoParcial;
    this.indIntegracaoEnvio = indIntegracaoEnvio;
    this.indIntegracaoRetorno = indIntegracaoRetorno;
    this.criacaoPagamento = criacaoPagamento;
    this.camposDinamicosPagamentoManual = camposDinamicosPagamentoManual;
    this.indStatusCriacaoLiquidado = indStatusCriacaoLiquidado;
  }
}
