import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('api', 'cliente/{idCliente}/carteira-implicita');

const carteiraImplicitaActions = {
  pesquisar: { method: 'GET', url: `${basePath}` },
  inserir: { method: 'POST', url: `${basePath}` },
  ativarInativar: { method: 'PUT', url: `${basePath}` },
  alterarLiderConta: { method: 'PUT', url: `${basePath}/alterar-lider-conta` },
};

export default (resource) => resource(`${basePath}`, {}, carteiraImplicitaActions);
