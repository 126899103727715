import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'contrato/configuracao');

const configuracaoActions = {
  buscarCamposFiltro: { methods: 'GET', url: `${basePath}/campos/filtro` },
  buscarCamposGrid: { methods: 'GET', url: `${basePath}/campos/grid/{idTipoContrato}` },
  buscarConfigVigente: { methods: 'GET', url: `${basePath}/vigente/{idTipoContrato}` },
  buscarConfigSnapshot: { methods: 'GET', url: `${basePath}/snapshot/{idContrato}` },
  buscarHoldingsCliente: { methods: 'GET', url: `${basePath}/campos/cliente/holdings` },
  buscarLabelCampoCliente: { methods: 'GET', url: `${basePath}/campo-cliente/{idTipoContrato}` },
  buscarLabelsCampoCliente: { methods: 'GET', url: `${basePath}/campo-cliente/labels` },
  buscarUltimoNivelExtensao: { methods: 'GET', url: `${basePath}/ultimo-nivel/{mnemonico}` },
};

export default (resource) => resource(`${basePath}`, {}, configuracaoActions);
