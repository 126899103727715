import ParametrizacaoContrato from '../../spa/parametrizacao/contrato/ParametrizacaoContrato';
import ParametrizacaoContratoList from '../../spa/parametrizacao/contrato/ParametrizacaoContratoList';
import ParametrizacaoContratoForm from '../../spa/parametrizacao/contrato/ParametrizacaoContratoForm';

export default {
  path: 'parametrizacao-contrato',
  component: ParametrizacaoContrato,
  children: [
    {
      path: '',
      name: 'parametrizacao-contrato',
      component: ParametrizacaoContratoList,
    },
    {
      path: 'novo',
      name: 'novoParametrizacaoContrato',
      component: ParametrizacaoContratoForm,
    },
    {
      path: ':id/editar',
      name: 'editarParametrizacaoContrato',
      component: ParametrizacaoContratoForm,
    },
    {
      path: '#/copiar',
      name: 'copiarParametrizacaoContrato',
      component: ParametrizacaoContratoForm,
    },
  ],
};
