import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'concorrente');

const concorrenteActions = {
  ...genericActions(basePath),
};

export default (resource) => resource(`${basePath}`, {}, concorrenteActions);
