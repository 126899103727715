export default class Regiao {
  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.nomRegiao = data.nomRegiao;
      this.indAtivo = data.indAtivo;
      this.codRegiao = data.codRegiao;
    } else {
      this.indAtivo = true;
    }
  }

  construirAPartirDaConsultaCompleta(dadosDaConsulta) {
    this.id = dadosDaConsulta.id;
    this.nomRegiao = dadosDaConsulta.nomRegiao;
    this.indAtivo = dadosDaConsulta.indAtivo;
    this.codRegiao = dadosDaConsulta.codRegiao;
  }

  ehEdicao() {
    return this.id;
  }
}
