import trigger from './trigger';

export default {
  update(el) {
    const compare = el.value.replace(/[^0-9.,]/gi, '');

    if (el.value !== compare) {
      el.value = compare;
      trigger(el, 'input');
    }
  },
};
