<template lang="html">
  <v-dialog v-model="showModal" max-width="600">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ this.$t('label.redirecionamento_fluxo') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row v-for="(item, index) in fluxo.passosFluxo"
          v-if="exibePasso(item)" :key="index">
          <div v-if="item.usuarioAprovador">
            <v-col cols="12" sm="12" :key="item.usuarioAprovador.id">
              {{`${$tc('label.passos', 1)} ${index + 1} / ${fluxo.passosFluxo.length}`}} <br />
              {{$t('label.avaliado_por') + ' ' + item.usuarioAprovador.nome}}
              <v-switch
                v-model="item.indAtivo"
                color="primary"
                :label="$t('label.ativo')"
                :disabled="true"></v-switch>
            </v-col>
          </div>
          <div v-if="item.usuariosAprovadores && item.usuariosAprovadores.usuarios">
            <v-col cols="12" sm="12"
              v-for="(usuario, uIndex) in item.usuariosAprovadores.usuarios"
              :key="usuario.id">
              {{`${$tc('label.passos', 1)} ${index + 1} / ${fluxo.passosFluxo.length}`}} <br />
              {{`${$tc('label.usuario', 1)} ${$t('label.aprovador')}:`}}
              <span v-if="transferido(item) && item.idCondicional === 2">{{ item.usuarioTransferenciaPasso.usuarioCliente }}</span>
              <span v-else>{{ usuario.nome }}</span>
              <v-switch
                v-model="item.indAtivo"
                color="primary"
                :label="$t('label.ativo')"></v-switch>
            </v-col>
          </div>
          <div v-if="item.usuariosAprovadores && item.usuariosAprovadores.grupos">
            <v-col cols="12" sm="12"
              v-for="(grupo, uIndex) in item.usuariosAprovadores.grupos"
              :key="grupo.id">
              {{`${$tc('label.passos', 1)} ${index + 1} / ${fluxo.passosFluxo.length}`}} <br />
              {{`${$tc('label.grupo', 1)} ${$t('label.aprovador')}:`}} {{ grupo.nome }}
              <v-switch
                v-model="item.indAtivo"
                color="primary"
                :label="$t('label.ativo')"></v-switch>
            </v-col>
          </div>
          <div v-if="item.usuariosAprovadores && item.usuariosAprovadores.subgrupos">
            <v-col cols="12" sm="12"
              v-for="(subgrupo, uIndex) in item.usuariosAprovadores.subgrupos"
              :key="subgrupo.id">
              {{`${$tc('label.passos', 1)} ${index + 1} / ${fluxo.passosFluxo.length}`}} <br />
              {{`${$tc('label.subgrupo', 1)} ${$t('label.aprovador')}:`}} {{ subgrupo.nome }}
              <v-switch
                v-model="item.indAtivo"
                color="primary"
                :label="$t('label.ativo')"></v-switch>
            </v-col>
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" @click.native="fechar">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" @click.native="salvar">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  data() {
    return {
      showModal: false,
      fluxo: {},
      idApuracao: null,
    };
  },
  props: {
  },
  components: {
  },
  computed: {
  },
  methods: {
    open() {
      this.fluxo.passosFluxo.forEach((passo) => {
        if (passo.statusPasso !== 'CONDICAO_NAO_ATENDIDA') {
          passo.indAtivo = true;
        }
      });
      this.showModal = true;
    },
    salvar() {
      const dados = {
        fluxo: this.fluxo,
        idApuracao: this.idApuracao,
      };
      this.$emit('INICIAR_FLUXO', dados);
    },
    fechar() {
      this.showModal = false;
    },
    toDate(data) {
      return this.moment(data, 'DD/MM/YYYY hh:mm:ss').format('DD-MM-YYYY HH:mm:ss');
    },
    exibePasso(passo) {
      return passo.statusPasso !== 'CONDICAO_NAO_ATENDIDA' && passo.statusPasso !== 'TRANSFERIDO';
    },
    transferido(passo) {
      return passo.statusPasso !== 'TRANSFERIDO';
    },
  },
  mounted() {
  },
};
</script>
