import getBasePath from '../functions/api-resource';

const basePath = getBasePath('usuario', 'usuario');

const redefinicaoEmailUsuarioActions = {
  listar: { method: 'GET', url: `${basePath}/simples?tamanhoPagina={size}&numeroPagina={page}` },
  filtrar: { method: 'GET', url: `${basePath}/simples?tamanhoPagina={size}&numeroPagina={page}&nome={nome}` },
  salvar: { method: 'POST', url: `${basePath}/email/redefinicao` },
};

export default (resource) => resource(`${basePath}`, {}, redefinicaoEmailUsuarioActions);
