export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      usuarioResources: this.$api.usuario(this.$resource),

      camposFormularioMagalu: {
        padrao: [
          {
            labelCampo: 'cod_acao',
            nomCampoId: 'cod_acao',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_contrato',
            nomCampoId: 'cod_contrato',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_fornecedor_acao',
            nomCampoId: 'cod_fornecedor_acao',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'nota_debito',
            nomCampoId: 'num_nota_debito',
            tipoCampo: 'TEXTO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'nome_usuario',
            nomCampoId: 'id_usuario',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.usuarioResources.listarAtivos(
                {
                  nome: autocomplete,
                  tamanhoPagina: 50000,
                  numeroPagina: 1,
                  indAtivo: 'true',
                },
              ),
              itemValue: 'id',
              itemText: 'nomUsuario',
            },
          },
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
        ],
      },
      camposFormularioKabum: {
        padrao: [
          {
            labelCampo: 'cod_acao',
            nomCampoId: 'cod_acao',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_contrato',
            nomCampoId: 'cod_contrato',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_fornecedor_acao',
            nomCampoId: 'cod_fornecedor_acao',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'nota_debito',
            nomCampoId: 'num_nota_debito',
            tipoCampo: 'TEXTO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'nome_usuario',
            nomCampoId: 'id_usuario',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.usuarioResources.listarAtivos(
                {
                  nome: autocomplete,
                  tamanhoPagina: 50000,
                  numeroPagina: 1,
                  indAtivo: 'true',
                },
              ),
              itemValue: 'id',
              itemText: 'nomUsuario',
            },
          },
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
        ],
      },
    };
  },
};
