import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('usuario', 'sso/usuario');

const usuarioActions = {
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}` },
  quantidade: { method: 'GET', url: `${basePath}/quantidade?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}` },
  buscarGrupo: { method: 'GET', url: `${basePath}/{idUsuario}/grupo` },
  buscarUsuario: { method: 'GET', url: `${basePath}/{idUsuario}` },
};

export default (resource) => resource(`${basePath}`, {}, usuarioActions);
