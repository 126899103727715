<template lang="html">
  <div>
    <v-form ref="form" lazy-validation>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.apuracao') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.habilitar_coeficiente_calculo')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col class="py-0" cols="12" sm="6" md="3">
          <v-switch
            dense
            hide-details
            :label="$t('label.fechamento_parcial')"
            v-model="parametrizacao.fechamentoParcial"/>
          <v-switch
            dense
            hide-details
            :label="$t('label.habilitar_coeficiente_calculo')"
            v-model="parametrizacao.coeficienteCalculo"/>
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.periodo') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.periodo')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="3">
          <v-radio-group v-model="parametrizacao.tipoVigencia"
            id="config_contrato_periodo"
            :disabled="travaSelecaoPeriodo">
            <v-radio :label="$t('label.vigencia_aberta')" value="VIGENCIA_ABERTA"></v-radio>
            <v-radio :label="$t('label.vigencia_periodo_configurado')" value="VIGENCIA_PERIODO"></v-radio>
            <v-radio :label="$tc('label.herdar_periodo_vigencia_acao', 2)" value="HERDAR_PERIODO_ACAO"></v-radio>
            <v-radio :label="$t('label.vigencia_predeterminada')" value="VIGENCIA_PREDETERMINADA"></v-radio>
          </v-radio-group>
          <v-text-field
            v-if="parametrizacao.tipoVigencia === 'VIGENCIA_PREDETERMINADA'"
            :label="`${$tc('label.duracao_anos', 1)} *`"
            v-model="parametrizacao.anosDuracaoVigencia"
            type="Number"
            min="1"
            style="width: 60%"
            class="pa-0"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.fluxo_aprovacao') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.fluxo_aprovacao')}`" />
      </v-card-title>
      <v-data-table align-center id="config_contrato_fluxo_campos"
        :headers="headersFluxosTrade"
        :items="parametrizacao.listaFluxosTrade"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:item.fluxo="{ item }">
          {{ $tc(`label.${item.fluxo}`.toLowerCase(), 1) }}
        </template>
        <template v-slot:item.indPossuiFluxoAprovacao="{ item }">
          <v-switch
              v-model="item.indPossuiFluxoAprovacao" />
        </template>
      </v-data-table>

      <v-card-title primary-title class="card-header" v-if="isTipoContratoSamsung || isTipoContratoEpoca">
        {{ $tc('title.nota_debito') }}
      </v-card-title>
      <v-container fluid v-if="isTipoContratoSamsung || isTipoContratoEpoca">
          <v-col cols="12" sm="12" class="pb-0">
            <v-switch
              dense
              hide-details
              :label="$t('label.gerar_nd_sem_historico_notas')"
              v-model="parametrizacao.indGerarNdSemHistoricoNotas"/>
          </v-col>
          <v-col cols="12" sm="4" class="pb-0">
            <v-switch
              id="config_acao_envio_email_nd"
              :label="$tc('label.envio_email_nd_lista_usuarios', 1)"
              v-model="parametrizacao.indEnviaEmailNd"
              @change="validarListaUsuariosSelecionados()"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="8" class="pb-0">
            <v-autocomplete
              ref="autocomplete"
              id="config_contrato_email_nd_lista_usuarios"
              class="custom-autocomplete"
              name="config_contrato_email_nd_lista_usuarios"
              v-model="parametrizacao.listaUsuariosEnvioNd"
              :filter="filtroUsuariosFuncionarios"
              :items="listaSelectUsuariosFuncionarios"
              :search-input.sync="buscaListaSelectUsuariosFuncionarios"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.usuario_funcionario', 2)} * `"
              :placeholder="$tc('label.digite_para_buscar', 1)"
              @click:append="triggerUsuariosFuncionarios"
              @click.native="clickUsuariosFuncionarios"
              :rules="parametrizacao.indEnviaEmailNd ? [rules.requiredLista] : []"
              :disabled="!parametrizacao.indEnviaEmailNd"
              item-text="nomeSobrenome"
              item-value="id"
              cache-items
              chips
              multiple
              deletable-chips
              clearable>
                <template
                  v-slot:selection="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="removeChipSelecao(data.item)">
                    {{ data.item.nomeSobrenome }}
                  </v-chip>
                </template>
            </v-autocomplete>
          </v-col>
      </v-container>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.visibilidade_configuracao_campos', 1) }}
      </v-card-title>
      <v-data-table align-center id="config_contrato_tabela_campos"
        :headers="headersCamposFluxos"
        :items="camposConfiguracao"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:body="{ items }">
          <tbody>
            <tr
                v-for="item in items"
                :key="item.label"
                v-if="flagCoeficienteCalculoHabilitada(item)">
              <td>{{ item.nome }}</td>
              <td v-for="fluxo in fluxos" :key="fluxo" style="height: '2em'">
                <v-switch
                  v-model="item[fluxo]"
                  v-if="campoDinamicoPlanejamentoEditavel(fluxo, item) &&
                  campoProdutoPlanejamentoEditavel(fluxo, item) &&
                  campoPlanejamentoEditavel(fluxo, item.label)"
                  />
                <span v-else>{{$tc('label.sim', 1)}}</span>
              </td>
              <td>
                <v-switch
                  v-if="campoHabilitadoEdicaoAprovado(item.label)"
                  v-model="item.edicaoAprovado" />
                <span v-else>{{$tc('label.nao', 1)}}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-row justify="end" class="my-4">
        <v-btn id="config_contrato_passo3_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_contrato_passo3_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn color="primary" id="config_contrato_passo3_botao_proximo" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import Passo3 from './Passo3';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoContratoPasso3',
  props: {
    id: Number,
  },
  components: {
    TooltipAjuda,
  },
  data() {
    return {
      paramContratoResources: this.$api.parametrizacaoContrato(this.$resource),
      usuarioResources: this.$api.usuario(this.$resource),
      passo: 3,
      headers: [
        {
          text: this.$tc('label.tipo_orcamento', 1), value: 'tipoVerba', sortable: false, width: '40%',
        },
        {
          text: this.$tc('label.vinculo', 1), value: 'vinculosCampos', sortable: false, width: '30%',
        },
      ],
      headersCampos: [
        {
          text: this.$tc('label.campo', 1), value: 'nome', width: '20%', sortable: false,
        },
      ],
      headerEdicaoAprovado: {
        text: this.$tc('label.edicao_status_aprovado', 1),
        sortable: false,
        value: 'edicao_status_aprovado',
      },
      parametrizacao: new Passo3(),
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
      },

      fluxos: [],
      camposConfiguracao: [],
      headersCamposFluxos: [],
      labelsCamposDinamicos: [],
      travaSelecaoPeriodo: false,
      headersFluxosTrade: [
        {
          text: this.$tc('label.fluxo_trademarketing', 1), value: 'fluxo', width: '30%', sortable: false,
        },
        {
          text: this.$tc('label.fluxo_aprovacao', 1), value: 'indPossuiFluxoAprovacao', width: '70%', sortable: false,
        },
      ],
      buscaListaSelectUsuariosFuncionarios: null,
      listaSelectUsuariosFuncionarios: [],
      usuariosFuncionarios: [],
    };
  },
  watch: {
    buscaListaSelectUsuariosFuncionarios(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.buscarUsuariosFuncionariosAutocomplete(val);
      }, 500);
    },
  },
  computed: {
  },
  methods: {
    clearCachedItems() {
      const cachedArray = this.$refs.autocomplete.cachedItems;
      this.$refs.autocomplete.cachedItems = [];
      if (cachedArray && this.parametrizacao.listaUsuariosEnvioNd) {
        cachedArray.forEach((el) => {
          let elementCached = {};
          elementCached = this.parametrizacao.listaUsuariosEnvioNd.find((val) => val === el.id);
          if (elementCached) {
            this.$refs.autocomplete.cachedItems.push(el);
          }
        });
      }
    },
    flagCoeficienteCalculoHabilitada(item) {
      return item.label !== 'coeficiente_calculo'
      || (item.label === 'coeficiente_calculo' && this.parametrizacao.coeficienteCalculo);
    },
    setTipoVigencia() {
      this.parametrizacao.tipoVigencia = 'VIGENCIA_ABERTA';
    },
    setCamposConfiguracao(todosCampos, fluxos) {
      this.camposConfiguracao = [...todosCampos];
      this.fluxos = fluxos.split(',');

      this.formataFluxosHabilitados();

      this.headersCamposFluxos = [...this.headersCampos];
      this.fluxos.forEach((f) => {
        this.headersCamposFluxos.push({
          text: this.$tc(`label.${f.toLowerCase()}`, 1),
          value: f,
          sortable: false,
          width: '20%',
        });
      });
      this.headersCamposFluxos.push(this.headerEdicaoAprovado);
    },
    setCamposDinamicos(camposDinamicos) {
      this.labelsCamposDinamicos = camposDinamicos.map((campo) => campo.label);
    },
    setFluxosTrade(fluxos) {
      const backupFluxosTrade = [...this.parametrizacao.listaFluxosTrade];
      this.parametrizacao.listaFluxosTrade = [];
      const nomesFluxos = fluxos.split(',');
      nomesFluxos.forEach((f) => {
        this.parametrizacao.listaFluxosTrade.push({
          idContratoTipo: this.id,
          fluxo: f,
          indPossuiFluxoAprovacao: (backupFluxosTrade.find((el) => el.fluxo === f) || {})
            .indPossuiFluxoAprovacao || false,
        });
      });
    },
    campoHabilitadoEdicaoAprovado(label) {
      return label === 'descricao'
        || label === 'coeficiente_calculo'
        || this.labelsCamposDinamicos.indexOf(label) >= 0;
    },
    campoPlanejamentoEditavel(fluxo, label) {
      if (fluxo === 'PLANEJAMENTO') {
        const naoEditaveis = [
          'descricao',
          'divisao',
          'unidadenegocio',
          'periodo_planejamento',
          'status',
          'valor',
          'coeficiente_calculo',
          'cliente',
          'holding',
          'cliente_pagador',
          'fornecedor',
          'grupo_fornecedor',
          'fornecedor_pagador',
          'campo_cliente',
        ];
        return naoEditaveis.indexOf(label) < 0;
      }
      return true;
    },
    campoProdutoPlanejamentoEditavel(fluxo, item) {
      if (fluxo === 'PLANEJAMENTO' && item.indCampoProduto && (item.obrigatorio || item.excecao)) {
        return false;
      }
      return true;
    },
    campoDinamicoPlanejamentoEditavel(fluxo, item) {
      if (fluxo === 'PLANEJAMENTO' && item.idCampoDinamico && item.obrigatorio) {
        return false;
      }
      return true;
    },
    defineVigenciaAberta() {
      this.parametrizacao.tipoVigencia = true;
      this.travaSelecaoPeriodo = true;
    },
    parseFluxosHabilitados() {
      this.camposConfiguracao.forEach((campo) => {
        campo.fluxos = [];
        this.fluxos.forEach((f) => {
          if (campo[f]) {
            campo.fluxos.push(f);
          }
        });
      });
    },
    formataFluxosHabilitados() {
      this.camposConfiguracao.forEach((campo) => {
        if (campo.fluxos && campo.fluxos.length) {
          campo.fluxos.forEach((f) => {
            campo[f] = true;
          });
        }
        if (!this.campoPlanejamentoEditavel('PLANEJAMENTO', campo.label)
          || !this.campoProdutoPlanejamentoEditavel('PLANEJAMENTO', campo)
          || !this.campoDinamicoPlanejamentoEditavel('PLANEJAMENTO', campo)) {
          campo.PLANEJAMENTO = true;
        }
      });
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_CONTRATO_CANCELAR');
    },
    proximoPasso() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.parseFluxosHabilitados();
      this.$emit('PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
      this.$emit('PARAMETRIZACAO_CONTRATO_CONTINUAR', this.passo + 1, this.id);
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_CONTRATO_VOLTAR', this.passo - 1);
    },
    prepararPasso() {
      if (this.id) {
        this.obter(this.id);
      } else {
        this.setTipoVigencia();
      }
    },
    obter(id) {
      this.paramContratoResources.obterPasso3({ id }, { id }).then((response) => {
        this.parametrizacao = new Passo3(
          id,
          response.data.tipoVigencia,
          response.data.listaFluxosTrade,
          response.data.fechamentoParcial,
          response.data.coeficienteCalculo,
          response.data.anosDuracaoVigencia,
          response.data.indEnviaEmailNd,
          response.data.indGerarNdSemHistoricoNotas,
          response.data.listaUsuariosEnvioNd,
        );
        if (response.data.indEnviaEmailNd) {
          this.buscarUsuariosFuncionariosAutocomplete(null, response.data.listaUsuariosEnvioNd);
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    filtroUsuariosFuncionarios(item, queryText, itemText) {
      const usuariosDescricao = item.nomeSobrenome;
      const searchText = queryText.toLowerCase();

      return itemText.toLowerCase().indexOf(searchText) > -1
        || usuariosDescricao.toLowerCase().indexOf(searchText) > -1;
    },
    triggerUsuariosFuncionarios() {
      this.triggerClick('config_contrato_email_nd_lista_usuarios');
    },
    clickUsuariosFuncionarios() {
      this.clearCachedItems();
      this.buscarUsuariosFuncionariosAutocomplete(this.buscaListaSelectUsuariosFuncionarios);
    },

    buscarUsuariosFuncionariosAutocomplete(busca = null, listaID = null) {
      const idsUsuariosFuncionariosSelecionados = [];
      if (this.usuariosFuncionarios) {
        this.usuariosFuncionarios.forEach((value) => {
          idsUsuariosFuncionariosSelecionados.push(value.id);
        });
      }

      const param = {
        filtro: busca,
        idsExcecao: idsUsuariosFuncionariosSelecionados,
        indAtivo: true,
        ids: listaID,
      };

      this.usuarioResources.listarAtivosResumido(param).then((response) => {
        this.listaSelectUsuariosFuncionarios = Object.assign([], response.data.resposta);
      }, (err) => {
        this.$error(this, err);
      });
    },
    triggerClick(id) {
      document.getElementById(id).click();
    },
    setTipoInclusaoAcao(tipoInclusaoAcao) {
      this.parametrizacao.tipoInclusaoAcao = tipoInclusaoAcao;
    },
    isTipoContratoSamsung() {
      return this.parametrizacao.tipoInclusaoAcao === 'CARTA_PARCERIA_SAMSUNG';
    },
    isTipoContratoEpoca() {
      return this.parametrizacao.tipoInclusaoAcao === 'CARTA_PARCERIA_EPOCA';
    },
    validarListaUsuariosSelecionados() {
      if (!this.parametrizacao.indEnviaEmailNd) {
        this.parametrizacao.listaUsuariosEnvioNd = [];
      }
    },
    removeChipSelecao(item) {
      const selecao = this.parametrizacao.listaUsuariosEnvioNd;
      const index = selecao.map((s) => s).indexOf(item.id);
      if (index >= 0) {
        selecao.splice(index, 1);
      }
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .card-header {
    background-color: var(--v-primary-base);
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
