import moment from 'moment';

function plugin(Vue) {
  Vue.prototype.moment = moment;
}

if (typeof window !== 'undefined' && window.Vue) { // eslint-disable-line no-undef
  window.Vue.use(plugin); // eslint-disable-line no-undef
}

// module.exports = plugin;
export default plugin;
