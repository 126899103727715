<template lang="html">
  <div style="height: 100%;">
    <v-card class="mb-1 mt-2">
      <v-card-title>
        <h3 class="headline mb-0">{{ titlePage }}</h3>
      </v-card-title>
      <tree-list v-if="mostrarTreeList"
        :disabled="somenteLeitura"
        :data-source="categorias"
        ref="treeList"
        coluna-chave="id"
        :search-panel="true"
        :cache-enabled="false"
        :search-placeholder="$t('label.pesquisar')"
        coluna-tem-item="tem_item"
        coluna-chave-pai="idPai"
        :colunas="colunas">
      </tree-list>
    </v-card>
  </div>
</template>

<script>
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import TreeList from '../../../shared-components/treeList/TreeList';

export default {
  name: 'CategoriaTabela',
  components: {
    Confirm,
    TreeList,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    titlePage: String,
    idNivelExtensao: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      mostrarTreeList: true,
      categorias: [],
      colunas: [
        {
          dataField: '',
          caption: '',
          cellTemplate: (element, info) => {
            if (!this.somenteLeitura) {
              element.append('<button type="button" style="margin: 0px 0px 0px 15px; padding: 0px; height: auto; min-height: auto" '
                .concat('class="md-button md-icon-button md-theme-default" onclick="TreeView_editPorId(')
                .concat(info.data.id)
                .concat(')"><i aria-hidden="true" class="v-icon material-icons">edit</i>'
                  .concat('</button>')));
            }
          },
        },
        {
          dataField: 'idExterno',
          caption: this.$tc('label.codigo', 1),
          minWidth: 300,
        },
        {
          dataField: 'nomExtensao',
          caption: this.$tc('label.descricao', 1),
        },
        {
          dataField: 'indAtivo',
          caption: this.$tc('label.ativo', 1),
          cellTemplate: (element, info) => {
            if (info.data.indAtivo) {
              element.append(this.$tc('label.sim', 1));
            } else {
              element.append(this.$tc('label.nao', 1));
            }
          },
        },
      ],
      esperar: false,
      headers: [
        {
          text: '', value: 'acoes', sortable: false, align: 'center',
        },
        { text: this.$tc('label.codigo', 1), value: 'id_externo' },
        { text: this.$tc('label.descricao', 1), value: 'nom_extensao' },
        { text: this.$tc('label.ativo', 1), value: 'ind_ativo' },
      ],
      nivelProdutoResources: this.$api.nivelProduto(this.$resource),
      totalPage: 0,
      search: '',
    };
  },
  methods: {
    buscar() {
      const params = {
        nome: this.search,
        idNivelExtensao: this.idNivelExtensao,
      };

      this.nivelProdutoResources.categorias(params).then((response) => {
        this.categorias = response.data.resposta;
        const filhos = [];
        this.categorias.forEach((categoria) => {
          categoria.filhos = categoria.filhos
            ? JSON.parse(categoria.filhos.value) : [];
          categoria.filhos.forEach((filho) => {
            filhos.push(filho);
          });
        });

        this.categorias = this.categorias.concat(filhos);

        this.recarregar();
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    editItem(item) {
      this.$emit('edit', item);
    },
    editPorId(id) {
      const item = this.categorias.filter((categoria) => categoria.id === id)[0];
      this.editItem(item);
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    recarregar() {
      this.mostrarTreeList = false;
      setTimeout(() => {
        this.mostrarTreeList = true;
      });
      // this.$refs.treeList.reload();
    },
  },
  mounted() {
    this.filtrar();
      window.TreeView_editPorId = this.editPorId; // eslint-disable-line
  },
};
</script>
<style>
  .icon-size {
    font-size: 17px;
  }
  .dx-pager .dx-page,
  .dx-pager .dx-page-size {
    font-size: 12px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
  }
  .dx-pager .dx-pages .dx-selection,
  .dx-pager .dx-page-sizes .dx-selection {
    font-weight: 500;
    background-color: #777;
    color: #fff;
  }
</style>
