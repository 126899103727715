import getBasePath from '../../functions/api-resource';
import genericActions from '../generic-resource';

const basePath = getBasePath('apuracao_acao', 'apuracao-parcial');

const apuracaoActions = {
  ...genericActions(basePath),
  buscarCalculoApuracaoParcial: { method: 'GET', url: `${basePath}/buscar-calculo-apuracao-parcial` },
  buscarRecebimentosParciais: { method: 'GET', url: `${basePath}/{idApuracao}` },
  buscarDataInicioProxApuracao: { method: 'GET', url: `${basePath}/buscar-data-inicio-prox-apuracao/{idApuracao}` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);
