import getBasePath from '../functions/api-resource';

const basePath = getBasePath('api', 'cliente-configuracao');

const parametrizacaoClienteActions = {
  buscar: { method: 'GET', url: `${basePath}/{chave}` },
  buscarTodos: { method: 'GET', url: `${basePath}/{chave}/todos` },
  salvar: { method: 'PUT', url: `${basePath}` },
  buscarTodas: { method: 'GET', url: `${basePath}` },
};

export default (resource) => resource(`${basePath}`, {}, parametrizacaoClienteActions);
