<template>
  <v-snackbar
      v-if="toast"
      v-model="snackbar"
      :timeout="toast.duration"
      app
      :bottom="bottom"
      :centered="centered"
      :left="left"
      :right="right"
      :top="top">
    {{ toast.message }}

    <template v-slot:action="{ attrs }">
      <v-btn
          v-if="toast.cbmessage"
          color="white"
          v-bind="attrs"
          text
          @click="closeMessage(toast.cbbutton)">
        {{ toast.cbmessage }}
      </v-btn>
      <v-btn
          v-else
          color="white"
          icon
          v-bind="attrs"
          @click="snackbar = false">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Snackbar',
  data: () => ({
    snackbar: false,
    toast: null,
    position: null,
    bottom: false,
    centered: false,
    left: false,
    right: false,
    top: false,
  }),
  computed: {
    ...mapGetters('toast', [
      'getToast',
    ]),
  },
  watch: {
    getToast(value) {
      if (value && value.position !== this.position) {
        this.startSnackbar(value);
      }
    },
    snackbar(value) {
      if (!value) {
        this.close();
      }
    },
  },
  methods: {
    ...mapActions('toast', [
      'removeToast',
    ]),
    closeMessage(cbbutton) {
      this.snackbar = false;
      if (cbbutton) {
        cbbutton();
      }
    },
    close() {
      if (this.toast.cb) {
        this.toast.cb();
      }
      const { position } = this;
      this.clear();
      setTimeout(() => {
        this.removeToast(position);
      }, 2E2);
    },
    clear() {
      this.toast = null;
      this.position = null;
    },
    startSnackbar(toast) {
      this.toast = toast.toast;
      this.position = toast.position;
      if (this.toast.location) {
        this.bottom = this.toast.location.includes('bottom');
        this.top = this.toast.location.includes('top');
        this.centered = this.toast.location.includes('centered');
        this.left = this.toast.location.includes('left');
        this.right = this.toast.location.includes('right');
      } else {
        this.bottom = true;
        this.centered = false;
        this.top = false;
        this.left = false;
        this.right = false;
      }
      setTimeout(() => {
        this.snackbar = true;
      }, 1000);
    },
  },
};
</script>
