export default class Passo3 {
  constructor(id = null, formaRateio, verbaParcial, composicao, fluxoAprovacao,
    operacaoTranferencia, operacaoAdendo, operacaoReducao, bloqueioUsoVerba) {
    const objetoPadrao = {
      chave: null,
      valor: null,
      tipo: null,
      idTipoVerba: null,
    };

    this.id = id;
    this.formaRateio = { ...formaRateio || Passo3.setForma(objetoPadrao) };
    this.composicao = { ...composicao || Passo3.setComposicao(objetoPadrao) };
    this.verbaParcial = { ...verbaParcial || Passo3.setVerbaParcial(objetoPadrao) };
    this.bloqueioUsoVerba = {
      ...bloqueioUsoVerba
      || Passo3.setBloqueioUsoVerba(objetoPadrao),
    };
    this.fluxoAprovacao = {
      ...fluxoAprovacao
      || Passo3.setFluxoAprovacao(objetoPadrao),
    };
    this.operacaoTranferencia = {
      ...operacaoTranferencia
      || Passo3.setOperacaoTranferencia(objetoPadrao),
    };
    this.operacaoAdendo = {
      ...operacaoAdendo
      || Passo3.setOperacaoAdendo(objetoPadrao),
    };
    this.operacaoReducao = {
      ...operacaoReducao
      || Passo3.setOperacaoReducao(objetoPadrao),
    };
  }

  static setForma(objeto) {
    objeto.tipo = 'RATEIO';
    objeto.chave = 'FORMA';
    return objeto;
  }

  static setComposicao(objeto) {
    objeto.tipo = 'RATEIO';
    objeto.chave = 'COMPOSICAO';
    return objeto;
  }

  static setVerbaParcial(objeto) {
    objeto.tipo = 'VALIDACOES';
    objeto.chave = 'ALOCACAO_VERBA_PARCIAL';
    objeto.valor = false;
    return objeto;
  }

  static setBloqueioUsoVerba(objeto) {
    objeto.tipo = 'VALIDACOES';
    objeto.chave = 'BLOQUEIO_USO_VERBA';
    objeto.valor = false;
    return objeto;
  }

  static setFluxoAprovacao(objeto) {
    objeto.tipo = 'FLUXO_APROVACAO';
    objeto.chave = 'HABILITAR_FLUXO_APROVACAO';
    objeto.valor = false;
    return objeto;
  }

  static setOperacaoTranferencia(objeto) {
    objeto.tipo = 'OPERACOES';
    objeto.chave = 'TRANSFERENCIA';
    objeto.valor = false;
    return objeto;
  }

  static setOperacaoAdendo(objeto) {
    objeto.tipo = 'OPERACOES';
    objeto.chave = 'ADENDO';
    objeto.valor = false;
    return objeto;
  }

  static setOperacaoReducao(objeto) {
    objeto.tipo = 'OPERACOES';
    objeto.chave = 'REDUCAO';
    objeto.valor = false;
    return objeto;
  }
}
