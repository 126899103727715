import administracaoRoutes from './administracao';
import alcadaRoutes from './alcada';
import alertaManutencaoRoutes from './alerta-manutencao';
import anoFiscalRoutes from './ano-fiscal';
import apuracaoContratoAntigo from './apuracao/apuracao-contrato-antigo';
import apuracaoContrato from './apuracao/apuracao-contrato';
import apuracaoContratoSamsung from './apuracao/apuracao-contrato-samsung';
import apuracaoContratoEpoca from './apuracao/apuracao-contrato-epoca';
import apuracaoAcaoRoutes from './apuracao/apuracao-acao';
import carteiraClienteRoutes from './carteira-cliente/carteira-cliente';
import calendarioRoutes from './calendario';
import camposCustomizadosRoutes from './campos-customizados';
import centroCusto from './centro-custo';
import clienteRoutes from './cliente';
import configuracaoSistema from './configuracao-sistema';
import concorrente from './concorrente';
import dashboardInicial from './dashboard-inicial';
import divisao from './divisao';
import estruturaUsuarioRoutes from './estrutura-usuario';
import extratoContaCorrente from './extrato-conta-corrente';
import filial from './filial';
import fornecedorRoutes from './fornecedor';
import gestaoAprovacao from './gestao-aprovacao';
import importacaoRoutes from './importacoes';
import nivelCliente from './nivel-cliente';
import nivelFornecedor from './nivel-fornecedor';
import nivelProduto from './nivel-produto';
import notas from './notas';
import notasDebitoContabilizacao from './notas-debito-contabilizacao';
import notasDebitoConsulta from './notas-debito-consulta';
import preUsuario from './pre-usuario';
import produtoRoutes from './produto';
import pagamentoRoutes from './liquidacao/pagamento';
import pagamentoContratoRoutes from './liquidacao/pagamento-contrato';
import parametrizacaoAcaoRoutes from './parametrizacao-acao';
import parametrizacaoClienteRoutes from './parametrizacao-cliente';
import parametrizacaoContratoRoutes from './parametrizacao-contrato';
import parametrizacaoOrcamentoRoutes from './parametrizacao-orcamento';
import planejamentoAcao from './planejamento/planejamento-acao';
import planejamentoContrato from './planejamento/planejamento-contrato';
import recebimento from './liquidacao/recebimento';
import regiaoRoutes from './regiao';
import relatorios from './relatorios';
import ssoGrupo from './sso-grupo';
import termoAceite from './termo-aceite';
import tipoInvestimentoRoutes from './tipo-investimento';
import transferenciaPosse from './transferencia-posse';
import unidadeMedida from './unidade-medida';
import usuarioRoutes from './usuario';
import usuarioFuncionarioRoutes from './usuario-funcionario';
import usuarioFornecedorRoutes from './usuario-fornecedor';
import workflowRoutes from './workflow';

export default [
  administracaoRoutes,
  apuracaoAcaoRoutes,
  apuracaoContratoAntigo,
  apuracaoContrato,
  apuracaoContratoSamsung,
  apuracaoContratoEpoca,
  anoFiscalRoutes,
  alcadaRoutes,
  alertaManutencaoRoutes,
  calendarioRoutes,
  camposCustomizadosRoutes,
  carteiraClienteRoutes,
  centroCusto,
  clienteRoutes,
  configuracaoSistema,
  concorrente,
  dashboardInicial,
  divisao,
  estruturaUsuarioRoutes,
  extratoContaCorrente,
  filial,
  fornecedorRoutes,
  gestaoAprovacao,
  importacaoRoutes,
  nivelCliente,
  nivelFornecedor,
  nivelProduto,
  notas,
  notasDebitoContabilizacao,
  notasDebitoConsulta,
  pagamentoRoutes,
  pagamentoContratoRoutes,
  parametrizacaoAcaoRoutes,
  parametrizacaoClienteRoutes,
  parametrizacaoContratoRoutes,
  parametrizacaoOrcamentoRoutes,
  planejamentoAcao,
  planejamentoContrato,
  preUsuario,
  produtoRoutes,
  recebimento,
  regiaoRoutes,
  relatorios,
  ssoGrupo,
  termoAceite,
  tipoInvestimentoRoutes,
  transferenciaPosse,
  usuarioRoutes,
  usuarioFuncionarioRoutes,
  usuarioFornecedorRoutes,
  unidadeMedida,
  workflowRoutes,
];
