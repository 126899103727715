<template lang="html">
  <div>
    <v-card>
      <v-card-title>
        <h3 class="headline mb-0">{{ $t('label.aceites_realizados') }}</h3>
      </v-card-title>
      <v-container fluid grid-list-md>
        <v-row>
          <v-col cols="12" sm="6">
            <v-container fluid grid-list-md>
              <v-text-field
                v-model="searchQuery"
                append-icon="search"
                :label="$t('label.pesquisar')"
                single-line
                clearable
                hide-details>
              </v-text-field>

              <v-data-iterator
                :items="items"
                :server-items-length="totalPage"
                :options.sync="pagination"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                content-tag="v-row"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                }">
                <template v-slot:default="props">
                  <v-row justify="start" class="mx-0">
                    <v-col cols="12" sm="6" v-for="item in items" :key="item.titulo">
                      <v-card @click.native="selecionar(item)">
                        <v-card-title>{{ item.descricao }}</v-card-title>
                        <v-card-actions>
                          <span>
                            {{ `${$tc('label.data')}: ${item.dtaAceite.split(' ', 1)[0]}` }}
                            <v-spacer></v-spacer>
                            {{ `${$tc('label.hora')}: ${item.dtaAceite.split(' ', 2)[1]}` }}
                          </span>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="accent"
                            @click.native="selecionar(item)"
                            class="details-button">
                            {{ $t('label.visualizar_detalhes') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-container>
          </v-col>
          <v-col class="details" cols="12" sm="6">
            <span v-html="termo.conteudo"></span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>

export default {
  name: 'TermoAceiteRealizado',
  data() {
    return {
      termoAceiteResources: this.$api.termoAceite(this.$resource),

      items: [],
      termo: {},
      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      searchQuery: '',
    };
  },
  computed: {
  },
  watch: {
    searchQuery() {
      if (this.timeoutTemp) {
        window.clearTimeout(this.timeoutTemp);
      }
      this.timeoutTemp = window.setTimeout(() => {
        this.buscarTermos();
      }, 500);
    },
    pagination: {
      handler() {
        this.buscarTermos();
      },
      deep: true,
    },
  },
  methods: {
    buscarTermos() {
      const params = {
        tamanhoPagina: this.pagination.itemsPerPage || 10,
        numeroPagina: this.pagination.page || 1,
        filter: this.searchQuery,
      };

      this.termoAceiteResources.listarRealizados(params).then((response) => {
        this.items = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
      }, (err) => {
        this.$error(this, err);
      });
    },
    selecionar(item) {
      this.termo = item;
    },
  },
  mounted() {
  },
};
</script>
<style scoped>
  .details-button {
    font-size: 0.75em
  }
  .details {
    margin-top: 70px;
  }
</style>
