export default class Passo2 {
  constructor(id, indAcessoCliente = false, listaDivisao = [], listaEstrutura = [],
    indHabilitarRateioContabil = false) {
    this.id = id;
    this.indAcessoCliente = indAcessoCliente;
    this.listaDivisao = listaDivisao;
    this.listaEstrutura = listaEstrutura;
    this.indHabilitarRateioContabil = indHabilitarRateioContabil;
  }
}
