import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'regiao');

const regiaoActions = {
  ...genericActions(basePath),
  ativarInativarRegistros: { method: 'POST', url: `${basePath}/ativarInativarRegistros` },
  listarAtivosPorRepresentante: { method: 'GET', url: `${basePath}/por-representante?&nome={nome}&indAtivo=true&colunaOrdenacao=nom_regiao` },
};

export default (resource) => resource(`${basePath}`, {}, regiaoActions);
