import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'parametrizacao-orcamento');

const parametrizacaoOrcamentoActions = {
  ...genericActions(basePath),
  buscar: { method: 'GET', url: `${basePath}` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  salvarPasso2: { method: 'PUT', url: `${basePath}/passo2` },
  salvarPasso3: { method: 'PUT', url: `${basePath}/{id}/passo3` },
  salvarPasso4: { method: 'PUT', url: `${basePath}/{id}/passo4` },
  salvarPasso5: { method: 'PUT', url: `${basePath}/{id}/passo5` },
  obterPasso2: { method: 'GET', url: `${basePath}/{id}/passo2` },
  obterPasso3: { method: 'GET', url: `${basePath}/{id}/passo3` },
  obterPasso4: { method: 'GET', url: `${basePath}/passo4` },
  obterPasso5: { method: 'GET', url: `${basePath}/passo5` },
  listarTipoInvestimento: { method: 'GET', url: `${basePath}/tipo-investimento` },
  listarEntidadeProduto: { method: 'GET', url: `${basePath}/entidade-produto{?labels*}` },
};

export default (resource) => resource(`${basePath}`, {}, parametrizacaoOrcamentoActions);
