import { accessObjectViaString } from './helpers';
import languages from '../i18n';

const errors = (lang) => languages.messages[lang].errors;

const extractMessage = (error, lang) => {
  const splitParams = error.split('|');
  const msg = accessObjectViaString(errors(lang), splitParams[0]);

  if (splitParams.length > 1) {
    const param = splitParams[1].replace(/(\w+):/g, '').split('&&');
    return msg(...param);
  }

  return msg;
};

const extractMessages = (error, lang) => {
  const splitParams = error.split('||');
  const errorsArray = [];
  splitParams.forEach((el) => errorsArray.push(extractMessage(el, lang)));
  return errorsArray;
};

const extractErrorMessage = (error, lang) => {
  if (error.indexOf('||') > -1) {
    return extractMessages(error, lang);
  }
  return extractMessage(error, lang);
};

export default (params, lang = 'pt-br') => {
  if (!params) {
    return 'Erro inesperado. Tente novamente.';
  }

  const err = (params.erros || params.error);

  if (!err) {
    return 'Erro inesperado. Tente novamente.';
  }

  if (typeof err === 'object' && err.hasOwnProperty('length')) {
    return err.map((el) => extractErrorMessage(el, lang)).join('<br>');
  }

  return extractErrorMessage(err, lang);
};
