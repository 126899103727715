<template>
  <transition name="slide-fade" mode="out-in">
    <router-view></router-view>
  </transition>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  methods: {
    ...mapActions('metadados', [
      'setClienteMetadado',
      'setCarteiraClienteMetadado',
    ]),
  },
  mounted() {
    this.setClienteMetadado({
      resource: this.metadadoResource.listarCliente,
    });
    this.setCarteiraClienteMetadado({
      resource: this.metadadoResource.listarCarteiraCliente,
    });
  },
};
</script>
