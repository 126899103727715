const ApuracaoAcao = () => import('../../../spa/apuracao-acao-lista/ApuracaoAcao');
const ApuracaoAcaoDetalhesNova = () => import('../../../spa/apuracao-acao-lista/ApuracaoAcaoDetalhesNova');
const ApuracaoAcaoRouter = () => import('../../../spa/apuracao-acao-lista/ApuracaoAcaoRouter');

export default {
  path: 'apuracao-acao',
  component: ApuracaoAcaoRouter,
  children: [
    {
      path: '',
      name: 'apuracao-acao',
      component: ApuracaoAcao,
    },
    {
      path: ':idAcao/detalharApuracaoAcao',
      name: 'apuracaoAcaoDetalhesNova',
      component: ApuracaoAcaoDetalhesNova,
    },
  ],
};
