const ordenacaoSimples = (todosCampos) => todosCampos.sort((a, b) => {
  // Campos dinamicos vem por ultimo
  if (!a.campoExtensao) {
    return 1;
  }
  // Entre extensoes recursivas ordena por indice de recursao
  if (a.idNivelExtensao === b.idNivelExtensao) {
    return a.indiceRecursao < b.indiceRecursao ? 1 : -1;
  }
  // Extensoes obrigatorias primeiro
  return a.depObrigatoria ? -1 : 1;
});

export const
  ordenaComponentesDinamicos = (ordenacao, dependencias, camposDinamicos, camposFixos) => {
    const todosCampos = [
      ...(camposFixos || []),
      ...dependencias,
      ...camposDinamicos,
    ];
    if (!ordenacao || ordenacao.length === 0) {
      return ordenacaoSimples(todosCampos);
    }

    const ordenados = [];
    ordenacao.forEach((label) => {
      for (let i = 0; i < todosCampos.length; i += 1) {
        const campo = todosCampos[i];
        const labelCampo = campo.labelCampo || campo.label;
        if (labelCampo === label) {
          ordenados.push(campo);
          break;
        }
      }
    });

    return ordenados;
  };

export const formataDependencia = (dependencia, formularioFiltros) => {
  const formatada = { ...dependencia };
  formatada.campoExtensao = true;
  if (formularioFiltros) {
    formatada.manyToMany = true;
  }
  return formatada;
};

export const desabilitadoPorDependencia = (metadados, objetoContainer) => {
  const { dependencia } = metadados;
  if (!dependencia) {
    return false;
  }
  const vlrDependencia = objetoContainer[dependencia];
  return !vlrDependencia
    || vlrDependencia === ''
    || Object.keys(vlrDependencia).length === 0;
};

export const getNivelExtensao = (metadados, label) => {
  const { mapaEntidades } = metadados;
  if (!mapaEntidades[label]) return null;

  const { idNivelExtensao, indiceRecursao } = mapaEntidades[label];
  return { idNivelExtensao, indiceRecursao };
};
