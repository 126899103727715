<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="400"
      transition="scale-transition"
      offset-x
      right>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...menu }" class="ma-0" icon :color="corIcone">
              <v-icon>{{ icone }}</v-icon>
            </v-btn>
          </template>
          <span>{{ textoAcao }}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title class="headline">{{ titleDialog }}</v-card-title>
        <v-card-text v-html="message"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="disagree">{{ $t('label.nao') }}</v-btn>
          <v-btn color="primary darken-1" text @click.native="agree">{{ $t('label.sim') }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="exibirSegundaPergunta">
        <v-card-text>
          {{ segundaPergunta }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="finish" text color="primary darken-1">
            {{ $t('label.nao') }}
          </v-btn>

          <v-btn @click="agreeSecond" text color="primary darken-1">
            {{ $t('label.sim') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'PopoverConfirmacao',
  data: () => ({
    menu: false,
    exibirSegundaPergunta: false,
  }),
  props: {
    title: '',
    message: '',
    corIcone: {
      type: String,
      default: null,
    },
    icone: {
      type: String,
      default: 'info',
    },
    textoAcao: {
      type: String,
      default: '',
    },
    segundaPergunta: {
      type: String,
      default: null,
    },
  },
  computed: {
    titleDialog() {
      return (!this.title || !this.title.length) ? this.$t('title.atencao') : this.title;
    },
  },
  methods: {
    inputLabel(item) {
      if (item.label) {
        return item.label;
      }
      return this.$tc(`label.${item.campo}`, 1);
    },
    disagree() {
      this.close();
      this.$emit('disagree');
    },
    agreeSecond() {
      this.finish();
      this.$emit('agreeSecond');
    },
    agree() {
      if (this.segundaPergunta) {
        this.exibirSegundaPergunta = true;
      } else {
        this.finish();
      }
    },
    finish() {
      this.close();
      this.$emit('agree');
      this.exibirSegundaPergunta = false;
    },
    close() {
      this.menu = false;
    },
  },
};
</script>
