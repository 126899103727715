import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'cadastro');

const planejamentoAcaoActions = {
  salvarAcao: { method: 'POST', url: `${basePath}` },
  atualizarAcao: { method: 'PUT', url: `${basePath}` },
  concluirAcao: { method: 'POST', url: `${basePath}/concluir_acao` },
  buscarTiposAcao: { method: 'GET', url: `${basePath}/campos/tipo_acao?tamanhoPagina={size}&numeroPagina={page}` },
  buscarDivisoes: { method: 'GET', url: `${basePath}/campos/divisao` },
  buscarDivisoesPorVisao: { method: 'GET', url: `${basePath}/campos/divisao/visao` },
  buscarUnidadesNegocioDivisao: { method: 'GET', url: `${basePath}/campos/entidade_controlador/{idDivisao}/unidade_negocio` },
  buscarExtensaoDivisao: { method: 'GET', url: `${basePath}/campos/entidade_controlador/{idDivisao}/extensao` },
  buscarUnidadesNegocioEntidadesControlador: { method: 'GET', url: `${basePath}/campos/entidade_controlador/unidade_negocio` },
  buscarUnidadesNegocioPorVisao: { method: 'GET', url: `${basePath}/campos/entidade_controlador/unidade_negocio/visao` },
  buscarPeriodos: { method: 'GET', url: `${basePath}/campos/periodo` },
  buscarPeriodosPlanejamento: { method: 'GET', url: `${basePath}/campos/periodo/planejamento/todos` },
  buscarPeriodosVigentePlanejamento: { method: 'GET', url: `${basePath}/campos/periodo/planejamento/vigente` },
  executarAnalise: { method: 'PUT', url: `${basePath}/{idAcao}/executar_analise` },
  buscarGruposFornecedores: { method: 'GET', url: `${basePath}/campos/grupo_fornecedor` },
  buscarFornecedores: { method: 'GET', url: `${basePath}/campos/fornecedor` },
  buscarFornecedoresPagadores: { method: 'GET', url: `${basePath}/campos/fornecedor/pagador` },
  cancelarAcao: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar` },
  buscarStatus: { method: 'GET', url: `${basePath}/campos/buscarStatus` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoActions);
