import getBasePath from '../functions/api-resource';

const basePath = getBasePath('liquidacao', 'pagamento/dashboard');

const pagamentoActions = {
  listarDash: { method: 'GET', url: `${basePath}` },
  listarPagamentos: { method: 'GET', url: `${basePath}/pagamentos-solicitados` },
  listarPagamentosAprovacoes: { method: 'GET', url: `${basePath}/pagamentos-aprovacoes` },
  listarReinvidicados: { method: 'GET', url: `${basePath}/reinvidicados` },
  listarTiposNegociacoes: { method: 'GET', url: `${basePath}/tipos-negociacoes` },
  listarMarcas: { method: 'GET', url: `${basePath}/marcas?marca={nome}` },
  listarFamilia: { method: 'GET', url: `${basePath}/familia?familia={nome}` },
  listarLinhaProduto: { method: 'GET', url: `${basePath}/linha-produto?linhaProduto={nome}` },
  listarPeriodos: { method: 'GET', url: `${basePath}/periodo` },
  listarSolicitantes: { method: 'GET', url: `${basePath}/solicitante?colunaOrdenacao=nome` },
  listarTipoAcordo: { method: 'GET', url: `${basePath}/tipo-acordo` },
  statusEntidade: { method: 'GET', url: `${basePath}/status-entidade` },
  listarRateio: { method: 'GET', url: `${basePath}/rateio/{id}` },
  consolidadoCliente: { method: 'GET', url: `${basePath}/cliente?competencia={competencia}` },
  periodosCliente: { method: 'GET', url: `${basePath}/cliente/periodos` },
};

export default (resource) => resource(`${basePath}`, {}, pagamentoActions);
