import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('recebimento', 'nota-debito/contabilizacao');

const notaDebitoActions = {
  atualizarNotasDebitoPendentes: { method: 'PUT', url: `${basePath}/atualizar` },
};

export default (resource) => resource(`${basePath}`, {}, notaDebitoActions);

export const listarNotasDebitoPendentes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/pendente', parametros).doGet();
