import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('usuario', 'usuario-cliente');

const regiaoActions = {
  ...genericActions(basePath),
  obterUsuariosMesmaConfiguracaoUsuarioId: { method: 'GET', url: `${basePath}/usuarios/mesma-configuracao/usuario/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, regiaoActions);
