export default class Filial {
  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.nome = data.nome;
      this.cod_filial = data.idExterno;
      this.regiao = {
        id: data.idRegiao,
        nome: data.regiao,
      };
      this.ativo = data.ativo;
      this.desAtributos = Filial.montarDesAtributos(data);
    } else {
      this.desAtributos = {};
      this.ativo = true;
    }
  }

  construirAPartirDaConsultaCompleta(dadosDaConsulta) {
    this.id = dadosDaConsulta.id;
    this.nome = dadosDaConsulta.nome;
    this.idExterno = dadosDaConsulta.idExterno;
    this.idRegiao = dadosDaConsulta.idRegiao;
    this.regiao = dadosDaConsulta.regiao;
    this.ativo = dadosDaConsulta.ativo;
    this.desAtributos = dadosDaConsulta.desAtributos;
  }

  static parsearCampos(todasFiliaisEmUmaUnicaString, atributo) {
    if (!todasFiliaisEmUmaUnicaString) {
      return [];
    }

    return todasFiliaisEmUmaUnicaString.split(',').map((umaFilialEmString) => {
      const arrayComAtributosDaFilial = umaFilialEmString.split('|');
      const retorno = {};
      const [id, valor] = arrayComAtributosDaFilial;
      retorno.id = parseInt(id, 10);
      retorno[atributo] = valor;
      return retorno;
    });
  }

  static parsearMetadados(itemEmUmaString, manyToMany) {
    if (manyToMany) {
      if (typeof itemEmUmaString === 'object') {
        return itemEmUmaString;
      }
      if (!itemEmUmaString) {
        return [];
      }
      return itemEmUmaString.split(',').map((umaFilialEmString) => {
        const arrayComAtributosDaFilial = umaFilialEmString.split('|');
        return {
          id: parseInt(arrayComAtributosDaFilial[0], 10),
          idExterno: arrayComAtributosDaFilial[1],
          nomExtensao: arrayComAtributosDaFilial[2],
        };
      });
    }
    if (!itemEmUmaString) {
      return {};
    }

    let arrayComAtributosDaFilial = null;
    if (Filial.ehObjeto(itemEmUmaString)) {
      return itemEmUmaString;
    }

    arrayComAtributosDaFilial = itemEmUmaString.split('|');
    return {
      id: parseInt(arrayComAtributosDaFilial[0], 10),
      idExterno: arrayComAtributosDaFilial[1],
      nomExtensao: arrayComAtributosDaFilial[2],
    };
  }

  montarEstruturaDinamica(dadosDaConsulta, metadados) {
    Object.keys(dadosDaConsulta).forEach((dados) => {
      Object.keys(metadados.mapaEntidades).forEach((entidade) => {
        if (dados === entidade) {
          this[entidade] = Filial.parsearMetadados(dadosDaConsulta[entidade],
            metadados.mapaEntidades[entidade].manyToMany);
        }
      });
    });
  }

  getNomesSeparadosPorVirgula(nomeObjeto, atributo) {
    if (!this[nomeObjeto]
      || (Filial.ehObjeto(this[nomeObjeto]) && Filial.objetoVazio(this[nomeObjeto]))
      || (Filial.ehArray(this[nomeObjeto]) && this[nomeObjeto].length === 0)) {
      return '';
    }

    if (Filial.ehArray(this[nomeObjeto])) {
      return this[nomeObjeto].map((obj) => obj[atributo]).join(', ');
    }
    return this[nomeObjeto][atributo];
  }

  static objetoVazio(objeto) {
    return Object.keys(objeto).length === 0 && objeto.constructor === Object;
  }

  static ehArray(objeto) {
    return Array.isArray(objeto);
  }

  static ehObjeto(objeto) {
    return objeto instanceof Object;
  }

  ehEdicao() {
    return this.id;
  }

  static montarDesAtributos(dados) {
    dados.desAtributos = dados.desAtributos
      && dados.desAtributos.value
      ? JSON.parse(dados.desAtributos.value) : {};

    return { ...dados.desAtributos };
  }
}
