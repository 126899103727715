<template lang="html">
  <div>
    <div v-if="termos.length > 0 && !aceito">
      <termo-aceite-login
          :termos="termos"
          :title="implantacao.nome"
          @ACEITO="termoAceito">
      </termo-aceite-login>
    </div>
    <div v-if="aplicacaoLiberada">
      <sidebar
        ref="sidenav"
        :abrir="abrirMenu"
        :logo="implantacao.logomarca"
        :menuList="filteredMenuList"></sidebar>

      <toolbar class="Home__PageTeller"
        :title="implantacao.nome"
        :hideMenu="hideMenu"
        :usuario="usuario"
        :perfil="usuarioLogado.perfil"
        :estruturaUsuario="usuarioLogado.estrutura_usuario"
        :mail="usuarioLogado.email"
        :possui-alerta-manutencao="possuiAlertaManutencao"
        :texto-alerta-manutencao="alertaManutencao.textoAlertaManutencao"
        :ind-alerta-comunicacao="indAlertaComunicacao"
        :ind-alerta-manutencao="indAlertaManutencao"
        :link="alertaManutencao.link ? alertaManutencao.link : ''"
        scroll-target="#content"
        @salvaUsuarioAcessoAlerta="salvaUsuarioAcessoAlerta"
        @TOGGLE_SIDEBAR="toggleSidenav"
        @RESET_SIDEBAR="resetSidebar"
        @Toolbar_selecionarTenant="setTenantERecarrega"></toolbar>

      <v-main id="content">
        <v-container
          class="fill-height"
          fluid>
          <v-row style="padding-bottom: 1em" v-if="dicionarioCarregado">
            <v-col cols="12">
              <transition name="slide-fade"
                          mode="out-in" v-if="dicionarioCarregado">
                <router-view></router-view>
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </div>

    <div id="spinner-loading">
      <div>
        <v-container fluid>
          <v-row align="center" justify="center">
            <v-progress-circular
              indeterminate
              :size="100"
              :width="3"
              color="primary"
            />
          </v-row>
        </v-container>
      </div>
    </div>

    <confirm ref="modalSessao"
      :persistent="true"
      :message="$t('errors.sessao_expirada')"
      :title="$t('title.sessao_expirada')"
      :agree-label="$t('label.ok')"
      hide-disagree
      @agree="logoutAceito()"
      />

    <confirm ref="modalErroInicializacaoAplicacao"
      :persistent="true"
      :message="mensagemModalInicializacao"
      :title="$tc('title.aviso', 1)"
      :agree-label="$t('label.ok')"
      :disagree-label="$t('label.recarregar')"
      @disagree="recarregarAplicacao()"
      @agree="fecharModalErro()"
      />

    <tenant-dialog
      ref="tenantDialog"
      @TenantDialog_selecionarTenant="setStorageTenant"
    />

    <snackbar></snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Sidebar from '@/shared-components/sidebar/Sidebar';
import Toolbar from '@/shared-components/toolbar/Toolbar';
import menuList from '@/menu/menu';
import Snackbar from '@/produto/shared-components/snackbar/Snackbar';
import TenantDialog from '@/produto/spa/tenant/TenantDialog';
import dicionario from '@/produto/common/functions/dicionario';
import { copyObject } from '@/produto/common/functions/helpers';
import {
  interpolarDicionario,
} from '@/produto/config/i18n';
import resourceImportacao from '../common/resources/importacoes';
import resourceDicionario from '../common/resources/dicionario';
import resourceUsuario from '../common/resources/usuario';
import resourceControladorTrade from '../common/resources/controlador-trade/controlador-trade';
import { auth } from '../config/sso/auth';
import { showInitialLoading, hideInitialLoading } from '../common/functions/loading';
import TermoAceiteLogin from './termo-aceite/TermoAceiteLogin';
import resourceTermoAceite from '../common/resources/termo-aceite';
import resourceUsuarioPreCadastro from '../common/resources/usuario-pre-cadastro';
import Confirm from '../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'Home',
  data() {
    return {
      importacaoResources: resourceImportacao(this.$resource),
      dicionarioResources: resourceDicionario(this.$resource),
      usuarioResources: resourceUsuario(this.$resource),
      controladorResources: resourceControladorTrade(this.$resource),
      termoAceiteResources: resourceTermoAceite(this.$resource),
      usuarioPreCadastroResources: resourceUsuarioPreCadastro(this.$resource),
      alertaManutencaoResources: this.$api.alertaManutencao(this.$resource),
      aplicacaoLiberada: false,
      dicionarioCarregado: false,
      eventLoop: null,
      eventLoopTimer: 10E3,
      mensagemModalInicializacao: '',
      menuList: [],
      usuarioInfo: {},
      sombraToolbar: false,
      abrirMenu: false,
      recarregar: false,
      termos: [],
      aceito: false,
      possuiAlertaManutencao: false,
      alertaManutencao: {},
      indAlertaComunicacao: false,
      indAlertaManutencao: false,
    };
  },
  components: {
    TenantDialog,
    Snackbar,
    Sidebar,
    Toolbar,
    TermoAceiteLogin,
    Confirm,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
      'getUsuario',
      'getTenantId',
      'getTenantIds',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'implantacao',
      'indModoVarejo',
    ]),
    ...mapGetters('parametros', [
      'parametros',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    canAccessPageList() {
      if (!this.getAllRoles) {
        return [];
      }
      return this.getAllRoles
        .filter((el) => el.indexOf('_PAGINA') !== -1 || el.indexOf('_CRUD') !== -1);
    },
    canImport() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.endsWith('_IMPORTAR') || el.endsWith('_CRUD')).length;
    },
    filteredMenuList() {
      if (!this.getAllRoles || !this.getAllRoles.length) {
        return [];
      }
      const filter = [];
      const routesFiltradas = [];
      this.menuList
        .forEach((m) => {
          if (m.sub) {
            const subFiltrado = this.filtrarSubMenu(m.sub);
            if (subFiltrado.length) {
              m.sub = subFiltrado;
              filter.push(m);
            }
          } else {
            this.getAllRoles.forEach((r) => {
              if (this.validarRotaPorRole(m, routesFiltradas, r) && this.validarTenants(m)) {
                filter.push(m);
                routesFiltradas.push(m.route);
              }
            });
          }
        });
      return filter;
    },
    hideMenu() {
      return !this.filteredMenuList.length;
    },
    pageName() {
      return this.$route.name;
    },
    usuario() {
      return this.getUsuario;
    },
    state() {
      return this.$route.name;
    },
    path() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapActions('importacao', [
      'popularImportacoesAndamento',
      'popularTiposImportacao',
    ]),
    ...mapActions('roles', [
      'setTenantId',
    ]),
    ...mapActions('usuario', [
      'carregarInformacoesUsuario',
    ]),
    ...mapActions('controlador', [
      'carregarControladores',
    ]),
    ...mapActions('implantacao', [
      'carregarImplantacao',
    ]),
    filtrarSubMenu(subMenu) {
      const filter = [];
      const routesFiltradas = [];
      subMenu.forEach((m) => {
        if (m.submenu) {
          const subFilter = this.filtrarSubMenu(m.sub);
          if (subFilter.length > 0) {
            filter.push({
              ...m,
              sub: subFilter,
            });
          }
        } else {
          this.getAllRoles.forEach((r) => {
            if (this.validarRotaPorRole(m, routesFiltradas, r) && this.validarTenants(m)) {
              filter.push(m);
              routesFiltradas.push(m.route);
            }
          });
        }
      });
      return filter;
    },
    validarRotaPorRole(menu, routesFiltradas, role) {
      return ((Array.isArray(menu.role) && menu.role.includes(role)) || role.startsWith(menu.role))
        && !routesFiltradas.includes(menu.route);
    },
    validarTenants(menu) {
      return Array.isArray(menu.tenants) && menu.tenants.includes(this.chaveEmpresa);
    },
    buscarImportacoesAndamento() {
      if (this.canImport) {
        this.popularImportacoesAndamento(this.importacaoResources.listarAndamento)
          .then(() => {
            this.resetEventLoop(10E3);
          });
      }
    },
    buscarTiposImportacao() {
      if (this.canImport) {
        this.popularTiposImportacao({
          resource: this.importacaoResources.listarTipos,
          roles: this.getAllRoles,
        });
      }
    },
    resetEventLoop(timer) {
      if (this.eventLoopTimer === timer) {
        return;
      }
      window.clearInterval(this.eventLoop);
      this.eventLoopTimer = timer;
      this.eventLoopImportacoes();
    },
    eventLoopImportacoes() {
      if (!this.eventLoop && this.canImport) {
        this.eventLoop = setInterval(() => {
          this.buscarImportacoesAndamento();
        }, this.eventLoopTimer);
      }
    },
    toggleSidenav() {
      this.abrirMenu = !this.abrirMenu;
    },
    resetSidebar() {
      this.irParaInicio();
      this.$refs.sidenav.resetSidebar();
    },
    irParaInicio() {
      if (this.$router.currentRoute
        && this.$router.currentRoute.name !== 'dashboard-inicial') {
        this.$router.push({ name: 'dashboard-inicial' });
      }
    },
    logoutAceito() {
      this.$refs.modalSessao.close();
      auth.doLogout();
    },
    iniciaAplicacao() {
      this.aplicacaoLiberada = true;
      this.dicionarioCarregado = true;
      this.buscarImportacoesAndamento();
      this.buscarTiposImportacao();
    },
    recarregarAplicacao() {
      window.location.reload();
    },
    fecharModalErro() {
      this.$refs.modalErroInicializacaoAplicacao.close();
      auth.doLogout();
    },
    carregarTermos() {
      return this.termoAceiteResources.buscarTermosLogin()
        .then((response) => {
          this.termos = response.data;
        });
    },
    termoAceito() {
      this.aceito = true;
      this.iniciaAplicacao();
      setTimeout(() => this.$vuetify.goTo(0), 3000);
      setTimeout(() => hideInitialLoading(), 3000);
    },
    carregarInformacoes() {
      showInitialLoading();
      Promise.all([
        this.carregarInformacoesUsuario(this.usuarioResources.informacao),
        this.carregarControladores(this.controladorResources.listar),
        this.carregarImplantacao(this.dicionarioResources.implantacao),
      ])
        .then(() => this.carregarTermos())
        .then(() => {
          if (this.termos.length === 0) {
            this.iniciaAplicacao();
            setTimeout(() => hideInitialLoading(), 3000);
          }
        })
        .catch((e) => {
          if (e.status === 403) {
            this.mensagemModalInicializacao = this.$t('errors.inicializacao_sem_perfil_ou_divisao');
          } else {
            this.mensagemModalInicializacao = this.$t('errors.inicializacao');
          }
          this.abrirModalErroInicializacao();
          window.setTimeout(() => { throw e; });
        });
    },
    abrirModalErroInicializacao() {
      this.$refs.modalErroInicializacaoAplicacao.open();
      setTimeout(() => hideInitialLoading());
    },
    preCadastroUsuario() {
      showInitialLoading();
      this.usuarioPreCadastroResources.primeiroAcesso()
        .then((res) => {
          hideInitialLoading();
          if (res.data) {
            this.recarregarAplicacao();
          } else {
            this.carregarInformacoes();
          }
          this.buscarAlertasManutencao();
        }).catch((err) => {
          this.carregarInformacoes();
          this.buscarAlertasManutencao();
          this.$error(this, err);
        });
    },
    buscarAlertasManutencao() {
      const params = {
        data_atual: this.moment().format('DD-MM-YYYY HH:mm'),
      };
      this.alertaManutencaoResources.listarAlertasParaExibir(params).then((response) => {
        this.possuiAlertaManutencao = response.data != null && response.data.id != null && response.data.id > 0;
        if (this.possuiAlertaManutencao) {
          this.alertaManutencao = response.data;

          if (this.alertaManutencao.tipoAlerta === 'COMUNICACAO') {
            const idsPerfil = this.alertaManutencao.idsPerfil.split(',').map((valor) => parseInt(valor, 10));
            const idPerfilUsuarioLogado = this.usuarioLogado.idPerfil;
            if (idsPerfil.includes(idPerfilUsuarioLogado)) {
              this.indAlertaComunicacao = true;
              this.indAlertaManutencao = false;
            }

            if (this.alertaManutencao.dataInicio === this.alertaManutencao.dataFim) {
              this.alertaManutencao.textoAlertaManutencao = this.$t('message.alerta_manutencao_inicio_fim_mesmo_dia_comunicacao', this.alertaManutencao);
            } else {
              this.alertaManutencao.textoAlertaManutencao = this.$t('message.alerta_manutencao_inicio_fim_dias_diferentes_comunicacao', this.alertaManutencao);
            }
          } else {
            this.indAlertaComunicacao = false;
            this.indAlertaManutencao = true;

            if (this.alertaManutencao.dataInicio === this.alertaManutencao.dataFim) {
              this.alertaManutencao.textoAlertaManutencao = this.$t('message.alerta_manutencao_inicio_fim_mesmo_dia', this.alertaManutencao);
            } else {
              this.alertaManutencao.textoAlertaManutencao = this.$t('message.alerta_manutencao_inicio_fim_dias_diferentes', this.alertaManutencao);
            }
          }
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    salvaUsuarioAcessoAlerta() {
      const params = {
        id_usuario: this.usuarioLogado.id,
        id_alerta: this.alertaManutencao.id,
      };

      this.alertaManutencaoResources.salvaUsuarioLogAlertaManutencao(params).then(() => {})
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    setStorageTenant(tenantId) {
      this.setTenantId(tenantId);
      this.setTenantColor(tenantId);
      window.localStorage.setItem('xTenantId', tenantId);
      window.sessionStorage.setItem('xTenantId', tenantId);
      this.baixarDicionario();
    },
    limparMetadados() {
      const state = { ...this.$store.state };
      const currentState = copyObject(state);
      const currentMetadados = copyObject(state.metadados);
      // percorre o metadados atual e limpa os valores carregados anteriormente em outro tenant
      Object.keys(currentMetadados).forEach((key) => {
        if (currentMetadados[key] !== null) {
          currentMetadados[key] = null;
        }
      });
      // atualiza a nova state
      const newState = Object.assign(currentState, {
        metadados: currentMetadados,
      });

      // carrega a nova state
      this.$store.replaceState(newState);
    },
    baixarDicionario() {
      dicionario.carregar()
        .then((resDicionario) => {
          this.$i18n.setLocaleMessage('pt-br', interpolarDicionario(resDicionario).messages['pt-br']);
          this.menuList = menuList(this);
          setTimeout(() => this.preCadastroUsuario(), 2E2);
        }).catch((e) => {
          if (e.status && e.message && e.status === 403) {
            window.alert(e.message); // eslint-disable-line
          }
        });
    },
    setTenantERecarrega(tenantId) {
      this.setStorageTenant(tenantId);
      this.irParaInicio();
      this.limparMetadados();
      this.aplicacaoLiberada = false;
    },
    setTenantColor(tenantId) {
      switch (tenantId) {
        case 'epoca':
          this.$vuetify.theme.themes.light.primary = '#F05A8C';
          break;
        case 'kabum':
          this.$vuetify.theme.themes.light.primary = '#FF6500';
          break;
        default:
          this.$vuetify.theme.themes.light.primary = '#0086ff';
      }
    },
    selecionarTenant() {
      if (this.getTenantIds.length === 1) {
        this.setStorageTenant(this.getTenantIds[0]);
      } else if (this.getTenantIds.length > 1) {
        const selectedTenant = window.localStorage.getItem('xTenantId');
        if (selectedTenant && this.getTenantIds.includes(selectedTenant)) {
          this.setStorageTenant(selectedTenant);
        } else {
          this.$refs.tenantDialog.open();
          setTimeout(() => hideInitialLoading());
        }
      } else {
        this.mensagemModalInicializacao = this.$t('errors.inicializacao_sem_perfil');
        this.abrirModalErroInicializacao();
      }
    },
  },
  mounted() {
    auth.registerSessionListener({
      onSessionExpired: this.$refs.modalSessao.open,
    });

    setTimeout(() => this.selecionarTenant(), 2E2);
  },
  beforeDestroy() {
    window.clearInterval(this.eventLoop);
  },
};
</script>
<style lang="scss">

.HOME__logo_div {
  width: 120px;
  height: 120px;
  padding: 35px;
  position: absolute;
}

.HOME__logo svg path {
  fill: var(--v-primary-base);
}

#spinner-loading > div {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #eae9e9;
  opacity: .7;
}
</style>
