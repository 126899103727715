<template>
  <v-autocomplete
    :id="inputId"
    class="custom-autocomplete"
    v-model="objetoInterno"
    clearable
    :no-data-text="$tc('message.nenhum_registro', 1)"
    :required="campoObrigatorio"
    :items="itens"
    :label="inputLabel"
    :item-text="textoItem"
    :item-value="valorItem"
    :disabled="somenteLeitura || campoDesabilitado"
    :placeholder="(somenteLeitura || campoDesabilitado) ? null : $tc('message.digite_para_pesquisar', 1)"
    :rules="[rules.requiredNome]"
    @input="emiteValorAlterado">
  </v-autocomplete>
</template>
<script>
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoLista',
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  props: {
    value: {
      type: [String],
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        const selecaoAtual = alterado || '';
        this.objetoInterno = selecaoAtual;
      },
    },
  },
  data() {
    return {
      itens: [],
      objetoInterno: null,
      textoItem: null,
      valorItem: null,

      rules: {
        requiredNome: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.objetoInterno || '';

          return !!(val) || msg;
        },
      },
    };
  },
  methods: {
    carregarItens() {
      const { desAtributos } = this.metadados;
      if (!desAtributos) {
        throw new Error('DesAtributos não pode ser null!');
      }

      const { textoItem, valorItem, lista } = desAtributos;
      if (textoItem) {
        this.textoItem = textoItem;
        this.valorItem = valorItem;
        this.itens = lista;
      } else {
        this.textoItem = 'texto';
        this.valorItem = 'valor';

        this.itens = lista.map((item) => {
          const formatado = {};
          formatado[this.textoItem] = item;
          formatado[this.valorItem] = item;
          return formatado;
        });
      }
    },
  },
  mounted() {
    this.carregarItens();
  },
};
</script>
