<template>
  <v-dialog v-model="dialogForm" persistent max-width="1200">
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-card v-if="dialogForm">
        <v-card-title>
          <h3 class="headline mb-0">{{ $t('label.transferencia_posse') }}</h3>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                id="posse_de"
                name="posse_de"
                v-model="posse.divisaoDe"
                :items="listaDe"
                :search-input.sync="triggerBuscaListaDe"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nome"
                item-value="id"
                :rules="[rules.required]"
                :label="`${$tc('label.de', 2)} *`"
                :placeholder="$tc('label.digite_para_buscar', 1)"
                clearable
                @click="() => buscarDivisoes(null, 'de')"
                :disabled="modoEdicao"
                return-object>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                id="posse_para"
                name="posse_para"
                v-model="posse.divisaoPara"
                :items="listaPara"
                :search-input.sync="triggerBuscaListaPara"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nome"
                item-value="id"
                :rules="[rules.required]"
                :label="`${$tc('label.para', 1)} *`"
                :placeholder="$tc('label.digite_para_buscar', 1)"
                return-object
                clearable
                @click="() => buscarDivisoes(null, 'para', this.posse.divisaoDe ? this.posse.divisaoDe.id : null)"
                :disabled="modoEdicao || !posse.divisaoDe">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                v-model="menuDtInicio"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :return-masked-value="true"
                    v-mask="'##-##-####'"
                    :rules="[rules.required]"
                    v-model="dtaInicioFormatada"
                    :label="`${$t('label.data_inicio')} *`"
                    :disabled="modoEdicao"
                    prepend-icon="event">
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  color="primary"
                  scrollable
                  @input="dtaInicioSelecionada"
                  v-model="posse.dtaInicio"
                  :disabled="modoEdicao"
                  :allowed-dates="dtaInicioValidas">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                v-model="menuDtFim"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :return-masked-value="true"
                    v-mask="'##-##-####'"
                    :rules="[rules.required]"
                    v-model="dtaFimFormatada"
                    :label="`${$t('label.data_fim')} *`"
                    prepend-icon="event">
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  color="primary"
                  scrollable
                  @input="menuDtFim = false"
                  v-model="posse.dtaFim"
                  :allowed-dates="dtaFimValidas"
                  :disabled="!permiteSalvar">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-text-field
                id="motivo"
                name="motivo"
                v-model="posse.motivo"
                :label="`${$t('label.motivo')} *`"
                :counter="100"
                :disabled="modoEdicao"
                :rules="[rules.required, rules.maxLength100]">
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="cancelar">{{ $t('label.cancelar') }}</v-btn>
          <v-btn
            :disabled="!permiteSalvar"
            @click.native="abrirDialogSalvar"
            color="primary">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
      <confirm
        ref="confirmDialog"
        :message="$t('message.deseja_salvar', {text: $t('label.transferencia_posse')})"
        :persistent="true"
        @agree="salvar">
      </confirm>
      <confirm
        ref="confirmUsuarioDialog"
        :message="$t('message.nao_existe_usuario_associado', { de: nomeDe, para: nomePara})"
        :persistent="true"
        @agree="salvar">
      </confirm>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import {
  parseDateYYYYMMDD,
  formatDateDDMMYYYY,
} from '../../common/functions/date-utils';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'TransferenciaPosseForm',
  components: {
    Confirm,
  },
  props: {
    somenteLeitura: true,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    dtaInicioFormatada: {
      get() {
        return this.posse.dtaInicio ? formatDateDDMMYYYY(this.posse.dtaInicio) : null;
      },
      set(newValue) {
        this.posse.dtaInicio = newValue ? parseDateYYYYMMDD(newValue) : null;
      },
    },
    dtaFimFormatada: {
      get() {
        return this.posse.dtaFim ? formatDateDDMMYYYY(this.posse.dtaFim) : null;
      },
      set(newValue) {
        this.posse.dtaFim = newValue ? parseDateYYYYMMDD(newValue) : null;
      },
    },
    permiteSalvar() {
      if (!this.modoEdicao) return true;
      if (!this.posse) return false;
      const { dtaFim } = this.posse;
      return moment(dtaFim, 'YYYY-MM-DD').isAfter(moment());
    },
  },
  data() {
    return {
      resources: this.$api.transferenciaPosse(this.$resource),
      listaDe: [],
      listaPara: [],
      tipo: 'de',
      posse: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        maxLength100: (v) => (v && v.length <= 100) || this.$t('message.limite_caracteres_excedido'),
      },
      triggerBuscaListaDe: null,
      triggerBuscaListaPara: null,

      menuDtInicio: false,
      menuDtFim: false,

      dialogForm: false,

      modoEdicao: false,
      nomeDe: null,
      nomePara: null,
    };
  },
  watch: {
    triggerBuscaListaDe(val) {
      if (this.posse.divisaoDe && this.posse.divisaoDe.nome === val) return;
      if (this.timeoutDe) {
        window.clearTimeout(this.timeoutDe);
      }
      this.timeoutDe = window.setTimeout(() => {
        if (val) {
          this.buscarDivisoes(val, 'de');
        }
      }, 500);
    },
    triggerBuscaListaPara(val) {
      if (this.posse.divisaoPara && this.posse.divisaoPara.nome === val) return;
      if (this.timeoutPara) {
        window.clearTimeout(this.timeoutPara);
      }
      this.timeoutPara = window.setTimeout(() => {
        if (val && this.posse.divisaoDe) {
          this.buscarDivisoes(val, 'para', this.posse.divisaoDe ? this.posse.divisaoDe.id : null);
        }
      }, 500);
    },
  },
  methods: {
    abrir(transferencia) {
      if (!transferencia) {
        this.posse = {
          id: null,
          divisaoDe: null,
          divisaoPara: null,
          motivo: '',
          dtaInicio: null,
          dtaFim: null,
        };

        this.modoEdicao = false;
      } else {
        this.posse = {
          ...transferencia,
          divisaoDe: {
            nome: transferencia.de,
            id: transferencia.idDe,
            existeUsuarioAssociado: transferencia.existeUsuarioAssociado,
          },
          divisaoPara: {
            nome: transferencia.para,
            id: transferencia.idPara,
          },
        };
        this.listaDe = [this.posse.divisaoDe];
        this.listaPara = [this.posse.divisaoPara];

        this.modoEdicao = true;
      }
      this.dialogForm = true;
    },
    dtaInicioSelecionada(dta) {
      this.menuDtInicio = false;
      const { dtaFim } = this.posse;
      if (!moment(dta, 'YYYY-MM-DD').isBefore(moment(dtaFim, 'YYYY-MM-DD'))) {
        this.posse.dtaFim = null;
      }
    },
    dtaInicioValidas(date) {
      const ontem = moment().subtract(1, 'day');
      return moment(date, 'YYYY-MM-DD').isAfter(ontem);
    },
    dtaFimValidas(date) {
      const mDate = moment(date, 'YYYY-MM-DD');
      if (this.modoEdicao) {
        return mDate.isAfter(moment());
      }

      const comparativo = this.posse.dtaInicio ? moment(this.posse.dtaInicio, 'YYYY-MM-DD') : moment();
      return mDate.isAfter(comparativo);
    },
    abrirDialogSalvar() {
      const param = {
        existeUsuarioAssociado: this.posse.divisaoDe.existeUsuarioAssociado,
        nomeDe: this.posse.divisaoDe.nome,
        nomePara: this.posse.divisaoPara.nome,
      };
      this.nomeDe = param.nomeDe;
      this.nomePara = param.nomePara;
      if (!this.$refs.form.validate()) return;

      if (!param.existeUsuarioAssociado) {
        this.$refs.confirmUsuarioDialog.open();
      } else {
        this.$refs.confirmDialog.open();
      }
    },
    alterarListas() {
      this.posse.divisaoDe = null;
      this.posse.divisaoPara = null;
      this.buscarDivisoes(null, 'de');
    },
    buscarDivisoes(busca = null, tipo = 'de', idNotIn = null) {
      const param = {
        nome: busca,
        tamanhoPagina: 20,
        idNotIn,
      };

      if (tipo === 'para') {
        param.ativo = true;
      }

      this.resources.listarDivisoes(param).then((response) => {
        if (tipo === 'de') {
          this.listaDe = Object.assign([], response.data.resposta);
        } else if (tipo === 'para') {
          this.listaPara = Object.assign([], response.data.resposta);
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    cancelar() {
      this.dialogForm = false;
    },
    salvar() {
      const param = {
        motivo: this.posse.motivo,
        dtaInicio: this.posse.dtaInicio,
        dtaFim: this.posse.dtaFim,
        existeUsuarioAssociado: this.posse.divisaoDe.existeUsuarioAssociado,
      };
      param.idDivisaoOrigem = this.posse.divisaoDe.id;
      param.idDivisaoDestino = this.posse.divisaoPara.id;

      let promise = null;
      if (this.modoEdicao) {
        const { idTransferencia } = this.posse;
        promise = this.resources.atualizarTransferencia({ idTransferencia }, param);
      } else {
        promise = this.resources.transferir(param);
      }

      promise.then(() => {
        this.$toast(this.$t('message.adicionado_confira_tabela'));
        this.$emit('atualizarGrid');
        this.dialogForm = false;
      }, (err) => {
        this.$error(this, err);
      });
    },
    setPara(item) {
      this.posse.divisaoPara = item;
    },
  },
};
</script>
