import { accessObjectViaString } from './helpers';

export const sortTruthy = (array, truthyIndex) => {
  array.sort(item => (item[truthyIndex]) ? -1 : 1); // eslint-disable-line
};

const sortComparison = (first, second, type, replaceNull = null) => {
  const auxFirst = replaceNull || first,
    auxSecond = replaceNull || second;

  if (auxFirst < auxSecond) {
    return (type === 'desc') ? -1 : 1;
  }
  if (auxFirst > auxSecond) {
    return (type === 'desc') ? 1 : -1;
  }
  return 0;
};

const extractSortableData = (data) => {
  const castDataToNumber = Number(data);
  if (Number.isNaN(castDataToNumber)) {
    if (typeof data === 'string') {
      return (data.indexOf(';') !== -1)
        ? data.replace(/[0-9:,;]/g, '').toLowerCase()
        : data.toLowerCase();
    }
    return data;
  }
  return castDataToNumber;
};

export const sortTableColumn = (array, sortObject, replaceNull = null) => {
  const { type } = sortObject;
  if (sortObject && typeof sortObject === 'object') {
    array.sort((a, b) => {
      const index = sortObject.name,
        first = extractSortableData(accessObjectViaString(a, index)),
        second = extractSortableData(accessObjectViaString(b, index));

      return sortComparison(first, second, type, replaceNull);
    });
  }
};
